.as-companies {
    @company-theme-color: var(--as-primary-color);
    @company-theme-sub-color: var(--as-secondary-color);
    @company-font-color: var(--as-base-text-color);

    .company-page-header {
        max-height: 55px;
        .as-global-header__languages{
            margin-right: 20px;
            margin-top: 20px
        }
        .contact-info-box--pc,
        .contact-info-box--mobile{
            margin-top: 5px;
            flex-direction: column;
            align-items: flex-end;
            .as-contact-info-mutable--pc,
            .as-contact-info-mutable--mobile{
                margin: 0;
                .as-contact-info-mutable__info{
                    &.company-mail-address{
                        .as-contact-info-mutable__icon{
                            &.icon-inquery{
                                background-image: url(/images/company-page/icon-inquiry.svg);
                                width: .8em;
                                height: .8em;
                                display: inline-block;
                                vertical-align: middle;
                            }
                        }
                    }
                    a{
                        color: #fff;
                    }
                }
            }
        }
        .contact-info-box--mobile{
            justify-content: flex-start;
            margin-right: 5px;
            max-width: 100%;
            height: 75px;
            .as-contact-info-mutable--mobile{
                max-width: 100%;
                .as-contact-info-mutable__info{
                    margin-left: 0;
                    &.company-mail-address{
                        max-width: 100%;
                        > a{
                            word-wrap: break-word;
                            display: flex;
                            align-items: center;
                            .as-contact-info-mutable__icon{
                                flex: 0 0 auto;
                            }
                            .as-contact-info-mutable__number{
                                font-size: 12px;
                                max-width: ~"calc(100% - 10px)";
                                white-space: normal;
                            }
                        }
                    }
                }
            }
        }
        .contact-info-box--pc{
            display: flex;
            justify-content: center;
            margin-right: 15px;
            height: 45px;
            .as-contact-info-mutable--pc{
                .as-contact-info-mutable__info{
                    margin: 0;
                    &.company-mail-address{
                        line-height: 16px;
                        padding-top: 0;
                        .as-contact-info-mutable__number{
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        .img-responsive{
            max-width: 100%;
            max-height: 55px;
            padding: 10px;
        }
        //レスポンシブ対応
        @media (max-width: @screen-sm-max){
            max-height: 85px;
            height: 85px;
            .as-global-header__menu{
                position: absolute;
                left: ~"calc(-100% + 15px)";
                top: 40px;
                .as-global-header__languages{
                    position: static;
                    .dropdown-menu{
                        -webkit-transform: translate(-42%,10%);
                        -moz-transform: translate(-42%,10%);
                        -ms-transform: translate(-42%,10%);
                        -o-transform: translate(-42%,10%);
                        transform: translate(-42%,10%);
                        &::before,
                        &::after{
                            left: 30%;
                        }
                    }
                }
            }
            .contact-info-box--mobile{
                display: flex !important;
            }
        }
        @media (max-width: @screen-xs-max){
            .as-global-header__menu{
                .as-global-header__languages{
                    .dropdown-menu{
                        -webkit-transform: translate(0%,10%);
                        -moz-transform: translate(0%,10%);
                        -ms-transform: translate(0%,10%);
                        -o-transform: translate(0%,10%);
                        transform: translate(0%,10%);
                    }
                }
            }
        }
    }

    .company-page-message {
        background-color: @company-theme-color;
        border-bottom: 4px solid @company-theme-sub-color;
        color: @company-font-color;
        font-size: 28px;
        font-weight: bold;
        .container{
            padding: 8px 3px;
        }
        //レスポンシブ対応
        @media (max-width: @screen-xs-max){
            font-size: 18px;
        }
    }

    .company-page-nav {
        background-color: rgba(60,60,60,0.8);
        font-weight: bold;
        .container{
            padding: 5px 0;
        }
        ul{
            display: flex;
            li{
                padding: 0 10px;
                border-left: 1px solid #ffffff;
                box-sizing: border-box;
                min-width: 150px;
                text-align: center;
                > a{
                    color: #ffffff;
                    font-size: 18px;
                }
                &:first-of-type{
                    border-left: none;
                }
                &.current-page{
                    > a{
                        text-decoration: underline;
                    }
                }
            }
        }
        //レスポンシブ対応
        @media (max-width: @screen-xs-max){
            ul{
                justify-content: space-between;
                li{
                    flex: 1 1 auto;
                    min-width: 100px;
                    > a{
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .as-global-header__body-wrapper{
        min-height: auto;
    }

    @media (max-width: @screen-xs-max){
        .as-header,
        .as-global-header__body{
            border: none;
        }
    }
}