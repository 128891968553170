// Modals

// Use in root element of modal
.modal-responsive-layout() {

  // Landscape style (Mobile and PC)
  &.modal-landscape {

    .modal-content {
      .display-flex;
      .flex-flow(row,wrap);
    }

    .modal-header {
      .flex(0,0,auto);
      width: 100%;
      margin-bottom: 10px;
    }

    .modal-subheader{
      .flex(0,0,auto);
      width: 100%;
    }

    .modal-body {
      .flex(1,1,auto);
      width: 50%;
      padding-top: 0;
      padding-bottom: 10px;
    }

    .modal-footer {
      .flex(1,1,auto);
      width: 50%;
      .align-self(center);
    }
  }

  // Portrait style (Mobile and PC)
  &.modal-portrait {
  }

}
