@company-theme-color: var(--as-primary-color);
@company-theme-sub-color: var(--as-secondary-color);
@company-font-color: var(--as-base-text-color);


.seller-company-page-edit{
    width: 100%;
    margin: auto;

    .h2, h2{
        margin-bottom: 0;
    }

    .v-application{
        padding: 0 20px;
        background-color: transparent;
    }

    .edit-part-label{
        display: inline-block;
        font-size: 24px;
        font-weight: normal;
    }

    .section-label{
        font-size: 18px;
        font-weight: bold;
        &.language-selection{
            position: absolute;
            & + div{
                & > div:not(.language-impact-area){
                    margin-left: auto;
                    margin-top: 10px;
                }
            }
        }
    }

    .v-chip{
        margin-top: -8px;
        margin-bottom: 0;
        .v-chip__content{
            height: 28px;;
        }
    }

    .v-input--selection-controls{
        margin-top: 0;
        .v-label{
            margin-bottom: 0;
            color: inherit;
        }
        &:not(.v-input--hide-details){
            .v-input__slot{
                margin-bottom: 0;
            }
        }
    }

    .language-impact-area{
        background-color: #f4f4f4;
        padding: 10px;
    }

    .v-text-field{
        padding-top: 0;
        margin-top: 0;
        &.v-text-field--enclosed{
            .v-text-field__details{
                margin-bottom: 0;
            } 
        }
    }

    .v-messages{
        min-height: auto;
    }

    .v-list-item {
        .v-list-item__title {
            font-size: 1em;
        }
    }

    .horizontal-list{
        & > div{
            margin: 5px 0;
        }
    }

    .theme-image{
        display: inline-block;
        margin-left: 10px;
        border: 1px solid #444444;
    }

    .feature-item-box{
        display: flex;
        flex-wrap: wrap;
        & > div{
            flex: 1 1 ~"calc(50% - 20px)";
            width: ~"calc(50% - 20px)";
            margin-bottom: -10px;
            &:nth-child(2n+1){
                margin-right: 10px;
            } 
            &:nth-child(2n){
                margin-left: 10px;
            } 
            .v-input__append-outer {
                margin-left: 5px;
                margin-top: 24px;
            }
            .v-text-field{
                & > .v-input__control{
                    & > .v-input__slot{
                        & > .v-text-field__slot{
                            .v-label{
                                transform: translateY(-18px) scale(.75) !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .image-list-item{
        padding: 0 10px;
        & > div{
            margin-top: 5px;
            .v-chip{
                position: absolute;
                right: 0;
                background-color: transparent;
                margin: 0;
                .v-chip__content{
                    padding: 0;
                    .v-icon{
                        font-weight: bold;
                        text-shadow: 1px 0 1px #fff, 0 1px 1px #fff, -1px 0 1px #fff, 0 -1px 1px #fff;
                    }
                }
            }
        }
        .image-label{
            font-weight: bold;
        }
    }

    hr{
        width: 100%;
        margin-bottom: 0;
    }

    //レスポンシブ対応
    @media (max-width: @screen-sm-max){
        .section-label{
            &.language-selection{
                position: static;
                & + div{
                    & > .layout{
                        justify-content: flex-start;
                        margin-top: 0;
                    }
                }
            }
        }

        .image-list-item{
            padding: 10px;
        }

        .feature-item-box{
            & > div{
                flex: 1 1 100%;
                width: 100%;
                &:nth-child(2n+1),
                &:nth-child(2n){
                    margin: 0 0 -10px 0;
                }
            }
        }

        .btn-primary{
            width: 100%;
            margin: 20px 0 0 0;
        }
    }

    /*vuetify override*/
    /*button*/
    .btn-primary{
        color: #fff;
        background-color: #4a4a4a !important;
        box-shadow: inset 0 -2px 0 #2f2f2f;
        transition: none;
        margin-top: 20px;
        &:hover{
            background-color: #009fd3 !important;
            box-shadow: inset 0 -2px 0 #0078a1;
        }
    }

    /*input*/
    input[type="text"]:not([readonly="readonly"]),
    input[type="tel"],
    input[type="email"],
    textarea{
        border: 1px solid #bebebe;
        background-color: #fff;
        box-shadow: inset 0 1px 1px rgba(0,0,0,0.1);
        padding: 5px;
    }

    .v-text-field{
        &:not(.v-select) > .v-input__control{
            margin-top: 20px;
            & > .v-input__slot{
                margin-bottom: 2px;
                &::before,
                &::after{
                    display: none;
                }
                & > .v-text-field__slot{
                    .v-label{
                        color: rgba(0,0,0,.87);
                        transform: translateY(-25px) scale(1) !important;
                    }
                }
            }
            & > .v-text-field__details{
                margin-bottom: 6px;
            }
        }
    }

    .primary--text{
        color: inherit !important;
        caret-color: auto !important;
    }

    .v-select.v-text-field--enclosed:not(.v-text-field--single-line){
        .v-select__selections{
            padding: 2px 0;
        }
    }

    .v-text-field--outlined{
        >.v-input__control{
            >.v-input__slot{
                margin-bottom: 2px;
                background-color: #fff !important;
                border-radius: 0;
                min-height: 30px;
                &:hover{
                    background-color: #ccc !important;
                }
            }
            & > .v-text-field__details{
                margin-bottom: 6px;
            }
        }
        fieldset {
            border-color: #444;
        }
    }

    .v-text-field.v-text-field--enclosed{
        .v-input__append-inner{
            margin: auto;
        }
    }

}
