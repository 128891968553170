// Heading

.as-heading--center {
  margin-bottom: 0;
  color: @as-heading-text-color-primary;
  text-align: center;

  > h1{
    font-size: @font-size-h1 + 10px;
  }
  > p{
    font-size: @font-size-h1;
  }
}

.as-heading--default {
  &, &.panel-heading, &.modal-header {
    .as-heading__base;
    margin-bottom: 0;
    color: @as-heading-text-color-default;
    background-color: @as-brand-base;

    > p {
      margin-bottom: 0;
    }
  }
}

.as-heading--primary {
  &, &.panel-heading, &.modal-header {
    .as-heading__base;
    margin-bottom: 0;
    color: @as-heading-text-color-primary;
    font-weight: bold;
    .horizontal-gradient(@as-brand-key-gradient-start, @as-brand-key-gradient-end);

    > p {
      margin-bottom: 0;
    }
  }
}

.as-heading--page-title {
  &, &.panel-heading, &.modal-header {
    .as-heading__base;
    min-height: 0;
    margin-top: 1px;
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    color: @text-color;
    border-bottom: 4px solid @as-brand-key;

    @media (max-width: @screen-xs-max) {
      padding-right: @as-grid-gutter-width / 2;
      padding-left: @as-grid-gutter-width / 2;
    }
  }
}

.as-heading--group-title {
  &, &.panel-heading, &.modal-header {
    .as-heading__base;
    margin-bottom: 0;
    min-height: initial;
    padding: 9px 0 2px 0;
    color: @as-heading-group-title-color;
    font-weight: normal;
    border-bottom: 1px dotted @as-heading-group-border-color;

    > h1, h2, h3, h4, h5, h6, p {
      font-size: @font-size-h6;
    }
    h1&, h2&, h3&, h4&, h5&, h6&, p& {
      font-size: @font-size-h6;
    }
  }
}

.as-heading--widget-title {
  &, &.panel-heading, &.modal-header {
    .as-heading__base;
    min-height: 32px;
    margin-bottom: 0;
    padding-left: 0;
    padding-bottom: 0;
    padding-right: 0;
    color: @as-brand-emphasis;

    p, .as-heading__actions p {
      margin-bottom: 0;
    }
  }

  .as-heading__actions{

    .as-heading__see-more{
      margin-bottom: 0;
    }

  }

}


.as-heading--paragraph-title {
  &, &.panel-heading, &.modal-header {
    .as-heading__base;
    min-height: initial;
    // margin-bottom: 5px;
    padding: 5px 0 2px 0;
    color: @as-heading-caption-title-color;
    font-weight: bold;
    border-bottom: 1px solid @as-heading-caption-border-color;
  }
}


// Option parts
.as-heading__actions {
  a.btn {
    margin-left: 5px;

    &:first-child {
      margin-left: 0;
    }
  }
}


// Options
.as-heading--hidden-text--xs {
  @media (max-width: @screen-xs-max) {
    &:extend(.as-heading--hidden-text all);
    .as-heading--hidden-text;
  }
}


// Compatible with screen readers
.as-heading--hidden-text {
  height: 0 !important;
  min-height: 0 !important;
  overflow: hidden !important;
  margin: 0 !important;
  padding: 0 !important;
  background: none !important;
  border: none !important;
}
.as-heading--hidden-text--xs {
  @media (max-width: @screen-xs-max) {
    &:extend(.as-heading--hidden-text all);
    .as-heading--hidden-text;
  }
}
