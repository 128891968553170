// For Machine Spec Page

.as-groups-product-spec__header {
}

.as-groups-product-spec__body {
  padding: 25px 15px 25px 15px;

  .as-flexible-table--minimum {
    .as-row--no-gutter;

    th {
      font-weight: bold;
    }
  }
}

.as-groups-product-spec__image {
  .img-responsive;
  .as-image--default;
  max-height: 200px;
}

.as-groups-product-spec__image-wrapper {
  margin-bottom: 20px;
  text-align: center;

  img {
    display: inline-block;
  }
}

.as-groups-product-spec__lead-info {
  min-height: 45px;

  > p {
    display: inline-block;
  }

  label {
    margin-right: 5px;
  }

  .as-groups-product-spec__lead-info-group {
    display: table-cell;
    vertical-align: middle;

    > * {
      margin-bottom: 5px;
    }

    .badge-large {
      margin-right: 10px;
    }
  }
}

@media (max-width: @screen-xs-max) {
  .as-groups-product-spec__header {

    &.row {
      .as-row--no-gutter;
    }

    &.row > *[class*="col-"] {
      .as-col--no-gutter;
    }

    .as-heading--page-title {
      margin-right: -@as-grid-gutter-width / 2;
      margin-left: -@as-grid-gutter-width / 2;
    }

  }
  .as-search-topic__contents {
    margin-bottom: 0;
  }
  .as-groups-product-spec__image {
    max-height: 150px;
  }
}

@media (max-width: @screen-md-min) {
  .as-groups-product-spec__body {
    padding: 15px 0 15px 0;
  }
}
