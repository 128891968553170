// Search Conditions

.as-search-conditions {
  margin: 0;

  ul& {
    padding-left: 0;
  }
}

.as-search-conditions__menu {
  display: block;
}

.as-search-conditions__menu-title {
  display: block;
  // position: relative;
  padding: 10px 15px;
  font-size: @font-size-h4;
  font-weight: bold;
  color: @as-search-conditions-menu-text-color;
  // Place the border on the list items and negative margin up for better styling
  // margin-bottom: -1px;
  background-color: @as-search-conditions-menu-background-color;
  border-top: 1px solid @as-area-border-color;
  // border: 1px solid @list-group-border;

  a&:hover,
  a&:focus {
    color: @as-search-conditions-menu-text-color;
    text-decoration: none;
  }

  .as-search-conditions > .as-search-conditions__menu:first-child & {
    border-top-color: transparent;
  }

  // for collapse menu
  &[data-toggle="collapse"] {
    padding: 10px 15px 10px 26px;
    cursor: pointer;
    .as-icon--collapse-in;
  }
  &[data-toggle="collapse"].collapsed {
    .as-icon--collapse;
  }
}

.as-search-conditions__submenus {
  padding: 5px 15px 5px 15px;
  border-top: 1px solid @as-area-border-color;
  background-color: @as-search-conditions-submenu-background-color;



  > *:first-child:last-child {
    margin-top: 9px;
    margin-bottom :10px;
  }



  .as-selector,
  // .as-selector__toggle,
  .bootstrap-select.btn-group .dropdown-toggle {
    height: (@line-height-computed + (@padding-base-vertical * 2) + 3);  // Override with Bootstrap variables

  }

  .bootstrap-select.btn-group .dropdown-toggle {
    //bugfix cases1405
    overflow: hidden;
  }

  .as-search-conditions__footer {

  }
}

.as-search-conditions__subitems{
  display: block;
  padding-bottom: 10px;

  .as-search-conditions__menu-subtitle{
    display: block;
    font-size: @font-size-h5;
    font-weight: bold;
    padding: 5px 0px;
  }
}

.as-search-conditions__submenus > ul {
  padding: 0;

  & > li {
    display: block;
    font-size: @font-size-base;
    padding: 8px 0;



    input {
      margin-right: 5px;
    }

    // for bootstrap badge
    .badge {
      position: absolute;
      right: 0;
      margin-right: 25px;
    }
  }
}

.as-search-conditions__submenu {

  a {
    display: flex;
    margin: -8px -15px -8px -15px;
    padding-left: 25px;
    padding-right: 15px;
    padding-top: 8px;
    padding-bottom: 8px;
    cursor: pointer;

    &:hover {
      background: @as-search-conditions-menu-background-color;
    }

    > span:first-child {
      width: 100%;
    }
  }

  &:first-child a {
    padding-left: 15px;

    .badge {
      background: none;
      border: solid 1px @as-search-conditions-submenu-badge-border-color;
      color: @as-search-conditions-menu-text-color;
    }
  }

 .badge {
    margin-left: 3px;
  }

  &.selected {
    display: block;
    margin: 0 -15px;
    padding-left: 15px;
    padding-right: 15px;
    background: @as-search-conditions-menu-background-color;
    font-weight: bold;

    a {
      color: @as-search-conditions-menu-text-color;
      text-decoration: none;
    }

  }
}

.as-search-conditions__clear {
  display: block;
  margin-top: 15px;
  margin-bottom: 5px;
}

.as-search-conditions__footer {
  padding-top: 4px;
  padding-bottom: 1px;
  text-align: right;
}


// for #as-searchOption (mobile)
#as-searchOption {
  .modal-body {
    padding: 0;

    .as-search-conditions {
      .as-search-conditions__menu:last-child {
        border-bottom: 1px solid @as-area-border-color;
      }
    }
  }

  // Style bootstrap-select
  // Make dropdown-menu as static element
  .as-selector {
    height: auto;

    .dropdown-menu.open {
      position: initial;
      z-index: initial;

      &, & .dropdown-menu.inner {
        max-height: initial !important;
        overflow: initial !important;
        min-height: initial !important;
      }

      // Style
      margin-top: 0;
      .box-shadow(none);
      border-radius: 0;
    }

    .dropdown-backdrop {
      z-index: initial;
    }
  }
  .as-selector.as-multiple-selector {
    .bs-donebutton button {
      .btn-default;
    }
  }
}


@media (min-width:@screen-sm-min) and (max-width:@screen-md-min) {

  // style for price dropdown on pc
  select#as-price_r {
    padding-top: 7px;
    font-size: @font-size-base - 2px;  //12px

    option {
      font-size: @font-size-base;
    }
  }
}


// New labels
.label-allstocker {
    background-color: #ffcb3d;
    color:#333;
    margin-left:5px;
    font-size:12px;
}
