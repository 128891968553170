//for prominent wrapper around download link of inspection report
.as-download-link {

  .as-download-link__text　{

    .as-download-link__caption {
    }
    .as-download-link__link {
    }

  }

}


.as-download-link {
  display: block;

  a&:hover {
    opacity: 0.75;

    .as-download-link__text {

      .as-download-link__caption {
        &, & span {
          text-decoration: none;
        }
      }

      .as-download-link__link {
      }
    }

  }

}

.as-download-link__text　{
  display: block;
}

.as-download-link__caption {
  text-decoration: none;
}

.as-download-link__link {
  a & {
    text-decoration: underline;
  }
}

.as-marketplace-download-conditionreport {
  &.btn {
    text-align: left;
  }
}