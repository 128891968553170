// Search Result

// Goods Search Result

@as-search-list-goods-image-small-max-width: 120px;
@as-search-list-goods-image-small-max-height: 90px;
@as-search-list-goods-image-max-width: 200px;
@as-search-list-goods-image-max-height: 150px;
@as-search-list-goods-vertical-padding: 15px;

.as-search-list-goods__list {
  ul&, ul {
    list-style: none;
  }
  p {
    margin-bottom: 5px;
  }
  min-height: 180px * 1;
  padding: 10px 0;

  .as-loading-view;

}

.as-search-list-goods__item {
  position: relative;
  padding-top: @as-search-list-goods-vertical-padding;
  padding-bottom: @as-search-list-goods-vertical-padding;
  border-bottom: 1px solid @as-sub-border-color;

  &:first-child {
    border-top: 1px solid @as-sub-border-color;
  }

  &.as-item-sold-out {
    .as-search-list-goods__wrapper {
      background-color: @as-search-list-sold-out-background-color;
    }
  }

  &.as-hover {
    cursor: pointer;

    .as-search-list-goods__wrapper {
      background-color: @as-brand-hover;
    }
  }

  // for excluded goods
  &.as-item-dimmed {

    &:after {
      content: "";
      position: absolute;
      top: @as-search-list-goods-vertical-padding;
      right: 0;
      bottom: @as-search-list-goods-vertical-padding;
      left: 0;
      // background-color: rgba(191,191,191,0.65);
      background-color: rgba(210,210,210,0.65);
      z-index: 1;
    }

    // Pop only action button
    .as-search-list-goods__body[class*="col-"] {
      position: static;
    }
    .as-search-list-goods__actions {
      position: relative;
      z-index: 2;
    }

    &:active:after,&:hover:after {
      display: none;
    }

  }

  //Show notification for dimmed item
  .as-search-list-goods__label--is-dimmed {
    z-index: 2;
    position: absolute;

    //Flap
    @as-search-list-goods__label--is-dimmed-flap-width: 5px;
    padding: 2px 7px;
    // color: #fff;
    color: @as-label-moderate-color;
    font-size: 10px;
    font-weight: bold;
    background-color: @as-label-moderate-background-color;
    box-shadow: 0  1px 1px rgba(0, 0, 0, 0.25);

    &:before {
      content: " ";
      position: absolute;
      border-style: solid;
      border-color: transparent;
    }

    //Top
    @media (max-width:@screen-xs-max) {
      top: @as-search-list-goods-vertical-padding - @as-search-list-goods__label--is-dimmed-flap-width;
      left: 0;

      &:before {
        top: 0;
        right: -(@as-search-list-goods__label--is-dimmed-flap-width);
        border-width: @as-search-list-goods__label--is-dimmed-flap-width 0 0 5px;
        border-right-color: transparent;
        border-left-color: darken(@as-label-moderate-background-color, 20%);
      }
    }

    //Left
    @media (min-width:@screen-sm-min) {
      top: @as-search-list-goods-vertical-padding;
      left: -(@as-search-list-goods__label--is-dimmed-flap-width);

      &:before {
      	top: 100%;
      	left: 0;
      	width: 0;
      	height: 0;
      	border-width: 0 @as-search-list-goods__label--is-dimmed-flap-width 5px 0;
      	border-right-color: darken(@as-label-moderate-background-color, 20%);
      }
    }

    .as-search-list-goods__item:active&,
    .as-search-list-goods__item:hover& {
      opacity: 0.5;
    }
  }

  > a {
    color: @text-color;
  }

}

.as-search-list-goods__wrapper {
  &.row {
    .as-row--no-gutter;
  }
  display: block;

}

.as-search-list-goods__image {
  padding-right: 0;
  padding-left: 0;


  // for customising bootstrap col width
  @media (min-width: @screen-sm-min) {
    width: @as-search-list-goods-image-small-max-width;
    height: @as-search-list-goods-image-small-max-height;
  }
  @media (min-width: @screen-md-min) {
    width: @as-search-list-goods-image-max-width;
    height: @as-search-list-goods-image-max-height;
  }
}

.as-search-list-goods__image-wrapper {
  .as-responsible-image-frame(4,3);
  background-color: @as-image-background-color;
  margin-bottom: 5px;
}

.as-search-list-goods__body {
  .display-flex;
  .flex-flow(column,nowrap);
  padding-right: 15px; //TODO

  // for customising bootstrap col width
  @media (min-width: @screen-sm-min) {
    width: ~"calc(100% - @{as-search-list-goods-image-small-max-width})";
    // height: 90px;
  }
  @media (min-width: @screen-md-min) {
    width: ~"calc(100% - @{as-search-list-goods-image-max-width})";
  }
  .as-search-list-goods__infos{
    li{
      font-size: 0.9em;
      margin-left:18px;
      list-style-type:disc;
    }
  }
}

.as-search-list-goods__title {
  .as-responsible-font-size(h2);
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 10px;
  word-break: break-word;
  word-wrap: break-word;  // for IE
}

.as-search-list-goods__category {
  font-size: @font-size-h6;
  word-break: break-word;
  word-wrap: break-word;  // for IE

 p& {
   margin-bottom: 0;
 }
}

.as-search-list-goods__price {
  .text-price;
  .as-responsible-font-size(h2);
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 10px;
  text-align: right;
}

.as-search-list-goods__currency {
  .text-currency;
  margin-right: 0.2em;
  // font-size: @font-size-h5;
}


.as-search-list-goods__flags {

}

.as-search-list-goods__features {

}

.as-search-list-goods__other-features {
  ul {
    padding: 0;
    .as-clearfix;

    & > li {
      float:left;
      margin-right: 10px;
      padding: 0;
      min-width: 4em;
    }
  }
  dl {
    margin-bottom: 0;
  }
  dl, dt, dd {
    white-space: nowrap;
  }
}

.as-search-list-goods__other-features-head {
  display: inline-block;
  vertical-align: baseline;
}

.as-search-list-goods__other-features-value {
  display: inline-block;
  vertical-align: baseline;
}

.as-search-list-goods__details {
}

.as-search-list-goods__actions {
  margin-top: 10px;
  text-align: right;

  &, & > *[class*="col-"] {
    .flex(1,1,auto);
    .display-flex;
    .flex-flow(column,nowrap);

    > * {
      .flex(1,1,auto);
    }
  }

  .as-search-list-goods__actions-container {
    .display-flex;
    .flex-flow(row,wrap);
    .justify-content(flex-end);
    .align-items(flex-end);
    margin-bottom: 10px;

    .btn {
      .flex(0,0,auto);
    }
  }

  .btn {
    .as-item-sold-out & {
      // Bootstrap default button style
      .btn-default;
    }
  }
}

// Mouse hover appearance
.as-hover {
  .as-search-list-goods__actions {
    p > a.btn-primary:last-child {
      .button-hover-appearance(@btn-primary-color-focus, @btn-primary-bg-focus, @btn-primary-shadow-focus);
    }
  }
  .as-search-list-goods__title {
    color: @as-brand-transition;
    text-decoration: underline;
  }
}

// When result is nothing
.as-search-list-goods__empty {
  margin: 30px 0 60px;
  text-align: center;
  font-weight: bold;
}

.as-search-list-goods__contact-info {
  font-size: 2em;
  font-weight: bold;
}


@media (max-width: @screen-xs-max) {

  .as-search-list-goods__list {
    min-height: initial;
  }

  .as-search-list-goods__other-features {
    ul {
      & > li {
        //country
        &:nth-child(3) {
          clear: both;
        }
      }
    }
  }

}


// Model Search Result

@as-search-list-models-vertical-padding: @as-search-list-goods-vertical-padding + 5px;
@as-search-list-models-horizontal-padding: 15px;
@as-search-list-models-thumbnails-horizontal-margin: 5px;

.as-search-list-models__list {
  ul&, ul {
    list-style: none;
  }
  min-height: 180px * 1;
  padding: 10px 0;

  .as-loading-view;

}

.as-search-list-models__item {
  position: relative;
  border-bottom: 1px solid @as-sub-border-color;

  &:first-child {
    border-top: 1px solid @as-sub-border-color;
  }

  &.as-item-out-of-stock {
    background-color: @as-search-list-out-of-stock-background-color;

    .as-search-list-models__thumbnails {
      display: none;
    }

  }

  &.as-hover {
    background-color: @as-brand-hover;
    cursor: pointer;
  }

  > a {
    color: @text-color;
  }
}

.as-search-list-models__wrapper {
  padding-top: @as-search-list-models-vertical-padding;
  padding-right: @as-search-list-models-horizontal-padding;
  padding-bottom: @as-search-list-models-vertical-padding;
  padding-left: @as-search-list-models-horizontal-padding;
}

.as-search-list-models__body {
  .display-flex;
  .align-items(stretch);
}

// Features
.as-search-list-models__features {
 p {
   margin-top: 0;
   margin-bottom: 0;
 }
}

.as-search-list-models__category {
  font-size: @font-size-h6;
  word-break: break-word;
  word-wrap: break-word;  // for IE
}

.as-search-list-models__title {
  .as-responsible-font-size(h2);
  line-height: 1.2;
  font-weight: bold;
  word-break: break-word;
  word-wrap: break-word;  // for IE

  .as-search-list-models__title-maker {
    .as-responsible-font-size(h4);
  }

  p& {
    margin-bottom: 4px;
  }

  a {
  //  color: inherit;
  }
}

.as-search-list-models__price-wrapper {
  text-align: right;
  margin-top: 5px;
  margin-bottom: 0;
}

.as-search-list-models__price-head {
  display: inline-block;
  font-size: @font-size-h6;
  color: @as-text-term-color;
}

.as-search-list-models__price-value {
  display: inline-block;
  min-width: 8em;
  .text-price;
  .as-responsible-font-size(h4);
  font-weight: bold;
  text-align: right;

  p& {
    margin- top: 4px;
  }
}

.as-search-list-models__currency {
  .text-currency;
  margin-right: 0.2em;
  font-size: @font-size-h6;
}

// Other Features
.as-search-list-model__other-features {

}

// Actions
.as-search-list-models__actions {

  &, & > p {
    .display-flex;
    .align-items(stretch);
  }

  & {
    .justify-content(flex-end);
  }

  > p {
    margin-bottom: 0;
  }

  &:before {
    content: "";
    display: block;
    width: 0;
    margin-right: 15px;
    border-left: 1px dotted @as-sub-border-color;
  }

}

.as-search-list-models__thumbnails {
  ul&, ul {
    padding-left: 0;
    list-style: none;
  }

  position: relative;
  .flex(1,1,auto);
  .display-flex;
  .flex-flow(row,wrap);
  margin-right: 10px;
  background-color: @as-search-list-models-thumbnails-background-color;
  border-right: solid 5px @as-search-list-models-thumbnails-background-color;
  border-radius: @border-radius-base;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: -13px;
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    border: 0 solid transparent;
    border-top-width: 13px;
    border-right-width: 0;
    border-bottom-width: 13px;
    border-left: 8px solid @as-search-list-models-thumbnails-background-color;
  }
}

.as-search-list-models__thumbnail {
  .flex(0,1,(100% / 3));
  max-width: (100% / 3);
  padding-left: @as-search-list-models-thumbnails-horizontal-margin;
}

.as-search-list-models__thumbnail-container {
  .as-responsible-image-frame(4,3);
  background-color: @as-image-background-color;
  border: 1px solid #ccc;
}

.as-search-list-models__see-goods {
  .btn {
    .display-flex;
    .align-items(center);
    .flex-flow(row,wrap);
    .justify-content(center);
    .as-responsible-font-size(h5);

    // Fix for each laugage

    html:lang(ja) & {
      padding-left: 8px;
      padding-right: 8px;
      & > span {
        .flex(1,1,auto);
        .display-flex;
        .flex-flow(row,wrap);
        .justify-content(center);
        .align-items(baseline);
      }
      & > span > span:nth-child(1) {  // "1件"
        .flex(0,0,auto);
        min-width: 2.8em;
        text-align: center;
      }
      & > span > span:nth-child(2) {  // "の出品を見る"
        .flex(0,0,auto);
        text-align: center;
      }
      & > span > span > span:nth-child(1) {  // "1"
        margin-right: 0.2em;
        text-align: right;
      }
    }

    html:lang(en) &,
    html:lang(vi) &,
    html:lang(th) & {
      padding-left: 10px;
      padding-right: 10px;
      & > span {
        .flex(1,1,auto);
        .display-flex;
        .flex-flow(column,nowrap);
      }
      & > span > span:nth-child(1) {  // "Show"
        .flex(1,1,auto);
      }
      & > span > span:nth-child(2) {  // "1 item"
        .flex(1,1,auto);
        .display-flex;
        .flex-flow(row,nowrap);
        .justify-content(center);
        .align-items(baseline);
      }
      & > span > span > span:nth-child(1) {  // "1"
        .flex(0,0,auto);
        text-align: right;
        margin-right: 0.2em;
      }
      & > span > span > span:nth-child(2) {  // "item"
        .flex(0,0,auto);
        min-width: 2.3em;
        text-align: left;
      }
    }

    .as-item-out-of-stock & {
      // Bootstrap default button style
      .btn-default;
    }
  }

  @media (max-width:(@screen-md-min - 1px)) {
    &, .btn {
      .flex(1,1,auto);
    }
  }
}

.as-search-list-models__goods-amount {
  // position: relative;
  margin-right: 3px;
  // bottom: 0.15em;
  .as-responsible-font-size(h2);
  font-weight: bold;
  // line-height: 1;
}

// Responsive

@media (max-width: @screen-xs-max) {
  .as-search-list-panel {
    & > .row > *[class*="col-"] {
      .as-col--no-gutter;
    }
  }
}

@media (min-width: @screen-sm-min){
  .as-search-list-models__features[class*="col-"] {
    padding-right: 15px;
  }

  .as-search-list-models__actions[class*="col-"] {
    padding-left:  0;
  }
}

@media (min-width: @screen-sm-min) and (max-width: @screen-md-min) {
  .as-search-list-goods__actions {
    p {
      margin-bottom: 0;
    }
  }
  .as-search-list-models__thumbnails {
    .flex-flow(row,wrap);
    padding-right: 0;
    padding-left: 0;
    padding: 2px;
  }
  .as-search-list-models__thumbnail {
    .flex(0,1,(floor(100% / 2)));
    .align-self(center);
    &:nth-child(n+1) {
      padding: 2px;
    }
  }
}

@media (min-width: @screen-md-min) and (max-width: @screen-lg-min) {
  .as-search-list-goods__actions {
    p {
      margin-bottom: 0;
    }
  }
  .as-search-list-models__thumbnail {
    .flex(0,1,(floor(100% / 3)));
    .align-self(center);
  }
  .as-search-list-models__see-goods {
    .btn {
      width: 150px;
    }
  }
}

@media (min-width: @screen-lg-min) {
  .as-search-list-models__thumbnail {
    .flex(0,1,(floor(100% / 3)));
    .align-self(center);
  }
  .as-search-list-models__see-goods {
    .btn {
      width: 170px;
    }
  }
}
