// Tables

.table {
  // Cells
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th {
        color: @as-text-term-color;
      }
      > td {
        color: @as-text-description-color;
        line-height: 1.7;
      }
    }
  }
}
