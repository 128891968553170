// Search List Auction Bids
// Using in Bidding List Page

@as-search-list-goods-auction-bid-image-min-width: 90px;

// Column width
@as-search-list-goods-auction-bid-column-width-1: 18%;   // Item
@as-search-list-goods-auction-bid-column-width-2: 12%;   // Time left
@as-search-list-goods-auction-bid-column-width-3: 8%;   // Watch
@as-search-list-goods-auction-bid-column-width-4: 8%;   // Bids
@as-search-list-goods-auction-bid-column-width-5: 17%;  // Current Price
@as-search-list-goods-auction-bid-column-width-6: 19%;  // Highest bidder
@as-search-list-goods-auction-bid-column-width-7: 17%;  // Bid amount

// Override .as-search-list-goods
.as-search-list-goods--auction-bid {

  // List header
  // Only show on PC view
  .as-search-list-goods__list-header {
    .display-flex;
    .flex-flow(row,nowrap);
    .align-items(center);
    margin-left: @as-search-list-goods-auction-bid-image-min-width + @as-grid-gutter-width + @as-grid-gutter-width / 2;
    margin-right: @as-grid-gutter-width / 2;
  }

  .as-search-list-goods__list-header-wrapper--fixed {

    @media (min-width: @screen-sm-min) {width: @container-sm - @as-grid-gutter-width; }
    @media (min-width: @screen-md-min) {width: @container-md - @as-grid-gutter-width; }
    @media (min-width: @screen-lg-min) {width: @container-lg - @as-grid-gutter-width; }
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: auto;
    padding-top: 15px;
    padding-bottom: 10px;
    background-color: @as-brand-background ;
    border-bottom: 1px solid @as-sub-border-color;
  }

  .as-search-list-goods__list-header-item {
    margin-bottom: 5px;
    padding: 0;
    color: @as-text-term-color;
    font-size: @font-size-h5;
    font-weight: bold;
    line-height: 1;
    text-align:center;
    &:nth-child(1) {
      position: relative;
      left: -(@as-search-list-goods-auction-bid-image-min-width + @as-grid-gutter-width);
    }
    &:nth-child(1) { .flex(0,0,auto); width:@as-search-list-goods-auction-bid-column-width-1; text-align: left;}  // Item
    &:nth-child(2) { .flex(1,0,auto); width:@as-search-list-goods-auction-bid-column-width-2; }  // Time left
    &:nth-child(3) { .flex(0,0,auto); width:@as-search-list-goods-auction-bid-column-width-3; }  // watch
    &:nth-child(4) { .flex(0,0,auto); width:@as-search-list-goods-auction-bid-column-width-4; }  // Bids
    &:nth-child(5) { .flex(0,0,auto); width:@as-search-list-goods-auction-bid-column-width-5; }  // Current Price
    &:nth-child(6) { .flex(0,0,auto); width:@as-search-list-goods-auction-bid-column-width-6; }  // Highest bidder
    &:nth-child(7) { .flex(0,0,auto); width:@as-search-list-goods-auction-bid-column-width-7; }  // Bid amount
  }

  .as-search-list-goods__item {
    cursor: pointer;

    &.as-sale-ending-soon {
      .as-search-list-goods__time-left {
        &, & .as-search-list-goods__other-features-value {
          color: @as-brand-attention;
        }
      }
    }
    &.as-sale-ended  {
      background-color: @as-search-list-sold-out-background-color;
    }
    &.as-outbid {
      background-color: lighten(spin(saturate(@as-brand-attention, 90%), 7%), 45%);
    }
    &.as-winning {
      background-color: lighten(spin(saturate(@brand-success, 20%), -39%), 40%);
    }
    &:hover {
      background-color: @as-brand-hover;
    }
    &.as-sale-old-event {
      cursor: default;
    }
  }
  .as-search-list-goods__wrapper {
    .display-flex;
  }
  .as-search-list-goods__header,
  .as-search-list-goods__body {
    width: auto;
    height: auto;
  }
  .as-search-list-goods__header {
    .flex(0,0,auto);
    width: (@as-search-list-goods-auction-bid-image-min-width + @as-grid-gutter-width);
    background-color: transparent;

    .as-search-list-goods__auction-features {
      font-size: @font-size-h5;
      padding: 0;
    }
  }
  .as-search-list-goods__body {
    .flex(1,1,auto);
    padding-right: @as-grid-gutter-width / 2;
    .background-image-icon("../images/icon-arrow-right.svg", 15px, 15px, 5px, 25px, right);
  }
  .as-search-list-goods__header {
    padding-right: @as-grid-gutter-width / 2;
    padding-left: @as-grid-gutter-width / 2;
  }
  .as-search-list-goods__auction-features {
    padding-left: @as-grid-gutter-width / 2;

    .as-search-list-goods__other-features-head {
      color: @as-text-term-color;
    }
  }
  .as-search-list-goods__other-features-value {
    color: @as-text-description-color;
  }
  .as-search-list-goods__auction-features {
    p {
      margin-bottom: 3px;
    }
    .as-search-list-goods__auction-event {
      font-size: @font-size-h5;
    }
    .as-search-list-goods__title {
      font-size: @font-size-h5;

      // Fix for btn btn_link
      .btn.btn-link {
        padding: 0;
        font-weight: bold;
        line-height: 1;
        text-align: left;
        white-space: normal;
        word-break: break-word;
        word-wrap: break-word;
        overflow-wrap: break-word;
      }
    }
    .as-search-list-goods__auction-unit-number {
      font-size: @font-size-h5;
      margin-bottom: 0;
      color: @as-text-description-color;
      font-weight: bold;
      line-height: 1;
    }
    .as-search-list-goods__auction-summary {
      margin-bottom: 10px;
      font-size: @font-size-h5;

      .as-search-list-goods__time-left {
        dt, dd {
          float: none;
        }
        dd {
          font-weight: bold;
        }
      }
      .as-search-list-goods__bid-state {
        display: inline-block;
      }

    }
    .as-search-list-goods__auction-price {
      dt {
        font-size: @font-size-h6;
        height: 1.3em;
        padding-top: @font-size-h5 - @font-size-h6;

        // fix for languages
        html:lang(en) & { min-width: 6.5em; }

      }
      dd {
        font-size: @font-size-h5;
        height: 1.3em;
        font-weight: bold;
        &.text-price {
          .text-price;
        }
      }
    }
    .text-notice {
      margin-top: 3px;
      text-align: left;
    }
    .as-search-list-goods__highest-bidder {
      dt, dd {
        font-size: @font-size-h6;
        line-height: 2;
        word-break: break-all;

        // Hide invalid empty flag icon
        [class="flag-icon flag-icon-"] {
          display: none;
        }
      }
    }
    .as-search-list-goods__time-left {
      dd {
        font-weight: bold;
        white-space: nowrap;
      }
    }
  }

  .as-search-list-goods__auction-features-container {
    .display-flex;
    .flex-flow(row,wrap);
    .justify-content(flex-start);
  }
  .as-search-list-goods__auction-features-item {
    &:nth-child(1) { .flex(0,0,100%); }  // Item
    &:nth-child(2) { .flex(0,0,auto); margin-right: 15px; }  // Time left
    &:nth-child(3) { .flex(0,0,auto); margin-right: 15px; }  // watch
    &:nth-child(4) { .flex(0,0,auto); }  // Bids
    &:nth-child(5) { .flex(0,0,100%); }  // Current Price
    &:nth-child(6) { .flex(0,0,100%); }  // Highest bidder
    &:nth-child(7) { .flex(0,0,100%); }  //Bid amount
  }

}


// Responsive Style
@media (min-width: @screen-sm-min) {
  .as-search-list-goods--auction-bid {

    .as-search-list-goods__header {
      .flex-flow(row,nowrap);
      .align-items(flex-start);
      width: (@as-search-list-goods-auction-bid-image-min-width + @as-grid-gutter-width);

      .as-search-list-goods__image {
        .flex(0,0,auto);
        width: (@as-search-list-goods-auction-watches-image-min-width);
        height: auto;
      }
      // .as-search-list-goods__actions {
      //   .flex(0,0,auto);
      //   .order(1);
      //   margin-top: 0;
      //   margin-right: @as-grid-gutter-width / 2;
      // }
    }
    .as-search-list-goods__item {
      padding-top: 20px;
      padding-bottom: 10px;
    }
    .as-search-list-goods__body {
      width: calc(~"100% - "(@as-search-list-goods-auction-bid-image-min-width + @as-grid-gutter-width));
      padding-left: @as-grid-gutter-width / 2;
      background-image: none;
    }
    .as-search-list-goods__auction-features {
      padding-right: @as-grid-gutter-width / 2;

      dl,dt,dd {
        float: none;
        vertical-align: baseline;
        font-weight: normal;
        color: #4d4d4d;
      }
      .as-search-list-goods__auction-features-container {
        .flex-flow(row,nowrap);
      }
      .as-search-list-goods__auction-features-item {
        &:not(:nth-child(1)) {
          padding: 20px 10px 15px;
          text-align:center;

          dl {
            margin-right: 0;
          }
          dd {
            display: inline;
            font-size: @font-size-h4;
            font-weight: bold;
          }
        }

        &:nth-child(1) { .flex(0,0,auto); width:@as-search-list-goods-auction-bid-column-width-1; }  // Item
        &:nth-child(2) { .flex(1,0,auto); width:@as-search-list-goods-auction-bid-column-width-2; margin-right: 0; }  // Time left
        &:nth-child(3) { .flex(0,0,auto); width:@as-search-list-goods-auction-bid-column-width-3; margin-right: 0; }  // watch
        &:nth-child(4) { .flex(0,0,auto); width:@as-search-list-goods-auction-bid-column-width-4; }  // Bids
        &:nth-child(5) { .flex(0,0,auto); width:@as-search-list-goods-auction-bid-column-width-5; text-align: right;}  // Current Price
        &:nth-child(6) { .flex(0,0,auto); width:@as-search-list-goods-auction-bid-column-width-6; word-break: break-all;}  // Highest bidder
        &:nth-child(7) { .flex(0,0,auto); width:@as-search-list-goods-auction-bid-column-width-7; }  //Bid amount
      }
      .as-search-list-goods__title {
        font-size: @font-size-h4;
      }
      .as-search-list-goods__auction-price {
        text-align: right;

        dd {
          font-size: @font-size-h4;
        }

        // Buyout price
        &.as-search-list-goods__auction-price--buyout {
          dt {
            margin-right: 0;
          }
          dt,dd {
            .text-caption;
            display: block;
            height: auto;
            line-height: 1.4;
          }
        }
      }
      .text-notice {
        text-align: left;
      }
      .as-search-list-goods__highest-bidder {
        dd {
          font-size: @font-size-h4;
          line-height: inherit;
        }
      }
      .as-search-list-goods__auction-price--reserved-bid {
        .display-flex;
        .flex-flow(column,nowrap);
        dt {
          .order(2);
          height: auto;
          .as-text__parenthesis;
        }
        dd {
          .order(1);
          height: auto;
        }
      }

    }

  }
}


@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
  .as-search-list-panel--auction {

    // Bid list
    .as-search-list-goods--auction-bid {
      .as-search-list-goods__auction-features-item {
        &:not(:nth-child(1)) {
          dd {
            font-size: @font-size-h5;
          }
        }
      }
    }

    // Bid list header
    .as-search-list-goods__list-header-item {
      font-size: @font-size-h6;
    }

  }
}
