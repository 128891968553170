// Bid Confirm Modal
// Use with Bootstrap .modal class

.as-groups-bid-result-modal {

  .modal-dialog {
  }
  .modal-body {
    padding-bottom: 0;
  }
  .modal-footer {
    .panel {
      margin-bottom: 0;
    }
    .text-notice {
      margin-bottom: 0;
    }
    .btn-purchase.btn-lg,
    .btn-buyout.btn-lg {
      font-weight: bold;
    }
  }

}

.as-groups-bid-result-modal__message {
  text-align: center;
  font-size: @font-size-h4;
}

.as-groups-bid-result-modal__message-main,
.as-groups-bid-result-modal__message-sub {
  margin-bottom: 5px;
}

.as-groups-bid-result-modal__status {
  .as-label-notify__item {
    margin-bottom: 10px;
  }
}

.as-groups-bid-result-modal__item {
  margin-bottom: 5px;
}
.as-groups-bid-result-modal__item-image {
  width: 70px;
}

.as-groups-bid-result-modal__price {
  // Override
  table.as-bid-price-table {
    & {
      td {
        text-align: left;
      }
    }
  }
}

.as-groups-bid-result-modal__time {
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  font-size: @font-size-h4;
}

.as-groups-bid-result-modal__actions {
  ul& > li {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    // Fix for main button
    .btn.btn-lg {
      font-size: @font-size-h5;
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
}


// When current information has changed
.as-groups-bid-result-modal__message--invalid,
.as-groups-bid-result-modal__item--invalid,
.as-groups-bid-result-modal__price--invalid {
  opacity: .3;
}




// Responsive

@media (max-width: @screen-xs-max){

  .as-groups-bid-result-modal {
    .modal-responsive-layout;
  }

}

@media (min-width: @screen-sm-min) {

  .as-groups-bid-result-modal {
    .modal-dialog {
      max-width: 400px;
    }
  }

}


// Landscape style (Mobile and PC)
.as-groups-bid-result-modal {
  &.modal-landscape {

    .as-groups-bid-result-modal__price {
      // Override
      table.as-bid-price-table {
        & {
          th {
            font-size: @font-size-h6;
            letter-spacing: -.02em;
          }
          td {
            .text-price, .text-currency {
              font-size: @font-size-h5;
            }
          }
        }
      }
    }

    .modal-footer{
      padding-top: 0;
    }

  }
}
