// Selector
//
// Contains Overriding for bootstrap-selector
// TODO bootstrapのdropdownへのオーバーライドの整理

.as-selector {

  .dropdown-menu.open {
    .bs-donebutton button {
      .btn-update;
    }

    .bs-actionsbox .btn-group {
      .display-flex;

      & button {
        .flex(1,1,auto);
        width: initial;
        border-radius: @btn-border-radius-base;

        &:nth-child(n+2) {
          margin-left: 10px;
        }
      }
    }
  }

}

// use with .as-selector
.as-multiple-selector {
  // Delete bootstrap-select default .check-mark style

  .check-mark {

    // Overide
    margin-top: 7px !important;
    right: initial !important;
    left: 12px;

    &:before {
      display: none;
    }

    // Add
    &:after {
      .as-icon__base--pseudo("../images/icon-check-box-checked.svg", 20px);
    }
  }
  .text {
    &:before {
      .as-icon__base--pseudo("../images/icon-check-box.svg", 20px);
    }
  }
  .filter-option .text {
    &:before {
      display: none;
    }
  }
}

.as-selector__option {

  &:before {
    .as-icon__base--pseudo("../images/icon-check-box.svg", 20px);

    .filter-option & {
      display: none;
    }
  }

}

.as-selector__count {
  &:extend(.badge);
  position: absolute;
  top: 15%;
  right: 5px;

  // Hide in toggle
  .filter-option &{
    display: none;
  }
}

// Override Bootstrap Select

// Remove width rimit
.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: auto;
  min-width: 220px;
}

// Fix for form-inline
.form-inline .bootstrap-select.form-control:not([class*="col-"]) {
  width: auto;
}

// Style to Dropdown from Button
.bootstrap-select.btn-group .dropdown-toggle {
  .form-control;
  padding-right: 18px;

  .caret {
    // Override
    // Delete implement using  boder
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin-top: auto;
    margin-bottom: auto;
    border: none;
    vertical-align: initial;

    // Implement using backgroun-image icon
    width: 20px;
    height: 20px;
    .background-image-icon('../images/icon-arrow-dropdown.svg', 20px, 20px,  0, center, center);
  }

  // Prevent style overriding
  &:hover,
  &:focus,
  &.focus {
    color: inherit;
    background-color: inherit;
    border-color: @as-ui-border-color;
  }

  // Omit a too long phrase
  .filter-option {
    text-overflow: ellipsis;
  }

}
.bootstrap-select.btn-group .dropdown-menu {
  box-shadow: 0 2px 2px rgba(0,0,0,.25);

  & > .dropdown-menu.inner {
    // Vertical margin
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;

    li a {
      padding-left: 12px;
    }

    // Maker select picker
    #as-machinery-makersFilter &,
    #as-makersFilter-mobile & {
      overflow-y: scroll !important;

      li a {
        padding-right: 50px;
        line-height: 1.8;
      }
    }

  }
}




// Alternatives

// Currency convert dropdown
.as-selector--currency {
  &.bootstrap-select.btn-group:not([class*="col-"]) {
    width: initial;
    height: initial;
  }

  &.bootstrap-select.btn-group .dropdown-toggle {
    padding: 1px 14px 1px 4px;
    border-color: lighten(@as-sub-border-color, 9.7%);  // #e5e5e5
  }
}

// Make dropdown-menu as area button list
// Available in mobile device
.as-selector--area-menu {
  &.bootstrap-select.btn-group {

    @media (max-width:@screen-xs-max) {

      .dropdown-toggle {
        height: 35px;
        padding: 8px 15px;
        border: none;
        border-bottom: 1px solid @as-area-border-color;
        background-color: @as-button-default-background-color;
        color: @as-button-default-text-color;
        font-size: @font-size-h5;
        box-shadow: none;

        .filter-option {
          float: none !important;
          text-align: center;
        }
        .caret {
          right: @as-grid-gutter-width / 2;
        }

        .open& {
          background-color: @as-button-default-background-color-focus;
          color: @as-button-default-text-color-focus;
          box-shadow: none;
        }
      }
      .dropdown-menu {
        padding-top: 0;
        padding-bottom: 0;
        border-right: none;
        border-left: none;
        background-color: #f5f5f5;
        // box-shadow: @as-selector-box-shadow;
        text-align: center;

        li:focus,
        li:hover {
          background-color: @as-brand-hover;
        }

        > li > a {
          padding: 9px 20px;
        }

        // Check mark on left
        li {
          a {
            .display-flex;
            .justify-content(center);
            padding-right: 12px;
            padding-left: 12px;
          }
          a span.text {
            .order(2);
          }
          a span.check-mark {
            .order(1);
            &:before {
              .as-icon__base--pseudo("../images/icon-check.svg", 100%);
              right: 7px;
              bottom: 0;
            }
          }
        }

        li.selected {
          a {
            font-weight: bold;
          }
          a span.check-mark {
            display: inline-block;
            margin-left: -14px;
          }
        }
      }

    }

  }

}

// fix for bootstrap-select

// ignore long option text
.bootstrap-select > select {
  overflow-x: hidden;
}
