// Sale Status

@as-sale-status-opening-soon-border-color:     @as-label-default-background-color;
@as-sale-status-opening-soon-background-color: lighten(@as-label-default-background-color, 50%);
@as-sale-status-opening-soon-color:            #4c4c4c;

@as-sale-status-opening-border-color:          @as-brand-active;
@as-sale-status-opening-background-color:      lighten(@as-brand-active, 40%);
@as-sale-status-opening-color:                 @as-brand-active;

@as-sale-status-ending-soon-border-color:      @as-brand-attention;
// @as-sale-status-ending-background-color:       lighten(@as-brand-attention, 40%);
@as-sale-status-ending-background-color:       lighten(spin(saturate(@as-brand-attention, 90%), 7%), 45%);
@as-sale-status-ending-color:                  @as-brand-attention;

@as-sale-status-ended-border-color:            @as-label-moderate-background-color;
@as-sale-status-ended-background-color:        lighten(@as-label-moderate-background-color, 10%);
@as-sale-status-ended-color:                   @as-brand-end;


.as-sale-status {
  width: 100%;

  .as-sale-status__body {
    display: block;
    border: solid 0 transparent;
    border-right-width: 0;
    border-left-width: 0;
    background: transparent;
    font-weight: bold;
    text-align: center;

  }

  &.as-sale-opening-soon {
    .as-sale-status__body {
      border-color:     @as-sale-status-opening-soon-border-color;
      background-color: @as-sale-status-opening-soon-background-color;
      color:            @as-sale-status-opening-soon-color;
    }
  }
  &.as-sale-opening {
    .as-sale-status__body {
      border-color:     @as-sale-status-opening-border-color;
      background-color: @as-sale-status-opening-background-color;
      color:            @as-sale-status-opening-color;
    }
  }
  &.as-sale-ending-soon {
    .as-sale-status__body {
      border-color:     @as-sale-status-ending-soon-border-color;
      background-color: @as-sale-status-ending-background-color;
      color:            @as-sale-status-ending-color;
    }
  }
  &.as-sale-ended {
    .as-sale-status__body {
      border-color:     @as-sale-status-ended-border-color;
      background-color: @as-sale-status-ended-background-color;
      color:            @as-sale-status-ended-color;
    }
  }

}
