.as-companies {
    @company-theme-color: var(--as-primary-color);
    @company-theme-sub-color: var(--as-secondary-color);
    @company-font-color: var(--as-base-text-color);

    .company-page-footer {
        padding: 5px;
        background-color: @company-theme-color;
        color: #fff;
        > div:not(:last-child){
            margin-bottom: 5px;
        }
        p{
            margin-bottom: 0;
        }
        .footer-label{
            text-align: center;
            font-weight: bold;
        }
        .footer-text{
            text-align: center;
        }

        //レスポンシブ対応
        @media (max-width: @screen-xs-max){
        }
    }
}