// Icon

// For Span Element
.as-icon__base(@url, @size: 100%) {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-right: 3px;
  bottom: 0.11em;
  vertical-align: middle;
  .background-image-icon(@url, @size, @size, center, center, left);
}

// Return a pseudo inline-block icon element
// This class is used with inline-block pseudo element;
.as-icon__base--pseudo(@url, @size: 100%) {
  position: relative;
  content: "";
  display: inline-block;
  width: 1em;
  height: 1em;
  bottom: 0.11em;
  right: 4px;
  vertical-align: middle;
  .background-image-icon(@url, @size, @size, center, center, left);
}

// Hide text in inline element
// Use with pseudo-based icon.
// Not recommended, Use .as-text--invisible.
.as-icon__hide-text() {
  width: 1em;
  white-space: nowrap;
  overflow: hidden;
}

// Set icon position element by position:absolute
// ex) use for a icon in text form
.as-icon__add-icon {
  position: relative;

  [class*="as-icon--"],
  .glyphicon {
    display: block;
    position: absolute;
    top:  0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 2;  // for bootstrap select
  }
}

.as-icon__add-icon--left {
  [class*="as-icon--"],
  .glyphicon {
    left: 15px;
  }
  input {
    padding-left: 2.7em;
  }
}

.as-icon__add-icon--right {
  [class*="as-icon--"],
  .glyphicon {
    rihgt: 15px;
  }
  input {
    padding-right:  2.7em;
  }
}

.as-icon__size-lg {
  font-size: 1.3em;
}

.as-icon__size-md {
  font-size: 1.2em;
}
