// Topic

.as-topic {
}

.as-topic__header {
}

.as-topic__title {
  font-size: @font-size-h4;
  font-weight: bold;
  .as-contents-body_top & {
    text-shadow: 1px 0px 1px #fff, 0px 1px 1px #fff, -1px 0px 1px #fff, 0px -1px 1px #fff;
  }
}

.as-topic__body {
}

.as-topic__body__feature {
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: auto;
  .as-contents-body_top & {
    text-shadow: 1px 0px 1px #fff, 0px 1px 1px #fff, -1px 0px 1px #fff, 0px -1px 1px #fff;
  }
}