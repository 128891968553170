// Serch View Forms

@as-search-result-header-horizontal-padding: 15px;

.as-search-result-header {

   &.row > div[class*="col-"] {
     //  margin-bottom: 10px;

    &.as-search-result-header__hit {
       margin-bottom: 10px;
    }

     // Pgaenation
     &:last-child {
       margin-bottom: 0;
     }

   }

  .form-group {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

  }

  .as-selector {
    width: auto;
  }

  label {
    font-size: @font-size-h6;
    font-weight: normal;
    margin-right: 5px;
  }

  form {
    float: left;
  }

  .as-pagination {
    float: right;
    margin-top: 3px;
    margin-right: @as-search-result-header-horizontal-padding;
    margin-bottom: 4px;
  }


}

.as-search-result-header__container {
  padding-right: @as-search-result-header-horizontal-padding;
  padding-left: @as-search-result-header-horizontal-padding;
  margin-bottom: 10px;
}

.as-search-result-header__hit {
}

.as-search-result-header__hit-container {
  padding-right: @as-search-result-header-horizontal-padding;
  padding-left: @as-search-result-header-horizontal-padding;
  font-size: @font-size-h5;
  font-weight: bold;

  > p {
    display: inline-block;
    margin-bottom: 0;
  }
}

.as-search-result-header__hit-number {
  font-size: @font-size-h3;
  margin-right: 0.3em;
}

.as-search-result-header__hit-comment {
  font-size: @font-size-h6;
  font-weight: normal;
  color: @as-text-term-color;
}

.as-search-result-header__title {
  display: inline-block;
  margin-bottom: 3px;
}

.as-search-result-header__original-words-wrapper {
  word-break: break-all;  // for firefox, IE
  &:before {
    content: ":";
  }
}

.as-search-result-header__original-words {
  display: inline-block;
  margin-bottom: 3px;
  color: lighten(@as-heading-text-color-primary, 35%);
  font-weight: normal;
  text-align: left;
  white-space: normal;
  word-break: break-word;
  word-wrap: break-word;  // for IE

  quotes : '"' '"';
  &:before {
    content: open-quote;
  }
  &:after {
    content: close-quote;
  }

  &:empty {
    display: none;
  }
}

.as-search-result-header__original-words-separator {
  color: @as-heading-text-color-primary;
  margin: 0 2px;
}

@media (max-width: @screen-xs-max) {
  .as-search-list-panel {
    .as-heading {
      margin-bottom: 0;
    }
  }

  .as-search-result-header {

    // Search condition button icon
    .btn {
      font-size: @font-size-h3;

      & [class*="as-icon--"] {
        margin: 0 -3px;
        bottom: 0;
        background-size: 85%;
      }
    }

    .form-inline .form-group {
      display: inline-block;
      margin-bottom: 0;
      vertical-align: middle;
    }
    .as-selector {
      display: inline-block;
    }

    // Icon label style
    label {
      margin-right: 3px;
      margin-left: 6px;
      vertical-align: middle;
      font-size: @font-size-h3;

      [class*="as-icon--"] {
        margin-right: 0;
        bottom: 0;
        background-size: 85%;

        // fix for english
        html:lang(en) & {
          margin-right: -3px;
        }
      }

      //hide text
      .hidden-xs {
        display: inline-block !important;
        .as-text--invisible;
      }
    }

    // Search Header Style
    .as-search-result-header__container {
      width: 100%;
      border-color: @panel-default-border;

      //TODO Refine
      white-space: nowrap;
      padding: 10px 5px;
      > * {
        margin-right: 2px;
      }
      html:lang(vi) & {
        padding: 10px 3px;
        > * {
          margin-right: 0;
        }
      }
    }

  }
}

@media (max-width: @screen-xs-max) {
  .as-search-result-header {

    &.row > div[class*="col-"] {
      // margin-bottom: 10px;
    }

    .form-inline .form-group {
      // margin-bottom: 10px;
    }
    // .as-selector {
    //   height: (@line-height-computed + (@padding-base-vertical * 2) + 3);  // Override with Bootstrap variables
    // }
    .as-pagination {
      float: none;
      display: block;
      margin-right: 0;
      margin-left: 0;
      text-align: center;

      > li {
        display: inline-block;
      }
    }
  }

  .as-search-result-header__containe {
    margin-bottom: 10px;
  }

  .as-search-result-header__hit {
    margin-bottom: 10px;
  }
}

@media (min-width: @screen-sm-min) {
  .as-search-result-header {
    .form-inline .form-group {
      margin-bottom: 10px;
    }
  }
}
