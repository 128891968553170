.common-machine-list-upload {

    .page-title {
        font-size: 2em;
        text-align: center;
    }

    .page-description {
        font-size: 1.2em;
        margin: 3px;
        margin-bottom: 10px;
    }

    .process-details {
        margin-bottom: 5px;
    }

    .operation-selection {
        margin-bottom: 10px;
        text-align: center;

        .as-toggle-button {
            font-size: 1.5em;
            margin-right: 20px;
        }
    }

    .uploaded-file-img {
        max-width: 100px;
    }

    .success-message {
        text-align: center;

        .message-title {
            font-size: 1.2em;
        }
    }

    .progress-bar-success {
        background-color: @link-color;
    }

}