.twitter-typeahead {
    width: 100%;
    vertical-align: top;
}

.tt-input,
.tt-hint {
    width: 100% !important;
}

.tt-input {
    // box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-hint {
    color: #999;
}

.tt-menu {
    min-width: 100%;
    background-color: #fff;
    color: @text-color;
    border: 1px solid @as-ui-border-color;
    border-radius: @border-radius-base;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
}

.tt-dataset-as_auction_unit_number,
.tt-dataset-as_model_number,
.tt-dataset-as_location {

  div:first-child {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 5px;
    background-color: @as-menu-background-color;
    font-size: @font-size-h6;
    color: @as-label-moderate-color;
  }

}

.tt-suggestion {
    padding: 3px @padding-large-horizontal;
    font-size: @font-size-h4;
    cursor: pointer;
}

.tt-highlight {
  font-weight: bold;
}

.tt-suggestion.tt-cursor,
.tt-suggestion:hover {
    background-color: @as-brand-hover;
}

.tt-suggestion{
    padding-left: 16px;
    font-size: @font-size-h4;

    p{
      margin: 0;
    }

    &:last-child{
      padding-bottom: 10px;
    }

}

//override for search conditions filter
.as-search-conditions__submenus {
    .twitter-typeahead {
     width: 100%;
    }
}
