@import '../sitewide.less';

.common-new-feature-popup {
    #new-feature-modal {
        display: block;
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0%;
        top: 0%;
        background: @modal-background-color;
        z-index: @modal-z-index;
    }
    .innerFrame {
        display: block;
        width: 100%;
        height: 100%;
        padding: 24px;
    }
    .innerFrame>div {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
    }
    .modalBody {
        display: block;
        min-width: 300px;
        max-width: 1100px;
        max-height: 100%;
        background-color: white;
    }
    .modal-contents-wrapper {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
    }

    .close-button-wrapper {
        width: 100%;
        text-align: right;
    }
    
    .close-button {
        width: 56px;
        height: 56px;
        font-size: 34px;
        bottom: 5px;
        text-align: center;
        border: none;
        border-radius: 28px;
        background: none;
        margin: 16px 16px 0;

        &:hover {
            background: @grey-2;
        }
    }
    .modal-contents {
        padding: 0 32px 32px;
    }
}