// navs

.as-auction {
  .nav {

      li {
        padding-top: 2px;
      }

  }
}
