// Auction Start Modal
// Use with Bootstrap .modal class

.as-groups-auction-start-modal {

  .modal-body {
    padding-bottom: 0;
  }

}

.as-groups-auction-start-modal__image {
  position: relative;
  height: 170px;
}

.as-groups-auction-start-modal__image-contents {

  // "START" Caption
  &, &:after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &:after{
    content: "START!";
    height: auto;
    top: ~"calc(52% - 1em)";
    color: #333333;
    font-weight: 700;
    .as-responsible-font-size(manual,60px,60px,60px,60px);
    letter-spacing: .1em;
    text-align: center;
    vertical-align: middle;
  }

}

.as-groups-auction-start-modal__message{
  text-align: center;
}
