// Search Result Header

// Auction Search Result Footer
// Override .as-search-result-footer div
.as-search-result-footer {
  > div[class^="col-"] {
    > *:first-child {
      margin-left: 0px;
    }
    > *:last-child {
      margin-right: 0px;
    }
  }
}
