.as-companies {
    @company-theme-color: var(--as-primary-color);
    @company-theme-sub-color: var(--as-secondary-color);
    @company-font-color: var(--as-base-text-color);


    .companies-information{
        width: 100%;
        margin: auto;
        .as-main-contents-header{
            display: none;
        }
        .as-main-contents-body{
            padding-top: 0;
        }
        .company-message-box{
            padding: 12px 0 20px 0;
            .company-message-text{
                pre{
                    padding: 10px;
                    border: 4px solid @company-theme-color;
                    color: @company-theme-color;
                    font-size: 20px;
                    font-weight: bold;
                    line-height: 1.2;
                    white-space: pre-wrap;
                }
            }
        }
        .company-page-label{
            background-color: @company-theme-color;
            color: @company-font-color;
            padding: 4px 4px 4px 45px;
            font-size: 23px;
            font-weight: bold;
            position: relative;
            line-height: 1.5;
            margin-bottom: 0;
            &::before{
                content: "";
                width: 25px;
                height: 25px;
                position: absolute;
                left: 10px;
                top: 0;
                bottom: 0;
                margin: auto 0;
                background-repeat: no-repeat;
                background-position: center;
            } 
            &.icon-excavator{
                margin-bottom: 12px;
            }
            &.icon-excavator::before{
                background-image: url(/images/company-page/icon-excavator.svg);
            }
            &.icon-company::before{
                background-image: url(/images/company-page/icon-company.svg);
            }
        }
        .company-detail-text{
            pre{
                background-color: #f2f2f2;
                padding: 15px;
                border: 1px solid #d2d2d2;
                border-radius: 0;
                font-size: 14px;
                white-space: pre-wrap;

            }
        }

        //レスポンシブ対応
        @media (max-width: @screen-xs-max){
        }
    }
}