// Pager pagination

.pager {
  li {
    > a,
    > span {
      padding: 0;
      background-color: transparent;
      border: none;
    }
    > a:hover,
    > a:focus {
      background-color: transparent;
    }
    &:not(.disabled) > a:hover,
    &:not(.disabled)  > a:focus {
      text-decoration: underline;
    }
  }

  .next {
    > a:after {
      .as-icon__base--pseudo("../images/icon-arrow-right-link.svg", 80%);
    }
    &.disabled > a:after {
      .as-icon__base--pseudo("../images/icon-arrow-right-disable.svg", 80%);
    }
  }


  .previous {
    > a:before {
      .as-icon__base--pseudo("../images/icon-arrow-left-link.svg", 80%);
    }
    &.disabled > a:before {
      .as-icon__base--pseudo("../images/icon-arrow-left-disable.svg", 80%);
    }
  }

  .disabled {
    > a,
    > a:hover,
    > a:focus,
    > span {
      color: @pager-disabled-color;
      background-color: transparent;
      cursor: @cursor-disabled;
    }
  }

  .next,     .next.disabled,
  .previous, .previous.disabled {
    > a:before,
    > a:after {
      // Fix for .as-icon__base--pseudo
      right: 0;
    }
  }
}
