// Search Topic

.as-search-topic__contents {
  margin-right: 0;
  margin-bottom: 35px;
  margin-left: 0;
}

.as-search-topic__thumbnail {
  .as-responsible-image-frame(1,1);
  background-color: @as-image-background-color;
}

.as-search-topic__description {
  text-indent: 1em;
}

@media (min-width:@screen-sm-min) {
  .as-search-topic__contents {
    margin-bottom: 0;
  }
}
