.marketplace-seller-machine-list {


font-size: @font-size-5;
line-height: @line-height-5;

// Just for temporary use
.currency-temporary {
  font-size: 1.5em; line-height: 48px;
}

.seller-contents, .as-seller-modal {


  .modal-body {
      padding-top: 0;
      padding-left: 3rem;
      padding-right: 3rem;
      padding-bottom: 3rem;
  }

  .modal-footer {
      padding-top: 0;
      padding-left: 3rem;
      padding-right: 3rem;
      padding-bottom: 3rem;
  }

  .dialog-text {
      font-size: 1.5em;
      line-height: 1.5em;
      margin-bottom: 16px;
  }

  label {
      font-weight: normal;
  }


  .tool-tip:hover {
      position: relative;
      cursor: default;
      .tool-tip-inner {
          display: block;
      }
  }

  .tool-tip-inner {
      display: none;
      position: absolute;
      white-space: nowrap;
      right: 100%;
      padding: 0 8px;
      background: @white;
      z-index: 4;
  }
  /*Only Firefox*/
      @-moz-document url-prefix() {
          .tool-tip-inner {
              line-height: 20px;
          }
      }
      

}







//Machine Information
.seller-item-info__title {
  color: @light-text-color;
  font-size: @small-text;
  margin-right: 4px;
}

.seller-item-info__value {
margin-right: 16px;
}

.greyout {
  background: #dddddd;
  color: #aaa;
}

.price-masking-part {
  margin-top: 10px;
  text-align: right;

  input {
    margin-right: 5px;
  }
}

.privacy-status-part {
  margin-top: 10px;
  text-align: center;

  input {
    margin-right: 5px;
  }
}


/*

Overlays

*/

.as-seller-list-item-overlay {
  display: none;   
}

.as-item-deleted, .as-item-published, .as-item-unpublished {
  display: block;
}

.as-seller-list-item-overlay-contents {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-right: -2px; //Covering outlined buttons 
  margin-left: -2px; //Covering outlined buttons 
  background: rgba(255,255,255,.9);
  z-index: 3;
}

.as-seller-list-overlay-title {
  color: @light-text-color;
  font-size: 1.6em;
  margin-bottom: 8px;
}

.as-seller-list-overlay-title-not-published {
  color: @warning-color;
}

.as-seller-list-overlay-description {
  margin-bottom: 16px;
}
/*

Mobile

*/
.as-seller-list-mobile-header-menu {
  flex: 1;
  text-align: right;
  white-space: nowrap;
}

.as-seller-list-mobile-header-menu__add {
vertical-align: middle;
}

.as-seller-list-image-default-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 96px;
  width: 96px;
  background-color: @grey-1;
}

.as-seller-list-edit-button {
  float: right;
  width: 48px;
  height: 100px;
  background: @grey-1;
}




.seller-contents .list-item-mobile-link {
  padding-top: 16px;
  padding-bottom: 16px;
  flex-grow: 1;
  color: @text-color;

  &:hover {
    background: @grey-1;
    color: @text-color;
  }
}

.list-item-mobile-photo {
  float: left;
  height: 100%;
}

.list-item-mobile-text {}

.list-item-mobile-edit {
  display: flex;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  flex: 0 0 48px;
  background: @grey-1;
}

.list-item-mobile-edit-icon {
      color: @grey-5;
}



.seller-contents .list-item-mobile-edit-option {
  display: block;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  background: @grey-1;
  cursor: pointer;
  color: @text-color;

  &:hover {
      background: @grey-2;
      color: inherit;
  }

  &.disabled {
      display: none;
  }

  .disabled-option {
    color: #cccccc;
  }
}

.unpublishable-item-note--mobile {
  display: none;
  padding-right: 10px; 
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  background: @grey-1;
  color: @warning-color;
} 









.as-seller-list-lot {
  margin-right: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.as-seller-list-maker {
  margin-right: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: @font-size-3;
  font-weight: bold;
}

.as-seller-list-model {
  margin-right: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: @font-size-3;
  font-weight: bold;
}
.as-seller-list-time {
  text-align: right;
  color: @light-text-color;
}



.as-seller-list-lot--mobile {
  margin-right: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.as-seller-list-maker--mobile {
  margin-right: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: @font-size-4;
  font-weight: bold;
}

.as-seller-list-model--mobile {
  margin-right: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: @font-size-4;
  font-weight: bold;
}

.as-seller-list-time--mobile {
  color: @light-text-color;
}

.as-seller-list-time--sold {
padding-top: 4px;
padding-bottom: 4px;
color: @light-text-color;
text-align: right;
}

.list-item-pc {
  .as-seller-list-lot-number {
  float: left;
  margin-right: 16px;
  }

  .as-seller-list-maker {
  float: left;
  margin-right: 16px;
  }
}


/**********   Photos   **********/
.as-seller-list-image-upload {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  
  border: solid 1px @link-color; 

  &:hover {
      // background: none;
      border: solid 1px @link-hover-color; 
      background: @grey-1;
  }   
}

.as-seller-list-image-wrapper {
  float: left;
  margin-right: 8px;
}
/* Trimming Photos */
.as-seller-list-image {
  position: relative;
  overflow: hidden;
  width: 96px;
  /* trimming width */
  height: 96px;
  /* trimming height */
  img {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: auto;
      height: 100%;
      z-index: 1;
  }

  &:hover {
      text-decoration: none;
  }
}



/* Image Overlay */
.as-seller-list-image-overlay {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  background: rgba(255,255,255,.8);
  opacity: 0;
  z-index: 2;
  cursor: pointer;

  &:hover {
      opacity: 1;
  }
}

.as-seller-list-image-overlay--text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 160px;
  width: 200px;
  text-decoration: none;
}


/*

List Item Details

*/
.as-seller-list-info {
line-height: 2em;
margin-right: 16px;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
}



//Secondary Actions
.as-seller-list-secondary-action {
  display: block;
  text-align: right;
  margin-bottom: @space-base;

  &:last-child {
    margin-bottom: 0;
  }
}


.unpublishable-item-note {
  display: none;
  margin-bottom: @space-sm;
  color: @warning-color;
  text-align: right;
} 

.seller-item-currency {
  margin-left: 4px;
  font-size: .8em;
}

.as-seller-list-price-button {
  margin-bottom: 10px;
  text-align: right;
  overflow: scroll;
  white-space: nowrap;
}

.as-seller-list-set-price-button {
  margin-bottom: 10px;
}

.as-seller-list-price--sold {
  cursor: default;
}


.badge--inspection-report {
  display: inline-block;
  height: 18px;
  min-width: 18px;
  margin-bottom: 2px; 
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 12px;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  background: @link-color;
  color: @white;
  text-align: center;
  vertical-align: middle;
}


/* 
Footer
*/
.sales-section {
  padding-top: @space-base;
  padding-bottom: @space-base;
  border-top: 1px solid @border-color;
}

.primary-section {
  padding-top: @space-base;
  padding-bottom: @space-base;
  border-top: 1px solid @border-color;
}


////////////
// EDITOR
////////////


.as-seller-modal .as-header {
  border-top: none;
}

.seller-editor-close{
flex: 1;
text-align: right;
line-height: 1.5em;
color: @text-color;
}

.seller-editor-close-icon{
font-size: 2em;
line-height: 1.5em;
font-weight: 300;
}


.modal-wrapper {
background: #ffffff;
min-height: 300px;
margin: 100px auto;
}

.seller-item-editor {
padding: 32px;
}

.machine-info {
display: flex;
background: #fafafa;
padding: 32px;
}


// For Editors
.machine-info-image {
.as-seller-list-image;
width: 96px;
/* trimming width */
height: 96px;
/* trimming height */
flex: 0 0 100px;
margin-right: 16px;
}


.as-seller-modal {

.container {
  width: 100%;
  padding: 0;
}

.modal-dialog {
  max-width: @screen-xs-max;
}

.modal-content {
  border: none;
  border-radius: 0;
}

.modal-header {
  padding: 32px;
  border: none;
}

.modal-title {
  font-size: 1.8em;
  font-weight: normal;
}

.close {
  font-size: 2em;
}
}


.as-condition-report-upload-dialog {
  .list-group-item{
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
  }
}


@media(max-width: @screen-xs-max) {
  .as-seller-modal .modal-dialog {
      margin: 0;
  }
  .as-seller-modal .modal-content {
      margin: 0;
  }
}

@media(min-width: @screen-sm-min) {
  //Full width on moible
  .as-seller-list {
      margin-left: 0;
      margin-right: 0;
  }
  .as-seller-list-image-default-pc {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 135px;
      width: 180px;
      background-color: @grey-2;
      color: @link-color;
    &:hover {
      background-color: @grey-3;
    }
  }

  .as-seller-list-image-wrapper {
      margin-left: 0;
  }

  .as-seller-list-image-wrapper {
      margin-right: 16px;
  }

  .as-seller-list-image {
      position: relative;
      width: 180px;
      /* trimming width */
      height: 135px;
      /* trimming height */
  }

  .as-seller-list-image--sold {
      position: relative;
      width: 120px;
      /* trimming width */
      height: 96px;
      /* trimming height */
  }
}
/* deprecated: @screen-tablet, or @screen-sm */
@media(min-width: @screen-md-min) {}
/* deprecated: @screen-desktop, or @screen-md */
@media(min-width: @screen-lg-min) {}
/* deprecated: @screen-lg-desktop, or @screen-lg */






// Utility


.d-block {
  display: block!important;
}

.d-none {
  display: none!important;
}

.mb-1 {
  margin-bottom: 1rem;
}
.mb-2 {
  margin-bottom: 2rem;
}
.mb-3 {
  margin-bottom: 3rem;
}

.mr-1 {
  margin-right: 1rem;
}
.mr-2 {
  margin-right: 2rem;
}

.primary-text {
  color: @text-color!important;
}

.secondary-text {
  color: @light-text-color!important;
}

.tertiary-text {
  color: @x-light-text-color!important;
}

.warning-text {
  color: @warning-color!important;
}


// New Variables
@default-border-radius: 2px;







  ////////////////////////////
  ////////////////////////////






  // For card layout 
  background: @grey-1;
    

  /**********  Side Menu  **********/

  .as-list-nav {
    margin-bottom: 30px;
  }

  .as-list-nav a {
    color: @text-color;
    &:hover {
        color: @text-color;
    }
  }

  .as-list-nav-link {
    & a {
      display: block;
      min-height: 44px;
      padding: 16px;
      margin-bottom: 2px;
      background: @white;
      color: @text-color;
      text-decoration: none;
  
      &:hover {
      background: @grey-2;
      color: inherit;
      }
    }
    
    &.selected a {
      font-weight: bold;
      border-left: solid 4px @theme-color; 
    }
  }


  /**********  Common Header  **********/
  .seller-page-header {
    display: flex;
    overflow:auto;
    margin-bottom: @space-sm;
    // line-height: 44px;
    // line-height: ~"calc(3em-4px)";
  }

  .seller-page-title {
    font-size: @font-size-2;
    line-height: @line-height-2;
  }

  @media(max-width: @screen-xs-max) {
    // For list layout
    background: @white;
      
    .seller-page-title {
      font-size: 1.4em;
    }
  }
  

  @media(min-width: @screen-md-min) {
    .seller-page-header {
        margin-bottom: 32px;
    }

    .header-border {
        padding-bottom: @space-tiny;
        border-bottom: solid 4px @theme-color;
    }

  }


 



   /*
  List Header
  */
  .as-seller-list-result {
    margin-bottom: @space-sm;
}

.as-seller-list-result-info {
    float: left;
    padding-top: @space-sm;
    padding-bottom: @space-sm;
    margin-bottom: @space-sm;
}

.as-seller-list-result-modifier {
    height: 100%;
    margin-bottom: @space-tiny;
}

.as-seller-list-result-modifier select,
.item-detail-group-wrapper select {
    float: right;
    height: 48px;
    width: auto;
    padding-left:  @space-sm;
    padding-right:  @space-base;
    margin-left: @space-sm;
    margin-bottom: @space-sm;
    border: none;
    border-radius: @border-radius-base;
    background-color: @grey-3;
    box-shadow: none;
    font-size: @font-size-6;
    white-space: nowrap;
}

.item-detail-group-wrapper select {
  float: none;
  margin-left: 0;
}

/**********  List Items  **********/

.as-search-list-goods__list {
  padding: 0;
}
//Full width on moible
.as-seller-list {
  margin-left: -10px;
  margin-right: -10px;
}

.as-seller-list-item {
  position: relative; //For Overlays
  padding-left: 2px;
  list-style: none;
}
.list-item-pc {
  border: solid 1px @border-color;
  background: @white;
  padding-left: @space-base;
  padding-right: @space-base;
  border-radius: @border-radius-base;
  margin-bottom: @space-base;
}

.list-item-pc--sold {
  padding-top: 2em;
  padding-bottom: 2em;
}

.list-item-mobile {
    display: flex;
    min-height: 128px;
}


@media (max-width: @screen-xs-max) {
  .as-search-list-goods__list {
    border-bottom: solid 1px @border-color;
  }
  .list-item-mobile {
    border-top: solid 1px @border-color;
  }
}

@media (min-width: @screen-sm-min) {
  .as-seller-list {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width: @screen-md-min) { }

@media (min-width: @screen-lg-min) { }




/**********  List Item Header  **********/

.as-seller-list-item-header {
  padding-top: @space-base;
  padding-bottom: @space-base;
  
}

.as-seller-list-title-link {
  display: block;
  font-size: 1.2em;
  
  &--sold {
      line-height: 2em;
  }
}

.as-seller-list-title {
float: left;
margin-right: 16px;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
}


.as-seller-list-middle-contents {
  padding-bottom: @space-base; 
}
















































  //

  .background-shade {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.6);
  }
  .refreshButtonRow {
    display: block;
    width:100%;
    margin-bottom: @space-base;

    .rowBackbone {
      display: flex;
      justify-content: center;
      width:100%;
    }
  }

  //Fixing select option height in Firefox 
  @-moz-document url-prefix() { 
    .as-input-field option {
        height: 44px;
    }
  }

  .as-images-upload-dialog {
    .seller-imageupload {
      .files {
        .as-loading-view();  
      }
    }

    // 文言がボタンからはみ出るため一時的に対応
    .sort-by-file-name {
      font-size: 12px;
    }

    .modal-button-container {
      box-sizing: border-box;
      height: 96px;
      padding: 24px 0;
    }

    .baseIcon {
      display: block;
      width: 32px;
      height: 32px;
      position: absolute;
      border-radius: 16px;
      cursor: pointer;
      text-align: center;
    }

    .baseImg {
      height: auto;
      max-width:100%;
      max-height:100%;
    }

    .modal-dialog {
      max-width: 1000px;

      .upload-photos-button {
        max-width: 300px;
        margin: 0 auto;
      }

      .upload-photos-text {
        margin-top: 16px;
      }

      .coverPhoto {
        width: 100%;
        padding: 8px;
        background-color: #f5f5f5;
        color: #4c4c4c;
        line-height: 1.5em;
        text-align: center;
      }

      .file-upload-outer {
        margin-bottom: 10px;
        cursor: -webkit-grab;
        cursor: -moz-grab;
        cursor: grab;

        .centerlize {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          position: relative;
          background-color: #f5f5f5;

          .preDeleteIcon {
            .baseIcon;
            background-color: #ffffff;
            border: solid 1px #e5e5e5;
            opacity: 0.4;
            font-size: 17px;
            top: 8px;
            left: 8px;
          }

          .preSetAsTopIcon {
            .baseIcon;
            background-color: #ffffff;
            border: solid 1px #e5e5e5;
            opacity: 0.4;
            font-size: 17px;
            top: 8px;
            right: 8px;

          }

          .deleteIcon {
            .baseIcon;
            width: auto;
            padding-left: 16px;
            padding-right: 16px;
            background-color: #F05522;
            color: #ffffff;
            font-size: 17px;
            line-height: 32px;
            top: 8px;
            left: 8px;
          }

          .setAsTopIcon {
            .baseIcon;
            width: auto;
            padding-left: 16px;
            padding-right: 16px;
            background-color:#ffffff;
            color: #14A2CC;
            font-size: 17px;
            line-height: 32px;
            top: 8px;
            right: 8px;
          }

          .icon-positioner {
            margin-top: 2px;
            width: 14px;
          }
          
        }

        &:hover .preDeleteIcon {
          opacity: 0.8;
        }
        .preDeleteIcon:hover {
          opacity: 0.9
        }

        &:hover .preSetAsTopIcon {
          opacity: 0.8;
        }
        .preSetAsTopIcon:hover {
          opacity: 0.9;
        }

      }
    }

    .discard-dialog {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0px;
      top: 0px;

      .outer {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        height: 100%;
        position: relative;
      }

      .backscreen {
        display: block;
        width: 100%;
        height: 100%;
        z-index: 0;
        position: absolute;
        left: 0px;
        top: 0px;
      }

      .content-area {
        display: block;
        width: 700px;
        box-shadow: rgba(122, 122, 122, 0.25) 0px 0px 6px 3px;
        background-color: #ffffff;
        z-index: 1;
      }
    }

    .fade-dialog-enter-active, .fade-dialog-leave-active {
      transition: transform 0.3s ease-out;
      transform: translate(0, 0);
    }

    .fade-dialog-enter, .fade-dialog-leave-to {
      transform: translate(0, -25%);
    }

    @media (max-width: @screen-xs-max) {

      .file-upload-outer {
          img {
            .baseImg;
            width:100%;
          }
          .preDeleteIcon {
          opacity: 0.8;
        }

        .preSetAsTopIcon {
          opacity: 0.8;
        }
      }
    }

    @media (min-width: @screen-sm-min) {

      // bootstrap over-writing

      .modal {
        padding-left: 24px;
        padding-right: 24px;
      }
      .modal-dialog {
        width: auto;
      }

      .file-upload-outer {
        display: block;
        height: 180px;
        margin-bottom: 16px;

        img {
          .baseImg;
        }
      }

    }

    @media (min-width: @screen-md-min) {

      .file-upload-outer {
        height: 150px;
      }

    }

  }

  .as-item-edit-dialog{
    .mb-3{
      &::after{
        content: "";
        display: block;
        width: calc(~"100% + 6rem");
        left: -3rem;
        border-bottom: 1px solid @as-sub-border-color;
        height: 1px;
        position: absolute;
        bottom: 0;
      }
    }

    .as-field-group {
      margin-bottom: @space-base;
    }
    
    .as-field-group-title {
      position: relative;
      margin-bottom: 0;
      padding: 20px 20px 20px 30px;
      cursor: pointer;

      &::before{
        content: "";
        display: block;
        width: calc(~"100% + 6rem");
        left: -3rem;
        border-top: 1px solid @as-sub-border-color;
        height: 1px;
        position: absolute;
        top: 0;
      }
      &::after{
        content: "";
        display: block;
        position: absolute;
        left: 3px;
        width: 16px;
        height: 16px;
        border-top: 4px solid @grey-8;
        border-right: 4px solid @grey-8;
      }
      &[data-toggle="collapse"]::after{
        top: calc(~"50% - 12px");
        transform: rotate(135deg);
      }
      &[data-toggle="collapse"].collapsed::after {
        top: calc(~"50% - 4px");
        transform: rotate(-45deg);
      }
    }

    @media(min-width: @screen-sm-min) {
      .mb-3{
        &::after{
          position: static;
          width: 100%;
          left: 0;
        }
      }
      .as-field-group-title {
        &::before{
          width: 100%;
          left: 0;
        }
      }    
    }
    @media(max-width: @screen-sm-min) {
      .mb-3{
        padding-left: 0;
        padding-right: 0;
      }
      .as-field-group{
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

}