// Panel Button

@as-panel-button-background-color: #ffffff;

.as-panel-button__container {
  .display-flex;
  .flex-flow(row,wrap);
  border: 1px solid transparent;
  border-top-color: @as-ui-border-color;
  border-right: none;
  border-bottom: none;
  border-left-color: @as-ui-border-color;
}

//Common
.as-panel-button__button {
  position: relative;
  display: block;
  background-color: @as-panel-button-background-color;
  border: 1px solid transparent;
  border-top: none;
  border-right-color: @as-ui-border-color;
  border-bottom-color: @as-ui-border-color;
  border-left: none;

  &:before {
    position:  absolute;
    content: "";
    display: block;
    width: 100%;
    height:  76%;
    z-index: 1;
    -webkit-user-select: none!important;
  }

  &:focus, &:hover {
    background: initial;
    background-color: @as-brand-transition;

    .as-panel-button__title-container {
      background: none;
      color: @as-brand-background;
    }

    a, span {
      text-decoration: underline;
    }
  }

}

.as-panel-button__title-container {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}

.as-panel-button__title {
  display: table-cell;
  width: 1%;  // hack for aligning table-cell to center
  height: 2.1em;
  line-height: 1em;
  font-size: @font-size-h6;
  // word-break: break-word;
  word-break: keep-all;
  overflow-wrap:break-word;
  text-align: center;
  vertical-align: middle;
}


@media (max-width: @screen-xs-max) {
  .as-panel-button__title {
    font-size: @font-size-h6 - 2px;  //10px
  }
}
