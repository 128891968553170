// Auction Top Page

// Auctino Info Contents Style
// Also can customize for each evnt by adding .as-groups-auction-event__hero-contents--hogeevent

// Top Auction Event Info Area style
@as-auction-event-info-panel-heading-height: 35px;

// Outline
.as-auction-event-info-panel {
  margin-bottom: 20px;
}

.as-auction-event-info-panel__wrapper {
  position: relative;
  margin-bottom: 20px;
}

@media (min-width: @screen-xs-max){
  .as-auction-bidding-dahsboard-button__wrapper{
    .as-bidding-dashboard-link{
      width: 68%;
      margin: auto;
    }

    .as-bidding-dashboard-description{
      width: 68%;
      margin: auto;
    }

  }
}

.as-auction-event-info-panel__heading {
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;

  p {
    .as-heading__base;
    min-height: @as-auction-event-info-panel-heading-height;
    margin-bottom: 0;
    background-color: rgba(15,15,15,.88);
    font-size: @font-size-h4;
    font-weight: bold;
    color: @as-heading-text-color-default;
  }

  [class*="as-icon--"] {
    margin-right: 4px;
  }
}

.as-auction-event-info-panel__body {
  overflow: hidden;  // ceil .row negative margin
}

// Contents style

// Left Contetns
// Image and title
.as-groups-auction-event-info__main {
  position: relative;
  margin-bottom: 10px; // minimum space
}

.as-groups-auction-event-info__main-container {
  position: relative;
  height: 100%;
}

.as-groups-auction-event-info__main-image {
  height: 100%;
  min-height: 130px;

  img {
    // Convertible with horizontal image
    // ex) facebook banner(1200 x 628)
    // width: 690px;
    width: 100%;
    height: auto;
  }
}
@media (max-width: @screen-xs-max){
  .as-groups-auction-event-info__main-image:after{
    content:"";
    position:absolute;
    top:0;
    left:0;
    display:block;
    width:100%;
    height:100%;
    background-color:rgba(0,0,0,.55)
  }
}

.as-groups-auction-event-info__main-contents {
  .display-flex;
  .flex-flow(column,nowrap);
  .justify-content(center);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  @media (max-width: @screen-xs-max) {
    bottom: 5px;  // Margin
  }
}

.as-groups-auction-event-info__title {
  font-family: 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ Pro W3', Meiryo, 'メイリオ', Osaka, 'MS PGothic', arial, helvetica, sans-serif;
}
.as-groups-auction-event-info__title {
  .as-responsible-font-size(manual, @font-size-h5, @font-size-h5, (@font-size-h5 + 2px), (@font-size-h5 + 4px));
  font-weight: lighter;
  letter-spacing: .08em;
  line-height: 1.4;
  word-break: break-word;
  word-wrap: break-word;
  overflow-wrap: break-word;

  span{
    display: inline-block;
  }

  // Fix fore each languages
  html:lang(ja) & {
    line-height: 1.6;
  }
  html:lang(en) & {
    .as-responsible-font-size(manual, @font-size-h5, @font-size-h5, (@font-size-h5 + 2px), (@font-size-h5 + 4px));
  }
}

.as-groups-auction-event-info__description {
  margin-bottom: 0
}

// Main Contents Alternative Styles
.as-groups-auction-event-info__main-contents--default {
  .as-groups-auction-event-info__main-contents--info{
    position: absolute;
    bottom:0px;
    width:100%;
    background-color: fadeout(#1a1a1a, 35%);
    padding:5px 0px;
    .as-groups-pricing-info-table{
      margin:5px 0px;
    }
    table th, table td{
      color:#FFF;
    }
  }
  .as-groups-auction-event-info__title {
    margin: 0px 10px;
    border-bottom:1px solid #FFF;
    color:#FFF;
  }
  .as-groups-auction-event-info__description {
    // min-height: 35%;
    .as-responsible-font-size(h5);
    color: #ddd;
    letter-spacing: .05em;

    // Fix for Demo description
    margin-left: 10px;
    margin-right: 10px;
    letter-spacing: .01em;
  }
}
@media (max-width: @screen-xs-max){
  .as-groups-auction-event-info__main-contents--default{
    .as-groups-auction-event-info__main-contents--info{
      position: absolute;
      top:0px;
      width:100%;
      background-color:initial;
      padding:10px 0px;
      table th, table td{
        font-size:@font-size-h6;
      }
    }
  }
}

.as-groups-auction-event-info__main-contents--title-only {
  .justify-content(center);
  padding-right: 30px;
  padding-left: 30px;
  text-align: center;

  .as-groups-auction-event-info__title {
    margin: 0px 10px;
    background-color: fadeout(#1a1a1a, 15%);
    border-bottom:1px solid #FFF;
  }

  .as-groups-auction-event-info__description {
    display: none;
  }

  @media (max-width: @screen-xs-max) {
    padding-right: 10px;
    padding-left: 10px;

    .as-groups-auction-event-info__title {
      margin: 0px 10px;
    }
  }
}

// Notify
.as-notify {
  .as-groups-auction-event-info__main & {
    position: relative;
    right: 0;
    left: 0;
    height: 30px;
    background-color: @as-notify-background-color;
    overflow: hidden;
  }
}

// Rihgt Contetns
// Event infomation
.as-groups-auction-event-info__details {
  .as-table {
    margin-bottom: 10px;
  }

  //fix for language
  .btn-lg {
    html:lang(vi) & {
      @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
        font-size: @font-size-h3 - 1px;   //17px
      }
    }
  }
}

.as-groups-auction-event-info__details-wrapper {
}

.as-groups-auction-event-info__flags {
  margin-bottom: 10px;

  ul& li {
    margin-bottom: 3px;
  }
  .label {
    display: block;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: @font-size-h6;
    font-weight: bold;
  }

  .as-sale-status {
    .as-sale-status__body {
      &.label {
        border-width: 1px;
        border-radius: 1em;
        font-size: @font-size-h5;
        font-weight: bold;
        white-space: nowrap;
      }
    }
  }
}

.as-groups-auction-event-info__details-period {
  tr& th {
    // Set minimun height
    &:before {
      content: '';
      display: block;
      float: left;
      width: 0px;
      height: 3em * 1.4;
      font-size: inherit;
      overflow: hidden;
    }
  }
  tr& td {
    vertical-align: top;

    > span {
      display: block;
    }
    > span > span {
      display: inline-block;
      white-space: nowrap;
    }
  }
}

// Particle word  e.g.) from, to
.as-groups-auction-event-info__details-period-particle {
  html:lang(en) & {
    min-width: 2em;
    text-align: right;

    @media (min-width: @screen-sm-min) and (max-width: (@screen-md-min - 1)) {
      text-align: left;
    }
  }
}

// Responsive

@media (max-width: @screen-xs-max) {
  // No gutter style
  .as-auction-event-info-panel__wrapper {
    margin-right: -(@as-grid-gutter-width / 2);
    margin-left: -(@as-grid-gutter-width / 2);
  }
  .as-auction-event-info-panel__body {
  }
  .as-groups-auction-event-info__details-wrapper {
    padding-right: (@as-grid-gutter-width / 2);
    padding-left: (@as-grid-gutter-width / 2);
  }

  // Hide sub heading for event details
  .as-groups-auction-event-info__sub-heading {
    .as-heading--hidden-text;
  }

  .as-groups-auction-event-info__main {

  }
}

@media (min-width: @screen-sm-min) {
  .as-auction-event-info-panel__heading {
    p {
      min-height: @as-auction-event-info-panel-heading-height;
      font-size: @font-size-h3;
      line-height: 1;
    }
  }
  .as-auction-event-info-panel__body {
    background-color: @as-brand-base;
    color: @as-heading-text-color-default;

    .row {
      .display-flex;
      .align-items(stretch);

      > [class*="col-"] {
        float: none;
      }
    }
  }
  .as-groups-auction-event-info__main {
    margin-bottom: 0;
    padding-right: 0;

    .as-notify {
      position:absolute;
      bottom: 0;
      left: (@as-grid-gutter-width/2);
      height: 43px;
      background-color: transparent;
    }
  }
  .as-groups-auction-event-info__main-image {
    // Clipping style
    position: relative;
    overflow: hidden;
    min-height: 242px;

    img {
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      width: auto;
      height: 100%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }

  .as-groups-auction-event-info__details {
    .display-flex;
    .flex-flow(column,nowrap);
    .justify-content(center);
    padding-top: @as-auction-event-info-panel-heading-height + 0px;
    padding-bottom: 20px;
    padding-left: 0;
  }
  .as-groups-auction-event-info__details-wrapper {
    // margin-top: 15px;
    padding-right: 30px;
    padding-left: 30px;
  }
  .as-groups-auction-event-info__flags {
    ul& li {
      margin-bottom: 5px;
    }
    .label {
      font-size: @font-size-h5;
      font-weight: normal;
    }
    .as-sale-status {
      .as-sale-status__body {
        &.label {
          font-size: @font-size-h4;
          padding: 3px;
          font-weight: bold;
          white-space: nowrap;
        }
      }
    }
  }
  .as-groups-auction-event-info__sub-heading {
    margin-top: 0;
    border-bottom: 2px solid #fff
  }
  .as-groups-auction-event-info__details {
    &, dt, dd, table>tbody>tr>th, table>tbody>tr>td {
      color: #fff;
    }
    dt, table>tbody>tr>th {
      font-weight: bold;
    }
  }
}


// Responsive

@media (min-width: @screen-sm-min) and (max-width: @screen-md-max) {

  .as-groups-auction-event-info__flags {
    .as-sale-status {
      .as-sale-status__body {
        &.label {
          font-size: @font-size-h5;
        }
      }
    }
  }
}


// Search & Filters
.as-section-auction_top{
  .as-search-header{
    margin-bottom: -10px
  }
}
@media (max-width: @screen-xs-max){
  .as-section-auction_top{
    .as-search-header{
      padding:0px;
    }
  }
}
.panel-category-title_auction{
  position:relative;
  p{
    margin-top:10px;
  }
  .panel-category-list_auction{
    position:absolute;
    z-index:99;
    top:42px;
    left:0px;
    background-color: #F8F8F8;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px; 
    padding:15px;
    box-shadow:0 5px 15px rgba(0,0,0,.5);
  }
  .panel-category-list_auction::after{
    border-left:solid 7px transparent;
    border-right:solid 7px transparent;
    border-bottom:solid 10px #F8F8F8;
    top:-10px;
    content:"";
    display:block;
    height:0;
    left:22px;
    position:absolute;
    width:0;
  }
  ul{
    li{
      font-weight:normal;
      a{
        cursor: pointer;
      }
    }
  }
}
.as-category-filter__auction{
  .btn-primary{
    height: 35px;
    margin-right: 5px;
    padding: 6px 10px;
  }
  span{
    vertical-align: middle;
  }
}
.as-search-header__auction{
  .input-lg{
    font-size:14px;
    font-weight:normal;
    height:35px;
  }
  .btn-lg{
    height:35px;
    width:80px;
  }
  .search-clear {
    position: absolute;
    right: 15px;
    top: 0;
    bottom: 0;
    height: 14px;
    margin: auto;
    font-size: 14px;
    cursor: pointer;
    color:silver;
    z-index: 999;
  }
}

// Views & Numbers
.as-search-result-header__original-words{
  margin-left: 10px;
}
.as-search-result-header__hit-number{
  font-weight: bold;
  font-size:16px;
  margin-left: 5px;
}

// Item list
.as-section-auction_top{
  @media (min-width: 1200px) {
    .as-search-list-goods--auction.as-search-list-goods .as-search-list-goods__item {
      -webkit-box-flex: 0;
      -ms-flex: 0 0;
      -webkit-flex: 0 0 auto;
      flex: 0 0 auto;
      width: initial;
      }
    }
  @media (max-width: 991px) and (min-width: 768px){
    .as-search-list-goods--auction.as-search-list-goods .as-search-list-goods__item {
      -webkit-box-flex: 0;
      -ms-flex: 0 0;
      -webkit-flex: 0 0 auto;
      flex: 0 0 auto;
      width: initial;
      margin-bottom: 30px;
    }
  }
}

// Item info
.as-search-list-goods__body{
  height: initial;
}
.as-search-list-goods__features{
  ul{
    li{
      font-weight:bold;
      font-size:12px;
      .as-search-list-goods__items{
        font-weight: bold;
      }
      span{
        font-weight:normal;
      }
      .as-search-list-goods__price{
        font-weight:bold;
        font-size:16px;
      }
      .as-search-list-goods__price-undecided{
        font-weight:bold;
        font-size:16px;
        color: @as-brand-base;
      }

    }
  }
}
