.as-nav-tabs {}
.as-nav-tabs__list {
  .display-flex;
}
.as-nav-tabs__list-item {
  .flex(1,1,auto);
  background: @as-button-default-background-color;
  text-align: center;

  h3, .h3 {
    margin: 0;
  }

  a {
    display: block;
    padding-top: 15px;
    padding-bottom: 15px;

    &:hover {
      background-color: @as-button-default-background-color-focus;
      color: @as-button-default-text-color;
      text-decoration: none;
    }
    &:focus {
      color: @as-button-default-text-color;
      text-decoration: none;
    }
  }
}

.as-nav-tabs__list-item--selected {
  background: transparent;
  border-top: 2px solid @as-brand-key;

  h3{
    display: block;
    padding-top: 14px;
    padding-bottom: 14px;
  }
}


// Responsive

@media (max-width: @screen-xs-max) {
  .as-nav-tabs__list-item h3, .as-nav-tabs__list-item .h3 {
    padding-top: 17px;
    padding-bottom: 17px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 1em;
  }
}
