// Login Modal
// Use with Bootstrap .modal class


@media (max-width: @screen-xs-max) {
  .as-groups-login-modal {
    .modal {
      .modal-body {
        padding: (@modal-inner-padding - 15px)  (@modal-inner-padding - 15px) (@modal-inner-padding - 15px);
      }
    }
  }
}

@media (min-width:@screen-sm-min) {
  .as-groups-login-modal {
    .modal-dialog {
      max-width: 400px;
    }
    .modal-content {
      background-color: @as-widget-background-color;
    }
    .modal-body {
      padding: 30px;
    }
  }
}
