@import '../sitewide.less';

.auction-announce-contents {

    color: @text-color;
    
    .sw-h2 {
        font-weight: 700;
    }

    .sw-h3 {
        font-weight: 700;
    }


     .button-center {
            margin-top: 32px;
            text-align: center;
        }
        .video-full {
            width: 100% !important;
            height: auto !important;
            background: @grey-1;
        }
        .row-space-top {
            margin-top: 48px;
        }

        .schedule {
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
            align-items: center;
            -webkit-justify-content: center;
            justify-content: center;
            flex-flow: column nowrap; 

            width: 100%;
            max-width: 1100px;
            margin: 0 auto 64px;
            box-sizing: border-box;

            background-image: url("../images/auction-announce/auction-teaser-top.png");
            background-size:　cover;
            background-position: center; 
        }

        .schedule-details {
            font-size: @font-size-4;
        }
        .schedule_header {
            font-size: @font-size-1;
        }

        .auction-announce-time {
            font-size: @font-size-3;
        }



        .join {
            width: 100%;
            max-width: 600px;
            margin: 0 auto 64px;
            box-sizing: border-box;
        }



        .about {
            width: 100%;      
            margin: 0 auto 64px;
            padding: 24px;
            box-sizing: border-box;

            background-image: url("../images/auction-announce/heavy-equip-1.jpg");
            background-size:cover;
            background-position: center; 
        }
        .about-info {
            max-width: 400px;
            padding: 24px;
            background: #FFB900;
            opacity: 0.9;
        }


        .how {
            width: 100%;
            max-width: 1100px;
            margin: 0 auto 64px;
            padding: 0 32px;
            box-sizing: border-box;
        }


       .faq {
            width: 100%;
            margin: 0 auto 64px;
            padding: 32px;
            box-sizing: border-box;

            background: @grey-1;
       }


       .contact {
            padding-bottom: 64px;
            box-sizing: border-box;
            font-size: @font-size-3;
            text-align: center;
       }

        @media(max-width: @screen-xs-max){
            background-image: none;
            .schedule {
                margin-top: 0;
                padding: 24px;
                background: @grey-1;
            }
            .schedule_header {
                font-size: @font-size-3;
            }
            
            .schedule-details {
                font-size: @font-size-4;
            }
            .auction-announce-time {
                font-size: @font-size-4;
            }
        }
        @media(min-width: @screen-sm-min){
            .schedule {
                min-height: 260px;
            }
        }
        @media(min-width: @screen-md-min){
            .schedule {
                min-height: 300px;
            }
            .about {
                padding: 48px;
            }
            .about-info {
                padding: 48px;
            }
        } 
        @media(min-width: @screen-lg-min){}
}