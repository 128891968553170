// Serch View Forms

.as-search-result-footer {
  .as-clearfix;

  > div[class^="col-"] {
    > *:first-child {
      margin-left: 15px; //TODO
    }
    > *:last-child {
      margin-right: 15px; //TODO
    }
  }

  margin-bottom: 20px;

  .as-pagination {
    margin: 0 0;
  }

  .as-pagination {
    float: right;
  }
}


@media (max-width: @screen-xs-max) {
  .as-search-result-footer {

    .as-pagination {
      float: none;
      display: block;
      margin-right: 0;
      margin-left: 0;
      text-align: center;

      > li {
        display: inline-block;
      }
    }

  }
}
