// Tables

// Responsive Table
//
// Mobile: Row style, Other: Column Style

.as-responsive-list-table {
  tbody > tr > th {
    padding-right: 50px;
    white-space: nowrap;
  }

  @media (max-width:@screen-xs-max) {
    table& {
      border-collapse: separate;
    }
    tbody > tr > th,
    tbody > tr > td {
      display: table;
      width: 100%;
      border-top: none;
      padding-right: 0;
      padding-left: 0;
    }
    tbody > tr > th {
      border-bottom: 1px solid @as-sub-border-color;
      padding-top: 0;
      padding-bottom: 0;
    }
    tbody > tr > td {
      padding-top: 10px;
      padding-bottom: 30px;
    }

  }

}

// Flexible Table
//
// Use with applying bootstrap "col-" class to tbodys.

.as-flexible-table--default {
  .as-flexible-table__base;
  font-size: @as-flexible-table-font-size;

  tr {
    border-bottom: 1px dotted @as-flexible-table-border-color;
  }
  th {
    color: @as-flexible-table-head-color;
  }
  td {
    color: @as-flexible-table-description-color;
    word-break: break-all;
    word-wrap: break-word;
  }

}


.as-flexible-table--minimum {
  .as-flexible-table__base;
  .as-flexible-table--default;
  margin-top: 5px;

  tbody {
    padding-right: 0;
    padding-left: 0;

  }
  tr {
    display: table-row;
    padding-top: 3px;
    padding-right: 10px;
    padding-bottom: 0;
    padding-left: 0;
    // line-height: floor(@as-flexible-table-font-size * 1.72); // 24
    border-bottom: none;
  }
  th {
    padding-left: 0;
  }
}


// Options

.as-flexible-table__borderless-row {
  tr& {
    border-bottom-width: 0;
  }
}


// Overridie table class

// Goods info table
//
// Use with Flexible Table

.as-goods-info-table {
}

@media (min-width: @screen-sm-min) {

  .as-goods-info-table {
    .as-flexible-table__multi-column-set(2);
  }

}


// Spec table
//
// Use with Flexible Table

.as-spec-table {
  table-layout: fixed;
}

.as-spec-table__wrapper {
}

.as-spec-table__image-wrapper {
  margin-bottom: 10px;
}

.as-spec-table__image {
  text-align: center;

  img {
    display: inline-block;
    max-height: 100%;
    min-height: 90px;
  }
}

.as-spec-table__spec-wrapper {
  .as-flexible-table__responsible-column-count(1,2,2,2);
  .column-gap(@grid-gutter-width * 1.5);

  // for IE: to prevent breaking a group having 1 group in 2 columns sytle when
  > *[class^="as-spec-table__"]:first-child {
    &:after {
      content: "";
      display: none;
      width: 100%;
      height: 3em;
    }
  }
  > *[class^="as-spec-table__"]:last-child {
    &:after {
      display: block;
    }
  }
}

.as-spec-table__lead-spec-wrapper,
.as-spec-table__other-spec-wrapper {

  .as-spec-table {
    .break-inside(avoid);
    margin-bottom: 0;

    tbody {
      .break-inside(avoid);
    }

    td {
      text-align: right;
    }
  }


  // to make space in 2 columns sytle
  .as-spec-table {
    &:after {
    content: "";
    display: block;
    width: 100%;
    height:  20px;
    }
  }
  &:last-child {
    .as-spec-table {
      &:after {
        height: 10px;
      }
    }
  }
}


// for AS original panel option .panel-option-collapsed-body

.as-spec-table {
  .panel-option-collapsed-body & {
    tbody {
      // for IE: to prevent breaking a group having 1 group in 2 columns sytle when
      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 3em;
      }
    }
  }
}

// to make space in 2 columns sytle2
.as-spec-table {
  &:after {
    .panel-option-collapsed-body & {
      height: 0;
    }
  }
}

.as-spec-table__image-wrapper {
  .panel-option-collapsed-body & {
    width: 24%;
    margin-bottom: 0;

    img {
      max-height: 90px;
    }
  }
}

.as-spec-table__other-spec-wrapper {
  .panel-option-collapsed-body & {
    display: none;
  }
}




@media (max-width: @screen-xs-max) {

  .as-spec-table__image-wrapper {
    .panel-option-collapsed-body & {
      width: 100%;
    }
  }

  .as-spec-table {
    .panel-option-collapsed-body & {
      tbody {
        // for IE: to prevent breaking a group having 1 group in 2 columns sytle when
        &:after {
          display: none;
        }
      }
    }
  }

}

@media (min-width: @screen-sm-min) {

  .as-spec-table {
    .panel-option-collapsed-body & {
      tbody {
        // for IE: to prevent breaking a group having 1 group in 2 columns sytle when
        &:after {
          display: none;
        }
      }
    }
  }

  .as-spec-table__spec-wrapper {
    .st-model & {
      width: 100% !important;

    }
    .panel-option-collapsed-body & {
      width: 75%;
      padding-right: 0;
      .columns(1,auto);
    }
  }
  .as-spec-table {
    .panel-option-collapsed-body & {
      .as-flexible-table__multi-column-set(2);

      tr {
        .display-flex();
      }
      td {
        display: block; //hack for IE
        .flex(1,1,auto);
      }
    }
  }

}


// for AS supporer info table

.as-groups-supporer-info-table {
  .break-inside(avoid);
  margin-bottom: 20px;

  tbody {
    .break-inside(avoid);
  }

  td {
    text-align: right;
  }
}

@media (min-width: @screen-sm-min) {
  .as-groups-supporer-info-table {
    .as-flexible-table__multi-column-set(2);

    tr {
      .display-flex();
    }
    td {
      display: block; //hack for IE
      .flex(1,1,auto);
    }
  }
}


//  Mixed columns Table
//
// ex) 1 column(row) style + 2 columns style in one table

.as-mixed-columns-table {
  table& {
  }
}

// Add for tbody
.as-mixed-columns-table__group {
  tbody& {
    display: table;
    width: 100%;
  }
}

// Force to show in one row per one th or td element
.as-mixed-columns-table__group--row {
  tbody& {
    th,td {
      display: table;
      border-collapse: separate;  // For activate padding
      padding-left: @as-grid-gutter-width / 2;
      padding-right: @as-grid-gutter-width / 2;
    }
    th {
      padding-bottom: 0;
    }
    td {
      padding-top: 5px;
    }
  }
}
