.lp-seller {
  max-width: 840px;
  margin: auto;

  & + .as-footer{
      padding-bottom: 94px;
  }

  h1{
      text-align: center;
      margin-top: 0;
      padding: 40px 0;
      max-height: 174px;
      & img{
          width: 90%;
          max-width: 747px;
      }
  }

  h2{
      margin-left: 10px;
      margin-bottom: 40px;
      & > span{
          border: 4px solid #edd20f;
          padding: 20px 15px;
          position: relative;
      }

      & .badge-small{
          &::before{
              position: absolute;
              top: -15px;
              left: -15px;
              margin: 0;
          }
      }
  }

  h3{
      font-size: 31px;
  }

  .important-text{
      color: #fe3737;
  }

  .catch-text{
      color: #9a8700;
  }
  
  .bold-text{
      font-weight: bold;
  }

  .large-text{
      font-size: 42px;
  }

  .badge-large{
      background-color: rgba(255,228,63,.85);
      border-radius: 50%;
      width: 185px;
      height: 185px;
      position: relative;
      text-align: center;
      padding: 38px 20px;
      color: #000000;
      & .catch{
          font-size: 32px;
      }
      & dt{
          margin-bottom: 10px;
      }
      & dd{
          line-height: 2em;
      }
      &::before{
          content: "";
          display: block;
          position: absolute;
          top: 4px;
          bottom: 4px;
          right: 4px;
          left: 4px;
          margin: auto;
          border: 4px solid #ffffff;
          border-radius: 50%;
      }
  }
  .badge-small{
      position: relative;
      &::before{
          content: "";
          display: block;
          width: 40px;
          height: 40px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          left: 3.5%;
      }
      &.badge-s_01{
          &::before{
              background-image: url(/images/lp/badge_01.png);
          }
      }
      &.badge-s_02{
          &::before{
              background-image: url(/images/lp/badge_02.png);
          }
      }
      &.badge-s_03{
          &::before{
              background-image: url(/images/lp/badge_03.png);
          }
      }
  }

  .header-box{
      position: absolute;
      top: 38px;
      right: 48px;
      padding-top: 5px;
      & .phone-number-box{
          & .phone-number{
              font-size: 26px;
              & .tip{
                  font-size: 10px;
                  padding: 0 5px;
              }
              & + p{
                  font-size: 11px;
              }
          }
      }
  }

  .fixed-box{
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 10;
      background-color: #f04d4d;
      padding-top: 15px;
      padding-bottom: 15px;
      color: #ffffff;
      & .phone-number-box{
        & .phone-number{
            &::before{
                content: "";
                display: inline-block;
                width: 30px;
                height: 30px;
                background-image: url('../images/lp/icon-phone.svg');
                background-position: center;
                background-repeat: no-repeat;
                margin-right: 7px;
                margin-bottom: -7px;
            }
            a{
                text-decoration: underline !important;
                color: #ffffff;
            }
        }
        & p{
            text-align: center;
            margin-bottom: 10px;
        }
        & .fixed-box-title{
            font-weight: bold;
            font-size: 22px;
        }
    }
  }

  .landing-top{
      background-image: url(/images/lp/LPtop002.png);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      .top-image{
          text-align: center;
          img{
              width: 350px;
          }
      }
      & .badge-list{
          margin: 0;
          display: flex;
          justify-content: space-around;
          align-items: center;
          li{
              text-align: center;
          }
      }
  }

  .additional-message{
      background-color: #d9d9d9;
      padding: 10px 30px;
      div{
          text-align: center;
      }
      img{
          width: 100%;
      }
  }

  .allstocker-summary{
      background-color: #f2f2f2;
      h2{
          font-size: 36px;
          text-align: center;
          margin: auto;
          padding: 40px 0;
      }
      .scene-list{
          margin: 0 40px;
          li{
              margin-top: 12px;
              background-color: #ffffff;
              padding: 15px 15px 15px 12.5%;
              font-size: 21px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              &:first-child{
                  margin-top: 0;
              }
              & > span{
                  flex: 1 1 auto;
              }
          }
      }
      p{
          text-align: center;
          font-size: 24px;
          line-height: 100%;
          padding: 20px 0;
          margin: 0;
          font-weight: bold;
         & > .icon-plus{
              margin: auto;
              display: block;
              width: 40px;
              height: 40px;
              background-color: #fff;
              color: #ffcc00;
              font-size: 40px;
              font-weight: bold;
              border-radius: 50%;
              padding: 4px 8px;
          }
      }
  }

  .benefit-list{
      margin: 0 30px;
      .benefit-listItem{
          background-color: #fff; 
          padding: 15px;
          & > dt{
              text-align: center;
              font-size: 24px;
          }
          & > dd{
              font-size: 21px;
              margin: 10px 0 5px 0;
              img{
                  width: 100%;
              }
          }
      }
  }

  .contact-box{
      margin: 20px 40px;
      border: 4px solid #edd20f;
      box-sizing: border-box;
      position: relative;
      padding: 20px;

      &::before{
          content: "";
          position: absolute;
          top: 6px;
          bottom: 6px;
          right: 6px;
          left: 6px;
          margin: auto;
          border: 2px solid #edd20f;
          pointer-events: none;
      }

      &.with-operator-image{
          &::after{
              content: "";
              width: 135px;
              height: 164px;
              position: absolute;
              bottom: 8px;
              right: 7px;
              background-image: url(/images/lp/operator.png);
              background-repeat: no-repeat;
              background-size: cover;
              background-position: bottom right;
          }
      }

      & > p{
          margin: 20px 0 30px 0;
          text-align: center;
          font-size: 22px !important;
          font-weight: bold;
      }

      & .contact-buttonArea{
          background-color: #fffbdb;
          outline: 15px solid #fffbdb;
          padding: 0 135px 0 25px;
          margin-top: 40px;
      }

      & .icon-or{
          display: block;
          width: 27px;
          height: 27px;
          border-radius: 50%;
          background-color: #edd20f;
          padding: 2px 5px;
          margin: 5px auto;
          text-align: center;
          font-size: 14px;
      }

      
      .mail-address-box{
          & .mail-address{
              font-size: 28px;
              font-weight: bold;
          }
          & p{
              font-size: 15px;
              margin: 0;
              text-align: center;
          }
      }
  }
  & .phone-number-box{
      & .phone-number{
          font-size: 32px;
          font-weight: bold;
          display: flex;
          justify-content: center;
          align-items: center;
          white-space: nowrap;
          & .tip{
              background-color: #4fa22c;
              color: #fff;
              font-size: 13px;
              font-weight: normal;
              padding: 5px;
              margin-right: 5px;
          }
          & + p{
              font-size: 15px;
              text-align: center;
          }
          & a{
              cursor: pointer;
          }
      }
      & p{
          margin: 0;
      }
  }

  & .btn-contact{
      position: relative;
      min-height: 55px;
      font-weight: bold;
      font-size: 22px;
      white-space: normal;
      display: flex;
      justify-content: center;
      align-items: center;
  }


  .scene-details{
      margin-top: 80px;
      h3{
          font-size: 27px;
          padding-left: 20px;
          position: relative;
          &::before{
              content: "";
              display: block;
              width: 7px;
              height: 7px;
              border-radius: 50%;
              background-color: #4c4c4c;
              position: absolute;
              top: 12px;
              left: 6px;
          }
      }
      & > div,
      & > p{
          margin: 0 20px 20px;
      }
      & p{
          font-size: 18px;
      }
      & .example-list{
          background-color: #f2f2f2;
          padding: 10px;
          font-size: 18px;
          & li{
              display: flex;
              justify-content: space-between;
              align-items: center;
          }
          & li:not(:first-child){
              margin-top: 10px;
          }
      }
      & .inspecting-detail{
          background-color: #f2f2f2;
          & > div{
              padding-left: 0;
              img{
                  width: 100%;
              }
          }
          & .inspecting-description{
              padding: 10px 10px 10px 0;
          }
      }
      & .app-download{
          > dt{
              text-align: center;
              margin-top: 10px;
          }
          > dd{
              text-align: center;
              padding: 10px 0 20px;
          }
      }
      & .feature-label{
          float: right;
          padding: 5px 15px;
          background-color: #ffd11a;
          font-size: 18px;
          text-align: center;
      }
      & .auction-details,
      & .market-details{
          img{
              width: 100%;
          }
      }
      & .bid-up-example{
          & .bid-up-example-list{
              & li{
                  margin-top: 10px;
                  text-align: center;
                  img{
                      max-width: 100%;
                  }
              }
          }
      }
      & .service-description{
          font-size: 18px;
          dt{
              margin-bottom: 10px;
          }
      }
      & .big-text{
          font-size: 27px;
          line-height: 150%;
      }
  }

  .user-reviews,
  .sales-compare,
  .media-coverage{
      margin-top: 60px;
      padding: 15px;
      h3{
          text-align: center;
          padding: 0 0 10px 0;
      }
  }
  
  .user-reviews{
      background-color: #f2f2f2;
      .user-review-list{
          li{
              padding: 15px 10px 0 10px;
              dl{
                  background-color: #fff;
                  padding: 10px 0 40px;
                  margin: 0;
                  img{
                      width: 100%;
                      max-width: 122px;
                  }
              }
              p{
                  position: absolute;
                  bottom: 5px;
                  width: calc(~"100% - 20px");
                  text-align: center;
                  margin: 0;
              }
          }
      }
  }
  
  .media-coverage{
      .media-list{
          li{
              padding: 15px 0 0 0;
              dt{
                  text-align: center;
                  img{
                      width: 100%;
                      max-width: 152px;
                  }
              }
              dd{
                  text-align: center;
              }
          }
      }
  }

  .sales-compare{
      background-color: #f2f2f2;
      .compare-list{
          li{
              padding: 15px 10px 0 10px;
              dl{
                  border: 2px solid #777777;
                  box-sizing: border-box;
                  background-color: #fff;
                  padding: 15px;
                  margin: 0;
                  text-align: center;
                  position: relative;
                  dt{
                      font-size: 21px;
                      margin-bottom: 10px;
                  }
              }
              &.catch{
                  padding: 0 10px 0 10px;
                  dl{
                      border: 4px solid #edd20f;
                      padding-top: 30px;
                      &::before{
                          content: "";
                          display: block;
                          position: absolute;
                          top: 5px;
                          left: 10px;
                          width: 60px;
                          height: 25px;
                          background-image: url(/images/lp/stars.svg);
                          background-repeat: no-repeat;
                          background-size: contain;
                          background-position: center;
                      }
                  }
              }
          }
      }
  }


  //レスポンシブ対応
  @media (max-width: @screen-xs-max){
      h2{
          white-space: pre-line;
          margin-left: 0;
          & .badge-small{
              &::before{
                  top: -10px;
                  left: -10px;
              }
          }
      }

      h3{
        white-space: pre-line;
        line-height: 130%;
      }

      & .badge-large{
          width: 158px;
          height: 158px;
          padding: 34px 20px;
          font-size: 14px;
      }

      & .badge-small{
          &::before{
              width: 30px;
              height: 30px;
          }
      }

      & .header-box{
          transform: scale(0.75);
          top: 5px;
          right: -20px;
      }

      & .landing-top{
          .row{
              margin: 0;
          }
      }

      & .additional-message{
          padding: 10px 10px;
      } 

      & .allstocker-summary{
        h2{
            font-size: 32px;
        }
        p{
            font-size: 20px;
            white-space: pre-line;
        }
        .scene-list{
            margin: 0 10px;
            li{
                font-size: 15px;
            }
        }
      }

      & .benefit-list{
          margin: 0;
          .benefit-listItem{
              margin-bottom: 12px; 
          }
      }

      & .contact-box{
          margin: 20px 10px;
          padding: 15px;
          & .contact-buttonArea{
              padding: 0 135px 0 0;
              outline: 10px solid #fffbdb;
          }
          & .btn-contact{
              white-space: pre-line;
              padding: 7px 6px 9px;
          }

          & > p{
              font-size: 18px !important;
          }
          & p{
              white-space: pre-line;
          }
      }

      & .scene-details{
          margin: 70px 10px 0 10px;
          h3{
              font-size: 23px;
          }
          & > div{
              margin: 0 10px 20px;
          }
          & p{
              white-space: pre-line;
          }
          & .inspecting-detail{
              & > div{
                  padding-right: 0;
              }
              & .inspecting-description{
                  padding: 10px;
              }
          }
          & .feature-label{
              float: none;
              display: block;
              padding: 5px 10px;
            }
        & .example-list{
            font-size: 15px;
            white-space: pre-line;
        }
      }

      & .sales-compare{
          p{
              white-space: pre-line;
          }
      }
  }
}