// Mail Batch Modal
// Use with Bootstrap .modal class


#modal-to-send-mail-batch .modal-content {
  ul&, ul {
    list-style: none;
  }
  p {
    margin-bottom: 5px;
  }
  min-height: 180px * 1;
  padding: 10px 0;

  .as-loading-view;
}