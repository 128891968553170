// Button

.as-auction {
  .btn {
    white-space: normal;

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
      // Not use opacity at root
      .opacity(1);

      [class*="as-icon__"] {
        .opacity(.45);
      }
    }

  }

  .btn-purchase {
    .button-variant(@btn-purchase-color; @btn-purchase-bg; @btn-purchase-shadow; @btn-purchase-color-focus; @btn-purchase-bg-focus; @btn-purchase-shadow-focus;);
  }

  .btn-transition {
    .button-variant(@btn-transition-color; @btn-transition-bg; @btn-transition-shadow; @btn-transition-color-focus; @btn-transition-bg-focus; @btn-transition-shadow-focus;);
  }

  .btn-buyout {
    .button-variant(@btn-buyout-color; @btn-buyout-bg; @btn-buyout-shadow; @btn-buyout-color-focus; @btn-buyout-bg-focus; @btn-buyout-shadow-focus;);
  }
}
