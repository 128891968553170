.as-header {
  .as-navigation {
    .navigationWrapper {
      display: flex;
      justify-content: center;
      align-items: center;

      .userInformation {
        margin-right: 10px;
        word-break: break-all;
      }
      .btn-request-bid-right {
        color: #202020;
        background-color: #ffc001;
        padding: 3px 10px 3px 10px;
        margin-right: 10px;
      }
      .btn-request-bid-right:hover {
        color: #202020;
        background-color: #EFA000;
      }
    }
  }

  .userInformationInToglle {
    word-break: break-all;
  }
}