// Forms

// Common form controls

.form-control {
  select& {
    padding: 5px 24px 5px 7px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    // for IE 10 or later
    &::-ms-expand {
      display: none;
    }
    border-radius: 0;
    .background-image-icon('../images/icon-arrow-dropdown.svg', 20px, 20px,  2px, center, right)
  }

  input&[type="email"],
  input&[type="password"] {
    letter-spacing: 0.02em;
  }
}


// AS Original Classes

// Flex forms
//
// Make forms appear Flex imtes by adding the `.form-flex` class.
//
// Requires wrapping inputs and labels with `.form-group` for proper display of
// default HTML form controls and our custom form controls (e.g., input groups).

.form-flex {

  @media (min-width: @screen-sm-min) {

    .form-group {
      .display-flex;
      .align-items(center);

      .form-control {
        .flex(1,1,auto);
      }
    }
  }

}
