// Search Result Header

// Auction Search Result Header
// Override .as-search-result-header
@media (min-width: @screen-sm-min) {
  .as-search-list-panel--auction {
    .as-search-result-header {
      .as-pagination {
        margin-right: 0;
      }
    }
  }
}
