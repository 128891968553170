// Bid Input Modal
// Use with Bootstrap .modal class

.as-groups-bid-input-modal {

  .modal-dialog {
  }
  .modal-body {
    padding-bottom: 0;
  }
  .modal-footer {
    padding-top: 0;

    .panel {
      margin-bottom: 0;
    }

    .text-notice {
      margin-bottom: 0;
    }

    .btn-purchase.btn-lg,
    .btn-buyout.btn-lg {
      font-weight: bold;
    }

  }

}


// Responsive

@media (max-width: @screen-xs-max){

  .as-groups-bid-input-modal {
    .modal-responsive-layout;
  }

}

@media (min-width: @screen-sm-min) {

  .as-groups-bid-input-modal {
    .modal-dialog {
      max-width: 400px;
    }
  }

}


// Landscape style (Mobile and PC)
.as-groups-bid-input-modal {
  &.modal-landscape {

    .as-groups-bid-confirm-modal__price {
      // Override
      table.as-bid-price-table {
        & {
          th {
            font-size: @font-size-h6;
            letter-spacing: -.02em;
          }
          td {
            .text-price, .text-currency {
              font-size: @font-size-h5;
            }
          }
        }
      }
    }

  }
}
