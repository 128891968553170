// Wizard widget style
// included in .as-main-contents-body class
// .as-wizard is used in .as-main-contents-header, as-main-contents-body, as-main-contents-footer

.as-wizard {
  max-width: 600px;
  min-height: 400px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 20px;
  text-align: center;

  .as-main-contents-header &,
  .as-main-contents-footer & {
    min-height: 0;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .as-main-contents-header & {
    text-align: left;
  }
}

.as-wizard__title {
  .as-main-contents-header__title {
    background: none;
    h1, h2 {
      height: @as-heading-height;
    }
  }
}

.as-wizard__breadcrumb {
  .breadcrumb {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .as-icon--back-link {
    margin-left: 0;
  }
}

.as-wizard__step {
  margin-top: 10px;
  margin-bottom: 10px;

  .as-wizard__breadcrumb + & {
    margin-top: 0;
  }

}

.as-wizard__lead {
  font-size: @font-size-h3;
  margin-bottom: 20px;

  .as-main-contents-header & {
    margin-bottom: 0;
  }
}

.as-wizard__footer-button {
  margin-bottom: 30px;
  text-align: center;

  .btn, &.btn {
    min-width: 260px;
  }

}




// Responsive

@media (max-width: @screen-xs-max) {
  .as-wizard {
    margin: 30px auto 10px;
    padding-right: @as-grid-gutter-width/2;
    padding-left: @as-grid-gutter-width/2;
  }
  .as-wizard__footer-button {
    .btn, &.btn {
      display: block;
    }
  }
}
