// Index Page Introduction Area Contents Style


.as-jumbotron-carousel__back-image {
  img[src$=".svg"] {
    width: 100%;  // for SVG image on IE
  }
}
.as-groups-introduction__catch,
.as-groups-introduction__subcatch {
  display: block;
  text-align: center;
  font-weight: bold;
  text-shadow: 0 0 2px #000;
}
.as-groups-introduction__catch {
  .as-responsible-font-size(manual,30px,50px,50px,50px);
}
.as-groups-introduction__subcatch {
  .as-responsible-font-size(manual,30px,30px,30px,30px);
}

html:lang(vi){
  .as-groups-introduction__catch,
  .as-groups-introduction__subcatch {
    font-family: "Tahoma";
  }
}

//１枚目 利用社数
.as-groups-introduction-1 .as-groups-introduction__contents{
  position: absolute;
  width: 100%;
  height: 50%;
  top: 25%;
  left: 0;
  text-align: center;
}

.as-groups-introduction-1 .as-groups-introduction__contents .as-groups-introduction__catch{
  font-size: 30px;
  position: static;
  color: #333;
  font-weight: 800;
  text-shadow: #fff 2px 2px 0px , #fff -2px 2px 0px , #fff 2px -2px 0px , #fff -2px -2px 0px;
  word-break: break-word;
  word-wrap: break-word;
  width: 100%;
}

.as-groups-introduction-1 .as-groups-introduction__contents .as-groups-introduction__catch .as-countup,
.as-groups-introduction-1 .as-groups-introduction__contents .as-groups-introduction__subcatch .as-countup{
  font-size: 80px;
  position: static;
  color: #ffc001;
  text-shadow: #fff 2px 2px 0px , #fff -2px 2px 0px , #fff 2px -2px 0px , #fff -2px -2px 0px;
}

.as-groups-introduction-1 .as-groups-introduction__contents .as-groups-introduction__subcatch{
  font-size: 24px;
  position: static;
  color: #333;
  font-weight: 800;
  text-shadow: 0px 2px 0px rgba(255,255,255,0.3);
  display: block;
}

@media (min-width: @screen-sm-min) and (max-width: @screen-md-min) {

  .as-groups-introduction-1 .as-groups-introduction__contents .as-groups-introduction__catch .as-countup,
  .as-groups-introduction-1 .as-groups-introduction__contents .as-groups-introduction__subcatch .as-countup{
    font-size: 30px !important;
  }

  .as-groups-introduction-1 .as-groups-introduction__contents {
    top: 30%;
    height: 20%;
    margin-top: 2%;
  }
}

@media (max-width:@screen-xs-max) {

  .as-groups-introduction-1 .as-groups-introduction__catch , .as-groups-introduction-1 .as-groups-introduction__subcatch , .as-groups-introduction-1 .as-groups-introduction__catch .as-countup,
  .as-groups-introduction-1 .as-groups-introduction__catch , .as-groups-introduction-1 .as-groups-introduction__subcatch , .as-groups-introduction-1 .as-groups-introduction__subcatch .as-countup{
    font-size: 18px !important;
    // font-size: 16px !important;
    font-weight: 700 !important;
  }

  .as-groups-introduction-1 .as-groups-introduction__contents {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    // display: block;
    // height: 50px;
  }

  .as-groups-introduction-1 .as-groups-introduction__contents .as-groups-introduction__catch{
    margin-bottom: .5em;
  }
}


html:lang(en),
html:lang(vi){
  @media (max-width:@screen-xs-max){
    .as-groups-introduction-1 .as-groups-introduction__catch , .as-groups-introduction-1 .as-groups-introduction__subcatch , .as-groups-introduction-1 .as-groups-introduction__catch .as-countup,
    .as-groups-introduction-1 .as-groups-introduction__catch , .as-groups-introduction-1 .as-groups-introduction__subcatch , .as-groups-introduction-1 .as-groups-introduction__subcatch .as-countup{
      font-size: 16px !important;
    }
    .as-groups-introduction-1 .as-groups-introduction__contents {
      bottom: 30%;
    }
  }
  @media (min-width: @screen-sm-min) and (max-width: @screen-lg-min){
    .as-groups-introduction-1 .as-groups-introduction__catch {
      font-size: 29px;
    }
  }
}

html:lang(th){
  @media (max-width:@screen-xs-max){
    .as-groups-introduction-1 .as-groups-introduction__catch , .as-groups-introduction-1 .as-groups-introduction__subcatch , .as-groups-introduction-1 .as-groups-introduction__catch .as-countup,
    .as-groups-introduction-1 .as-groups-introduction__catch , .as-groups-introduction-1 .as-groups-introduction__subcatch , .as-groups-introduction-1 .as-groups-introduction__subcatch .as-countup{
      font-size: 16px !important;
    }
    .as-groups-introduction-1 .as-groups-introduction__contents {
      bottom: 40%;
    }
  }
}



//2枚目 在庫数
.as-groups-introduction-2 .as-groups-introduction__contents{
  position: absolute;
  width: 100%;
  height: 50%;
  top: 25%;
  left: 0;
  text-align: center;
}

.as-groups-introduction-2 .as-groups-introduction__contents .as-groups-introduction__catch{
  font-size: 30px;
  position: static;
  color: #333;
  font-weight: 800;
  text-shadow: #fff 2px 2px 0px , #fff -2px 2px 0px , #fff 2px -2px 0px , #fff -2px -2px 0px;
}

.as-groups-introduction-2 .as-groups-introduction__contents .as-groups-introduction__catch .as-countup{
  font-size: 80px;
  position: static;
  color: #ffc001;
}

.as-groups-introduction-2 .as-groups-introduction__contents .as-groups-introduction__subcatch{
  font-size: 24px;
  position: static;
  color: #fff;
  font-weight: 800;
  text-shadow: 0px 2px 0px rgba(0,0,0,0.5);
  display: block;
}

.as-groups-introduction-2 .as-groups-introduction__contents address{
  display: inline-block;
  font-size: 24px;
  font-weight: 500;
  position: static;
  background-color: #22b11d;
  // padding: 8px 5px 5px;
  //padding: 4px 7px 4px 7px;
  padding: 5px 15px;
  text-shadow: none;
  display: inline-block;
  margin: 10px auto;
  letter-spacing: .05em;
  border-radius: 40px;
  margin-bottom: 0;

  // Override phone number style
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold;
  letter-spacing: 0.035em;

 > a {
   color: #fff;
 }
  > a > span {
    font-family: Verdana;  //Design for specific font family
    font-size: 0.9em;
    vertical-align: 5%;

    // Style for "+"
    &:first-child:not(:last-child) {
       letter-spacing: 0em;
    }
    // Style for "-"
    &:not(:first-child) {
       letter-spacing: 0.1em;
    }
  }
}

.as-groups-introduction-2 .as-groups-introduction__contents .as-groups-introduction__subcatch address a{
  color: #fff;
}

@media (min-width: @screen-sm-min) and (max-width: @screen-md-min) {

  .as-groups-introduction-2 .as-groups-introduction__catch .as-countup{
    font-size: 30px !important;
  }

  .as-groups-introduction-2 .as-groups-introduction__contents {
    top: 30%;
    height: 20%;
    margin-top: 2%;
  }
}

@media (max-width:@screen-xs-max) {

  .as-groups-introduction-2 .as-groups-introduction__catch , .as-groups-introduction-2 .as-groups-introduction__subcatch , .as-groups-introduction-2 .as-groups-introduction__catch .as-countup , .as-groups-introduction-2 address{
    // font-size: 18px !important;
    font-size: 16px !important;
    font-weight: 700 !important;
  }

  .as-groups-introduction-2 .as-groups-introduction__contents {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    // display: block;
    // height: 50px;
  }

  .as-groups-introduction-2 address{
    padding: 2px 4px !important;
  }

  .as-groups-introduction-2 .as-groups-introduction__contents .as-groups-introduction__catch{
    margin-bottom: .5em;
  }
}

html:lang(en),
html:lang(th),
html:lang(vi){

  @media (max-width:@screen-xs-max) {

    .as-groups-introduction-2 .as-groups-introduction__catch , .as-groups-introduction-2 .as-groups-introduction__subcatch , .as-groups-introduction-2 .as-groups-introduction__catch .as-countup , .as-groups-introduction-2 address{
      font-size: 16px !important;
    }

  }

  @media (min-width: @screen-sm-min){

    .as-groups-introduction-2 .as-groups-introduction__contents{
      position: absolute;
      width: 100%;
      height: 50%;
      top:  35%;
      left: 0;
    }

  }

}


// 3枚目 鑑定・出品代行
.as-groups-introduction-3 .as-groups-introduction__contents{
  position: absolute;
  width: 100%;
  height: 50%;
  top: 25%;
  left: 0;
  text-align: center;
}

.as-groups-introduction-3 .as-groups-introduction__contents .as-groups-introduction__catch{
  font-size: 30px;
  position: static;
  color: #333;
  font-weight: 800;
  text-shadow: #fff 2px 2px 0px , #fff -2px 2px 0px , #fff 2px -2px 0px , #fff -2px -2px 0px;
  margin-top: 20px;
}

.as-groups-introduction-3 .as-groups-introduction__contents .as-groups-introduction__catch .strong{
  font-size: 65px;
  position: static;
  color: #ffc001;
  font-weight: 800;
}

.as-groups-introduction-3 .as-groups-introduction__contents .as-groups-introduction__catch .as-countup{
  font-size: 65px;
  position: static;
  color: #ffc001;
  font-weight: 800;
}

.as-groups-introduction-3 .as-groups-introduction__contents .as-groups-introduction__subcatch{
  font-size: 24px;
  position: static;
  color: #333;
  font-weight: 800;
  text-shadow: #fff 2px 2px 0px , #fff -2px 2px 0px , #fff 2px -2px 0px , #fff -2px -2px 0px;
  display: block;
}

.as-groups-introduction-3 .as-groups-introduction__contents .as-groups-introduction__subcatch a{
  margin-left: 10px;
  margin-top: -4px;
  text-shadow: none;
}

@media (min-width: @screen-sm-min) and (max-width: @screen-md-min) {

  .as-groups-introduction-3 .as-groups-introduction__catch .strong{
    font-size: 30px !important;
  }

  .as-groups-introduction-3 .as-groups-introduction__contents {
    top: 30%;
    height: 20%;
    margin-top: 2%;
  }

  .as-groups-introduction-3 .as-groups-introduction__contents .as-groups-introduction__catch {
    margin-top: 0;
  }
}

@media (max-width:@screen-xs-max) {

  .as-groups-introduction-3 .as-groups-introduction__catch , .as-groups-introduction-3 .as-groups-introduction__subcatch , .as-groups-introduction-3 .as-groups-introduction__catch .strong {
    font-size: 16px !important;
    // font-size: 16px !important;
    font-weight: 700 !important;
  }

  .as-groups-introduction-3 .as-groups-introduction__contents {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    // display: block;
    // height: 130px;
  }

  .as-groups-introduction-3 .as-groups-introduction__contents .as-groups-introduction__catch {
    margin-bottom: .5em;

    //override
    padding-top: 5px;
  }

  .as-groups-introduction-3 .as-groups-introduction__contents .as-groups-introduction__subcatch {
  }

  .as-groups-introduction-3 .as-groups-introduction__contents .as-groups-introduction__subcatch a {
    display: block;
    // margin: .2em auto 0;
    margin: .3em  22% 0;
  }

}

html:lang(en),
html:lang(th),
html:lang(vi){

  @media (max-width:@screen-xs-max) {

    .as-groups-introduction-3 .as-groups-introduction__catch , .as-groups-introduction-3 .as-groups-introduction__subcatch , .as-groups-introduction-3 .as-groups-introduction__catch .strong {
      // font-size: 18px !important;
      font-size: 16px !important;
      font-weight: 700 !important;
    }

  }

}

// force to flex based sytle

.as-groups-introduction__contents {
  .display-flex;
  .flex-flow(column,nowrap);
  .justify-content(center);
  .align-items(center);

  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  margin: auto !important;
  width: auto !important;
  height: auto !important;

  > * {
    position: static !important;
    top:initial !important;
    right: initial !important;
    bottom: initial !important;
    left: initial !important;
  }
}

.as-groups-introduction__catch,
.as-groups-introduction__subcatch {
  margin-top: 0 !important;
  margin-right: 5px !important;
  margin-left:  5px !important;
  margin-bottom: 0 !important;
}
