// Image

.as-image--default {
  border: 1px solid @as-image-default-border-color;
}

.as-image__item {
  display: block;
  width: 100%;
  margin-bottom: 20px;
}