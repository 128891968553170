// Icon
//
//These classes are used with inline span.

.as-icon--allstocker-logo{
  .as-icon__base("../images/logo-allstocker.png");
  width: 7em;
  background-size: contain;
}

.as-icon--year {
  .as-icon__base("../images/icon-year.svg");
}

// .as-icon--hours {
//   .as-icon__base("../images/icon-hour.svg");
// }

.as-icon--hours {
  .as-icon__base("../images/icon-meter.svg");
  width: 1.1em;
  height: 1.1em;
  bottom: .2em;
}

.as-icon--help {
  .as-icon__base("../images/icon-help.svg");
}

.as-icon--location {
  .as-icon__base("../images/icon-location.svg");
}

.as-icon--location-invert {
  .as-icon__base("../images/icon-location-invert.svg");
}

.as-icon--user {
  .as-icon__base("../images/icon-user.svg");
}

.as-icon--mail{
  .as-icon__base("../images/icon-mail.svg");
}

.as-icon--mail-invert{
  .as-icon__base("../images/icon-mail-invert.svg");
}

.as-icon--password {
  .as-icon__base("../images/icon-password.svg");
}

.as-icon--filter {
  .as-icon__base("../images/icon-filter.svg");
}
.as-icon--filter-label {
  .as-icon__base("../images/icon-filter_label.svg");
}

.as-icon--search {
  .as-icon__base("../images/icon-search.svg");
}

.as-icon--search-label {
  .as-icon__base("../images/icon-search-label.svg");
}

.as-icon--phone {
  .as-icon__base("../images/icon-phone.svg");
}

.as-icon--phone-gray {
  .as-icon__base("../images/icon-phone-gray.svg");
}

.as-icon--toll-free-ja {
  .as-icon__base("../images/icon-freedial.svg");
}

.as-icon--continue-link {
  .as-icon__base("../images/icon-arrow-right-link.svg", 70%);
  bottom: .1em;
  margin-left: 3px;
}

.as-icon--convert {
  .as-icon__base("../images/icon-convert.svg");
}

.as-icon--back-link {
  .as-icon__base("../images/icon-arrow-left-link.svg", 70%);
  bottom: .1em;
  margin-right: 3px;
  margin-left: -1.1em;
}

.as-icon--items {
  .as-icon__base("../images/icon-items.svg");
}

.as-icon--sort {
  .as-icon__base("../images/icon-sort.svg");
}

.as-icon--view {
  .as-icon__base("../images/icon-view.svg");
  width: 1.1em;
  height: 1.1em;
}

.as-icon--inspection {
  .as-icon__base("../images/icon-inspection.svg");
}

.as-icon--inspection-color {
  .as-icon__base("../images/icon-inspection-color.svg");
}

.as-icon--view-large {
  .as-icon__base("../images/icon-view-large.svg");
}

.as-icon--view-large-invert {
  .as-icon__base("../images/icon-view-large-invert.svg");
}

.as-icon--warning-blue {
  .as-icon__base("../images/icon-warning-blue.svg");
}

.as-icon--warning-gray {
  .as-icon__base("../images/icon-warning-gray.svg");
}

.as-icon--watches {
  .as-icon__base("../images/icon-watches.svg");
}
.as-icon--watches-invert {
  .as-icon__base("../images/icon-watches-invert.svg");
}
.as-icon--watches-transition {
  .as-icon__base("../images/icon-watches-transition.svg");
}

.as-icon--watches-transition {
  .as-icon__base("../images/icon-watches-transition.svg");
}

.as-icon--star {
  .as-icon__base("../images/icon-star.svg");
}

.as-icon--star-outline {
  .as-icon__base("../images/icon-star-outline.svg");
}

.as-icon--favorite-on {
  .as-icon__base("../images/icon-heart.svg");
}

.as-icon--favorite-off {
  .as-icon__base("../images/icon-heart-outline.svg");
}


// Use Only for collapse area title
.as-icon--collapse {
  .background-image-icon("../images/icon-arrow-collapse.svg", 10px, 10px, 9px, 50%, left);
}

.as-icon--collapse-in {
  .background-image-icon("../images/icon-arrow-collapse-in.svg", 10px, 10px, 9px, 50%, left);
}


.as-icon--inspection-report{
  .as-icon__base("../images/icon-inspection-report.svg");
}

.as-icon--sound-on{
  .as-icon__base("../images/icon-sound-on.svg");
}
.as-icon--sound-off{
  .as-icon__base("../images/icon-sound-off.svg");
}
