// For auction Item detail Page
.as-auction{

  .as-groups-auction-item-detail-main {
    margin-bottom: 20px;
  }
  .as-groups-auction-item-detail-main__header {
    float: right;
  }
  .as-groups-auction-item-detail-main__thumbnails {
  }
  .as-groups-auction-item-detail-main__bidding {
  }
  
  .as-groups-auction-item-detail-sale-status {
    margin-bottom: 5px;
  
    .as-sale-status {
      .as-sale-status__body {
        border-top-width: 0;
        border-bottom-width: 1px;
        padding-top: 2px;
        padding-bottom: 2px;
      }
    }
  
  }
  
  .as-groups-auction-item-detail-title {
    min-height: 0;
    padding-top: 0;
    padding-bottom: 5px;
    font-size: @font-size-h2;
  }
  
  .as-groups-auction-item-detail-sale-status,
  .as-groups-auction-item-detail-title,
  .as-groups-auction-item-detail-download-panel,
  // .as-groups-auction-item-detail-activity-widget,
  .as-groups-auction-item-detail-status {
    margin-right: -(@as-grid-gutter-width / 2);
    margin-left: -(@as-grid-gutter-width / 2);
  }
  
  .as-thumbnail-viewer {
    .as-col--no-gutter;
  }
  
  .as-groups-auction-item-detail-pager {
  
    .pager {
      margin-top: 0;
      margin-bottom: 0;
      font-size: @font-size-h6;
      text-align: left;
    }
  
  }
  
  .as-groups-auction-item-detail-pager__current {
    color: @as-text-description-color;
    font-size: @font-size-h5;
    font-weight: bold;
  }
  
  .as-groups-auction-item-detail-download-panel {
    .panel {
      border: none;
      margin-bottom: 0;
    }
    .panel .panel-body {
      border: none;
      margin: 0;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
  
  .as-groups-auction-item-detail-download-panel__list {
    .display-flex;
    .justify-content(center);
  }
  
  .as-groups-auction-item-detail-download-panel__item {
    .flex(1,1,auto);
    text-align: center;
  
    .as-auction-download-conditionreport {
      font-weight: bold;
    }
  
    .glyphicon {
      margin-right: 5px;
    }
  
    .as-icon--allstocker-logo {
      width: 7.3em;
      margin-right: 3px;
      margin-left: 1px;
      bottom: .1em;
    }
  
    .btn {
      width: 100%;
      font-size: @font-size-h5;
    }
  
    &:nth-child(n+2) {
      padding-left: 10px;
    }
  }
  
  .as-groups-auction-item-detail-summary {
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: @font-size-h6;
  
    &,dl {
      .clearfix;
    }
    dl,dt,dd {
      display: block;
      float: left;
    }
    dl {
      margin-right: 10px;
      white-space: nowrap;
    }
  
    .as-features__head {
      vertical-align: middle;
      margin-right: 3px;
  
      [class*="as-icon--"] {
        margin-right: 0;
        margin-top: -.18em;
      }
      [class*="as-icon--hours"] {
        bottom: .12em;
      }
    }
    .as-features__value {
      min-width: 2em;
      margin-right: 0;
    }
  }
  
  @as-groups-auction-item-detail-activity-widget-background-color: #e3e3e3;
  .as-groups-auction-item-detail-activity-widget {
    padding: 5px (@as-grid-gutter-width / 2);
    background-color: @as-groups-auction-item-detail-activity-widget-background-color;
    text-align: center;
    font-weight: bold;
  
    dl {
      display: inline-block;
      margin-bottom: 0;
      white-space: nowrap;
    }
  
    .as-features__head {
      // margin-left: 5px;
    }
    .as-features__value {
      // margin-right: 5px;
      // min-width: 1.1em;
      min-width: .5em;
      text-align: left;
    }
  
    .as-groups-auction-item-detail-activity-widget__time-left {
      float: none;
      margin-right: 5px;
      margin-left: 5px;
  
      .as-features__value {
        min-width: 8em;
  
        &.as-sale-opening-soon {
          min-width: 16em;
          letter-spacing: -.01em;
        }
      }
  
    }
  
    .as-groups-auction-item-detail-activity-widget__attentions {
      display: inline-block;
      margin-right: 5px;
      margin-left: 5px;
    }
  
    .as-groups-auction-item-detail-activity-widget__watch-button {
      display: inline-block;
      margin-top: 5px;
      [class*="as-button--watch"] {
        min-width: 10em;
        font-size: @font-size-h5;
        padding-top: 5px;
        padding-bottom: 5px;
  
        // Icon
        &:before {
          font-size: @font-size-h3;
        }
  
        .as-button--watch__text,
        .as-button--watching__text {
          display: inline-block;
          min-width: 4.5em;
          margin-left: 10px;
          line-height: 1;
          text-align: center;
        }
  
      }
    }
  
  }
  
  @as-groups-auction-item-detail-action-panel-background-color: #fafafa;
  .as-groups-auction-item-detail-action-panel {
    background-color: @as-groups-auction-item-detail-action-panel-background-color;
  
    .panel .panel-body {
      padding-top: 10px;
      padding-bottom: 10px;
      background-color: @as-groups-auction-item-detail-action-panel-background-color;
    }
  
    > :first-child {
      padding-top: 5px;
    }
    > :last-child {
      padding-bottom: 5px;
    }
  
  }
  
  @as-groups-pricing-info-table-horizontal-padding: 10px;
  table.as-groups-pricing-info-table {
    position: relative;
    width: 100%;
    margin-bottom: 10px;

    th {
      padding-right: 10px;
      padding-left: @as-groups-pricing-info-table-horizontal-padding;
      color: @as-text-term-color;
      font-weight: normal;
      vertical-align: top;
    }
    td {
      color: @as-text-description-color;
    }

    // Fix for current price style
    tr:not(.as-groups-pricing-info-table__primary-info--main-price) {
      th {
        width: 50%;
      }
    }
    tr.as-groups-pricing-info-table__primary-info--main-price {
      .as-groups-pricing-info-table__price {
        display: inline-block;
        width: auto;
        margin-right: auto;
        margin-left: auto;
        min-width: ~"calc(100% - @{as-groups-pricing-info-table-horizontal-padding} * 2)";
      }
    }

    // Fix for converted price style
    .as-groups-pricing-info-table__secondary-info--converted-price + .as-groups-pricing-info-table__secondary-info {
      th,td {
        padding-top: 10px;
      }
    }

  }
  
  .as-groups-pricing-info-table {
    margin-top: 15px;
    
    .text-price,
    .text-currency {
      color: @as-text-description-color;
      font-weight: bold;
    }
  
    .as-selector--currency {
      &.bootstrap-select.btn-group {
        font-size: inherit;
        vertical-align: text-bottom;
        margin-bottom:-3px;
      }
      &.bootstrap-select.btn-group .dropdown-toggle {
        font-size: inherit;
        font-weight: bold;
        background-color: inherit;
        line-height:26px;
        overflow:hidden;
      }
    }
  }
  
  .as-groups-auction-item-detail-info-panel {
    margin-top: 15px;
  
    .text-notice {
      display: block;
      text-align: center;
    }
  }
  
  .as-groups-pricing-info-table__price {
    font-weight: bold;
  }
  
  .as-groups-pricing-info-table__currency {
    display: inline-block;
    margin-left: 0.2em;
    font-weight: bold;
    // vertical-align: baseline;
  }
  
  tr.as-groups-pricing-info-table__primary-info th {
    font-weight: bold;
  }
  tr.as-groups-pricing-info-table__primary-info td {
    font-weight: bold;
  }
  
  .as-groups-pricing-info-table__primary-info {
    .text-price,
    .text-currency {
      color: @as-brand-attention;
    }

    .text-price-undecided {
      color: @as-brand-base;
    }

  }
  
  tr.as-groups-pricing-info-table__primary-info--main-price th,
  tr.as-groups-pricing-info-table__primary-info--main-price td {
    display: block;
  }
  tr.as-groups-pricing-info-table__primary-info--main-price td {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
  }
  tr.as-groups-pricing-info-table__primary-info--main-price {
    .as-groups-pricing-info-table__price {
      display: block;
      min-width: ~"calc(100% - @{as-groups-pricing-info-table-horizontal-padding} * 2)";
      margin-right: auto;
      margin-left: auto;
      text-align: center;
      font-size: @font-size-h1 + 3px;  //24px
      border-bottom: 2px solid @as-brand-attention;
    }
  }
  .as-groups-pricing-info-table__primary-info--main-price {
    position: relative;
    height: 6em;  // Spacing
  
    .as-groups-pricing-info-table__currency {
      font-size: @font-size-h2;
    }
    .as-groups-pricing-info-table__currency-convert-caption {
      display: block;
      font-weight: normal;
      font-size: @font-size-h5;
      line-height: 1.6;
    }
  }
  
  .as-groups-pricing-info-table__secondary-info {
    line-height: 1.6;
  
    .as-icon--help {
      padding-left: 20px;
    }
  
    .as-balloon.as-balloon--top.as-balloon--align-left {
      &:after {
        left: -90%;
      }
    }
  
  }
  
  .as-groups-pricing-info-table__secondary-info--converted-price {
    position: relative;
    height: 60px;  // Spacing
  }
  
  tr.as-groups-pricing-info-table__secondary-info--converted-price {
    th {
      // hidden element for layout converted-price
      visibility: hidden;
    }
    td {
      position: absolute;
      left: @as-groups-pricing-info-table-horizontal-padding;
      right: @as-groups-pricing-info-table-horizontal-padding;
      padding-top: 5px;
      padding-bottom: 5px;
      text-align: center;
      vertical-align: middle;
      background-color: @as-widget-background-color;
    }
    th {

    }
    .as-groups-pricing-info-table__price {
      font-size: @font-size-h1;
    }
    .as-groups-pricing-info-table__currency {
      font-size: @font-size-h3;
    }
  }
  .as-groups-pricing-info-table__currency {
    margin-left: 0;
  }
  
  .as-groups-pricing-info-table__othre-info {
  }
  
  .as-groups-auction-item-detail-note {
    margin-top: 20px;
  }
  
  dl.as-groups-bid-history-summary {
    margin-bottom: 5px;
  }
  .as-groups-bid-history-summary {
    dt, dd {
      display: inline;
      font-weight: bold;
    }
    dt {
      color: @as-text-term-color;
    }
    dd {
      color: @as-text-description-color;
    }
  }
  
  .as-groups-bid-history-table {
    position: relative;
    overflow-y: auto;
  
    // Font size
    // cell width in thead affects whole th & td width
    table {
      > thead > tr > th {
        font-size: @font-size-h5;
      }
      > tbody > tr > td {
        font-size: 3.95vw;  // 12.5px in iPhone5
      }
    }
  
    // Column layout
    table {
      table-layout: fixed;
      width: 100%;
  
      > thead > tr > th,
      > tbody > tr > td {
        padding-right: 5px;
        padding-left: 5px;
  
  
        &.as-groups-bid-history-table__bidder {
          width: 40vw;
          padding-right: 0;
        }
        &.as-groups-bid-history-table__bids {
          padding-right: 0;
          padding-left: 0;
        }
        &.as-groups-bid-history-table__time {
          width: 23.5vw;
        }
      }
      > tbody > tr {
        border-top: none;
        border-bottom: none;
  
        > td {
          padding-bottom: 10px;
          border-top: 1px dotted @as-sub-border-color;
        }
      }
    }
  
    // Text layout
    table {
      > thead, > tbody > tr {
        > th, > td {
          line-height: 1.2;
          word-break: break-word;
          overflow-wrap: break-word;
          word-wrap: break-word;
        }
      }
      > thead > tr > th {
        &.as-groups-bid-history-table__bidder,
        &.as-groups-bid-history-table__bids,
        &.as-groups-bid-history-table__time {
          text-align: center;
        }
  
        &.as-groups-bid-history-table__time {
          // Fix for timezone element
          > span {
            white-space: nowrap;
          }
        }
  
      }
      > tbody > tr > td {
        &.as-groups-bid-history-table__bidder {
          text-align: left;
        }
        &.as-groups-bid-history-table__bids {
          text-align: right;
        }
        &.as-groups-bid-history-table__time {
          text-align: right;
        }
      }
    }
  
    // Style for fixed position
    table > thead > tr > th {
      position: relative;
      background-color: #fff;
      z-index: 1;
  
      // Alternative to border
      border-bottom-color: transparent;
      &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        border: inherit;
        border-bottom-color: @as-sub-border-color;
      }
  
    }
  
  }
  
  .as-groups-bid__amount-form {
    .display-flex;
    .flex-flow(row,wrap);
    margin-bottom: 3px;
  }
  
  .as-groups-bid__amount-view {
    .flex(1,1,auto);
    .order(1);
    width: 100%;
    background-color: #fff;
    text-align: center;
    vertical-align: middle;
  
    dl {
      margin-bottom: 0;
    }
  }
  
  .as-groups-bid__amount-head {
  }
  
  .as-groups-bid__amount-value {
    font-size: @font-size-h1;
    font-weight: bold;
  }
  
  .as-groups-bid__amount-currency {
    margin-left: 0.2em;
    font-size: 0.8em;
  }
  
  .as-groups-bid__amount-control {
    .flex(1,1,auto);
    
    &.btn[disabled] {
      pointer-events: none;
      touch-action: none;
    }
  }
  .modal-landscape .as-groups-bid__amount-control {
      padding-top: 3px;
      padding-bottom: 3px;
  }

  .modal-footer .btn + .btn.as-groups-bid__amount-control {
    margin: 0;
  }
  
  .as-groups-bid__amount-control--decrement {
    .order(2);
    border-radius: 0;
    border-bottom-left-radius: @border-radius-base;
    margin-right: 2px;
  }
  
  .as-groups-bid__amount-control--increment {
    .order(3);
    border-radius: 0;
    border-bottom-right-radius: @border-radius-base;
    margin-left: 2px;
  }
  
  .as-groups-bid__amount-increments {
    width: 100%;
    .flex(1,1,auto);
    .order(3);
    // color: #b2b2b2;
    font-size: @font-size-h6;
    text-align: center;
  
    dt,dd {
      display: inline;
      font-weight: normal;
    }
  }
  
  .as-groups-bid,
  .as-groups-buyout {
    .panel {
      margin-bottom: 0;
      border-top: none;
    }
  
    // Customized style for Bid & Buyout button
    .btn-lg {
      font-weight: bold;
      letter-spacing: .02em;
      border-radius: 5px;
  
      .text-caption {
        margin-top: 2px;
        margin-bottom: 3px;
        color: fadeout(@btn-primary-color, 20%);
        font-weight: 600;
      }
  
    }
  }
  
  .as-groups-bid {
    // Customized style for Bid & Buyout button
    .btn-purchase.btn-lg {
      box-shadow: inset 0 -6px 0 @btn-purchase-shadow;
  
      &.disabled,
      &[disabled] {
        &,
        &:hover,
        &:focus,
        &.focus {
          background-color: fadeout(@btn-purchase-bg, 90%);
          color: fadeout(@btn-purchase-color, 30%);
          .box-shadow(none);
          
          .text-caption{
            color: fadeout(@btn-primary-color, 30%);
          }
        }
      }
    }
  }
  fieldset[disabled] .as-groups-bid .btn-purchase.btn-lg {
    &,
    &:hover,
    &:focus,
    &.focus {
      background-color: fadeout(@btn-purchase-bg, 90%);
      color: fadeout(@btn-purchase-color, 30%);
      .box-shadow(none);
      
      .text-caption{
        color: fadeout(@btn-primary-color, 30%);
      }
    }
  }
      
  
  .as-groups-buyout {
    // Customized style for Bid & Buyout button
    .btn-buyout.btn-lg {
      box-shadow: inset 0 -6px 0 @btn-buyout-shadow;
  
      &.disabled,
      &[disabled] {
        &,
        &:hover,
        &:focus,
        &.focus {
          background-color: fadeout(@btn-buyout-bg, 90%);
          color: fadeout(@btn-buyout-color, 30%);
          .box-shadow(none);
  
          .text-caption{
            color: fadeout(@btn-primary-color, 30%);
          }
        }
      }
  
    }
  }
  fieldset[disabled] .as-groups-buyout .btn-buyout.btn-lg {
    &,
    &:hover,
    &:focus,
    &.focus {
      background-color: fadeout(@btn-buyout-bg, 90%);
      color: fadeout(@btn-buyout-color, 30%);
      .box-shadow(none);

      .text-caption{
        color: fadeout(@btn-primary-color, 30%);
      }
    }
  }
  
  // Confirm Panel
  .as-groups-auction-confirm-info {
  
    // Contacti Info
    .as-groups-auction-confirm-info__contact-info {
      dl {
        margin-bottom: 10px;
      }
      dt {
        font-weight: normal;
      }
      dd {
        margin-bottom: 10px;
      }
    }
  }
  
  .as-groups-auction-item-detail-panel__tabs {
    .display-flex;
  
    > li {
      .flex(1,1,auto);
  
      > a {
        text-align: center;
      }
  
      &:last-child > a {
        margin-right: 0;
      }
    }
  }
  
  .as-groups-auction-item-detail-panel__panel--left {
    float: left;
  }
  
  .as-groups-auction-item-detail-panel__panel--right {
    float: right;
  }
  
  .as-groups-auction-item-detail-panel__panel--details {
    .as-flexible-table--default {
      background-color:@as-brand-background;
      tr:hover {
        background-color:@as-submenu-background-color;
        cursor: pointer;
      }
      tr.as-mixed-columns-table__group_item {
        background-color:@as-menu-background-color;
        cursor: initial;
        text-align:center;
        td{
          font-weight:bold;
        }
      }
      caption {
        margin-bottom: 0;
      }
      tr th {
        vertical-align: top;
        width:30%;
        font-weight: bold;
        border-right: 1px dotted @as-ui-border-color;
      }
      tr td:nth-child(3), tr td:nth-child(4) {
        border-left: 1px dotted @as-ui-border-color;
      }
    }
  }
  
  // Comment Panel
  .as-groups-item-detail-main__comment{
    margin-top:10px;
    margin-bottom: -10px;
    position: relative;
    p{
      margin-top:5px;
    }
    .label-as-important-point{
      position: relative;
      padding: .3em 0 .3em 1.4em;
      &:before{
        width: 18px;
        height: 18px;
        padding: 0;
        top: 4px;
        font-size: 13px;
      }
    }
  }
  
  // Responsive
  
  @media (max-width: @screen-xs-max) {
  
    .as-groups-bid-history-table {
      // Column layout
      table {
        > thead > tr > th,
        > tbody > tr > td {
          padding-right: 0;
          padding-left: 0;
        }
      }
  
    }
  
  }
  
  
  @media (min-width: @screen-sm-min) {
  
    .as-groups-auction-item-detail-sale-status,
    .as-groups-auction-item-detail-title,
    .as-groups-auction-item-detail-download-panel,
    .as-groups-auction-item-detail-status {
      margin-right: 0;
      margin-left: 0;
    }
  
    .as-groups-auction-item-detail-sale-status {
  
      .as-sale-status {
        .as-sale-status__body {
          border-top-width: 1px;
          border-bottom-width: 0;
        }
      }
  
    }
  
    .as-thumbnail-viewer__images {
      padding-right: @as-grid-gutter-width/2;
      padding-left: @as-grid-gutter-width/2;
    }
  
    .as-groups-auction-item-detail-pager {
      margin-top: 0;
    }
  
    .as-groups-auction-item-detail-download-panel {
      margin-top: 5px;
  
      .panel {
        margin-bottom: 20px;
      }
      .panel-option-mini {
        .panel-body {
          background-color: transparent;
          border: 1px dotted @as-ui-border-color;
          border-right: none;
          border-left: none;
          padding: 10px 0;
        }
      }
    }
  
    .as-groups-auction-item-detail-summary {
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: @font-size-h5;
  
      .as-features__head {
        [class*="as-icon--"] {
          margin-top: -.07em;
        }
        [class*="as-icon--hours"] {
          bottom: .06em;
        }
      }
    }
  
    .as-groups-auction-item-detail-activity-widget {
      padding: 8px 0 8px;
      border: 1px solid  @as-ui-border-color;
      border-right: none;
      border-left: none;
      font-size: @font-size-h4;
    }
  
    .as-groups-auction-item-detail-action-panel {
      position: relative;
      border-bottom: 1px solid @as-ui-border-color;
      border-right: none;
      border-left: none;
      border-top: none;
  
      > :first-child {
        padding-top: 10px;
      }
      > :last-child {
        padding-bottom: 10px;
      }
  
      // Fix for a border when action has no panels
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0
        width: 100%;
        border-top: 1px solid @as-ui-border-color;
      }
    }
  
    @as-groups-pricing-info-table-desktop-horizontal-padding: @as-groups-pricing-info-table-horizontal-padding + 5px;
    table.as-groups-pricing-info-table {
      margin-bottom: 20px;
      th {
        padding-left: @as-groups-pricing-info-table-desktop-horizontal-padding;
      }
      tr.as-groups-pricing-info-table__primary-info--main-price {
        .as-groups-pricing-info-table__price {
          min-width: ~"calc(100% - @{as-groups-pricing-info-table-desktop-horizontal-padding} * 2)";
        }
      }

      tr.as-groups-pricing-info-table__secondary-info--converted-price {
        td {
          left: @as-groups-pricing-info-table-desktop-horizontal-padding;
          right: @as-groups-pricing-info-table-desktop-horizontal-padding;
        }
      }
    }

    // Fix for current price style
    table.as-groups-pricing-info-table {
      tr:not(.as-groups-pricing-info-table__primary-info--main-price) {
        th {
          width: auto;
        }
      }
    }
  
  
    // Fix for converted price style
    .as-groups-pricing-info-table__secondary-info--converted-price + .as-groups-pricing-info-table__secondary-info {
      th,td {
        padding-top: 20px;
      }
    }
  
    .as-groups-pricing-info-table__othre-info {
    }
  
    .as-groups-auction-item-detail-panel {
      // Show all tabs
      .tab-pane {
        display: block !important;
      }
    }
  
    .as-groups-auction-item-detail-panel__panel--details {
      .panel {
        .panel-widget;
      }
    }
  
    .as-groups-bid-history-table {
      max-height: 225px;
  
    // Font size
      table > tbody > tr > td {
        font-size: @font-size-h6;
      }
  
      // Column layout
      table {
        > thead > tr > th,
        > tbody > tr > td {
          &.as-groups-bid-history-table__bidder {
            width: 9em;
          }
          &.as-groups-bid-history-table__bids {
          }
          &.as-groups-bid-history-table__time {
            width: 9.5em;
          }
        }
      }
  
    }
  
    // Confirm Panel
    .as-groups-auction-confirm-info {
  
      // Limit height
      .panel-body {
        overflow-y: scroll;
        max-height: 570px;
        font-size: @font-size-h6;
      }
    }
  
  }
  
  @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
  
    .as-groups-auction-item-detail-download-panel__list {
      .flex-flow(row,wrap);
    }
  
    .as-groups-auction-item-detail-download-panel__item {
      .flex(1,0,100%);
  
      &:nth-child(n+2) {
        margin-top: 10px;
        padding-left: 0;
      }
  
    }
  
    //fix for language
    html:lang(vi) .as-groups-auction-item-detail-action-panel .btn-lg .text-caption {
      font-size: @font-size-h6 - 2px;    //10px
      margin-right: -3px;
      margin-left: -3px;
    }

  
    .as-groups-bid-history-table {
      max-height: 300px;
  
      // Font size
      table {
        > thead > tr > th {
          font-size: @font-size-h6;
        }
        > tbody > tr > td {
          font-size: (@font-size-h6 - 2px);  //10px
        }
      }
  
      // Column layout
      table {
        > thead > tr > th,
        > tbody > tr > td {
          &.as-groups-bid-history-table__bidder {
            width: 9em;
          }
          &.as-groups-bid-history-table__bids {
          }
          &.as-groups-bid-history-table__time {
            width: 6.5em;
          }
        }
      }
  
    }
  
  }
  
  @media (min-width: @screen-md-min) and(max-width: @screen-md-max) {
  
    //fix for language
    html:lang(en) .as-auction-download-conditionreport {
      padding-top: @padding-large-vertical;   //8px
      padding-bottom: @padding-large-vertical + 1px;    //9px

      &:last-child {
        font-size: @font-size-h5 - 1px;  //13px
      }
    }

    html:lang(vi) .as-auction-download-conditionreport {
      .display-flex;
      .justify-content(center);
      .flex-flow(row,nowrap);
      .align-items(center);
      padding-top: @padding-large-vertical + 1px;   //9px
      padding-bottom: @padding-large-vertical + 1px;    //9px

      span {
        &:nth-child(1) {
          .order(1);
        }
        &:nth-child(2) {
          .order(3);
        }
        &:nth-child(3) {
          .order(2);
          font-size: @font-size-h5 - 2px;  //12px
        }
      }

    }
  
  }
  
  @media (min-width: @screen-md-min) {
  
    // Fix for current price style
    table.as-groups-pricing-info-table {
      tr:not(.as-groups-pricing-info-table__primary-info--main-price) {
        th {
          width: 45%;
        }
      }
    }
  
  
    .as-groups-auction-item-detail-download-panel__item {
      .flex(0,0,auto);
      width: 50%;
    }
  
    // Confirm Panel
    .as-groups-auction-confirm-info {
  
      // Limit height
      .panel-body {
        max-height: 575px;
        font-size: @font-size-h5;
      }
    }
  
  }
  
  @media (min-width: @screen-lg-min) {
  
    .as-groups-bid-history-table {
      max-height: 235px;
  
      // Font size
      table {
        > tbody > tr > td {
          font-size: @font-size-h5;
        }
      }
  
      // Column layout
      table {
        > thead > tr > th,
        > tbody > tr > td {
          &.as-groups-bid-history-table__bidder {
            width: 10.5em;
          }
          &.as-groups-bid-history-table__bids {
          }
          &.as-groups-bid-history-table__time {
            width: 11em;
          }
        }
      }
  
    }
  
    // Confirm Panel
    .as-groups-auction-confirm-info {
  
      // Limit height
      .panel-body {
        max-height: 535px;
      }
    }
  
  }
  
  
  
  
  .as-groups-pricing-info-table__estimation-info--converted-price{
    background: #EEE;
    padding: 10px;
    .as-groups-pricing-info-table__estimation-info--converted-price-title{
      font-weight: bold;
      overflow: hidden;
      form{
        float:right;
        font-weight: normal;
        select{
          margin-left:3px;
          margin-bottom: 1px;
        }
      }
    }
    .as-groups-pricing-info-table__estimation-info--converted-price-fees{
      li{
        border-bottom: 1px dotted #bebebe;
        padding-top:5px;
        label{
          width:100%;
          font-weight: normal;
        }
        input{
          float: left;
          margin-right:5px;
        }
        input[type=checkbox]:checked ~ div.as-estimation-fee-transaction-price{
          color:@as-brand-emphasis;
        }
        .as-estimation-fee-transaction-price{
          float: right;
          color:#CCC;
          text-align:right;
          span{
            margin-left: 5px;
            font-size: 0.8em;
          }
        }
      }
      li:last-child{
        border-bottom: none;
        margin-bottom:-5px;
      }
    }
    .as-groups-pricing-info-table__estimation-info--converted-price-total{
      display: block;
      .as-groups-pricing-info-table__currency{
        float:right;
        .as-groups-pricing-info-table__price{
          font-size: @font-size-h2;
          margin-right: 3px;
        }
      }
    }
    .as-groups-pricing-info-table__estimation-info--converted-price-notice{
      display: block;
      margin-top: 20px;
      ul{
        list-style-type: initial;
        margin: 0 0 0 1.1em;
      }
      ul li{
        font-size: 0.8em;
        margin: 0 0 0 0em;
      }
    }
    hr{
      margin:5px 0px;
    }
  }

}