//新ボタンを反映したいページ
.marketplace-seller-machine-list, .seller-contents {
  
      .button-shadow {
        -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);
      }
      
      /*
      BUTTON COLORS
      */
      // Button Color gets darker on hover state.
      @link-shadow-color:                     darken(@link-color, 10%);
      @link-hover-color:                      darken(@link-color, 8%);
      @outline-link-hover-background-color:   @grey-1;
      
      // warning button color is  @warning-color;
      @warning-shadow-color:                  darken(@warning-color, 10%);
      @warning-hover-color:                   darken(@warning-color, 8%);
      @warning-hover-shadow-color:            darken(@warning-hover-color, 10%);
  
  
      
      //Link
      a {
        color: @link-color;
        text-decoration: none;
        cursor: pointer;
        &:hover {
          color: @link-hover-color;
        }
      }
  
      .btn {
        height: 48px;
        padding-top: 12px;
        padding-left: @space-sm;
        padding-right: @space-sm;
        background: none;
        font-size: @font-size-5;
        line-height: @line-height-5;
        border-radius: 2px;
        .button-shadow;
    
        &.disabled,
        &[disabled],
        fieldset[disabled] & {
          -webkit-filter: grayscale(1);
          filter: grayscale(1);
          cursor: @cursor-disabled;
          .opacity(.3);
          .box-shadow(none);
        }
      }
  
      .btn-sm {
        height: 32px;
        padding-top: 6px;
        font-size: @font-size-6;
        line-height: @line-height-6;
      }
      
      .btn-xs {}
  
  
  
      //Primary Button
      .btn-primary {
        background: @link-color;
        color: @white;
        &:hover {
          background: @link-hover-color;
          color: @white;
        }
      }
  
      //Secondary Button
      .btn-secondary {
        color: @link-color;
        border: 1px solid @link-color;
        .box-shadow(none);
        &:hover {
          background: @grey-2;
          color: @link-hover-color;
          border: 1px solid @link-hover-color;
        }
      }
  
      //Warning Button
      .btn-warning {
        color: @warning-color;
        border: 1px solid @warning-color;
        .box-shadow(none);
        &:hover {
          background: @warning-color;
          color: @white;
          border: 1px solid @warning-color;
        }
      }
  
      //Price Form Button
      .btn-form-align-right {
        color: @text-color;
        border: 1px solid @border-color;
        text-align: right;
        .box-shadow(none);
        &:hover {
          color: @text-color;
          border: 1px solid @border-color;
        }
      }
    
    
    }