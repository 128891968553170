// Offer Password Reset Page

.as-groups-offer-password-reset {
}

.as-groups-offer-password-reset__contents {

  .form-control {
    max-width: 25em;
    margin-right: auto;
    margin-left: auto;
  }
}
