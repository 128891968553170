// Global Footer

@as-global-footer-text-color: @as-text-description-color;

.as-global-footer__body {
  background-color: @as-global-footer-body-background-color;

  &, a {
    color: @as-global-footer-text-color;
  }
}

.as-global-footer__body-wrapper {
  padding-top: 16px;

  > div[class*="row"] {
    margin-bottom: 15px;
  }
}

.as-global-footer {
}

.as-global-footer__link-group {
}

.as-global-footer__link-head {
  font-weight: bold;
  margin-bottom: 10px;
  padding-bottom: 7px;
  border-bottom: 2px dotted @as-global-footer-text-color;
}

.as-global-footer__link-list {
  ul& {
    margin-bottom: 15px;

    .as-global-footer__link-head ~ div[class*="row"] > div[class*="col-"] & {
      margin-bottom: 0;
    }
    .as-global-footer__link-head ~ div[class*="row"] > div[class*="col-"]:last-child & {
      margin-bottom: 15px;
    }
  }

  ul& > li {
    list-style: none;
    line-height: 1.8;
    text-indent: -1em;
    padding-left: 1em;

    &:before {
      .as-icon__base--pseudo("../images/icon-caret-right.svg");
    }

    // for Multi Language
    html:lang(ja) & {
      letter-spacing: -0.05em;
    }
  }
}

.as-global-footer__footer {
  background-color: @as-global-footer-footer-background-color;
}

.as-global-footer__foter-wrapper {
}

.as-global-footer__copylight {
  padding: 5px 0;
  text-align: center;
  font-size: 11px;
  color: #d7d7d7;

  > p {
    margin-bottom: 0;
  }
}

.as-global-footer__terms-of-use {
  .as-global-footer__copylight;
  a {
    color: @as-global-header-link-color;
    text-decoration: underline;

    &.focus,
    &:focus,
    &:hover {
      color: @as-global-header-text-color;
    }
  }
}


// Phone Number

.as-contact-info-mutable--pc {
  margin-top: 1px;
  margin-bottom: 0;

  a {
    color: inherit;
  }
  .as-contact-info-mutable__caption {
    vertical-align: middle;
    position: relative;
    top: 1px;
    margin-right: 5px;
    margin-bottom: 0;
    color: @as-brand-base;
    font-size: 10px;
    font-weight: bold;
    line-height: 1;
    white-space: nowrap;

    // spanが正しく置換されていないことを考慮した指定
    > .as-contact-info-mutable__caption--attention {
      color: hsl(0, 90%, 45%);

      > span {
        color: @as-brand-base;
      }
    }
  }
  .as-contact-info-mutable__info {
    display: inline-block;
    vertical-align: middle;
    margin-top: 4px;
    margin-bottom: 1px;
    padding: 2px 4px 0 4px;
    background-color: @as-brand-base;
    border-radius: @border-radius-base;
    color: #ffffff;
    font-size:  25px;
    line-height: 1;

    > a {
      display: block;
      text-align:center;
    }

    > span > span{
      display: inline-block;
    }
  }

  html:lang(en) &,
  html:lang(th) &{
    .as-contact-info-mutable__caption--primary {
      font-size: 12px;
    }
    .as-contact-info-mutable__info > span {
      padding-top: 1px;
      padding-bottom: 1px;
    }
  }
  html:lang(vi) &{
    .as-contact-info-mutable__caption--primary {
      font-size: 10px;
    }
    .as-contact-info-mutable__info > span {
      padding-top: 1px;
      padding-bottom: 1px;
    }
  }
}




// Text banner (mobile)
// sytle only for japanese

.as-contact-info-mutable--mobile {
  margin-top: 3px;
  white-space: nowrap;

  a {
    color: inherit;
  }
  .as-contact-info-mutable__caption {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: 1px;
    margin-bottom: 5px;
    color: @as-brand-base;
    font-size: 10px;
  }
  .as-contact-info-mutable__info {
    display: inline-block;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: -2px;

    > a {
      display: inline-block;
      padding: 2px 3px 0 4px;
      background-color: @as-brand-base;
      border-radius: @border-radius-base;
      color: #ffffff;
      font-size:  16px;
      line-height: 1;
    }
    > a > span{
      display: inline-block;
    }
  }
}


.as-contact-info-mutable__icon {
  &.as-icon--toll-free-ja {
    width: 1.3em;
    height: 1.3em;
    margin-bottom: -0.3em;
    bottom: 0.25em;

    .as-contact-info-mutable--pc & {
      margin-right: -4px;
    }
  }
  &.as-icon--phone {
    width: 0.8em;
    height: 0.8em;
    bottom: 0.13em;

    .as-contact-info-mutable--pc & {
      margin-right: -3px;
      margin-left: 2px;
    }
  }
}
.as-contact-info-mutable__number {
  // font-family: Arial;  //Design for specific font family
  font-weight: bold;
  letter-spacing: 0.035em;
  display: inline-block;

  > span {
    font-family: Verdana;  //Design for specific font family
    font-size: 0.9em;
    vertical-align: 5%;

    // Style for "+"
    &:first-child:not(:last-child) {
       letter-spacing: 0em;
    }
    // Style for "-"
    &:not(:first-child) {
       letter-spacing: 0.1em;
    }
  }
}

// language Selector
.as-global-footer__dropdown,
.as-global-footer__languages {
  hr {
    margin-bottom:15px;
    border-top: 1px solid @as-global-footer-footer-background-color;
  }
  .as-global-footer-box__languages {
    position: relative;
    display:inline-block;
    label {
      display: none;  // for spacing problem
      margin-right: 0.5em;
    }
    .dropdown-toggle {
      position: relative;
      padding:  4px 22px 3px 9px;
      font-size: inherit;
      text-align: left;
      color: @as-global-footer-footer-background-color;
      text-decoration: none;
      border-radius: 0;
      border: 1px solid @as-global-footer-footer-background-color;
      .caret {
        position: absolute;
        top: 0;
        right:  8px;
        bottom: 0;
        margin: auto;
        border-top-width: 7px;
        border-right-width: 4px;
        border-left-width: 4px;
        border-top-color: @as-global-header-ui-border-color;
      }
      &.focus,
      &:focus,
      &:hover {
        color: @as-text-description-color;
        border-color: darken(@as-global-footer-footer-background-color, 35%);

        .caret {
          border-top-color: darken(@as-global-footer-footer-background-color, 35%);
        }
      }
      .open& {
        color: #0e0e0e;
        background-color: #f1f1f1;
        border-color: darken(@as-global-footer-footer-background-color, 35%);

        .caret {
          border-top-color: #0e0e0e;
        }
      }
    }
    .dropdown-menu {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%,-100%);
      -moz-transform: translate(-50%,-100%);
      -ms-transform: translate(-50%,-100%);
      -o-transform: translate(-50%,-100%);
      transform: translate(-50%,-100%);
      margin-top: -25px;
      background-color: #FFF;
      border-radius: 0;
      border-color: @as-global-footer-footer-background-color;
      font-size: @font-size-h6;
      box-shadow: 0  2px 2px rgba(0, 0, 0, 0.25);
      > li > a {
        padding: 5px 10px;
        color: @as-global-footer-footer-background-color;
        &.focus,
        &:focus,
        &:hover {
          background-color: @as-disable-link-color;
        }
      }
      hr {
          margin-top: 5px;
          margin-bottom: 5px;
          border: 0;
          border-top: 1px solid @as-global-footer-footer-background-color;
      }
    }
    .dropdown-menu:before,
    .dropdown-menu:after {
      position: absolute;
      left: 50%;
      top: 100%;
      border: solid transparent;
      content: '';
      height: 0;
      width: 0;
      border-width: 7px;
    }
    .dropdown-menu:before {
      border-top-color: @as-global-footer-footer-background-color;
      margin-left: -7px;
    }
    .dropdown-menu:after {
      border-top-color: #FFFFFF;
      margin: -1px 0 0 -7px;
    }
  }
}

.as-banner{
  .as-banner-box{
    position: relative;
    width: 100%;
    height: 290px;
    background-image: url(../images/bg-banner_pc01.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @media (max-width: 1920px){
      background-image: url(../images/bg-banner_pc02.png);
    }
    .as-banner-message-box{
      color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      .as-banner-message-01{
        font-size: 22px;
        color: #e9ab20;
      }
      .as-banner-message-02{
        display: flex;
        align-items: center;
        font-size: 34px;
        font-weight: bold;
        margin: 15px 0;
        .logo-allstocker{
          width: 270px;
          margin: 0 10px 0 0;
        }
      }
      .as-banner-message-03{
        display: flex;
        .as-banner-message-03-text{
          font-size: 21px;
          border-radius: 7px 0 0 7px;
          background-color: rgba(0,0,0,0.3);
          padding: 10px 20px;
        }
        .as-banner-link-button{
          font-size: 19px;
          color: #4c4b4c;
          background-color: #edb217;
          border-radius: 0 7px 7px 0;
          padding: 10px 20px;
          display: flex;
          align-items: center;
          position: relative;
          &::before{
            content: "";
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            width: 0;
            height: 0;
            border-top: 7px solid transparent;
            border-bottom: 7px solid transparent;
            border-left: 8px solid rgba(0,0,0,0.65);
          }
          .icon-link{
            width: 21px;
            margin: 0 0 3px 5px;
          }
        }
      }
    }
  }
}

@media (max-width: @screen-sm-max){
  .as-banner{
    .as-banner-box{
      height: 250px;
      background-image: url(../images/bg-banner_sp01.png);
      @media (max-width: 375px){
        background-image: url(../images/bg-banner_sp02.png);
      }
      .as-banner-message-box{
        justify-content: space-between;
        .as-banner-message-01{
          color: #ffffff;
          font-size: 13px;
          margin-top: 20px;
        }
        .as-banner-message-02{
          font-size: 19px;
          flex-direction: column;
          .logo-allstocker{
            margin: 0 0 5px 0;
          }
        }
        .as-banner-message-03{
          width: 100%;
          .as-banner-message-03-text{
            font-size: 12px;
            color: #f8b800;
            background-color: #3c3c3c;
            border-radius: 0px;
            padding: 10px;
            flex: 1 1 auto;
          }
          .as-banner-link-button{
            font-size: 13px;
            border-radius: 0px;
            padding: 10px;
            flex: 1 1 auto;
            justify-content: center;
            &::before{
              border-left: 8px solid #3c3c3c;
            }
            .icon-link{
              width: 15px;
            }
          }
        }
      }
    }
  }
}