.as-companies {
    @company-theme-color: var(--as-primary-color);
    @company-theme-sub-color: var(--as-secondary-color);
    @company-font-color: var(--as-base-text-color);

    /*container*/
    .container{
        width: 100%;
    }

    /*pagination*/
    .v-pagination{
        width: auto;
        &.theme--light{
            .v-pagination__navigation,
            .v-pagination__item{
                /*reset*/
                box-shadow: none;
                margin: 0;
                border-radius: 0;
                /*overrider*/
                line-height: 1.42857143;
                color: #14A2CC;
                background-color: #fff;
                border: 1px solid #bebebe;
                margin-left: -1px;
                padding: 5px 10px;
                font-size: 14px;
                min-width: 36px;
                width: 36px;
                height: 32px;
                text-align: center;
                &:hover{
                    color: #009fd3;
                    background-color: #fffad6;
                }
                &:focus{
                    outline: none;
                }
                &.v-pagination__item--active{
                    color: #fff;
                    background-color: #009fd3 !important;
                    border-color: #009fd3 !important;
                }
        
            }
            .v-pagination__item{
                &:hover{
                    text-decoration: underline;
                }
            }
            .v-pagination__navigation{ 
                .v-icon{
                    color: #009fd3;
                    font-size: 1.5rem;
                    font-weight: bold;
                }
            }
            > li:first-child{
                .v-pagination__navigation{ 
                    margin-left: 0;
                    border-bottom-left-radius: 2px;
                    border-top-left-radius: 2px;
                }
            }
            > li:last-child{
                .v-pagination__navigation{ 
                    margin-right: 0;
                    border-bottom-right-radius: 2px;
                    border-top-right-radius: 2px;
                }
            }
        }
    }
    
    /*grid*/
    .row{
        margin: 0 -10px 0 -10px;
        margin-right: -10px;
        &::before,
        &::after{
            content: normal;
        }
    }
    
    .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9,
    .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9,
    .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9,
    .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9 {
        position: relative;
        min-height: 1px;
        padding: 0 10px 0 10px;      
    }


    /*table*/
    .theme--light{
        .v-data-table{
            tbody{
                tr:not(:last-child) {
                    border-bottom: 1px solid #cccccc;
                    td {
                        border-bottom: none;
                    }
                }
            }
        }
    }

    /*carousel*/
    .v-carousel{
        .v-carousel__prev,
        .v-carousel__next{
            background: rgba(0, 0, 0, 0.3);
            border-radius: 50%;
            &:hover{
                opacity: 0.92;
            }
            .v-icon{
                font-size: 40px !important;
            }
        }
    }

    /*form*/
    .v-input__slot{
        &::before,
        &::after{
            display: none;
        }
    }
    .v-text-field{
        margin: 0;
        padding: 0;
        &:not(.v-select) input,
        textarea{
            color: #4c4c4c;
            cursor: pointer;
            padding: 5px 12px;
            background-color: #fff;
            border: 1px solid #bebebe;
            border-radius: 0;
            -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
            -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
            transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
            &:focus{
                caret-color: auto;
            }
        }
        &:not(.v-select) input{
            width: 100%;
            height: 30px;
        }
        & > .v-input__control{
            & > .v-input__slot{
                margin-bottom: 0;
                & > .v-text-field__slot{
                    display: block;
                    & > label{
                        position: static !important;
                        width: 100%;
                        overflow: visible;
                        margin-bottom: 0;
                    }
                }
            }
        }
        .v-label--active {
            max-width: 100%;
            -webkit-transform: none;
            transform: none;
            color: #676767 !important;
        }
    }
    .v-card__actions{
        padding: 16px;
        justify-content: center;
        .v-btn{
            background-color: @company-theme-sub-color;
            color: #ffffff !important;
            font-weight: bold;
        }
    }

    /*dialog*/
    .v-dialog{
        .v-card__title{
            border-bottom: 1px solid #cccccc;
            font-size: 16px !important;
            line-height: 16px !important;
            font-weight: bold;
        }
        .v-card__text{
            font-size: 16px;
        }
        .v-card__actions{
            padding: 16px;
            justify-content: center;
            .v-btn{
                background-color: @company-theme-sub-color;
                color: #ffffff !important;
                font-weight: bold;
            }
        }
    }

    /*list*/
    .v-list{
        .v-list-item{
            .v-list-item__title{
                font-size: 1em;
            }
        }
    }

    .v-application{
        ul{
            padding-left: 0;
        }
    }
     

    /*button*/
    .v-btn{
        &.v-size--default{
            font-size: 1em;
        }
    }
}

