// Carousel

.as-carousel {
    position: relative;
}

.as-carousel__container--line {
    margin-left: 15px;
    margin-right: 15px;
}

.as-carousel__control-button {
    .btn;
    .btn-update;
    box-shadow: none;
    position: absolute;
    top: 0px;
    width: 15px;
    height: 100%;
    z-index: 99;

    &:focus,
    &:hover {
      box-shadow: none;
    }
}

.as-carousel__control-button--prev {
    left: -8px;
    padding-left: 0;
    padding-right: 24px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &:focus,
    &:hover {
      &:before {
        .as-icon__base--pseudo("../images/icon-arrow-left-button-hover.svg", 80%);
        right: -5px;
      }
    }

    &,
    &:active,
    &:focus:active {
      &:before {
        .as-icon__base--pseudo("../images/icon-arrow-left-button.svg", 80%);
        right: -5px;
      }
    }

}

.as-carousel__control-button--next {
    right: -8px;
    padding-left: 0;
    padding-right: 24px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    &:focus,
    &:hover {
      &:before {
        .as-icon__base--pseudo("../images/icon-arrow-right-button-hover.svg", 80%);
        right: -5px;
      }
    }

    &,
    &:active,
    &:focus:active {
      &:before {
        .as-icon__base--pseudo("../images/icon-arrow-right-button.svg", 80%);
        right: -5px;
      }
    }

}

.as-carousel__container-control--expand {
}


// Jumbotron Carousel

@as-jumbotron-carousel-max-height: 400px;

.as-jumbotron-carousel {
  max-height: @as-jumbotron-carousel-max-height;
  overflow: hidden;

  .as-jumbotron-carousel__slide {
    position: relative;
    height: 100%;
  }

  .as-jumbotron-carousel__back-image {
    z-index: 1 !important;
    > img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .as-jumbotron-carousel__front-elements {
    z-index:  2 !important;
    position: absolute;;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@media (max-width: @screen-xs-max) {

  // .as-carousel-container.as-carousel__container--collapsed {
  .as-carousel__container--line {
      margin-left: -5px;
      margin-right: -5px;
  }
  // .as-carousel-container {
  //   margin-right: -15px;
  //   margin-left: -15px;
  // }

  //Ignore controling by Bootstrap col-
  .fs-carousel-canister {
    .display-flex;
    .flex-flow(row, wrap);
  }

  .as-carousel__container--control {
    margin-left: 15px;
    margin-right: 15px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
