// Labels

.label {
  border-radius: 0.85em;
  padding: .25em .55em .3em .6em;
}

.label-attention {
  .label-variant(@as-sale-status-ending-background-color);
  color: @as-sale-status-ending-color;
  box-shadow: 0 0 0 2px @as-sale-status-ending-soon-border-color inset;
}

.label-moderate {
  .label-variant(@as-label-moderate-background-color);
  color: @as-label-moderate-color;
}

.label-as-important-point {
  color: @as-sale-status-ending-color;
  white-space: pre-wrap !important;
  display: block;
  position: absolute;
  bottom: 0px;
  padding: .3em 0 .3em 16px;
  text-align: left;
  z-index: 1;
  background-color: rgba(255,255,255,.85);
  border-radius: 0;
  width: 100%;
  font-weight: normal;
  &:before{
    content: "!";
    display: inline-block;
    padding: 2px 0;
    width: 14px;
    height: 14px;
    border-radius: 10px;
    box-shadow: 0 0 0 1.5px @as-sale-status-ending-soon-border-color inset;
    background-color: @as-sale-status-ending-background-color;
    position: absolute;
    left: 0;
    top: 1px; 
    text-align: center;
    font-weight: @badge-font-weight;
  }
}

@media (max-width: @screen-xs-max) {
  .label-as-important-point {
    background-color: initial;
    top: 100%;
  }
}
