// Global Header

@as-global-header-header-height: 38px;
@as-global-header-border-width: 4px;
@as-global-header-body-height: 50px;
@as-global-header-text-color: #ffffff;
@as-global-header-link-color: #e5e5e5;
@as-global-header-ui-border-color: #868686;

.as-global-header {
  position: relative;
  font-size: @font-size-h6;
  color: @as-global-header-text-color;
}

.as-global-header__header {
  height: @as-global-header-header-height;
  padding-bottom: @as-global-header-border-width;
  border-bottom: @as-global-header-border-width solid @as-brand-key;
  background-color: @as-brand-base;
}

.as-global-header__message {
  padding: 0 10px;
  > p {
    display: table-cell;
    vertical-align: middle;
    height: @as-global-header-header-height - @as-global-header-border-width;
    margin-bottom: 0;
    line-height: 1.2em;
  }
}

// For Vuetify page
.as-material {
  .as-global-header__message {
    > p {
      display: table-cell;
      vertical-align: middle;
      height: auto;
      margin-bottom: 0;
      line-height: 1.2em;
    }
  }
}

.as-global-header__menu-wrapper {
}

.as-global-header__menu {
  .display-flex;
  .justify-content(flex-end);
  .align-items(center);
  height: @as-global-header-header-height - @as-global-header-border-width;

  > a {
    color: @as-global-header-link-color;
    text-decoration: underline;

    &.focus,
    &:focus,
    &:hover {
      color: @as-global-header-text-color;
    }
  }

  > * {
    margin-left: 10px;
  }

  > a, label, span {
    white-space: nowrap;
  }

  > label {
    font-weight: normal;
  }
  .as-header-util{
    flex: 0 0 auto;
  }
}

.as-global-header__dropdown,
.as-global-header__languages {
  label {
    display: none;  // for spacing problem
    margin-right: 0.5em;
  }

  .dropdown-toggle {
    position: relative;
    padding:  4px 22px 3px 9px;
    font-size: inherit;
    text-align: left;
    color: @as-global-header-link-color;
    text-decoration: none;
    border-radius: 0;
    border: 1px solid @as-global-header-ui-border-color;

    .flag-icon + span {
      margin-left: 0.5em;
    }

    .caret {
      position: absolute;
      top: 0;
      right:  8px;
      bottom: 0;
      margin: auto;
      border-top-width: 7px;
      border-right-width: 4px;
      border-left-width: 4px;

      border-top-color: @as-global-header-ui-border-color;
    }
  }
}
.as-global-header__dropdown{
  .dropdown-toggle {
    .open& {
      color: #0e0e0e;
      background-color: #f1f1f1;
      border-color: darken(@as-global-header-text-color, 35%);

      .caret {
        border-top-color: #0e0e0e;
      }
    }
    &.focus,
    &:focus,
    &:hover {
      border-color: darken(@as-global-header-text-color, 35%);

      .caret {
        border-top-color: darken(@as-global-header-text-color, 35%);
      }
    }
  }

  .dropdown-menu {
    margin-top: -1px;
    background-color: #646464;
    border-radius: 0;
    border-color: #a6a6a6;
    font-size: @font-size-h6;
    box-shadow: 0  2px 2px rgba(0, 0, 0, 0.25);

    > li > a {
      padding: 5px 10px;
      color: @as-global-header-link-color;

      &.focus,
      &:focus,
      &:hover {
        background-color: #808080;
      }
    }

    .flag-icon {
      display: none;
      // margin-right: 0.5em;
    }

    hr {
        margin-top: 5px;
        margin-bottom: 5px;
        border: 0;
        border-top: 1px solid #a6a6a6;
    }

  }
}
.as-global-header__languages {
  margin-right: 10px;
  .dropdown-toggle {
    color: lighten(@as-brand-emphasis, 10%);
    background-color: #fff;
    &.focus,
    &:focus,
    &:hover {
      background-color: #ccc;
    }
  }
  .dropdown-menu {
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%,7%);
    -moz-transform: translate(-50%,7%);
    -ms-transform: translate(-50%,7%);
    -o-transform: translate(-50%,7%);
    transform: translate(-50%,7%);
    margin-bottom: -25px;
    background-color: #FFF;
    border-radius: 0;
    border-color: @as-global-footer-footer-background-color;
    font-size: @font-size-h6;
    box-shadow: 0  2px 2px rgba(0, 0, 0, 0.25);
    > li > a {
      padding: 5px 10px;
      color: @as-global-footer-footer-background-color;
      &.focus,
      &:focus,
      &:hover {
        background-color: @as-disable-link-color;
      }
    }
    hr {
        margin-top: 5px;
        margin-bottom: 5px;
        border: 0;
        border-top: 1px solid @as-global-footer-footer-background-color;
    }
  }
  .dropdown-menu:before,
  .dropdown-menu:after {
    position: absolute;
    left: 50%;
    bottom: 100%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    border-width: 7px;
  }
  .dropdown-menu:before {
    border-bottom-color: @as-global-footer-footer-background-color;
    margin-left: -7px;
  }
  .dropdown-menu:after {
    border-bottom-color: #FFFFFF;
    margin: 0 0 -1px -7px;
  }
  //レスポンシブ対応
  @media (max-width: @screen-xs-max){
    .dropdown-menu {
      left: 0;
      -webkit-transform: translate(0%,7%);
      -moz-transform: translate(0%,7%);
      -ms-transform: translate(0%,7%);
      -o-transform: translate(0%,7%);
      transform: translate(0%,7%);
    }
    .dropdown-menu:before,
    .dropdown-menu:after {
      left: 30%;
    }
  }
}


.as-global-header__offcanvas-toggle {
  position: absolute;
  top: 0;
  right: 0;

  // for formstone navigation handle
  > a {
    display: block;
    width: 50px;
    height: @as-global-header-header-height !important;
    padding: 0!important;
    background-color: transparent!important;
    border: none !important;
  }
}

.as-global-header__body {
  min-height: @as-global-header-body-height;
  .col-xs-6.text-right.visible-xs {
    min-height: 33px; // AS-9932
  }
}

.as-global-header__body-wrapper {
  min-height: 40px;
    //mobile
    @media (max-width: @screen-xs-max) {
    border-bottom: solid 1px #e0e0e0;
  }
}

.as-global-header__logo {
  max-height: 27px;  //28px;
  margin-top: 11px;
  margin-bottom: 5px;
}

.as-global-header__caption {
  color: @as-text-description-color;
}

.as-global-header__banners {
  .clearfix;
  .display-flex;
  .justify-content(flex-end);
}

.as-global-header__links {
  background-color: @as-brand-base;
  text-align: center;
  margin-top:10px;
  padding:5px 0px;

  > a {
    color: @as-global-header-link-color;
    text-decoration: underline;
    &.focus,
    &:focus,
    &:hover {
      color: @as-global-header-text-color;
    }
  }
}
.as-global-header__link{
  margin:0px 10px;
}

.as-global-header__search-bar {
  float: right;
}

// Header right contents

.as-exhibit-request {
  text-align: right;
}

.as-exhibit-request__caption {
  margin: 0;
  font-size: 10px;
  font-weight: bold;
}

// Text banner

@media (max-width: @screen-xs-max) {

  .as-global-header__dropdown {
    position: absolute;
    right: 50px;
    top: 3px;

    .dropdown-menu {
      font-size: @font-size-h3;

      > li > a {
        padding: 7px 15px;
        color: @as-global-header-link-color;
        background-color: #646464;

        &.focus,
        &:focus,
        &:hover {
          background-color: #808080;
        }
      }

      .flag-icon {
        margin-right: 0.5em;
      }
    }

  }

  .as-global-header__languages {
    position: absolute;
    top: 4px;

    .dropdown-toggle {
      font-size: 14px;
      color: lighten(@as-brand-emphasis, 10%);

      .caret {
        border-top-color: lighten(@as-brand-emphasis, 10%);
      }

      .open&,
      &.focus,
      &:focus,
      &:hover {
        background-color: transparent;
        color: @as-brand-emphasis;

        .caret {
          border-top-color: @as-brand-emphasis;
        }
      }

      // Fix for each languages
      html:lang(zh-HK) & {
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 12px;
        letter-spacing: -.02em;

        > span:not(.caret) {
          margin-right: -.5em;
        }
      }

    }
  }

  // for formstone offcanvas
  .as-global-header__header {
    position: absolute;
    top: 0;
    right: 0;
    // height: 0;
    padding: 0;
    background-color: transparent;
    background-image: none;
    border: none;

    // for v1 header style
    &, a {
      margin-top:2px;
    }
  }
  .as-global-header__menu {
    // height: 0;
  }

}

@media (min-width: @screen-sm-min) {
  .as-global-header__links {
    background-color: transparent;
    text-align: right;
    margin-top:10px;
    padding:0px;

    > a {
      color: @as-brand-transition;
      text-decoration: underline;
      &.focus,
      &:focus,
      &:hover {
        color: @as-brand-transition;
      }
    }
    .as-global-header__link{

    }
  }

  .as-global-header__tabs {
    position:absolute;
    left: 240px;
    top: 16px;
  
    span {
      a {
        padding: 4px;
        font-size: 16px;
        color: #4c4c4c;
        text-decoration: none;
      }
    }
    span.active a {
      border-bottom: 4px solid #ffcb3d;
    }
  }

}

.as-header {
  // for mobile style
  @media (max-width: @screen-xs-max) {
    border-top: solid 8px #565656;
  }
}

.as-global-header__body {

  // for mobile style
  @media (max-width: @screen-xs-max) {
    background-color: transparent;
    border-top: solid 2px @as-brand-key;
  }
  @media (min-width: @screen-sm-min) {
    background-color: transparent;
  }
}

.as-global-header__logo {
  max-height: 27px;  //28px;
  margin-top: 11px;
  margin-bottom: 5px;
  max-width: 292px;

}


//== Formstone Offcanvas Style

@as-offcanvas-background-color: #fff;
@as-offcanvas-text-color: #3c3c3c;
@as-offcanvas-handle-color: #3c3c3c;

// Overrides

.fs-navigation-push-nav.fs-navigation-enabled, .fs-navigation-reveal-nav.fs-navigation-enabled, .fs-navigation-overlay-nav.fs-navigation-enabled {
  margin: 0;
  // box-shadow: none;
}
.fs-navigation-push-nav.fs-navigation-open, .fs-navigation-overlay-nav.fs-navigation-open {
  // box-shadow: 2px 0 2px rgba(0, 0, 0, 0.15);
}

.fs-navigation-handle.fs-navigation-enabled:after {
  height: 4px!important;
  width: 21px!important;
  position: absolute!important;
  top: -2px!important;
  right: 14px!important;
  bottom: 0!important;
  background: @as-offcanvas-handle-color!important;
  box-shadow: 0 7px 0 @as-offcanvas-handle-color, 0 -7px 0 @as-offcanvas-handle-color!important;
  content: ''!important;
  display: block!important;
  margin: auto 0!important;
}

.fs-navigation-handle.fs-navigation-open:after {
  width: 22px!important;
  height: 22px!important;
  background: transparent!important;
  border: none!important;
  box-shadow: none!important;
  color: @as-offcanvas-handle-color!important;
  content: "\00d7"!important;
  font-size: 40px!important;
  font-weight: 700!important;
  line-height: 14px!important;
}

// Styles

.as-offcanvas-navigation.fs-navigation-enabled {
  z-index: 5 !important;
  background-color: @as-offcanvas-background-color;
  padding: 30px 30px 100px!important;

  .userInformationInToglle{
    color: @as-offcanvas-text-color;
  }

  .as-offcanvas__item {
    padding: 5px 10px;

    &, a {
      font-size: 20px;
      color: @as-offcanvas-text-color;
      text-decoration: none;
      font-weight: bold;
    }

    a:hover, a:focus {
      color: #333333;
      background: #d6d6d6;
      text-decoration: underline;
    }
  }

  .as-global-header__link  {
    display: block;

    &:before {
      display: none;
    }
  }

}
