// Button

.btn {
  border: none;
  .button-size(@padding-base-vertical; @padding-base-horizontal; @font-size-base; @line-height-base; @btn-border-radius-base);
}

// Alternate buttons

.btn-default {
  .button-variant(@btn-default-color; @btn-default-bg; @btn-default-shadow; @btn-default-color-focus; @btn-default-bg-focus; @btn-default-shadow-focus;);
}
.btn-primary {
  .button-variant(@btn-primary-color; @btn-primary-bg; @btn-primary-shadow; @btn-primary-color-focus; @btn-primary-bg-focus; @btn-primary-shadow-focus;);
}

// AS Original Class

.btn-update {
  .button-variant(@btn-update-color; @btn-update-bg; @btn-update-shadow; @btn-update-color-focus; @btn-update-bg-focus; @btn-update-shadow-focus;);
}

.btn-update-heading {
  .button-variant(@btn-default-color; @btn-default-bg; @btn-default-shadow; @btn-update-color-focus; @btn-update-bg-focus; @btn-update-shadow-focus;);
}

.btn-appeal {
  .button-variant(@btn-appeal-color; @btn-appeal-bg; @btn-appeal-shadow; @btn-appeal-color-focus; @btn-appeal-bg-focus; @btn-appeal-shadow-focus;);
}

.btn-contact{
  .button-variant(@btn-contact-color; @btn-contact-bg; @btn-contact-shadow; @btn-contact-color-focus; @btn-contact-bg-focus; @btn-contact-shadow-focus;);
}

.btn-moderate{
  .button-variant(@btn-moderate-color; @btn-moderate-bg; @btn-moderate-shadow; @btn-moderate-color-focus; @btn-moderate-bg-focus; @btn-moderate-shadow-focus;);
  border: 1px solid @btn-moderate-border;
}


// Button Sizes
// --------------------------------------------------
// Recompilation Bootstrap code with new variables.less

.btn-lg {
  // line-height: ensure even-numbered height of button next to large input
  .button-size(@padding-large-vertical; @padding-large-horizontal; @font-size-large; @line-height-large; @btn-border-radius-large);
}
.btn-sm {
  // line-height: ensure proper height of button next to small input
  .button-size(@padding-small-vertical; @padding-small-horizontal; @font-size-small; @line-height-small; @btn-border-radius-small);
}
.btn-xs {
  .button-size(@padding-xs-vertical; @padding-xs-horizontal; @font-size-small; @line-height-small; @btn-border-radius-small);
}
