

// Modals

.modal-open {
    //overflow: hidden;
    //position: fixed; //does not work on mobile safari
}


.modal {
  .modal-dialog{
    max-width: 400px;
  }
  .modal-header {
    padding-top: (@modal-title-padding - 5px);
    padding-bottom: (@modal-title-padding - 10px);
    font-size: @font-size-h3;
    font-weight: bold;
    border-bottom-color: @as-sub-border-color;

    // Close icon
    .close {
      position: relative;
      //bottom: 0.15em;
      font-size: (@font-size-base * 2);
      color: inherit;
      text-shadow: none;
      .opacity(1);

      &:hover,
      &:focus {
        color: inherit;
        text-decoration: none;
        cursor: pointer;
        .opacity(.8);
      }
    }
  }
  .modal-body {
    padding-top: (@modal-inner-padding - 20px);
    padding-bottom: (@modal-inner-padding - 20px);
  }
  .modal-footer {
    padding-top: (@modal-inner-padding - 20px);
    border-top: none;

    // AS Original Classes
    // Allow containig 1 or 2 buttons
    .modal-actions {
      .display-flex;
      .justify-content(center);

      .btn {
        .flex(0,1,auto);
        min-width: 120px;
      }
    }
  }
  // AS Original Classes
  // Explanatory text about modal
  .modal-subheader{
    padding-top: (@modal-inner-padding - 20px);
    padding-left: 30px;
    padding-right: 30px;
  }
}

// AS Original Classes

.modal-fullscreen {
  .modal;

  // Modal background for Fullscreen
  &:before {
    content: "";
    display:block;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: @modal-content-bg;
  }
}

.modal-dialog-fullscreen {
  .modal-dialog;
  position: fixed;
  width:  100%;
  height: 100%;
  margin: 0;
  overflow-y: scroll;

  .modal-content {
    border-radius: 0;
    .box-shadow(none);
    border: none;

    // for as-modal__fixed-footer
    padding-bottom: 50px;  // TODO
  }

  .modal-header {
    .display-flex;
    .align-items(center);
    color: #fff;
    background-color: #545454;

    > * {
      // &.btn {
      //   .flex(1,1,auto);
      // }
      &.modal-title {
        .flex(1,1,auto);
      }
      &.as-modal__header-space {
        position: relative;
        .flex(1,1,30%);
      }
    }

    .modal-title {
      font-size: @font-size-h4;
      text-align: center;
      .align-self(center);
    }

    // for Bootstrap button
    .btn {
      position: relative;
      // position: absolute;
      // left: 20px;

      &:active:before,
      &:hover:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        box-shadow: 0 0 0 1px rgba(255,255,255,.7);
        border-radius: @btn-border-radius-base;
      }
    }
  }
}

.as-modal__fixed-footer {
  z-index: 1060;
  position: fixed;
  width: 100%;
  bottom: 0;
  padding: 10px 0 10px;
  text-align: center;
  background-color: rgba(100, 100, 100, 0.9);
}


// Responsive
@media (max-width: @screen-xs-max) {
  .modal {
    .modal-dialog{
      max-width: none;
    }
    .modal-header {
      padding-left: (@modal-title-padding - 5px);
    }
    .modal-body,
    .modal-subheader,
    .modal-footer {
      padding-right: (@modal-inner-padding - 20px);
      padding-left: (@modal-inner-padding - 20px);
    }
    .modal-footer {
      padding-bottom: (@modal-inner-padding - 20px);

      .modal-actions {
        .btn {
          .flex(1,1,auto);
          min-width: 0;
        }
      }
    }
    .panel {
      .panel-body {
        padding-right: (@panel-body-padding - 20px);
        padding-left: (@panel-body-padding - 20px);
      }
    }
  }
}

