// Balloon
// Toolchip widget for hover

// as-balloon--(position) : Set the position where to show
// as-balloon--(align) : Set the align of position ( for prevent overflow)

.as-balloon__root {
  position: relative;
}
.as-balloon {
  position: absolute;
  background-color: @as-balloon-background-color;
  border-radius: @border-radius-base;
  color: @as-balloon-color;
  font-size: @font-size-h6;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
  white-space: nowrap;  // balloon text spreads beyond as-baloon__root area
  user-select: none;
  text-align: center;
  z-index: 10;

  a {
    color: @as-balloon-color;
    text-decoration: underline;
  }

  // Hide in default
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;

  // Show when root element was hovered
  .as-balloon__root:hover & {
    width: auto;
    height: auto;
    padding: 10px;
    overflow: visible;
    box-shadow: @as-selector-box-shadow;
  }

  // Position

  // Default position
  .as-balloon--top&, & {
    top: auto;
    right: auto;
    bottom: ~"calc(100% + @{as-balloon-margin-from-root})";
    left: 50%;
    .transform(translateX(-50%));
  }

  .as-balloon--bottom& {
    top: ~"calc(100% + @{as-balloon-margin-from-root})";
    right: auto;
    bottom: auto;
    left: 50%;
    .transform(translateX(-50%));
  }

  // Align
  // Must override position style
  .as-balloon--align-right& {
    &,
    .as-balloon--top&,
    .as-balloon--bottom& {
      right: 0;
      left: auto;
      .transform(translateX(0));
    }
  }

  .as-balloon--align-left& {
    &,
    .as-balloon--top&,
    .as-balloon--bottom& {
      right: auto;
      left: 0;
      .transform(translateX(0));
    }
  }

  // Persistent
  .as-balloon-persistent& {
    width: auto;
    height: auto;
    padding: 10px;
    overflow: visible;
    box-shadow: @as-selector-box-shadow;
  }

  // Nozzle
  &:after {
    content: "";
    position: absolute;
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    border: 0 solid transparent;


    // Position

    // Default position
    .as-balloon--top&, & {
      top: auto;
      right: 0;
      bottom: -(@as-balloon-nozzle-height - 1px);
      left: 0;
      border-top: @as-balloon-nozzle-height solid @as-balloon-background-color;
      border-right-width: @as-balloon-nozzle-width / 2;
      border-bottom-width: 0;
      border-left-width:  @as-balloon-nozzle-width / 2;
    }

    .as-balloon--bottom& {
      top: -(@as-balloon-nozzle-height - 1px);
      right: 0;
      bottom: auto;
      left: 0;
      border-top-width: 0;
      border-right-width: @as-balloon-nozzle-width / 2;
      border-bottom: @as-balloon-nozzle-height solid @as-balloon-background-color;
      border-left-width:  @as-balloon-nozzle-width / 2;
    }


    // Align
    // Must override position style

    .as-balloon--align-right& {
      &,
      .as-balloon--top&,
      .as-balloon--bottom& {
        right: -50%;
      }
    }

    .as-balloon--align-left& {
      &,
      .as-balloon--top&,
      .as-balloon--bottom& {
        left: -50%;
      }
    }

  }

}
