.as-companies {
    @company-theme-color: var(--as-primary-color);
    @company-theme-sub-color: var(--as-secondary-color);
    @company-font-color: var(--as-base-text-color);


    .companies-item{
        width: 100%;
        margin: auto;
        .as-main-contents-header{
            display: none;
        }
        .as-main-contents-body{
            padding-top: 0;
        }
        .item-center-contents{
            padding-top: 0;
            color: #333;
        }
        .photo-viewer{
            position: relative;
            height: 100%;
        }
        .photoIndex {
            position: absolute;
            bottom: 15px;
            left: 15px;
            margin-bottom: 0;
            padding: 6px 12px;
            background-color: rgba(255,255,255,.75);
            border-radius: 2px;
            font-size: 14px;
            font-weight: bold;
        }
        .photoViewer {
            position: absolute;
            bottom: 15px;
            right: 15px;
            margin-bottom: 0;
            padding: 6px 12px;
            background-color: rgba(255,255,255,.75);
            border-radius: 2px;
            font-size: 14px;
            font-weight: bold;
        }
        .maker-name{
            font-size: 18px;
            line-height: 1;
        }
        .model-number{
            font-size: 30px;
            font-weight: bold;
            line-height: 1;
            border-bottom: 4px solid @company-theme-color;
        }
        .company-page-groups-item-detail-summary{
            padding-left: 0;
            display: flex;
            > li{
                margin-right: 10px;
            }
        }
        .item-price,
        .item-currency,
        .item-tax{
            color: @as-brand-attention;
            font-weight: bold;
        }
        .item-price{
            font-size: 40px;
        }
        .item-tax,
        .item-currency{
            font-size: 24px;
        }
        .currency-converter{
            & + div{
                background-color: #f2f2f2 !important;
                border: 1px solid #cfcfcf;
                .text-label{
                    font-size: 16px;
                }
            }
        }
        .converted-price{
            font-size: 30px;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            .v-input{
                flex: 0 0 auto;
                padding-top: 3px;
                max-width: 120px;
                margin-left: 10px;
                .v-input__slot{
                    border: 1px solid #4c4c4c;
                    background-color: #ffffff;
                    margin-bottom: 0;
                    &::before,
                    &::after{
                        display: none;
                    }
                }
                .v-select__selection--comma{
                    margin-left: 7px;
                }
                .theme--light.v-icon {
                    color: rgba(0,0,0,.54) !important;
                }
            }
        }
        .currency-converter,
        .company-page-file-download,
        .inquiery{
            .v-btn{
                background-color: @company-theme-sub-color !important;
                color: @company-font-color;
                font-weight: bold;
                padding: 5px;
                border-radius: 2px;
                box-shadow: none;
            }
        }
        .photo-viewer{
            .photoViewer{
                background-color: @company-theme-sub-color !important;
                color: @company-font-color;
                font-weight: bold;
                padding: 5px;
                border-radius: 2px;
                box-shadow: none;
                cursor: pointer;
                padding-left: 25px;
                &::before{
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    opacity: .12;
                    transition: .3s cubic-bezier(.25,.8,.5,1);
                }
                &::after{
                    content: "";
                    width: 14px;
                    height: 14px;
                    position: absolute;
                    left: 8px;
                    top: 8px;
                    background-image: url(../images/icon-view-large-invert.svg);
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    background-position: center center;
                }
                &:hover{
                    &::before{
                        background-color: currentColor;
                    }
                }
            }
        }
        .company-page-label{
            background-color: @company-theme-color;
            color: @company-font-color;
            padding: 4px 4px 4px 45px;
            font-size: 23px;
            font-weight: bold;
            position: relative;
            line-height: 1.5;
            &::before{
                content: "";
                width: 25px;
                height: 25px;
                position: absolute;
                left: 10px;
                top: 0;
                bottom: 0;
                margin: auto 0;
                background-repeat: no-repeat;
                background-position: center;
            } 
            &.icon-detail::before{
                background-image: url(/images/company-page/icon-detail.svg);
            }
            &.icon-inquiry::before{
                background-image: url(/images/company-page/icon-inquiry.svg);
            }
        }
        .as-mixed-columns-table{
            background-color: #f2f2f2;
            padding: 58px 15px 15px 15px;
            border: 1px solid #d2d2d2;
            display: flex;
            position: relative;
            .company-page-label{
                position: absolute;
                top: -1px;
                left: -1px;
                width: calc(~"100% + 2px");
                border-bottom: 1px solid #d2d2d2;
            }
            .as-mixed-columns-table__group{
                &:not(:first-of-type){
                    margin-left: 10px;
                }
                tr{
                    border-bottom: 1px solid #d2d2d2;
                    background-color: #ffffff;
                    &:first-of-type{
                        border-top: 1px solid #d2d2d2;
                    }
                    th{
                        border-right: 1px solid #d2d2d2;
                    }
                }
            }
            .as-mixed-columns-table__group--row{
                tr{
                    th{
                        border-right: none; 
                    }
                }
            }
        }
        .inquiry{
            .company-page-label{
                margin: 0;
            }
            form{
                background-color: #f2f2f2;
                padding: 15px;
                border: 1px solid #d2d2d2;
                .v-card__actions{
                    padding: 0;
                }
            }
        }

        //レスポンシブ対応
        @media (max-width: @screen-xs-max){
            .as-mixed-columns-table{
                flex-wrap: wrap;
                .as-mixed-columns-table__group{
                    &:not(:first-of-type){
                        margin: 0;
                    }
                }
            }
        }
    }
}