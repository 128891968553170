// Typography
//
// Nothing with Bootstlap type.less

// Add colon
.as-text__colon {
  &:after {
    content: ":";
  }
}

.as-text__parenthesis {
  &:before {
    content: "(";
  }
  &:after {
    content: ")";
  }
}
