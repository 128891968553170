// Animation




// Using for slide-in-out notify on top page

.as-animation--bounce-in-right {
  .animation-name(as-animation--bounce-in-right);
}

.keyframes(as-animation--bounce-in-right;{
  from, 50% to {
    .animation-timing-function(cubic-bezier(0.215, 0.610, 0.355, 1.000));
  }

  from {
    opacity: 0;
    .transform(translate3d(2000px, 0, 0));
  }

  50% {
    opacity: 1;
    .transform(translate3d(-5px, 0, 0));
  }

  to {
    .transform(none);
  }
});

.as-animation--bounce-out-left {
  .animation-name(as-animation--bounce-out-left);
}

.keyframes(as-animation--bounce-out-left;{
  from {
    opacity: 1;
    .transform(translate3d(0, 0, 0));
  }

  20% {
    .transform(translate3d(20px, 0, 0));
  }

  to {
    opacity: 0;
    .transform(translate3d(-2000px, 0, 0));
  }
});




// Using for changed value on item page, etc

.as-animation--changed-value {
  .animation-name(as-animation--spread-text-shadow);
  .animation-duration(1s);
  .animation-timing-function(ease-in);
}

.keyframes(as-animation--spread-text-shadow;{
  from {
    text-shadow: 0 0 0 fadeout(@as-brand-key, 0%);
  }
  90% {
    text-shadow: 0 0 90px fadeout(@as-brand-key, 10%);
  }
  to {
    text-shadow: 0 0 90px fadeout(@as-brand-key, 100%);
  }
});




// Using for appearing bid button and state on item page

.as-animation--appeal-flash {
  position: relative;

  @as-animation--appeal-flash-width: 6%;
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .8;
    .linear-gradient(~"-45deg, rgba(255,255,255,0),rgba(255,255,255,0) 20%,#ffffff 44%,#ffffff 50%,rgba(255,255,255,0) 51%,rgba(255,255,255,0)");
    background-size: 200% 200%;
    .animation-name(as-animation--appeal-flash);
    .animation-duration(5s);
    .animation-timing-function(linear);
    .animation-iteration-count(infinite);
  }
}

.keyframes(as-animation--appeal-flash;{

  from {
    opacity: 0;
    background-position:100% 50%;
  }
  46% {
    opacity: 0;
  }
  47% {
    opacity: 1;
    background-position:100% 50%;
  }
  54% {
    opacity: 1;
    background-position:-50% 50%;
  }
  55% {
    opacity: 0;
  }
  to {
    opacity: 0;
    background-position:-50% 50%;
  }

});




// Using for showing label notify on item page, etc

.as-animation--split-in {
  .animation-name(as-animation--split-in);
  .animation-duration(400ms);
  .animation-timing-function(linear);
  .animation-iteration-count(1);
}

.keyframes(as-animation--split-in;{

  from {
    // opacity: 1;
    width: 50%;
  }
  20% {  // for delay
    // opacity: 1;
    width: 50%;
  }
  40% {
    // opacity: 0;
  }
  to {
    width: 0%;
  }

});




// Using for showing label notify on item page, etc

.as-animation--fade-in {
  .animation-name(as-animation--fade-in);
  .animation-duration(600ms);
  .animation-timing-function(ease);
}

.keyframes(as-animation--fade-in;{
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
});




// Using for showing label notify area on item page, etc

.as-animation--label-notify-show-down {
  .animation-name(as-animation--label-notify-show-down);
  .animation-duration(150ms);
  .animation-timing-function(ease-out);
}

.keyframes(as-animation--label-notify-show-down;{
  from {
    max-height: 0;
  }
  to {
    max-height: 3.5em;
  }
}; );
