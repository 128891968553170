// widgetMockup
// This is temporary styles

.as-widget__title {
  color: #333;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
}

.as-widget__body {
  padding-top: 45px;
}

.as-widget__item {
  border-bottom: 1px solid #d3d3d3;
}

.as-widget__item-container {
  overflow: hidden;
}

.as-widget__item-title {
  color: #333;

  span {
    display: block;
    font-size: 12px;
  }
}

.as-widget__item-price {
  color: #ff5400;
  font-weight: bold;
  font-size: 12px;

  span {
    font-size: 18px;
  }
}

.as-widget__item-photo {
  display: block;
  width: 30%;
  float: left;
  margin-right: 10px;
}

.as-widget__link {
  display: block;
  padding: 10px 5px;

  &:hover {
    background-color: #fffad6;
  }
}

.as-widget__item-feature {
  color: #333;
  font-size: 12px;
  line-height: 1.7em;
}


@media (min-width:@screen-sm-min) {

.as-widget__item {
  width: 50%;
  float: left;
  border-right: 1px solid #d3d3d3;

  &:nth-child(even) {
    border-right: none;
  }
}

}
