.marketplace-item {
  // Modal Inquiry Input Page
  .as-inquiry-modal {
    .modal-dialog {
      max-width: 600px;
    }

    h2{
      margin-top:0px;
    }
    .modal-header{
      background-color:#FFF;
      color:#CCC;
      .modal-title{
        color:#333;
      }
    }
    .modal-body{
      padding:15px;
      .form-group{
        .required_area{
          color:#C30;
        }
        ul{
          font-size:13px;
          list-style-type: disc;
          margin-left:15px;
        }
        .goods__price{
          font-size:18px;
          font-weight: bold;
          color:#ff5400;
          margin-top: 10px;
          span{
            display:block;
            color:#333;
            font-size:12px;
          }
        }
      }
    }
    .btn-close{
      color: #333;
      background-color: #CCC;
      border:1px solid #999;
      padding: 4px 20px 6px 20px;
    }
    .btn-close:hover{
      background-color: #999;
    }
    .btn-send{
      color: #FFF;
      background-color: #ffc001;
      border:1px solid #ffc001;
      padding: 4px 20px 6px 20px;
    }
    .btn-send:hover{
      background-color: #FF9A00;
    }
  }


  /*2020/2以降のUX改善用デザインスタイル*/
  /* 
  TYPE VARIABLES
  */
  //@font-size-1: 44px;
  //@line-height-1: 56px;
  
  @font-size-2: 32px;
  @line-height-2: 30px; 
  
  @font-size-3: 22px;
  //@line-height-3: 28px; 
  
  @font-size-4: 20px;
  //@line-height-4: 24px; 
  
  @font-size-5: 16px;
  //@line-height-5: 22px;  
  
  @font-size-6: 14px; 
  //@line-height-6: 18px;

  @font-size-7: 12px; 
  
  /*
  COLOR VARIABLES
  */
  @brand-color:          	               #FFC002;
  @brand-border-color:          	       #D6A922;
  @sub-color:           	               #1F50BC;
  @accent-color:        	               #EB2A2A;
  
  /*
  GREYSCALE
  */
  
  // Background
  @white:			                       #ffffff; //e.g. Standard background
  @grey-1: 		                       #f2f2f2;	//e.g. Header background, Form background
  @grey-2: 		                       #eaeaea; //e.g. Background
  @grey-3:		                       #dedede;	//e.g. line, Footer background
  @grey-4:		                       #cecece;	//e.g. border
  @grey-5:		                       #afafaf;	//e.g. text, line, icon, Ghost button
  @grey-6:		                       #838383;	//e.g. Main text
  @grey-7:		                       #565656;	//e.g. Main text

  // Shadow
  @shadow-1: 		                     rgba(0,0,0,0.3);//e.g. checkbox shadow
  
  /*
  BUTTON COLORS
  */
  // Button Color gets darker on hover state.
  @brand-hover-color:                      darken(@brand-color, 8%);
  @sub-hover-color:                      darken(@sub-color, 8%);
  @white-hover-color:                      darken(@white, 8%);
  

  color: @grey-7;

  /*
    機械概要
  */
  .as-groups-item-detail-main__header{
    .as-groups-item-detail-subtitle{
      font-size: @font-size-6;
      color: @grey-5;
    }
    .as-groups-item-detail-title{
      margin: 0;
      font-size: @font-size-2;
      @media (max-width: @screen-xs-max) {
        font-size: @font-size-3;
      }
    }
    .as-groups-item-promotion-label-box{
      .as-groups-item-promotion-label{
        display: inline-block;
      }
      .label-attention{
        background-color: @brand-color;
        color: @grey-7;
        border-radius: 0;
        box-shadow: none;
        margin-right: 0;
        padding: .25em .25em .3em .3em;
      }
      .updated-date{
        font-size: @font-size-7;
      }
    }
    .inline-feature-list{
      display: flex;
      margin-top: 2px;
      margin-bottom: 5px;
      dl{
        font-size: @font-size-7;
        color: @grey-7;
        padding-right: 5px;
        position: relative;
        &:not(:first-child){
          padding: 0 5px;
          &::before{
            content: "";
            width: 1px;
            height: 12px;
            background-color: @grey-6;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto 0;
          }
        }
        dt{
          display: none;
        }
      }
    }
  }

  /*
    機械画像
  */
  .as-groups-item-detail-main__thumbnails{
    .as-photo-viewer{
      @media (max-width: @screen-xs-max) {
        margin-bottom: 10px;
      }
    }
    .btn-update{
      &.as-photo-viewer__bookmark-container{
        min-width: auto;
        width: 38px;
        height: 32px;
        background-color: @grey-1;
        border: 1px solid @grey-4;
        border-radius: 3px;
        padding: 4px 6px;
        box-shadow: 0 3px 6px @shadow-1;
        .as-icon--favorite-on,
        .as-icon--favorite-off {
          width: 24px;
          height: 24px;
        }
        .as-icon--favorite-off {
          background-image: url(../images/icon-favorite.svg);
        }
      }
    }
    .as-photo-viewer__thumbnail-photos{
      padding-top: 5px;
      .as-carousel__control-button--next,
      .as-carousel__control-button--prev{
        border-radius: 0;
        @media (max-width: @screen-xs-max) {
          width: 24px;
        }
        &::before{
          right: -5px;
        }
      }
    }
    .as-groups-item-detail-download-panel,
    .as-groups-item-detail-inspection-report-download-panel{
      font-weight: normal;
      color: @grey-6;
      margin: 0;
      .panel-body{
        background-color: transparent;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-right: 0;
        padding-left: 0;
        text-align: left;
        @media (max-width: @screen-xs-max) {
          padding: 0;
        }
        .as-marketplace-download-conditionreport{
          display: block;
          @media (max-width: @screen-xs-max) {
            line-height: 20px;
            }
        }
      }
      .as-download-link__link,
      .as-marketplace-download-conditionreport{
        font-size: @font-size-6;
        color: @grey-6;
        font-weight: normal;
        text-decoration: none;
        @media (max-width: @screen-xs-max) {
          font-size: @font-size-7;
        }
      }
      .as-download-link,
      .as-marketplace-download-conditionreport{
        &:hover{
          opacity: 0.75;
          text-decoration: none;
        }
      }
      .as-icon--allstocker-logo{
        @media (max-width: @screen-xs-max) {
          width: 6.5em;
          margin-right: 0;
        }
      }
      .as-icon--download-alt,
      .as-icon--inspection-report{
        display: inline-block;
        width: 22px;
        height: 22px;
        vertical-align: middle;
        margin-right: 3px;
        position: relative;
        bottom: .11em;
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
        @media (max-width: @screen-xs-max) {
          width: 16px;
          height: 16px;
        }
      }
      
    }
  }

  /*
    査定リクエスト
  */
  .as-groups-item-detail-assessment-request-page-link {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    margin-bottom: 15px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center -15px;
    background-image: url(../images/assessment-request-image.jpg);
    @media (max-width: @screen-xs-max) {
      height: 63px;
      margin: 10px -10px 15px -10px;
      width: calc(100% + 20px);
    }
    
    a {
      width: 100%;
      display: flex;
      align-items: center;
      text-align: center;
      vertical-align: middle;
      height: calc(~"100% - 10px");
      background-color: rgba(0,0,0,.25);
      padding: 0 16px;
      .assessment-request-text{
        flex: 1 1 auto;
        color: #fff;
        font-weight: 700;
        text-shadow: #000 0px 0px 5px;
      }
      .assessment-request-check{
        font-weight: bold;
        padding: 3px 18px;
        border-radius: 13px;
        flex: 0 0 auto;
      }
    }
  }
  .as-groups-item-detail-assessment-request-page-link,
  .as-groups-machine-model-detail-assessment-request-page-link{
    a {
      color: @white;
      font-size: @font-size-5;
      .assessment-request-check{
        color: @sub-color;
        font-size: @font-size-6;
        background-color: @white;
      }
    }
  }

  /*
    本体価格
  */
  .as-groups-item-detail-info-panel{
    table{
      &.as-groups-pricing-info-table{
        margin-bottom: 0;
        min-height: auto;
      }
    }
    .as-groups-pricing-info-table__primary-info--main-price{
      height: auto;
      @media (max-width: @screen-xs-max) {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        flex-wrap: wrap;
      }
      th{
        font-size: @font-size-7;
        color: @grey-5;
        padding-left: 0;
        padding-right: 0;
        flex: 0 0 auto;
        @media (max-width: @screen-xs-max) {
          margin-top: 25px;
        }
      }
      td{
        text-align: left;
        position: static;
        flex: 1 1 auto;
        @media (max-width: @screen-xs-max) {
          text-align: right;
        }
      }
      .as-groups-pricing-info-table__price{
        font-size: 31px;//「JPY10,200,000 - 10,300,000」とした時に画面内に収まるサイズ
        color: @accent-color;
        text-align: left;
        border-bottom: none;
        min-width: auto;
        margin: 10px 0;
        @media (max-width: @screen-xs-max) {
          text-align: right;
          font-size: 28px;//「JPY10,200,000 - 10,300,000」とした時に画面内に収まるサイズ
        }
        .text-currency{
          font-size: @font-size-6;
          margin-left: 0;
          margin-right: -.2em;
        }
        &.price-masked{
          font-size: @font-size-3;
          display: block;
          text-align: center;
          margin: 10px 0;
        }
      }
    }
    /*在庫なし*/
    .as-groups-pricing-info-table__primary-info--soldout{
      font-size: @font-size-3;
      td{
        margin: 0;
        border-bottom: none;
        min-width: auto;
      }
      span{
        color: @accent-color;
        display: block;
        text-align: center;
        margin: 10px 0;
      }
    }
  }

  /*
    通貨変換
  */
  .as-groups-pricing-info-table__currency-convert-caption{
    &.btn{
      height: auto;
      font-size: @font-size-7;
      color: @grey-5;
      border: 1px solid @grey-5;
      border-radius: 2px;
      background-color: transparent;
      padding: 0 3px;
      box-shadow: none;
      position: absolute;
      right: 0;
      bottom: calc(~"100% - 20px");
      @media (max-width: @screen-xs-max) {
        right: auto;
        left: 0;
      }
      &:hover{
        background-color: @white-hover-color;
      }
    }
    .as-icon--convert{
      background-image: url(../images/icon-convert-neo.svg);
    }
  }

  /*
    お問い合わせ、電話ボタン
  */
  .as-groups-item-detail-action-panel{
    /*お問い合わせボタン*/
    .as-groups-contact{
      margin-bottom: 8px;
      .btn-contact{
        background-color: @brand-color;
        border: 1px solid @brand-border-color; 
        color: @grey-7;
        white-space: pre-wrap;
        height: auto;
        box-shadow: 0 3px 6px rgba(0,0,0,.29);
        padding-bottom: 13px;
        &:hover{
          background-color: @brand-hover-color;
        }
        .text-caption{
          font-size: @font-size-7;
          color: @grey-7;
          font-weight: normal;
          margin-bottom: 0;
        }
      }
    }
    /*電話ボタン*/
    .phone-call-box-pc{
      display: flex;
      justify-content: space-between;
      align-items: center;
      &::before,
      &::after{
        content: "";
        flex: 1 1 auto;
        height: 1px;
        background-color: @grey-7;
      }
      > .contact-phone-number-area{
        display: flex;
        align-items: center;
        > span{
          margin: 0 5px;
        }
        .as-icon--phone{
          margin-right: 0;
          background-image: url(../images/icon-phone-neo-grey.svg);
        }
        .phone-number{
          flex: 0 0 auto;
          margin-left: 0;
          font-size: @font-size-4;
        }
      }
    }
  }

  /*
  商品情報テーブル
  */
  .as-groups-item-detail-panel__panel--details{
    font-size: @font-size-7;
    @media (max-width: @screen-xs-max) {
      font-size: @font-size-6;
    }
    .panel-default{
      border: none;
    }
    .panel .panel-body{
      padding: 0;
      background-color: transparent;
    }
    .item-detail-panel-title{
      display: flex;
      align-items: center;
      margin: 0 -10px;
      & > div{
        margin-right: 15px;
      }
      .as-icon--warning-blue{
        width: 24px;
        height: 24px;
        @media (max-width: @screen-xs-max) {
          display: none;
        }
      }
      .important-notes{
        font-size: @font-size-6;
        vertical-align: middle;
      }
    }
    .item-detail-panel-table{
      margin-top: 10px;
      border: 1px solid @grey-3;
      @media (max-width: @screen-xs-max) {
        border: none;
      }
      .details-part,
      .description-part{
        padding: 0 20px;
        @media (max-width: @screen-xs-max) {
          padding: 0 10px;
        }
      }
    }
    dl{
      padding: 10px 0;
      border-bottom: 1px solid @grey-3;
      &:first-of-type{
        @media (max-width: @screen-xs-max) {
          border-top: 1px solid @grey-5;
        }
      }
      &:last-of-type{
        border-bottom: none;
        @media (max-width: @screen-xs-max) {
          border-bottom: 1px solid @grey-3;
        }
      }
      dt,dd{
        padding-left: 0;
      }
    }
    .description-part{
      dl{
        border-bottom: none;
        padding: 0;
        &:first-of-type{
          @media (max-width: @screen-xs-max) {
            border-top: none;
          }
        }
        &:last-of-type{
          @media (max-width: @screen-xs-max) {
            border-bottom: 1px solid @grey-5;
          }
        }
        dt,dd{
          padding: 10px 0;
        }
        dt{
          border-bottom: 1px solid @grey-3;
          @media (max-width: @screen-xs-max) {
            border-bottom: none;
          }
        }
        dd{
          max-height: 340px;
          overflow: auto;
          padding: 0;
          background-image: radial-gradient(farthest-side at 50% 0, rgba(0,0,0,0.2), rgba(0,0,0,0)), radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,0.2), rgba(0,0,0,0));
          background-repeat: no-repeat;
          background-attachment: scroll;
          background-position: 0 0, 0 100%;
          background-size: 100% 14px;
          @media (max-width: @screen-xs-max) {
            max-height: none;
          }
          .description-text-wrap{
            background: ~"linear-gradient(to top, hsla(0,0%,100%,0), white 15px) 0 0/100% 50px, linear-gradient(to bottom, hsla(0,0%,100%,0), white 15px) bottom/100% 50px";//コンパイル時に一部プロパティが省略されてしまうため、エスケープする
            background-repeat: no-repeat;
            background-attachment: local;
            padding: 10px 0;
            @media (max-width: @screen-xs-max) {
            padding: 0 0 10px 0;
            }
          }
        }
      }
    }
  }

  /*
    類似機械
  */
  .as-groups-item-detail-panel{
    .panel-body,
    .panel-footer{
      background-color: @grey-2;
    }
    /*カルーセル内アイテム*/
    .fs-carousel-item{
      .as-goods__title{
        font-size: @font-size-6;
        font-weight: bold;
        color: @sub-color;
      }
      .as-goods__price{
        font-size: @font-size-5;
        color: @accent-color;
      }
      .as-goods__other-features{
        ul{
          display: flex;
          flex-wrap: wrap;
          margin-top: 2px;
          margin-bottom: 5px;
          & > li{
            font-size: @font-size-7;
            color: @grey-7;
            padding-right: 5px;
            margin-right: 5px;
            position: relative;
            &:not(:last-child){
              //padding: 0 5px;
              &::after{
                content: "";
                width: 1px;
                height: 12px;
                background-color: @grey-6;
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto 0;
              }
            }
            .as-goods__other-features-head{
              display: none;
            }
          }
        }
      }
    }
    .panel-footer{
      .as-groups-new-arrivals__view-all{
        text-align: center;
      }
    }
    /*同一機械のカルーセル*/
    .as-groups-item-detail-panel__panel--same-model{
      .fs-carousel-item{
        .as-goods__title{
          display: none;
        }
        .as-goods__price{
          margin-top: 5px;
        }
      }
    }
  }

  /*
    注意事項
  */
  .as-groups-confirm-info{
    //color: @grey-7;
    border: 1px solid @grey-5;
    .as-heading--default{
      &.panel-heading{
        color: @grey-7;
        margin: 0;
        background-color: @grey-2;
        border: none;
      }
      .as-icon--warning-gray{
        width: 24px;
        height: 24px;
        float: left;
        margin-top: 4px;
        margin-right: 8px;
      }
    }
    .panel-body{
      overflow: auto;
      color: @grey-6;
      font-size: @font-size-7;
    }
    .as-heading--paragraph-title{
      color: @grey-7;
      border: none;
      font-size: @font-size-6;
      margin-bottom: 5px;
      padding-top: 0;
    }
    .as-another-confirm-message{
      p{
        @media (max-width: @screen-xs-max) {
          background-color: @grey-2;
          text-align: center;
          padding: 10px;
        }
      }
    }
  }

  .search-box{
    margin: 0 -10px 20px -10px;
    background-color: @grey-7;
    padding: 20px 12px;
    color: @white;
    text-align: center;

    .search-box-message{
      padding-bottom: 15px;
      display: inline-block;
      font-size: @font-size-5;
      font-weight: bold;
    }

    .input-group{
      display: flex;
    }

    .twitter-typeahead{
      flex: 1 1 auto;
    }
  
    .as-category-pulldown{
      flex: 0 0 35%;
      height: 32px;
    }
  
    .as-keyword-input{
      height: 32px;
      border-left: none;
    }
  
    .open{
      & > .dropdown-toggle{
        &.btn-default{
          box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        }
      }

    } 
    //Search Button
    .input-group-btn{
      flex: 0 0 41px;
      .as-keyword-submit{
        width: 41px;
        height: 32px;
        background-color: @brand-color;
        border: 1px solid @brand-border-color;
        border-radius: 0;
        padding: 5px;
        box-shadow: none;
        &:hover,
        &:active{
          background-color: @brand-hover-color;
        }
      }
    }
  }

  .phone-call-box{
    margin-bottom: 20px;
    .btn{
      background-color: @sub-color;
      color: @white;
      font-weight: bold;
      font-size: @font-size-5;
      .as-icon--phone{
        margin-right: -5px;
      }
      .phone-number{
        font-size: @font-size-3;
      }
      &:hover,
      &:active{
        background-color: @sub-hover-color;
      }
    }
  }
  
  .notify-request-box{
    margin-bottom: 20px;
    background-color: @grey-2;
    border: 3px solid @sub-color;
    padding: 5px 10px 20px 10px;
    text-align: center;
    font-weight: bold;
    font-size: @font-size-4;
    color: @sub-color;
    p{
      margin-bottom: 15px;
    }
    .annotation-message{
      background-color: @brand-color;
      color: @grey-7;
      font-size: @font-size-6;
      padding: 3px;
    }
    .btn{
      background-color: @sub-color;
      color: @white;
      display: inline-block;
      width: 73%;
      min-height: 51px;
      line-height: @line-height-2;
      position: relative;
      box-shadow: none;
      &:hover,
      &:active{
        background-color: @sub-hover-color;
      }
      &.as-icon--mail-in-banner {
        &::before{
          content: "";
          display: block;
          position: absolute;
          top: -1.6em;
          right: -2.2em;
          width: 5em;
          height: 4em;
          background-image: url(../images/icon-mail-in-banner.svg);
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
  }
  
  .notify-request-box-pc{
    background-color: @grey-2;
    border: 3px solid @sub-color;
    padding: 17px 36px;
    text-align: center;
    font-weight: bold;
    font-size: @font-size-4;
    color: @sub-color;
    .as-groups-contact{
      display: flex;
      & > *{
        display: flex;
        justify-content: center;
        align-items: center;
      }
      p{
        margin: 0;
      }
      .annotation-message{
        flex: 0 0 19%;
        background-color: @brand-color;
        color: @grey-7;
        font-size: @font-size-5;
        padding: 3px;
      }
      .notify-message{
        flex: 0 0 39%;
        background-color: @white;
        position: relative;
        margin-right: 55px;
        &::after{
          content: "";
          width: 25px;
          height: 100%;
          background:
            linear-gradient(to bottom left, rgba(255,255,255,0) 50%, @white 50.5%) no-repeat top left/100% 50.5%,
            linear-gradient(to top left, rgba(255,255,255,0) 50%, @white 50.5%) no-repeat bottom right/100% 50.5%;
          position: absolute;
          top: 0;
          left: 100%;
        }
      }
      .btn{
        flex: 1 1 33%;
        height: auto;
        min-height: 89px;
        padding-left: 80px;
        background-color: @sub-color;
        color: @white;
        line-height: @line-height-2;
        position: relative;
        box-shadow: none;
        font-size: @font-size-4;
        white-space: normal;
        &:hover,
        &:active{
          background-color: @sub-hover-color;
        }
        &.as-icon--mail-in-banner {
          &::before{
            content: "";
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            left: 0.2em;
            width: 5em;
            height: 4em;
            background-image: url(../images/icon-mail-in-banner.svg);
            background-repeat: no-repeat;
            background-position: center;
          }
        }
      }
    }
  }

  .inquiry-input-wrapper{
    display: flex;
    justify-content: space-between;
    //レスポンシブ対応
    @media (max-width: @screen-xs-max) {
      display: block;
    }
    .as-groups-inquiry-input__inquiry-form{
      @media (min-width: @screen-sm-min){
        flex: 0 0 33.33%;
      }
      @media (max-width: @screen-xs-max) {
        margin: 12px 0;
      }
      .panel-selectable{
        .panel-heading{
          .panel-checkbox{
            @media (min-width: @screen-sm-min){
              top: 2px;
              bottom: auto;
            }
          }
        }
      }
    }
  }

  /*
  ICON
  */
  .as-icon--search {
    background-image: url(../images/icon-search-neo.svg);
    width: 1.5em;
    height: 1.5em;
  }

  .disable .as-icon--search{
    background-image: url(../images/icon-search-neo-disable.svg);
  }

  .bootstrap-select.btn-group{
    .dropdown-toggle{
      .caret{
        background-image: url(../images/icon-arrow-dropdown-neo.svg);
      }
    }
  }

  .as-icon--phone {
    background-image: url(../images/icon-phone-neo.svg);
    width: 1.8em;
    height: 1.8em;
  }

  .as-icon--download-alt,
  .as-icon--inspection-report{
    background-image: url(../images/icon-cloud-download.svg);
  }

  /*
  TEXT LINK
  */
  a{
    color: @sub-color;
  }

  /*
  BUTTON
  */
  .btn {
    height: 44px;
    padding-top: 10px;
    padding-left: @space-sm;
    padding-right: @space-sm;
    font-size: @font-size-5;
    line-height: @line-height-5;
    border-radius: 3px;
    box-shadow: 0 3px 6px rgba(0,0,0,.29);
  }
  .action-panel {
    .btn { 
      padding: 4px 12px 6px;
      border: none;
    }
  }
  .form-control{
    .btn{
      height: auto;
      min-height: auto;
    }
  }
  .btn-update{
    color: @white;
  }

  /*
    selectbox
  */

  .as-category-pulldown{
    color: @grey-6;
    max-width: 180px;
  }

  .as-groups-inquiry-input__inquiry-forms{
    .as-selector{
      width: 100%;
      min-width: auto;
      .dropdown-toggle{
        color: @grey-7;
        background-color: @grey-1;
        border: 1px solid @grey-3;
        border-radius: 0;
        min-height: 30px;
        height: 30px;
        padding: 5px 12px;
        font-size: @font-size-6;
        line-height: initial;
        &.disabled{
          opacity: 1;
          .filter-option{
            color: @grey-5;
          }
          .caret{
            opacity: 0.4;
          }
        }
      }
      .placeholder{
        display: none;
      }
    }
  }
  /*
    checkbox
  */
  .panel-selectable{
    margin-bottom: 10px;
    .panel-heading{
      color: @grey-7;
      .panel-checkbox {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        display: block;
        width: 30px;
        height: 30px;
        background-color: @grey-1;
        border: 1px solid @grey-4;
        border-radius: 3px;
        box-shadow: 0 3px 6px @shadow-1;
      }
      .panel-title{
        padding: 7px 5px 7px 38px;
        font-size: @font-size-6;
        color: @grey-7;
        &[data-toggle=collapse]:not(.collapsed){
          background-color: transparent;
          .panel-checkbox:after {
            content: "";
            position: absolute;
            top: 9px;
            left: 5px;
            display: block;
            width: 18px;
            height: 11px;
            background-image: url(../images/icon-check-neo.svg);
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: 0 0;
          }
        }
      }
      a:focus,
      a:hover{
        .panel-checkbox {
          box-shadow: 0 3px 6px @shadow-1;
        }
      }
      .attention{
        color: @accent-color;
        font-weight: bold;
      }
    }
    > .panel-heading{
      background-color: transparent;
    }
  }

  /*
    modal
  */
  .modal{
    .modal-content{
      border-radius: 0;
      border: 1px solid @grey-4;
      box-shadow: 0 5px 15px rgba(0,0,0,0.4);
    }
    .modal-body{
      padding: 15px 21px 36px 21px;
      background-color: @white;
    }

    .inquiry-input-message{
      font-weight: bold;
      margin-bottom: 15px;
      font-size: @font-size-7;
    }
    .as-goods__title{
      margin: 0;
      font-weight: normal;
      font-size: @font-size-6;
    }
    
    .panel{
      border: 0;
      padding: 0;
      margin-bottom: 0;
      .panel-body{
        border: 0;
        padding: 0;
      }
    }
    
    .as-groups-inquiry-input__machine-info{
      margin-bottom: 21px;
    }

    .search-list-goods{
      display: flex;
      margin-top: 2px;
      margin-bottom: 5px;
      li{
        font-size: @font-size-7;
        color: @grey-6;
        padding-right: 5px;
        position: relative;
        &:not(:first-child){
          padding: 0 5px;
          &::before{
            content: "";
            width: 1px;
            height: 12px;
            background-color: @grey-6;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto 0;
          }
        }
      }
    }

    .text-price,
    .as-search-list-goods__price{
      font-size: @font-size-6;
      color: @accent-color;
      font-weight: bold;
    }
    &.as-inquiry-dialog{
      .as-search-list-goods__price{
        font-size: @font-size-4;
        @media (max-width: @screen-xs-max) {
          font-size: @font-size-6;
          }
      }
    }
    .as-heading--page-title{
      border: none;
      padding: 0;
      h2{
        font-size: @font-size-7;
        margin: 1px 0;
      }
    }

    .as-main-contents{
      .as-main-contents-body{
        padding-bottom: 0;
      }
    }
    /*form*/
    .form-group{
      margin-bottom: 12px;
    }
    .form-control{
      border: 1px solid @grey-3;
      box-shadow: none;
    }

    .action-panel{
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
    }
    .cancel-button,
    .submit-button{
      flex: 0 0 calc(~"50% - 3px");
    }
    /*button*/
    .btn{
      width: 100%;
      display: inline-block;
      min-height: 46px;
      line-height: @line-height-2;
      border-radius: 3px;
      font-size: @font-size-5;
      &.btn-send{
        background-color: @sub-color;
        color: @white;
        &:hover,
        &:active{
          background-color: @sub-hover-color;
        }
      }
      &.btn-close{
        background-color: @white;
        color: @grey-5;
        box-shadow: none;
        &:hover,
        &:active{
          background-color: @white-hover-color;
        }
      }
    }
  }

  /*
    currency-popover
  */
  .as-popover-parent {
    position: relative;
    .as-currency-popover {
      &.hidden {
        opacity: 0;
        height: 0px;
        padding: 0px 12px;
      }
      &.shown {
        opacity: 1;
        height: auto;
        padding: 12px 12px;
        -webkit-transition: all 500ms;
        transition: all 500ms;
        z-index: 99;
      }
      position: absolute;
      bottom: calc(~"100% + 10px");
      right: 0;
      background: @white;
      border: 1px solid @grey-4;
      border-radius: 0;
      overflow: hidden;
      box-shadow: 0 3px 6px @shadow-1;
      @media (max-width: @screen-xs-max) {
        right: auto;
        left: 0;
      }
      .text-notice{
        color: @grey-7;
      }
      .as-groups-pricing-info-table__price{
        font-size: @font-size-4;
        font-weight: bold;
      }
    }
    .close-flat {
      margin-top: 5px;
      display: block;
      text-align: center;
      cursor: pointer;
    }
  }

}