// Heading

.as-heading__base() {
  .as-clearfix;

  position: relative;
  padding: @as-heading-padding;
  min-height: @as-heading-height;

  > h1, h2, h3, h4, h5, h6, p {
    & + * {
      display: block;
      float: right;
    }
  }

  div& {
    margin-bottom: 15px;

    //for Bootstlap Panel component
    &.panel-heading {
      margin-bottom: 0;
    }
    //for Bootstlap Modal component
    &.modal-header {
      margin-bottom: 0;
    }
  }

  > h1, h2, h3, h4, h5, h6, p {
    @as-heading-vertical-base-padding: @as-heading-height - @as-heading-padding-top - @as-heading-padding-bottom;
    margin-top: ~"calc( ( @{as-heading-vertical-base-padding} - 1em ) / 2 )";  //vertical-align:center
    margin-bottom: ~"calc( ( @{as-heading-vertical-base-padding} - 1em ) / 2 )";  //vertical-align:center
    float: left;
    line-height: 1;
  }

  h1&, h2&, h3&, h4&, h5&, h6&, p& {
    margin-top: 0;
  }

  // for Bootstrap button
  a.btn {
    position: relative;

    &:active:before,
    &:hover:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      box-shadow: 0 0 0 1px rgba(255,255,255,.7);
      border-radius: @btn-border-radius-base;
    }
  }
}
