@import '../sitewide.less';

.common-user-registration-with-promotion-main-contens {

    .as-field-container {
        width: 100%;
        margin-right: 8px;
        margin-bottom: 16px;

    &:last-child {
        margin-right: 0;
    }
    }

    .as-field-container {
        & .form-control {
            min-height: 48px;
            padding: 0 16px;
            border: solid 1px @border-color;
            border-radius: 2px;
            font-size: 19px;
        }

        & .form-input-field {
            padding-top: 8px;
            padding-bottom: 8px;
        }




        .input-field input {
            width: 100%;
            min-height: 48px;
            box-sizing: border-box;
            padding: 0 16px;
            border: solid 1px @border-color;
            border-radius: 2px;

            &:extend(.sw-p-lg);
            margin-bottom: 0;
        }

        .new-password-wrapper input {
            padding: 0 100px 0 16px;
        }

    }

    .as-horizontal_fields {
        display: flex;
    }

        .new-password-wrapper {
        position: relative;

        input {
            padding-right: 100px;
        }
    }

    .show-password-wrapper {
        position: absolute;
        top: 0;
        right: 16px;
        margin-top: @space-tiny;
        margin-bottom: @space-tiny; 
    }

    .show-password-checkbox {
        display: none;
    }

    .show-password-label {
        line-height: 32px;
        border-radius: 16px;
        padding-left: 16px;
        padding-right: 16px;
        color: @link-color;
        background: @grey-2;
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 0; 

        &:hover {
            background: @grey-3;
        }
    }



    .password-strength {
        &-0 {
            width: 20%;
            height: 8px;
        }
        &-1 {
            width: 20%;
            height: 8px;
            background: @warning-color;
        }
        &-2 {
            width: 40%;
            height: 8px;
            background: @warning-color;
        }
        &-3 {
            width: 60%;
            height: 8px;
            background: @link-color;
        }
        &-4 {
            width: 80%;
            height: 8px;
            background: @link-color;
        }
        &-5 {
            width: 100%;
            height: 8px;
            background: @link-color;
        }
        
    }

    



}