.auction-bidding-dashboard {
  .form-inline .form-control{
    display: inline-block;
  }
  
  .as-search-list-goods{
    float: left;
    width: 100%;
  }

  .as-search-list-goods__list-header{
    margin-left: 0;
  }

  .as-search-list-goods__list-header-item{
    text-align: center;
    padding: 0;
    color: #676767;
    font-size: 14px;
    font-weight: 700;
  }
  
  .as-search-list-goods__list-header-item:first-child{
    text-align: left;
    padding-left: 10px;
  }

  .as-search-list-goods__list-header-item.item-summary{
    width: 33%;
  }
  .as-search-list-goods__list-header-item.item-prices{
    width: 20%;
    padding-left: 40px;
  }
  .as-search-list-goods__list-header-item.item-bids{
    width: 20%;
    padding-left: 40px;
  }
  .as-search-list-goods__list-header-item.item-action{
    width: 25%;
  }

  .as-search-list-goods__item{
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 0;
  }

  .as-search-list-goods .as-search-list-goods__item.as-outbid{
    background-color: lighten(spin(saturate(@as-brand-attention, 90%), 7%), 45%);
  }

  .as-search-list-goods .as-search-list-goods__item.as-sale-opening-soon,
  .as-search-list-goods .as-search-list-goods__item.as-sale-ended{
    background-color: @as-search-list-sold-out-background-color;
  }

  .as-search-list-goods .as-search-list-goods__item:hover{
    background-color: #fffad6;
  }


  .item-summary-area-wrapper{
    width: 100%;
    position: relative;
    .display-flex;
    cursor: pointer; 
  }

  .item-summary-image-area{
    width: 90px;
    min-width: 90px;
    margin-right: 15px;
  }

  .item-summary-list-area{
    .flex(1, 1, auto);
  }

  .item-summary-list-item{
    font-size: 12px;
    font-weight: 700;
  }

  .lot-number-label,
  .lot-number-text,
  .model-label,
  .model-text,
  .serial-label,
  .serial-text,
  .highest-bigger-label,
  .highest-bigger-text,
  .is-highest-bigger-label,
  .is-highest-bigger-text,
  .seconds-left-label,
  .seconds-left-text{
    display: inline-block;
  }

  .item-summary-list .item-summary-list-item{
    line-height: 20px;
  }

  .current-price-label,
  .current-price-text,
  .converted-price-label,
  .converted-price-text,
  .buyout-price-label,
  .buyout-price-text,
  .bids-price-label,
  .bids-price-text,
  .auto-bids-price-label,
  .auto-bids-price-text{
    display: inline-block;
  }
  .current-price-text,
  .converted-price-text,
  .buyout-price-text,
  .auto-bids-price-text{
    font-weight: 700;
  }
  .converted-price-label,
  .converted-price-text,
  .buyout-price-label,
  .buyout-price-text,
  .auto-bids-price-label,
  .auto-bids-price-text{
    font-size: 12px;
  }

  .label-as-important-point {
    position: relative;
    top: 0;
  }

  .as-features__value {
    min-width: 2em;
  }

  .text-price,
  .text-bids-price{
    display: inline;
    font-size: 16px;
    font-weight: 700;
  }
  .text-price-undecided{
    display: inline;
    font-size: 16px;
    font-weight: 700;
    color: @as-brand-base;
  }
  .text-price-lowest{
    color: #ff5400;
    font-size: 12px;
  }
  .current-price-noticetext{
    text-align: left;
  }
  
  .prices-list .prices-list-item{
    line-height: 20px;
    text-align: left;
  }
  
  .as-search-list-goods__list p{
    margin-bottom: 10px;
  }

  .bid-button-list-item{
    margin-bottom: 8px;
  }

  .bid-button-list-item:last-child{
    margin-bottom: 0;
  }

  .btn-lg{
    padding: 4px 10px 6px;
  }
  
  
  .bidding-info-list-item.item-action{
    .panel,
    .as-groups-auction-item-detail-action-panel,
    .as-groups-auction-item-detail-action-panel .panel .panel-body{
      background-color: transparent;
    }

    .as-groups-auction-item-detail-action-panel{
      border: none;
    }

    .as-groups-auction-item-detail-action-panel .panel .panel-body{
      padding: 5px 10px;
    }
    
    .as-groups-auction-item-detail-action-panel >:first-child{
      padding-top: 5px;
    }
  }
  
  .panel{
    margin-bottom: 0;
  }
  
  .panel-option-mini .panel-body{
    padding: 0;
  }

  .as-groups-auction-item-detail-activity-widget__time-left{
    text-align: center;
    margin-top: 10px;
  }
  
  .as-sale-ending-soon {
    .seconds-left-text{
      color: @as-sale-status-ending-color;
    }
  }
  
  .modal {
    transition: all 0.3s ease;
  }
  
  .modal.in {
    background-color: rgba(0,0,0,0.3);
  }
  
  .modal.zoom .modal-dialog {
    -webkit-transform: scale(0.1);
    -moz-transform: scale(0.1);
    -ms-transform: scale(0.1);
    transform: scale(0.1);
    top: 300px;
    opacity: 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
  }
  
  .modal.zoom.in .modal-dialog {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transform: translate3d(0, -300px, 0);
    transform: translate3d(0, -300px, 0);
    opacity: 1;
  
  }
 
  .emphasized-link {
      color: @warning-color;
      text-decoration: underline;
  }

  @media (min-width: @screen-sm-min){
    .bidding-info-list-item.item-action{
      .as-groups-auction-item-detail-action-panel>:last-child {
          padding-bottom: 0;
      }
    }
  }
  
  //Responsive(PC)
  
  //@media (min-width: @screen-lg-min){
  @media (min-width: (@screen-xs-max + 1px)){
    .bidding-info-list{
      .display-flex;
      .align-items(center);
      -ms-justify-content: space-between;
      -webkit-justify-content: space-between;
      justify-content: space-between;
    }

    .bidding-info-list-item{
      padding: 0 20px;
    }
    .bidding-info-list-item:first-child{
      padding: 0 20px 0 0;
    }
    .bidding-info-list-item:last-child{
      padding: 0 0 0 20px;
    }
    .bidding-info-list-item.item-summary{
      .flex(1, 0, auto);
      width: 33%;
    }
    .bidding-info-list-item.item-prices{
      .flex(0, 0, auto);
      width: 20%;
    }
    .bidding-info-list-item.item-bids{
      .flex(0, 0, auto);
      width: 20%;
    }
    .bidding-info-list-item.item-action{
      .flex(0, 0, auto);
      width: 27%;
    }
    
    .as-search-list-goods__item{
      padding-top: 20px;
      padding-bottom: 15px;
    }

    .current-price-label,
    .bids-price-label{
      display: none;
    }

    .prices-list .prices-list-item,
    .bids-list > li{
      text-align: right;
    }

    .bidding-info-list-item{
      &.item-action{
        .as-label-notify__item:after{
          display: block;
          content: "";
          border-top: 1px solid @as-sub-border-color;
          width: 100%;
          position: relative;
          top: 10px;
        }
      } 
    }

  }

  //Responsive(Tablet)
  @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
    .text-bids-price,
    .text-price{
      font-size: @font-size-h5;
    }
    .text-price-undecided{
      font-size: @font-size-h5;
    }

    .as-label-notify__item{
      font-size: 12px;
    }

    .btn-lg{
      font-size: 16px;
    }

    .btn .text-caption{
      font-size: 10px;
    }
  }
  
  //Responsive(Mobile)

  //@media (max-width: (@screen-lg-min - 1px)){
  @media (max-width: @screen-xs-max) {
    .item-summary-list .item-summary-list-item{
      width: calc(~"100% - 15px");
    }
    .item-summary-area-wrapper{
      background-image: url(../images/icon-arrow-right.svg);
      background-repeat: no-repeat;
      background-size: 15px 15px;
      background-position: right center;
    }

    .bidding-info-list-item.item-summary{
      margin-top: 51px;
    }
    
    .bidding-info-list-item.item-prices,
    .bidding-info-list-item.item-bids,
    .as-groups-auction-item-detail-activity-widget__time-left{
      margin-left: 105px;
    }

    .as-groups-auction-item-detail-statusas-label-notify{
      position: absolute;
      top: 10px;
      width: calc(~"100% - 20px");
      height: 46px;
      .display-flex;
      .align-items(center);
    }

    .as-search-list-goods__list p {
      margin-bottom: 0;
    }

    .as-label-notify__item:after{
      display: none;
    }

    .as-groups-auction-item-detail-activity-widget__time-left{
      text-align: left;
      margin-bottom: 5px;
      margin-top: 0;
    }

  }
}