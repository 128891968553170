// Label Notify
// Nothing with PNotify.

.as-label-notify {
  text-align: center;
  // transition: min-height 300ms ease;
  // overflow: hidden;

  // Animation
  // max-height: 3.5em;
  // .as-animation--label-notify-show-down;
}

.as-label-notify__item {
  .label;
  position: relative;
  font-size: @font-size-h5;

  // Override Bootstrap .label
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
  padding: 0;
  border-radius: 0;
  line-height: 1.4;
  white-space: normal;

  // Animation
  .as-animation--fade-in;
}

.as-label-notify__body {
  display: inline-block;
  width: 100%;
  padding: 3px 10px 5px;
  vertical-align: middle;

  > span {
    display: block;
  }
  .flag-icon {
    margin-right: 3px;
  }

  // Mask for split-in effect
  &:before,
  &:after {
    content: "";
    position: absolute;
    display: block;
    background-color: #fff;
    top: 0;
    bottom: 0;

    // Animation
    .as-animation--split-in ;
  }
  &:before {
    left: 0;
  }
  &:after {
    right: 0;
  }

}


// Alternatives

.as-label-notify__item--attention {
  .label-attention;
  // Animation
  .as-animation--appeal-flash;
}

.as-label-notify__item--success {
  .label-success;
}

.as-label-notify__item--info {
  .label-moderate;
}


// Responsive

@media (min-width: @screen-lg-min) {
  .as-label-notify__item {
    font-size: @font-size-h4;
  }
}
