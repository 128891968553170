// Global utilities


// Bootstrap Wapper
//
// use only in less file

.as-clearfix() {
  .clearfix;
}

.as-row() {
  .row;
}

.as-img-responsive() {
  .img-responsive;
}

.as-collapse() {
  .collapse;
}


// Vue.js Common Style
//
//

// v-cloak
[v-cloak] {
  &:not(.v-cloak--invisible) {
    display: none !important;
  }

  &.v-cloak--block {
    display: block !important;
  }

  &.v-cloak--inline {
    display: inline !important;
  }

  &.v-cloak--inlineBlock {
    display: inline-block !important;
  }

  &.v-cloak--invisible {
    visibility: hidden !important;
  }
}

.v-cloak--block:not([v-cloak]),
.v-cloak--inline:not([v-cloak]),
.v-cloak--inlineBlock:not([v-cloak]) {
  display: none !important;
}


// Common Hacks
//
//

// For IE 10/11
wbr:after {
  // U+200B ZERO WIDTH SPACE
  content: "\00200B";
}


// Mixins
//
// use only in less file

// space fix
.as-row--no-gutter() {
  margin-right:0;
  margin-left: 0;
}
.as-col--no-gutter() {
  padding-right:0;
  padding-left: 0;
}

// pre wrap to support new lines
.pre-wrap {
  white-space: pre-wrap;
}

// pre wrap to support new lines for alpaca
.pre-wrap-alpaca div {
  white-space: pre-wrap;
}

// image, icon
.background-image-icon(@url; @width; @height; @position-x; @position-y; @_){
  background-image: url('@{url}');
  background-position: @position-x @position-y;
  background-repeat: no-repeat;
  background-size: @width @height;
}

.background-image-icon(@url; @width; @height; @position-x; @position-y; left){
  // @position-x unit is px only.
  background-position: @position-x @position-y;
}

.background-image-icon(@url; @width; @height; @position-x; @position-y; right){
  // @position-x unit: px only
  background-position: ~"calc(100% - @{position-x})" @position-y;
}

// Responsible image and frame with constant vertical-horizontal ratio
.as-responsible-image-frame(@vertical-ratio; @horizontal-ratio) {
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  padding-bottom: 100% / @vertical-ratio * @horizontal-ratio;

  img {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    display: block;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
}

// Responsible text-sizes
@as-xs-font-size-coef: 0.8;
@as-sm-font-size-coef: 0.9;
@as-md-font-size-coef: 1;
@as-lg-font-size-coef: 1;

//Set by Heading name (recommended)
.as-responsible-font-size(@tag) {
  .as-font-size-define-set(@tag);
  @media (max-width: @screen-xs-max) {
    &  { font-size: floor((@@as-responsible-font-size-set * @as-xs-font-size-coef)) }
  }
  @media (min-width: @screen-sm-min) {
    &  { font-size: floor((@@as-responsible-font-size-set * @as-sm-font-size-coef)) }
  }
  @media (min-width: @screen-md-min) {
    &  { font-size: floor((@@as-responsible-font-size-set * @as-md-font-size-coef)) }
  }
  @media (min-width: @screen-lg-min) {
    &  { font-size: floor((@@as-responsible-font-size-set * @as-lg-font-size-coef)) }
  }
}
.as-font-size-define-set(@tag){
  @as-responsible-font-size-set: "font-size-@{tag}";
}

//Set Manualy
.as-responsible-font-size(manual, @size-xs, @size-sm, @size-md, @size-lg) {
  @media (max-width: @screen-xs-max) {
    &  { font-size: @size-xs }
  }
  @media (min-width: @screen-sm-min) {
    &  { font-size: @size-sm }
  }
  @media (min-width: @screen-md-min) {
    &  { font-size: @size-md }
  }
  @media (min-width: @screen-lg-min) {
    &  { font-size: @size-lg }
  }
}

// Loading Spinner
.as-loading-view() {
  &.as-loading {
    &:before {
      opacity: 1;
      z-index: 100;
      // for appear
      transition: z-index 0ms linear , opacity 50ms ease;
    }
    &:after {
      opacity: 1;
      z-index: 101;
      // for appear
      transition: z-index 0ms linear , opacity 50ms ease;
    }
  }

  // Loading background panel
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.5);
    // for disappear
    transition: z-index 0ms linear 500ms, opacity 200ms ease 300ms;
    opacity: 0;
    z-index: -1;
  }

  // Loading icon
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    width: 100px;
    height: 100px;
    margin-right: auto;
    margin-left: auto;
    background-image: url("../images/loading_bars.gif");
    background-position: center ceneter;
    background-repeat: no-repeat;
    background-size: 100%;
    // for disappear
    transition: z-index 0ms linear 400ms, opacity 200ms ease 200ms;
    opacity: 0;
    z-index: -2;

    @media (max-width:@screen-xs-max) {
      top: 50px;
      width:  50px;
      height: 50px;
    }
  }
}

// Vender Prefix
//
//

.gradient (@start-color: #eee, @end-color: white) {
	background-color: @start-color;
	background: -webkit-gradient(linear, left top, left bottom, from(@start-color), to(@end-color));
	background: -webkit-linear-gradient(top, @start-color, @end-color);
	background: -moz-linear-gradient(top, @start-color, @end-color);
	background: -ms-linear-gradient(top, @start-color, @end-color);
	background: -o-linear-gradient(top, @start-color, @end-color);
}

.horizontal-gradient (@start-color: #eee, @end-color: white) {
 	background-color: @start-color;
	background-image: -webkit-gradient(linear, left top, right top, from(@start-color), to(@end-color));
	background-image: -webkit-linear-gradient(left, @start-color, @end-color);
	background-image: -moz-linear-gradient(left, @start-color, @end-color);
	background-image: -ms-linear-gradient(left, @start-color, @end-color);
	background-image: -o-linear-gradient(left, @start-color, @end-color);
}

.linear-gradient (@arguments) {
  background-image: -webkit-linear-gradient(@arguments);
	background-image: -moz-linear-gradient(@arguments);
	background-image: -ms-linear-gradient(@arguments);
	background-image: -o-linear-gradient(@arguments);
}

.display-flex () {
  display: -webkit-box;  // for iOS -6.1
  display: -ms-flexbox;  // for IE10
  display: -webkit-flex; // for iOS 7-, Safari
  display: flex;         // for Chrome, Firefox IE11
}

.flex (@grow, @shrink, @flex-basis) {
  -webkit-box-flex: @grow;
  -ms-flex: @grow @shrink;
  -webkit-flex: @grow @shrink @flex-basis;
  flex: @grow @shrink @flex-basis;
}

.flex-flow (@direction, @wrap) {
  .box-orient(@direction);
  .box-lines(@wrap);
  -ms-flex-flow: @direction @wrap;
  -webkit-flex-flow: @direction @wrap;
  flex-flow: @direction @wrap;
}

.box-orient(row) {
  -webkit-box-orient: horizontal;
}

.box-orient(column) {
  -webkit-box-orient: vertical;
}


.box-lines(nowrap) {
  -webkit-box-lines: single;
}

.box-lines(wrap) {
  -webkit-box-lines: multiple;
}

.box-lines(wrap-reverse) {
  //This needs fix
  -webkit-box-lines: multiple;
}

.justify-content(@value) {
  .box-pack(@value);
  .flex-pack(@value);
  -webkit-justify-content: @value;
  justify-content: @value;
}

.box-pack(flex-start) {
  -webkit-box-pack: start;

}
.flex-pack(flex-start) {
  -ms-flex-pack: start;
}

.box-pack(flex-end) {
  -webkit-box-pack: end;

}
.flex-pack(flex-end) {
  -ms-flex-pack: end;
}

.box-pack(center) {
  -webkit-box-pack: center;

}
.flex-pack(center) {
  -ms-flex-pack: center;
}

// TODO Implement not only for "center"
.align-items(@value) {
  -webkit-box-align: @value;
  -ms-flex-align: @value;
  -webkit-align-items: @value;
  align-items: @value;
}

.align-self(@value) {
  // no property for -webkit-box-
  .flex-item-align(@value);
  -webkit-align-self: @value;
  align-self: @value;
}

.flex-item-align(@value) {
  -ms-flex-item-align: @value;
}

.flex-item-align(flex-start) {
  -ms-flex-item-align: start;
}

.flex-item-align(flex-end) {
  -ms-flex-item-align: end;
}

.order(@value) {
  box-ordinal-group: @value;
  flex-order: @value;
  -webkit-order: @value;
  order: @value;
}

.columns(@column-count: auto, @column-width: auto) {
  -moz-columns: @column-count @column-width;
  -webkit-columns: @column-count @column-width;
  columns: @column-count @column-width;
  min-height: initial; // for Safari bug. Because of when columns property is used with Bootstrap col- property.
}

.column-gap(@value: normal) {
  -moz-column-gap: @value;
  -webkit-column-gap: @value;
  column-gap: @value;
}

.break-inside(@value: auto) {
  break-inside: @value;
  -webkit-column-break-inside: @value;
  page-break-inside: @value;
}

.transform(@arguments) {
	-moz-transform: @arguments;
  -webkit-transform: @arguments;
	transform: @arguments;
}

.user-select(@arguments) {
  -moz-user-select: @arguments;
  -webkit-user-select: @arguments;
  -ms-user-select: @arguments;
  user-select: @arguments;
}

// Animation
.keyframes(@name; @arguments) {
	@-moz-keyframes @name { @arguments(); }
	@-webkit-keyframes @name { @arguments(); }
	@keyframes @name { @arguments(); }
}

.animation(@arguments) {
	-moz-animation: @arguments;
  -webkit-animation: @arguments;
	animation: @arguments;
}

.animation-name(@arguments) {
	-moz-animation-name: @arguments;
  -webkit-animation-name: @arguments;
	animation-name: @arguments;
}

.animation-timing-function(@arguments) {
	-moz-animation-timing-function: @arguments;
  -webkit-animation-timing-function: @arguments;
	animation-timing-function: @arguments;
}

.animation-iteration-count(@arguments) {
	-moz-animation-iteration-count: @arguments;
  -webkit-animation-iteration-count: @arguments;
	animation-iteration-count: @arguments;
}

.animation-duration(@arguments) {
	-moz-animation-duration: @arguments;
  -webkit-animation-duration: @arguments;
	animation-duration: @arguments;
}

.animation-delay(@arguments) {
	-moz-animation-delay: @arguments;
  -webkit-animation-delay: @arguments;
	animation-delay: @arguments;
}
