// Static Contents
//

// About Auction
.as-auction-info__container{
  margin-bottom: 20px;
  .panel-body{
    padding:initial;
  }
  .as-auction-info-details{
    .as-auction-info__schedule{
      color:#CCC;
      padding:0px 10px 10px 10px;
      ul{
        margin:-10px 0px 10px 10px;
      }
      .btn{
        cursor: auto;
      }
      .btn-default{
        color:#FFF;
        background-color: #CCC;
        border-color:#CCC;
        box-shadow: inset 0 -3px 0 #CCC;
      }
    }
    .as-auction-info__schedule__active{
      color:#333;
    }
  }
  .benefits-list{
    list-style-type: disc;
    margin-left: 15px;
  } 
  
  @media (min-width: @screen-xs-max){
    .as-auction-info__to-auction-page{
      .btn{
        width: 68%;
        margin: auto;
      }
    }
    .benefits-list{
      margin-left: 0;
    } 
  }
}

// start.html
.as-start-page{
  height: 100%;
  .display-flex;
  -ms-justify-content: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  flex-direction: column;


  .as-contents-body{
    text-align: center;
    .loading-image{
      width: 80px;
    }
  }
}

