// For auction Price Estimator Page
.as-auction{

  .as-groups-auction-price-estimator-main {

    ol.how-to-use-list {
      list-style-type: decimal;
      padding-left: 1.2em;
    }

    ul.note-list {
      li:before {
        content: '-';
        padding-left: 0.2em;
        padding-right: 0.6em;
      }
    }

    span.with-space {
      padding-left: 1em;
    }

    tr.breakdown-header {
      border-bottom: 4px solid #ccc;
    }
  }
}