.as-companies {
    @company-theme-color: var(--as-primary-color);
    @company-theme-sub-color: var(--as-secondary-color);
    @company-font-color: var(--as-base-text-color);


    .companies-item-list{
        width: 100%;
        margin: auto;
        .as-main-contents-header{
            display: none;
        }
        .as-main-contents-body{
            padding-top: 0;
        }
        .company-page-label{
            background-color: @company-theme-color;
            color: @company-font-color;
            padding: 4px 4px 4px 45px;
            font-size: 23px;
            font-weight: bold;
            margin-bottom: 20px;
            position: relative;
            &::before{
                content: "";
                width: 25px;
                height: 25px;
                position: absolute;
                left: 10px;
                top: 0;
                bottom: 0;
                margin: auto 0;
                margin-bottom: 13px;
                background-image: url(/images/company-page/icon-excavator.svg);
                background-repeat: no-repeat;
                background-position: center;
            } 
        }
        .company-page-item-list-box{
            margin: 10px 0;
            border-top: 1px solid #cccccc;
            border-bottom: 1px solid #cccccc;
            table{
                thead{
                    display: none;
                }
                tbody{
                    tr{
                        cursor: pointer;
                        padding: 8px;
                        display: flex;
                        justify-content: space-between;
                    }
                    td{
                        font-size: 14px;
                        flex: 0 0 auto;
                        height: auto;
                        &:first-child,
                        &:not(:nth-child(1)){
                            padding: 0;
                        }
                        &.item-image{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 25%;
                            max-width: 230px;
                            img{
                                width: 100%;
                                height: auto;
                            }
                        }
                        &.item-info{
                            width: 70%;
                            display: flex;
                            .item-info-primary{
                                width: 34%;
                                padding-left: 20px;
                            }
                            .item-info-secondary{
                                width: 66%;
                                padding-left: 20px;
                            }
                        }
                        &.item-info-detail{
                            width: 5%;
                            font-size: 24px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            .v-icon{
                                color: #707070;
                                font-size: 48px;
                                width: 100%;
                            }
                        }
                        .item-model-number,
                        .item-price{
                            font-size: 24px;
                            font-weight: bold;
                        }
                        .item-category-name{
                            font-size: 12px;
                        }
                        .item-maker-name{
                            margin-top: 5px
                        }
                        .item-model-number{
                            margin-top: -5px; 
                        }
                        .item-price{
                            color: @as-brand-attention;
                        }
                        .item-year{
                            margin-top: 15px;
                        }
                    }
                } 
            }
        }

        .as-currencyUnit{
            margin-left: 5px;
            font-size: smaller;
        }
        
        //レスポンシブ対応
        @media (max-width: @screen-xs-max){
            .company-page-item-list-box{
            .v-data-table{
                    table{
                        tbody{
                            td{
                                &.item-info{
                                    display: block;
                                    .item-info-primary,
                                    .item-info-secondary{
                                        width: 100%;
                                        padding-left: 10px;
                                    }
                                }
                                .item-year{
                                    margin-top: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}