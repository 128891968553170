// Button
// Use with Bootstrap .btn class

.as-button--watch {
  .as-button__icon("../images/icon-watches-outline.svg");
  padding-top: 6px;
  padding-right: 8px;
  padding-left: 12px;
  padding-bottom: 5px;
  background: #ffffff;
  border: 1px solid #b3b3b3;
  color: #212121;
  font-size: @font-size-h6;

  &:hover {
    opacity: .9;
    background-color: darken(#ffffff, 2%);
  }
  &:active,
  &.active {
    box-shadow: none;
  }

  // Icon
  &:before {
    font-size: @font-size-h5;
  }
}

.as-button--watching {
  .as-button--watch;
  .as-button__icon("../images/icon-watches-fill.svg");
  color: fadeout(#212121, 35%);
}

.as-icon--sound-on,
.as-icon--sound-off {
  background-color: #ffffff;
  border: 1px solid #b3b3b3;
  color: #212121;
  width: 36px;
  height: 30px;
  background-size: 50%;

  &:hover {
    opacity: .9;
    background-color: darken(#ffffff, 2%);
  }
  &:active,
  &.active {
    box-shadow: none;
  }
}
