// machine request
// This is temporary styles
.p-machine-request__hero {
  background-image: url("../images/machine-request/hero-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  h1 {
    text-align: center;
    font-size: 40px;
    margin: 0 auto;
    padding: 3em 0;
    line-height: 1.3em;
    text-shadow: 0px 0px 5px #fff;
  }
}
.p-machine-request__hero2 {
  background-image: url("../images/machine-request/hero-bg2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  h1 {
    text-align: center;
    font-size: 40px;
    margin: 0 auto;
    padding: 3em 0;
    line-height: 1.3em;
    color:#FFF;
    text-shadow: 0px 0px 5px #333;
  }
}

.c-service-tag__market {
  .mixin-service-tag;
  background-color: #22b11d;
  &:after {
    .mixin-service-tag__after("Market");
  }
}

.c-service-tag__auction {
  .mixin-service-tag;
  background-color: #ff5400;
  &:after {
    .mixin-service-tag__after("Auction");
  }
}

.c-service-tag__other {
  .mixin-service-tag(8em);
  background-color: #555;
  &:after {
    .mixin-service-tag__after("Coming soon");
  }
}

.mixin-service-tag(@width : 5em) {
  display: inline-block;
  position: relative;
  font-size: 16px;
  width: @width;
  height: 1.4em;
  margin-bottom: 5px;
  -webkit-transform: skew(-15deg);
  -moz-transform: skew(-15deg);
  -o-transform: skew(-15deg);
}

.mixin-service-tag__after(@text : hoge) {
  content: @text;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  position: absolute;
  top: 3px;
  left: 14px;
  transform: skew(15deg);
}

@media (max-width:@screen-sm-min) {
  .p-machine-request__hero {
    h1 {
      font-size: 25px;
    }
  }
}