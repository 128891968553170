// Bid Price Table

.as-bid-price-table {
  table& {
    min-width: 70%;
    margin-bottom: 5px;

    th,td {
      vertical-align: baseline;
    }

    th {
      padding-right: 10px;
      color: @as-text-term-color;
      font-weight: normal;
      white-space: nowrap;
    }
    td {
      text-align: right;

      .text-price,
      .text-currency {
        font-size: @font-size-h4;
        color: @as-text-description-color;
        font-weight: bold;
      }
      &.as-bid-price-table__current-price {
        .text-price,
        .text-currency {
          color: @as-brand-attention;
        }
      }
      .text-currency {
        margin-right: 0.2em;
      }
    }
  }
}
