// Extend Bootstrap Mixins

// Button variants
.button-variant(@color; @background; @shadow;) {
  box-shadow: inset 0 -2px 0 @shadow
}


.button-variant(@color; @background; @shadow; @color-focus; @background-focus; @shadow-focus;) {
  .button-variant(@color, @background, @shadow);

  &:hover {
    .button-hover-appearance(@color-focus, @background-focus, @shadow-focus);
  }
  &:focus,
  &.focus {
    .button-hover-appearance(@color-focus, @background-focus, @shadow-focus);
  }

  &:active,
  &.active,
  .open > .dropdown-toggle& {
    .button-hover-appearance(@color-focus, @background-focus, @shadow-focus);

    &:hover {
      .button-hover-appearance(@color-focus, @background-focus, @shadow-focus);
    }
    &:focus,
    &.focus {
      .button-hover-appearance(@color-focus, @background-focus, @shadow-focus);
    }
  }
}


// Modify Bootstrap Mixins

// Button sizes
.button-size(@padding-vertical; @padding-horizontal; @font-size; @line-height; @border-radius) {
  padding: (@padding-vertical - 1px) @padding-horizontal (@padding-vertical + 1px) @padding-horizontal;
}


// AS Orignial Mixins

// Button hover appearance
.button-hover-appearance(@color-focus, @background-focus, @shadow-focus){
  color: @color-focus;
  background-color: @background-focus;
  box-shadow: inset 0 -2px 0 @shadow-focus;
}
