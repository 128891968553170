// jQuery Validation Engine Override


.formError {
  .formErrorContent {
    color: @alert-danger-text;
    background-color: @alert-danger-bg;
    border: 1px solid @alert-danger-border;
    border-radius: @alert-border-radius;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
  }
  .formErrorArrow{
    *[class*="line"] {
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
      background: @alert-danger-bg;
      border-left: 1px solid @alert-danger-border;
      border-right: 1px solid @alert-danger-border;
    }
    .line8, .line9, .line10 {
      box-shadow: none;
    }
    .line9, .line10 {
      border: none;
    }
    .line3 {
      border: none;
      background: @alert-danger-border;
    }
    .line1, .line2 {
      display: none;
    }
  }
}
