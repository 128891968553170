// For auction Item detail Page

.as-groups-item-detail-main {
  margin-bottom: 20px;
}
.as-groups-item-detail-main__header {
  float: right;
}
.as-groups-item-detail-main__thumbnails {
}

.as-groups-item-detail-title {
  min-height: 0;
  padding-top: 0;
  padding-bottom: 5px;
  font-size: @font-size-h2;
}

.as-groups-item-detail-subtitle {
  min-height: 0;
  padding-top: 0;
  padding-bottom: 5px;
  font-size: @font-size-h3;
}

.as-groups-item-detail-title,
.as-groups-item-detail-download-panel,
.as-groups-item-detail-inspection-report-download-panel,
.as-groups-item-detail-status {
  margin-right: -(@as-grid-gutter-width / 2);
  margin-left: -(@as-grid-gutter-width / 2);
}

.as-thumbnail-viewer {
  .as-col--no-gutter;
}

.as-groups-item-detail-pager {
  .pager {
    margin-top: 0;
    margin-bottom: 0;
    font-size: @font-size-h6;
    text-align: left;
  }
}


.as-groups-item-detail-pager__current {
  color: @as-text-description-color;
  font-size: @font-size-h5;
  font-weight: bold;
  float:left;
}

.as-groups-item-detail-download-panel,
.as-groups-item-detail-inspection-report-download-panel {
  .panel {
    border: none;
    margin-bottom: 0;
  }
  .panel .panel-body {
    border: none;
    margin: 0;

    &:empty {
      display: none;
    }
  }
}

.as-groups-item-promotion-label {
  margin-bottom: 5px;

  .label {
    display: inline-block;
    margin-right: 5px;
  }

}

.as-groups-item-detail-download-panel {
  .panel {
    .panel-body {
      padding-top: 15px;
      padding-bottom: 15px;
      text-align: center;
    }
  }

  .as-download-link {
    color: @text-color;
  }
  .as-download-link__link {
    font-weight: bold;

    .glyphicon {
      padding-right: 0.5em;
    }

  }

}

.as-groups-item-detail-summary {
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: @font-size-h6;

  &,dl {
    .clearfix;
  }
  dl,dt,dd {
    display: block;
    float: left;
  }
  dl {
    margin-right: 10px;
    margin-bottom: 0;
    white-space: nowrap;
  }

  .as-features__head {
    vertical-align: middle;
    margin-right: 3px;

    [class*="as-icon--"] {
      margin-right: 0;
      margin-top: -.18em;
    }
    [class*="as-icon--hours"] {
      bottom: .12em;
    }
  }
  .as-features__value {
    min-width: 2em;
    margin-right: 0;
  }
}

@as-groups-item-detail-action-panel-background-color: #fafafa;
.as-groups-item-detail-action-panel {
}
.as-groups-item-detail-action-panel--with-bookmark {
  border: none;
}
.as-groups-item-detail-action-panel-bookmark-button {
  margin-bottom: @space-base;
}

.as-groups-pricing-info-table {
  table& {
    position: relative;
    width: 100%;
    min-height: 80px; // for v-cloak
    margin-bottom: 10px;

    th {
      padding-right: 10px;
      color: @as-text-term-color;
      font-weight: normal;
      vertical-align: top;
    }
    td {
      color: @as-text-description-color;
    }

    // Fix for base price style
    tr:not(.as-groups-pricing-info-table__primary-info--main-price) {
      th {
        width: 40%;
      }
    }
    tr.as-groups-pricing-info-table__primary-info--main-price {
      .as-groups-pricing-info-table__price {
        display: inline-block;
        width: auto;
        margin-right: auto;
        margin-left: auto;
        min-width: ~"calc(100% - 30px)";
      }
    }

    // Fix for converted price style
    .as-groups-pricing-info-table__secondary-info--converted-price + .as-groups-pricing-info-table__secondary-info {
      th,td {
        padding-top: 10px;
      }
    }

  }

  .text-price,
  .text-currency {
    color: @as-text-description-color;
    font-weight: bold;
  }

  .as-selector--currency {
    &.bootstrap-select.btn-group {
      font-size: inherit;
      vertical-align: text-bottom;
      margin-bottom:-3px;
    }
    &.bootstrap-select.btn-group .dropdown-toggle {
      font-size: inherit;
      font-weight: bold;
      background-color: inherit;
    }
  }
}

.as-groups-item-detail-info-panel {

  .text-notice {
    display: block;
    text-align: center;
  }
}

.as-groups-item-detail-activity-widget{
  dl.text-caption {
    margin-bottom: 10px;

    dt,dd {
      display: inline-block;
    }

    dt {
      font-weight: normal;
    }

    dd {
      padding-left: 5px;
    }
  }
}

.as-groups-pricing-info-table__price {
  font-weight: bold;
}

.as-groups-pricing-info-table__currency {
  display: inline-block;
  margin-left: 0.2em;
  font-weight: bold;
}

.as-groups-pricing-info-table__primary-info {
  tr& th {
    font-weight: bold;
  }
  tr& td {
    font-weight: bold;
  }

  .text-price,
  .text-currency {
    color: @as-brand-attention;
  }
}

.as-groups-pricing-info-table__primary-info--soldout {
  tr&,
  tr& td {
    display: block;
    text-align: center;
  }
  tr& td {
    display: inline-block;
    min-width: ~"calc(100% - @{as-groups-pricing-info-table-main-price-padding} * 2)";
    margin-top: 10px;
    margin-bottom: 20px;
    color: @as-brand-end;
    font-size: @font-size-h1 + 3px;  //24px
    border-bottom: 2px solid  @as-brand-end;
  }
}

@as-groups-pricing-info-table-main-price-padding: 15px;
.as-groups-pricing-info-table__primary-info--main-price {
  position: relative;
  height: 8em;  // Spacing

  th{
    padding-left:@as-groups-pricing-info-table-main-price-padding;
  }

  tr& th,
  tr& td {
    display: block;
  }
  tr& td {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
  }
  .as-groups-pricing-info-table__price {
    display: block;
    min-width: ~"calc(100% - @{as-groups-pricing-info-table-main-price-padding} * 2)";
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    .as-responsible-font-size(manual, @font-size-h1 + 9px, @font-size-h1 + 11px, @font-size-h1 + 17px, @font-size-h1 + 22px);  //30px  32px  38px  48px
    border-bottom: 2px solid @as-brand-attention;
  }
  .as-groups-pricing-info-table__currency {
    .as-responsible-font-size(manual, @font-size-h2, @font-size-h2, @font-size-h1 + 4px, @font-size-h1 + 7px);  //20px  20px  24px  28px
  }
  .as-groups-pricing-info-table__currency-convert-caption {
    margin-top: 10px;
    width: auto;
    font-weight: normal;
    font-size: @font-size-h5;

    [class*="as-icon--"] {
      margin-right: 5px;
    }

  }
}

.as-groups-pricing-info-table__secondary-info {
  line-height: 1.6;
}

.as-groups-pricing-info-table__secondary-info--converted-price {
  position: relative;
  height: 60px;  // Spacing

  tr& {
    th {
      // hidden element for layout converted-price
      visibility: hidden;
    }
    td {
      position: absolute;
      left: @as-groups-pricing-info-table-main-price-padding;
      right: @as-groups-pricing-info-table-main-price-padding;
      padding-top: 5px;
      padding-bottom: 5px;
      text-align: center;
      vertical-align: middle;
      background-color: @as-widget-background-color;
    }
    .as-groups-pricing-info-table__price {
      font-size: @font-size-h1;
    }
    .as-groups-pricing-info-table__currency {
      font-size: @font-size-h3;
    }
  }
  .as-groups-pricing-info-table__currency {
    margin-left: 0;
  }
}


  // Style for fixed position
  table > thead > tr > th {
    position: relative;
    background-color: #fff;
    z-index: 1;

    // Alternative to border
    border-bottom-color: transparent;
    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      border: inherit;
      border-bottom-color: @as-sub-border-color;
    }

  }


.as-groups-contact,
.as-groups-watch {
  .panel {
    margin-bottom: 0;
    border-top: none;
  }

  // Customized style for button
  .btn-lg {
    font-weight: bold;
    letter-spacing: .02em;
    border-radius: 5px;
    .text-caption {
      margin-top: 2px;
      margin-bottom: 3px;
      color: fadeout(@btn-primary-color, 20%);
      font-weight: 600;
    }
  }
}

.as-groups-contact {
  .btn-contact.btn-lg {
    padding-top: 13px;
    padding-bottom: 17px;
    box-shadow: inset 0 -6px 0 @btn-contact-shadow;
  }
}

// Confirm Panel
.as-groups-confirm-info {

  // Contacti Info
  .as-groups-confirm-info__contact-info {
    dl {
      margin-bottom: 10px;
    }
    dt {
      font-weight: normal;
    }
    dd {
      margin-bottom: 10px;
    }
  }
}

.as-groups-item-detail-panel__tabs {
  .display-flex;

  > li {
    .flex(1,1,auto);

    > a {
      text-align: center;
    }

    &:last-child > a {
      margin-right: 0;
    }
  }
}

.as-groups-item-detail-panel__panel--left {
  float: left;
}

.as-groups-item-detail-panel__panel--right {
  float: right;
}

.as-groups-item-detail-panel__panel--details {
  .as-flexible-table--default {
    caption {
      margin-bottom: 0;
    }
    tr:hover {
      background-color:@as-submenu-background-color;
      cursor: pointer;
    }
    tr.as-mixed-columns-table__group_item {
      background-color:@as-menu-background-color;
      cursor: initial;
      text-align: center;
      td {
        font-weight:bold;
      }
    }

    tr th {
      width: 30%;
      border-right: 1px dotted @as-sub-border-color;
      vertical-align: top;
      font-weight: bold;
    }

    tr td:nth-child(3), tr td:nth-child(4) {
      border-left: 1px dotted @as-sub-border-color;
    }
  }

  .as-mixed-columns-table__group {
    background-color: #fff;
  }

  .as-mixed-columns-table__group--row {
    tr {
      border-bottom: none;
    }

    tr th {
      border-right: none;
    }

    td {
      text-align: left;
    }

  }

  .as-groups-item-detail-table-panel__documents-table {
    tr {
      border: none;
    }
  }
}

.as-groups-item-detail-panel__panel--recommended {
  .panel {
    .panel-body {
      padding: 0;
    }
  }
}

.as-recommended-link {
  padding: 10px;

  .as-recommended-link__list {
    box-sizing: border-box;
    border: 0 solid @as-area-border-color;
    border-top-width: 1px;
    border-left-width: 1px;

    .as-recommended-link__item {
      padding-left: 30px;
      padding-top: 20px;
      padding-bottom: 20px;
      background-color: @as-search-conditions-submenu-background-color;
      border: 0 solid @as-area-border-color;
      border-bottom-width: 1px;
      border-right-width: 1px;
      font-size: @font-size-h4;
      text-align: center;
    }

  }

}

// Comment Panel
.as-groups-item-detail-main__comment{
  margin-top:10px;
  margin-bottom: -10px;
  position: relative;
  p{
    margin-top:5px;
  }
  .label-as-important-point{
    position: relative;
    padding: .3em 0 .3em 1.4em;
    &:before{
      width: 18px;
      height: 18px;
      padding: 0;
      top: 4px;
      font-size: 13px;
      font-weight: bold;
    }
  }
}

// Respomsive

@media (min-width: @screen-sm-min) {

  .as-groups-item-detail-title,
  .as-groups-item-detail-download-panel,
  .as-groups-item-detail-status {
    margin-right: 0;
    margin-left: 0;
  }

  .as-thumbnail-viewer__images {
    padding-right: @as-grid-gutter-width/2;
    padding-left: @as-grid-gutter-width/2;
  }

  .as-groups-item-detail-pager {
    margin-top: 0;
  }

  .as-groups-item-detail-summary {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: @font-size-h5;

    .as-features__head {
      [class*="as-icon--"] {
        margin-top: -.07em;
      }
      [class*="as-icon--hours"] {
        bottom: .06em;
      }
    }
  }

  .as-groups-pricing-info-table__primary-info--soldout {
    tr& td {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }

  .as-groups-item-detail-action-panel {
    position: relative;

    // Fix for a border when action has no panels
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      width: 100%;
      border-top: 1px solid @as-ui-border-color;
    }
  }

  // Fix for converted price style
  .as-groups-pricing-info-table__secondary-info--converted-price + .as-groups-pricing-info-table__secondary-info {
    th,td {
      padding-top: 20px;
    }
  }

  .as-groups-pricing-info-table {
    table& {
      margin-bottom: 20px;
    }

    // Fix for current price style
    table& {
      tr:not(.as-groups-pricing-info-table__primary-info--main-price) {
        th {
          width: auto;
        }
      }
    }

  }

  .as-groups-pricing-info-table__primary-info--main-price{
    height: 10em;   //spacing
  }

  .as-groups-item-detail-panel {
    // Show all tabs
    .tab-pane {
      display: block !important;
    }
  }

  .as-groups-item-detail-panel__panel--details {

    .panel{
      .panel-widget;

      .panel-body{
        padding-top: 10px;
      }

    }

    .as-groups-item-detail-panel__inspection-report {
      .display-flex;
      .flex(0,0,auto);
      .flex-flow(row, nowrap);
      .align-items(center);
      .justify-content(center);

      margin-bottom: 15px;
      padding-top: 10px;
      padding-bottom: 10px;

      // White background
      background-color: #fff;
      border-radius: @border-radius-base;

      .as-icon--inspection-report {
        width: 6em;
        height: 6em;
      }
      .as-download-link {
        color: @text-color;

        &:hover{
          text-decoration: none;
        }
      }
      .as-download-link__caption {
        font-weight: bold;
      }
      .as-download-link__link {
        font-size: @font-size-h4;
        font-weight: bold;

      }

      .as-icon--allstocker-logo {
        width: 7.3em;
        margin-right: 3px;
        margin-left: 1px;
        bottom: .1em;
      }
    }

  }

  // Confirm Panel
  .as-groups-confirm-info {

    // Limit height
    .panel-body {
      overflow-y: scroll;
      max-height: 570px;
      font-size: @font-size-h6;
      padding-top: 10px;

    }
  }

}

@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {

  .as-groups-item-detail-download-panel__item {
    .flex(1,0,100%);

    &:nth-child(n+2) {
      margin-top: 10px;
      padding-left: 0;
    }
  }
}

@media (min-width: @screen-md-min) {

  .as-groups-pricing-info-table {

    // Fix for current price style
    table& {
      tr:not(.as-groups-pricing-info-table__primary-info--main-price) {
        th {
          width: 40%;
        }
      }
    }

  }

  .as-groups-item-detail-download-panel {
    .as-download-link__link{
      font-size: @font-size-h4;
    }
  }

  .as-groups-item-detail-panel__inspection-report {
    .as-download-link__link{
      font-size: @font-size-h3;
    }
  }


  // Confirm Panel
  .as-groups-confirm-info {

    // Limit height
    .panel-body {
      max-height: 575px;
      font-size: @font-size-h5;
    }
  }

}

@media (min-width: @screen-lg-min) {

  .as-groups-item-detail-download-panel {
        .as-download-link__link{
      font-size: @font-size-h3;
    }
  }

  .as-groups-item-detail-panel__inspection-report {
    .as-download-link__link{
      font-size: @font-size-h2;
    }
  }

  // Confirm Panel
  .as-groups-confirm-info {

    // Limit height
    .panel-body {
      max-height: 535px;
    }
  }

}
