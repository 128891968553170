// Tables
// Nothing with bootstrap tables.less

.as-table {
  table& {

    thead {
      th {
        color: @as-text-term-color;
        font-weight: bold;
      }
    }

    tbody {
      th {
        padding-right: 10px;
        color: @as-text-term-color;
        font-weight: normal;
        vertical-align: top;
      }
      td {
        color: @as-text-description-color;
        vertical-align: middle;
      }
    }
  }
}
