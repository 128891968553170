// Pagination (multiple pages)

.pagination {
  // Fix for z-index problem
  position: relative;
  z-index: 0;

  > li {

    > a,
    > span {
      padding: @pagination-padding-vertical @pagination-padding-horizontal;
      font-size: @font-size-h5;
      min-width: 36px;
      text-align: center;

      &:hover {
        text-decoration: underline;
      }
    }
    &.first {
      > a:before {
        .as-icon__base--pseudo("../images/icon-double-arrow-left-link.svg", 98%);
      }
      &.disabled > a:before {
        .as-icon__base--pseudo("../images/icon-double-arrow-left-disable.svg", 98%);
      }
    }
    &.last {
      > a:before {
        .as-icon__base--pseudo("../images/icon-double-arrow-right-link.svg",  98%);
      }
      &.disabled > a:before {
        .as-icon__base--pseudo("../images/icon-double-arrow-right-disable.svg", 98%);
      }
    }
    &.prev {
      > a:before {
        .as-icon__base--pseudo("../images/icon-arrow-left-link.svg", 80%);
      }
      &.disabled > a:before {
        .as-icon__base--pseudo("../images/icon-arrow-left-disable.svg", 80%);
      }
    }
    &.next {
      > a:before {
        .as-icon__base--pseudo("../images/icon-arrow-right-link.svg", 80%);
      }
      &.disabled > a:before {
        .as-icon__base--pseudo("../images/icon-arrow-right-disable.svg",  80%);
      }
    }
    &.first, &.first.disabled,
    &.last,  &.last.disabled,
    &.prev,  &.prev.disabled,
    &.next,  &.next.disabled{
      > a {
        .as-icon__hide-text;
        width: ~"calc(1em + ( @{pagination-padding-horizontal} * 0.7 ) * 2)";
      }
      > a:before {
        margin-right: 1em;
        right: -1px;
      }
    }
  }
}

@media (max-width: @screen-xs-max) {
  .pagination {
    > li {
      &.prev,
      &.next {
        display: none !important;
      }
    }
  }
}
