// Navs

.nav-tabs {
  border-bottom: 4px solid @as-brand-base;
  > li {
    float: left;
    margin-bottom: 0;

    // Actual tabs (as links)
    > a {
      margin-right: 0x;
      // line-height: @line-height-base;
      color: @as-button-default-background-color;
      background-color: @as-button-default-background-color;
      border: none;
      border-radius: @border-radius-base @border-radius-base 0 0;
      &,
      &:hover,
      &:focus {
        color: @as-button-default-text-color-focus;
        background-color: @as-button-default-background-color-focus;
      }
    }

    // Active state, and its :hover to override normal :hover
    &.active > a {
      &,
      &:hover,
      &:focus {
        // color: @nav-tabs-active-link-hover-color;
        // background-color: @nav-tabs-active-link-hover-bg;
        border: none;
        cursor: default;
      }
    }
  }
  // pulling this in mainly for less shorthand
  &.nav-justified {
    .nav-justified();
    .nav-tabs-justified();
  }
}

@media screen {

}
