// Search List Auction Event Goods
// Using in Auction Event Detail Page

@blink-off: white;
@blink-on: rgba(255, 255, 0, 0.8);

.as-search-list-panel--auction {
  &.as-search-list-panel {
  }
}

// Auction Goods Search Result
// Override .as-search-list-goods

.as-search-list-goods--auction {
  &.as-search-list-goods {

    .as-search-list-goods__item {
      padding-top: 0;
      padding-bottom: 30px;
      border-bottom: none;
      &:first-child {
        border: none;
      }
      a:hover > img {
        opacity: .8;
      }
    }

    .as-search-list-goods__wrapper {
      padding-bottom: 5px;
    }

    .as-search-list-goods__image {
      padding-left: (@as-grid-gutter-width / 2);
      background-color: transparent;
    }

    .as-search-list-goods__body {
      .background-image-icon("../images/icon-arrow-right.svg", 15px, 15px, 5px, 25px, right);
    }

    .as-search-list-goods__title {
      margin-bottom: 0;
      font-size: @font-size-h4;
    }

    .as-search-list-goods__other-features {
      margin-bottom: 5px;
      font-size: @font-size-h6;

      &,dl {
        .clearfix;
      }
      dl,dt,dd {
        display: block;
        float: left;
      }
      dl {
        margin-right: 5px;
        white-space: nowrap;
      }

      .as-features__head {
        vertical-align: middle;
      }
    }

    .as-search-list-goods__other-features-head {
      margin-right: 3px;

      [class*="as-icon--"] {
        margin-right: 0;
        margin-top: -0.15em;
      }
      [class*="as-icon--hours"] {
        bottom: .1em;
      }
    }

    .as-search-list-goods__other-features-value {
      min-width: 2em;
    }

    .as-search-list-goods__action {
      margin-top: 10px;

      .as-balloon__root {
        display: inline-block;
      }
    }

    // State
    .as-search-list-goods__item {

      // Sale Status
      .as-search-list-goods__auction-info {
        border: solid 0 transparent;
        border-top-width: 1px;
        background: transparent;
      }
      &.as-sale-opening-soon {
        .as-search-list-goods__auction-info {
          border-color:     @as-sale-status-opening-soon-border-color;
          background-color: @as-sale-status-opening-soon-background-color;
          color:            @as-sale-status-opening-soon-color;
          [class*="as-icon--time"] {
            background-image: url('../images/icon-time-sale-opening-soon.svg');
          }
        }
      }
      &.as-sale-opening {
        .as-search-list-goods__auction-info {
          border-color:     @as-sale-status-opening-border-color;
          background-color: @as-sale-status-opening-background-color;
          color:            @as-sale-status-opening-color;
          [class*="as-icon--time"] {
            background-image: url('../images/icon-time-sale-opening.svg');
          }
        }
      }

      &.as-sale-ending-soon {
        .as-search-list-goods__auction-info {
          border-color:     @as-sale-status-ending-soon-border-color;
          background-color: @as-sale-status-ending-background-color;
          color:            @as-sale-status-ending-color;
          [class*="as-icon--time"] {
            background-image: url('../images/icon-time-sale-ending-soon.svg');
          }
        }
      }
      &.as-sale-ended {
        .as-search-list-goods__auction-info {
          border-color:     @as-sale-status-ended-border-color;
          background-color: @as-sale-status-ended-background-color;
          color:            @as-sale-status-ended-color;
          [class*="as-icon--time"] {
            background-image: url('../images/icon-time-sale-ended.svg');
          }
        }
        .as-search-list-goods__wrapper {
          background-color: @as-search-list-sold-out-background-color;
        }

        // "CLOSED" Caption
        .as-search-list-goods__image {
          .as-thumbnail {
            a,a:before,a:after {
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
            a:before {
              content: "";
              background-color: rgba(229,229,229,.75);
              z-index: 1;
            }
            a:after {
              content: "CLOSED!";
              top: ~"calc(51% - 1em)";
              height: auto;
              color: #fff;
              .as-responsible-font-size(manual,5vw,30px,34px,34px);
              font-weight: bold;
              text-decoration: none;
              letter-spacing: .05em;
              text-align: center;
              vertical-align: middle;
              z-index: 2;
            }
          }
        }
      }

      &.as-sale-ended.is-sold {
        .as-search-list-goods__auction-info {
          border-color:     @as-sale-status-ended-border-color;
          background-color: @as-sale-status-ended-background-color;
          color:            @as-sale-status-ended-color;
          [class*="as-icon--time"] {
            background-image: url('../images/icon-time-sale-ended.svg');
          }
        }
        .as-search-list-goods__wrapper {
          background-color: @as-search-list-sold-out-background-color;
        }

        // "SOLD" Caption
        .as-search-list-goods__image {
          .as-thumbnail {
            a,a:before,a:after {
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
            a:before {
              content: "";
              background-color: rgba(229,229,229,.75);
              z-index: 1;
            }
            a:after {
              top: ~"calc(51% - 1em)";
              left: ~"10%";
              content: "SOLD!";
              color: white;
              background-color: red;
              border-radius: 10px;
              .as-responsible-font-size(manual,5vw,30px,34px,34px);
              text-decoration: none;
              font-weight: bold;
              text-align: center;
              vertical-align: middle;
              width: 80%;
              height: auto;
              transform: rotate(-10deg);
              z-index: 2;
            }
          }
        }
      }

      &.is-bid-just-happened {
        .as-search-list-goods__wrapper {
          background: linear-gradient(0deg, @blink-on 0%, @blink-off 50%, @blink-on 100%);
          background-size: 100% 300%;
          animation: gradient 2s linear 0s 2 normal
        }
      }

      // Animation
      @keyframes gradient {
        0% {
            background-position: 50% 0%;
        }
        50% {
            background-position: 50% 100%;
        }
        100% {
            background-position: 50% 0%;
        }
      }

      // Hover
      &:hover {
        .as-search-list-goods__wrapper {
          background-color: @as-brand-hover;
        }
      }

    }

  }
}




// New Elements for Auction Goods Search List

.as-search-list-goods--auction {
  &.as-search-list-goods {

    .as-search-list-goods__auction-info {
      padding-top: 2px;
      padding-bottom: 2px;
      font-size: @font-size-h6;
      line-height: 1;
      border-top: 3px solid darken(@as-label-default-background-color, 10%);

      ul > li {
        display: inline-block;
        margin-right: 5px;
        padding-top: .1em;

        &:last-child {
          margin-right: 0;
        }

        [class*="as-icon--"] {
          margin-right: -2px;
        }
      }


      .as-search-list-goods__auction-unit-number {
        font-weight: bold;
        letter-spacing: -.01em;
      }
      .as-auction-time-left {
        letter-spacing: -.04em;
        opacity: .95;
      }
      .as-auction-event-item-countdown{
        word-spacing:-.04em;
      }

      // Fix for Safari font weight
      // except for 8 on pc and iOS
      // TODO refactoring
      .as-auction-time-left {

        // Safari 7.1+
        _::-webkit-full-page-media, _:future, :root & {
          font-weight: 500;
        }
        // Safari 9
        @supports-selector: ~"@supports (overflow:-webkit-marquee) and (justify-content:inherit)";
        @supports-selector {
          .as-auction-time-left{
            font-weight: 500;
          }
        }
        // Safari 9.0+
        _::-webkit-:not(:root:root), & {
          font-weight: 500;
        }
        // Safari 10.0+
        _::-webkit-:host:not(:root:root), & {
          font-weight: 500;
        }

      }

    }

    .as-search-list-goods__auction-sub-info {
      p {
        margin-bottom: 3px;
      }
      .label {
        // padding-top: .15em;
        // padding-bottom: .15em;
        padding: .15em .6em .15em;
      }
    }

    .as-search-list-goods__auction-features {
      padding-right: 0;
      font-size: @font-size-h6;

      &,dl {
        .clearfix;
      }
      dl {
        display: block;
        float: left;
        margin-right: 5px;
        white-space: nowrap;
      }
      dt,dd {
        display: inline-block;
        vertical-align: baseline;
      }

      .as-features__head {
        vertical-align: middle;
        font-weight: normal;
      }

      .as-search-list-goods__other-features-head {
        margin-right: 0;

        [class*="as-icon--"] {
          margin-right: 0;
        }
      }
      .as-search-list-goods__other-features-value {
        min-height: 1em;

        // Fix for v-cloak
        &:nth-of-type(1),
        &:nth-of-type(2) {
          min-width: .6em;
        }
      }

    }

    .as-search-list-goods__current-price{
      // Override
      .as-search-list-goods__other-features-head {
        margin-right: 5px;
      }
      .as-search-list-goods__other-features-value {
        font-size: @font-size-h4;
        font-weight: bold;
        margin-top: -(@font-size-h4 - @font-size-h6);
      }
    }

  }
}




// Auction Item Column Number

.as-search-list-goods--auction {
  &.as-search-list-goods {
    .as-search-list-goods__item {
      @media (min-width: @screen-sm-min) and (max-width: (@screen-md-min - 1)) {
        .as-search-list-goods-auction-columns-set(2);
        margin-bottom: 30px;

        .as-search-list-goods__wrapper {
          width: 220px;
        }
      }
      @media (min-width: @screen-md-min) and (max-width: (@screen-lg-min - 1)) {
        .as-search-list-goods-auction-columns-set(3);
      }
      @media (min-width: @screen-lg-min) {
        .as-search-list-goods-auction-columns-set(3);
      }
    }
  }
}

.as-search-list-goods-auction-columns-set(@columns){
  .flex(0,0,auto);
  width: (100% / @columns);
  &:nth-child(@{columns}n+1) {
    .justify-content(flex-start);
  }
  &:nth-child(@{columns}n+@{columns}) {
    .justify-content(flex-end);
  }
}




// Responsive Style

@media (max-width: @screen-xs-max) {
  .as-search-list-goods--auction {
    &.as-search-list-goods {
      margin-top: 15px;

      .as-search-list-goods__auction-sub-info {
        p {
          margin-bottom: 2px;
          line-height: 1.4;
        }
        .as-search-list-goods__category {
          margin-right: 5px;
        }
        // .label {
        //   padding-top: .09em;
        //   padding-bottom: .15em;
        // }
      }
    }
  }
}

@media (min-width: @screen-sm-min) {
  .as-search-list-goods--auction {

    &.as-search-list-goods {
      .as-search-list-goods__list {
        .display-flex;
        .flex-flow(row,wrap);
        margin-right: -15px;
        margin-left: -15px;
      }
      .as-search-list-goods__item {
        .display-flex;
        .justify-content(center);
        padding-right: 15px;
        padding-bottom: 15px;
        padding-left: 15px;
        border-bottom: none;
      }
      .as-search-list-goods__wrapper {
        width: 252px;
      }
      .as-search-list-goods__image {
        width: 100% !important;
        height: auto !important;
        margin-left: 0;
        padding-left: 0;
      }
      .as-search-list-goods__body {
        width: 100%;  // Override bootstrap col-
        .as-col--no-gutter;
        background-image: none;
      }

      // Fix for languarge
      html:lang(ja) & {
        .as-search-list-goods__header{
          padding-left: 5px;
          padding-right: 0;
          ul{
              letter-spacing: -.4em;
          }
        }
        .as-auction-time-left {
          margin-left: 5px;
        }
        .as-auction-event-item-countdown{
          word-spacing: -.08em;
        }
      }

    }

    .as-search-list-goods__auction-info {
      font-size: @font-size-h6;
      line-height: inherit;
      border-top: none;

      ul > li {
        [class*="as-icon--"] {
          bottom: 0.2em;
        }
      }

      // Fix for languarge
      html:lang(ja) & {
        .as-search-list-goods__auction-unit-number {
          margin-right: 0px;
          word-spacing: -.5px;
        }
      }

    }

    .as-search-list-goods__auction-sub-info {
      p {
        margin-bottom: 0;
      }
    }

  }


}


@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {

  .as-search-list-panel--auction {

    // Auction goods
    .as-search-list-goods--auction {

      &.as-search-list-goods {
        // Fix for languarge
        html:lang(ja) & {
          .as-search-list-goods__header {
            padding-left: 2px;
          }
        }
      }

      .as-search-list-goods__auction-info {
        // Fix for Layout in 1 row
        font-size: 11px;

        // Fix for languarge
        html:lang(ja) & {
          .as-search-list-goods__auction_unit_number {
            margin-right: 0;
            letter-spacing: -.02em;
          }
          .as-auction-time-left {
            letter-spacing: -.05em;
          }
        }

      }


    }

  }


}

@media (min-width: @screen-md-min) {
  .as-search-list-goods--auction {
    &.as-search-list-goods {
      .as-search-list-goods__list{
        margin-right: -10px;
        margin-left: -10px;
      }
      .as-search-list-goods__item {
        padding-right: 10px;
        padding-left: 10px;
      }
    }

    .as-search-list-goods__auction-info {
      font-size: 11px;
    }
  }
}

@media (min-width: @screen-lg-min) {
  .as-search-list-goods--auction {
    &.as-search-list-goods {
      .as-search-list-goods__list{
        margin-right: -15px;
        margin-left: -15px;
      }
      .as-search-list-goods__item {
        padding-right: 15px;
        padding-left: 15px;
      }
    }

    .as-search-list-goods__auction-info {
      font-size: @font-size-h6;
    }
  }
}
