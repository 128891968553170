// Inquiry Complete Page

.as-groups-inquiry-complete {

  .as-wizard {
    margin-top: 0;
    .as-col--no-gutter;
    text-align: left;

    .as-wizard__footer-button {
      margin-top: 40px;
      margin-bottom: 60px;
    }

  }

}
