// Panels
// Use with Bootstrap .panel--widget class

.as-groups-login__panel {
  width: 100%;
  max-width: 350px;
  margin: 30px auto 10px;
  box-shadow: 0  2px 2px rgba(0, 0, 0, 0.25);

  .panel-heading {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .panel-title {
    width: 100%;
  }
}

.as-groups-login__background {
  .as-frame .as-contents-body& {

    // Market
    // background-image: url("../images/bg-image_01_hero.png");
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-color: @as-brand-key;

    // Auction
    background-image: url("../images/bg-image_02_auction.png");
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center;
    // background-color: @as-brand-key;
    background-color: #ffb701;  // darken than original color

  }
}

.as-groups-login__catch-wrapper {
  position: relative;
  height: 100%;
}

.as-groups-login__catch {
  display: inline-block;
  margin-top: 20px;
  padding-top: 10%;
  text-align: left;
  font-weight: bold;

  > span {
    display: block;
    margin-bottom: 30px;

    &:nth-child(1) {
      font-size: 38px;
    }
    &:nth-child(2) {
      font-size:  @font-size-h3;
    }
  }
}

.as-groups-login__message {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: @font-size-h5;
  color: @as-text-term-color;
}

.as-groups-login__rule {
  margin-top: 15px;
  margin-bottom: 15px;
}

.as-groups-signup-content {
  background-color:@as-widget-background-color;
  border:1px solid #CCC;
}

.as-groups-signup-body {
  padding:10px 20px;
}

.as-groups-alert-content {
  color:@as-brand-attention;
}

// Responsive

@media (max-width: @screen-xs-max) {
  .as-groups-login__background {
    .as-frame .as-contents-body& {
      background-image: none;
      background-color: #fff;
    }
  }
  .as-groups-login__panel {
    margin-top: 5px;
    padding-right: @as-grid-gutter-width/2;
    padding-left: @as-grid-gutter-width/2;
    box-shadow: none;
    .panel-heading {
      .as-heading--page-title;
      background: none;
      text-align: center;
    }
    .panel-body {
      background-color: transparent;
      padding-right: 0;
      padding-left: 0;
    }
  }
  .as-groups-login__message {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}


@media (min-width: @screen-sm-min) {
  .as-groups-login__panel {
    .panel-widget > .panel-body {
      padding-left: 25px;
      padding-right: 25px;
    }
  }
}


//会員登録LP用
.as-groups-login__background_campaign {
  .as-frame .as-contents-body& {
    background-image: url("../images/bg-image_campaign.jpg");
    background-repeat: no-repeat;
    background-size: auto 57%;
    background-position: top center;
    background-color: #FFF;
  }
}
@media (max-width: @screen-md-max) {
  .as-groups-login__background_campaign {
    .as-frame .as-contents-body& {
      background-size: auto 60%;
    }
  }
}
@media (max-width: @screen-sm-max) {
  .as-groups-login__background_campaign {
    .as-frame .as-contents-body& {
      background-size: auto 40%;
    }
  }
}
@media (max-width: @screen-xs-max) {
  .as-groups-login__background_campaign {
    .as-frame .as-contents-body& {
      background-image: none;
    }
  }
}
.as-groups-login__catch-wrapper-display{
  background-color: rgba( 255, 255, 255, 0.65 );
  padding:10px;
  margin-top:5%;
  margin-bottom:5%;
  .as-groups-login__catch{
    margin-top: 0px;
    padding-top: 0px;
    > span{
      margin-bottom: 0px;
    &:nth-child(1) {
      font-size: 38px;
      margin-bottom: 20px;
    }
    &:nth-child(2) {
      font-size:  @font-size-h3;
      margin-bottom: 10px;
    }
    }
  }
}
.as-registration_about{
  padding:10px;
  img{
    margin: 10px 0px;
  }
}

.common-login,
.auction-login{
  margin-top: 5px;
}

