// Panels

// Base class
.panel {
  box-shadow: none;
  border: 1px solid transparent;
}

// Panel contents
.panel-body {
  padding: (@panel-body-padding - 10px) @panel-body-padding @panel-body-padding;
}

// Optional heading
.panel-heading {
  margin-top: -1px;
  margin-right: -1px;
  margin-left: -1px;
  .border-top-radius((@panel-border-radius));
}

.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: @font-size-h3;
  font-weight: bold;
}

// Optional footer (stays gray in every modifier class)
.panel-footer {
  .border-bottom-radius((@panel-border-radius));
}

// List groups in panels

.panel {
  > .list-group,
  > .panel-collapse > .list-group {

    &:first-child {
      .list-group-item:first-child {
        .border-top-radius((@panel-border-radius));
      }
    }

    &:last-child {
      .list-group-item:last-child {
        .border-bottom-radius((@panel-border-radius));
      }
    }
  }
}

// Tables in panels
.panel {
  // Add border top radius for first one
  > .table:first-child,
  > .table-responsive:first-child > .table:first-child {
    .border-top-radius((@panel-border-radius));

    > thead:first-child,
    > tbody:first-child {
      > tr:first-child {
        border-top-left-radius: (@panel-border-radius);
        border-top-right-radius: (@panel-border-radius);

        td:first-child,
        th:first-child {
          border-top-left-radius: (@panel-border-radius);
        }
        td:last-child,
        th:last-child {
          border-top-right-radius: (@panel-border-radius);
        }
      }
    }
  }
  // Add border bottom radius for last one
  > .table:last-child,
  > .table-responsive:last-child > .table:last-child {
    .border-bottom-radius((@panel-border-radius));

    > tbody:last-child,
    > tfoot:last-child {
      > tr:last-child {
        border-bottom-left-radius: (@panel-border-radius);
        border-bottom-right-radius: (@panel-border-radius);

        td:first-child,
        th:first-child {
          border-bottom-left-radius: (@panel-border-radius);
        }
        td:last-child,
        th:last-child {
          border-bottom-right-radius: (@panel-border-radius);
        }
      }
    }
  }
}


// Collapsable panels (aka, accordion)
// .panel-group {
//
//   .panel {
//     border-width: 0;
//     + .panel {
//       margin-top: 0;
//     }
//   }
//   .panel-heading {
//     border-bottom-width: 1px;
//     border-bottom-style: solid;
//   }
//   .panel-body {
//     border-bottom-width: 1px;
//     border-bottom-style: solid;
//   }
//   .panel:last-child .panel-body {
//     border-bottom-width: 0;
//   }
//
// }

// Contextual variations
.panel-default {
  .panel-variant(@panel-default-border; @panel-default-text; @panel-default-heading-bg; @panel-default-border;);
}

.panel-widget {
  .panel-variant(@panel-primary-border; @panel-primary-text; @panel-primary-heading-bg; @panel-primary-border;);

  .panel-body {
    background-color: @panel-primary-bg;
    margin-right: -1px;
    margin-bottom: -1px;
    margin-left: -1px;
    padding: (@panel-body-padding - 5px) @panel-body-padding (@panel-body-padding - 5px);
  }
  .panel-footer {
    border-top: none;
    background-color: @panel-primary-bg;
  }
}

.panel-selectable {
  .panel-variant(@panel-selectable-border; @panel-selectable-text; @panel-selectable-heading-bg; @panel-selectable-border;);
  .panel-heading {
    position: relative;
    padding: 0;
    font-size: @font-size-h4;
    .user-select(none);

    .panel-title {
      display: block;
      padding: 7px 10px 7px 50px;
      min-height: 30px;
      font-weight: normal;
    }

    // AS Original New Element for Checkbox Style
    .panel-checkbox {
      position: absolute;
      left: 10px;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      display: block;
      width:  24px;
      height: 24px;
      background-color: #fff;
      border: 2px solid @as-ui-border-color;

    }

    a:hover,
    a:focus {
      color: @as-search-conditions-menu-text-color;
      text-decoration: none;

      .panel-checkbox {
        box-shadow: 0 0 10px fadeout(#000, 60%) inset;
      }

    }

    .panel-title[data-toggle="collapse"]:not(.collapsed) {

      background-color: @as-brand-key;

      .panel-checkbox {
        // Chrck Mrak
        &:after {
          content: "";
          position: absolute;
          top:  -4px;
          left: 4px;
          display: block;
          width: 27px;
          height: 27px;
          .background-image-icon("../images/icon-check.svg", 100%, 100%, 0, 0, left);
        }
      }

    }

  }

  .panel-body {
    background-color: @panel-selectable-bg;

    // Fix for collapse
    padding-top: 0;
    padding-bottom: 0;
    > *:first-child {
      margin-top: 20px;
    }
    > *:last-child {
      margin-bottom: 20px;
    }
  }
}

// AS Original Options
.panel-option-sidebar {
  .panel-body {
    padding: 0;
  }
}

.panel-option-collapse-footer-toggle {
  .panel-footer,
  .panel-footer > * {
    margin: 0;
    padding: 2px;
  }
  .panel-footer > a {
    display: block;
    text-align: center;
    font-size: @font-size-h6;
  }
}

.panel-option-collapsed-body {
  // This class is used as a trigger in other styles.
  // Attach to a .panel-body element.
}

.panel-option-mini {
  .panel-body {
    padding: (@panel-body-padding - 20px) (@panel-body-padding - 15px);
  }
}




// Responsive

@media (max-width: @screen-xs-max) {
  .panel-body,
  .panel-widget .panel-body {
    padding: (@panel-body-padding - 20px)  (@panel-body-padding - 20px) (@panel-body-padding - 20px);
  }
}
