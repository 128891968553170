// Typography

// Headings

h1, .h1 { font-size: @font-size-h1; }
h2, .h2 { font-size: @font-size-h2; }
h3, .h3 { font-size: @font-size-h3; }
h4, .h4 { font-size: @font-size-h4; }
h5, .h5 { font-size: @font-size-h5; }
h6, .h6 { font-size: @font-size-h6; }

// AS Original Contextual colors
.text-price {
  .text-emphasis-variant(@as-brand-attention);
}
.text-term {
  .text-emphasis-variant(@as-text-term-color);
}
.text-description {
  .text-emphasis-variant(@as-text-description-color);
}
.text-currency {
  .text-emphasis-variant(@as-brand-attention);
  margin-left: 0.2em;
}
.text-notice {
  .text-emphasis-variant(@as-brand-attention);
}
.text-caption {
  display: block;
  font-size: @font-size-h6 - 2px;  //10px

  @media (min-width: @screen-sm-min) {
    font-size: @font-size-h6;
  }
}

// Lists
// -------------------------

// Remove list style
ul,
ol {
  list-style: none;
  padding-left: 0;
}

ul,
ol,
dl {
  margin-bottom: 0;
}
