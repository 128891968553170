//
// Scaffolding
// --------------------------------------------------

html {
  height: 100%;
}

body {
  height: 100%;
  background-color: @as-global-footer-footer-background-color;
}
