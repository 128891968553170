.as-groups-login__panel, .as-groups-login-modal {
        .or-text {
        width: 100%;
        text-align: center;
    }

    .sns-login {
        margin-top: 16px; 
        margin-bottom: 16px;
        
        &:hover {
            opacity: 0.8;
        }

    }

    .sns-login-icon {
        width: 20px;
        margin-right: 8px;
    }

    .sns-login-facebook {
        .sns-login;
        background: #3b5998;
        color: #ffffff;
        
        &:hover {
            color: #ffffff;
        }
    }

    .sns-login-google {
        .sns-login;
        background: #ffffff;
        border: solid 1px #b3b3b3;
        color: #4c4c4c;

        &:hover {
            color: #4c4c4c;
        }

    }

    .sns-login-twitter {
        .sns-login;
        background: #4099ff;
        color: #ffffff;

        &:hover {
            color: #ffffff;
        }
        
    }

}