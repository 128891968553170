@import '../sitewide.less';

// Default Type
.static-pages {

    // Commmon
    color: @text-color;

    p {
        .sw-type-reset;
        margin-bottom: @space-sm;
        font-size: @font-size-5;
        line-height: @line-height-5; 
    }
    
    .as-main-contents-header__title {
        padding: 24px;
    }

    hr {
        margin-top: @space-lg;
        margin-bottom: @space-lg;
        border-color: @border-color;
    }



    // Company
    &.company {

        li {
            margin-bottom: @space-sm;
        }
        
        .table>tbody>tr>th, .table>tfoot>tr>th, .table>thead>tr>th {
            padding: 24px 0 8px;
            font-size: @font-size-5;
            line-height: @line-height-5;
            vertical-align: top;
            // border-top: 1px solid @border-color;
            // color: @text-color;
            border-color:  @border-color;
            padding-right: @space-xlg;
        }

        .table>tbody>tr>td, .table>tfoot>tr>td, .table>thead>tr>td {
            padding: 24px 0;
            font-size: @font-size-5;
            line-height: @line-height-5;
            vertical-align: top;
            // border-top: 1px solid @border-color;
            // color: @text-color;
            border-color: @border-color;
        }

        // body直下のdivに対応できるのが、ここしかないので記述している
        @media (max-width: @screen-xs-max) {
            .table>tbody>tr>th, .table>tfoot>tr>th, .table>thead>tr>th {
                font-weight: 700;
            }
            .table>tbody>tr>td, .table>tfoot>tr>td, .table>thead>tr>td {
                padding-top: @space-tiny;
            }
        }

        .service-list{
            list-style-type: disc;
            margin-left: 15px;
          } 
          
          @media (min-width: @screen-xs-max){
            .service-list{
              margin-left: 0;
            } 
          }

    }

    &.how-to-buy {
        .bold {
            font-weight: 700!important;
        }

        .step-number {
            display: inline-block;
            height: 32px;
            width: 32px;
            margin-right: @space-base;
            border-radius: 16px;
            background: @theme-color;
            text-align: center;
            line-height: @line-height-3;
        }

        .circle {
            display: inline-block;
            height: 36px;
            width: 36px;
            border-radius: 50%;
            padding: 0;
            margin-right: @space-sm;
            background-color: @theme-color;
            color: @white;
            line-height: 36px;
            text-align: center;
            font-size: @font-size-3;
            font-weight: 300;
          }

        .money-back {
            padding: @space-base;
            margin-top: @space-xlg;
            background: @grey-2;
        }
    }

    &.seller-flow {
        ol:not(.breadcrumb){
            list-style-type: decimal;
            margin-left: @space-sm;
            font-size: @font-size-5;
        }

        .bold {
            font-weight: 700!important;
        }

        .step-number {
            display: inline-block;
            height: 32px;
            width: 32px;
            margin-right: @space-base;
            border-radius: 16px;
            background: @theme-color;
            text-align: center;
            line-height: @line-height-3;
        }

        .circle {
            display: inline-block;
            height: 36px;
            width: 36px;
            border-radius: 50%;
            padding: 0;
            margin-right: @space-sm;
            background-color: @theme-color;
            color: @white;
            line-height: 36px;
            text-align: center;
            font-size: @font-size-3;
            font-weight: 300;
          }

        .money-back {
            padding: @space-base;
            margin-top: @space-xlg;
            background: @grey-2;
        }
    }

    &.terms_of_use,
    &.inspection_app_privacy_policy {
        .tab-content {
            margin-top: @space-base;
        }
        p {
            .sw-type-reset;
            margin-bottom: @space-sm;
            font-size: @font-size-6;
            line-height: @line-height-6; 
        }
        .link-to-FAQ{
            font-weight: bold;
            font-size: 16px;
            margin-bottom: 45px;
        }
    }

    &.inspection_app_privacy_policy {
        .tab-content {
            margin-top: 0;
            padding-bottom: @space-base;
        }
    }



// about ALLSTOCKER
    &.about-allstocker {
        .marketBox{
            border:2px solid #1AAD2F;
            border-radius: 10px;
            -webkit-border-radius: 10px;  
            -moz-border-radius: 10px;
            padding:0px 15px;
            margin-bottom:10px;
          }
          .auctionBox{
            border:2px solid #FF5400;
            border-radius: 10px;
            -webkit-border-radius: 10px;  
            -moz-border-radius: 10px;
            padding:0px 15px;
            margin-bottom:10px;
          }
          table{
            width:100%;
            border:1px solid #333;
          }
          table th{
            border:1px solid #333;
            background-color:#545454;
            color:#FFF;
            text-align: center;
            padding:10px;
          }
          table th.table-header-benefits{
            width: 60%
          }
          table td{
            border:1px solid #333;
            padding:10px;
          }
          table td ul{
            list-style-type: disc;
            margin-left:15px;
          }
          table td ol{
            list-style-type: decimal;
            margin-left:15px;
        }
        .as-heading--page-title-space{
            margin-top: @space-xlg;
        }
    }


// how-to-buy
    &.how-to-buy {
        ul{
            list-style-type: disc;
            margin-left: @space-sm;
            font-weight: bold;
            font-size: @font-size-5;
        }
        ol:not(.breadcrumb){
            list-style-type: decimal;
            margin-left: @space-sm;
            font-size: @font-size-5;
        }
        .responsible-pabel{
            margin-top: @space-sm;
        }
        .as-heading--page-title-space{
            margin-top: @space-xlg;
        }
    }



// seller-flow
    &.seller-flow {
        ul{
            list-style-type: disc;
            margin-left: @space-sm;
            font-weight: bold;
            font-size: @font-size-5;
        }
        .responsible-pabel{
            margin-top: @space-sm;
        }
        .as-heading--page-title{
            margin-top: @space-xlg;
        }
    }

// inspection-app
    &.inspection-app {
        .inspection-app__to-download-page{
            .btn{
                img{
                    width: 150px;
                }
            }
        }
        .how-to-use-inspection-app{
            border: 1px solid @border-color;
            border-radius: 5px;
        }
        ul{
            list-style-type: disc;
            margin-left: @space-sm;
            font-size: @font-size-5;
            li{
                margin-bottom: @space-sm;
            }
        }
        .responsible-pabel{
            margin-top: @space-sm;
        }
        .as-heading--page-title-space{
            margin-top: @space-xlg;
        }
        .circle {
            display: inline-block;
            height: 36px;
            width: 36px;
            border-radius: 50%;
            padding: 0;
            margin-right: @space-sm;
            background-color: @theme-color;
            color: @white;
            line-height: 36px;
            text-align: center;
            font-size: @font-size-3;
            font-weight: 300;
        }

        .table>tbody>tr>th, .table>tfoot>tr>th, .table>thead>tr>th {
            padding: 24px 0 8px;
            font-size: @font-size-5;
            line-height: @line-height-5;
            vertical-align: top;
            border-color:  @border-color;
            padding-right: @space-xlg;
        }

        .table>tbody>tr>td, .table>tfoot>tr>td, .table>thead>tr>td {
            padding: 24px 0;
            font-size: @font-size-5;
            line-height: @line-height-5;
            vertical-align: top;
            border-color: @border-color;
            word-break: break-all;
        }

        // body直下のdivに対応できるのが、ここしかないので記述している
        @media (max-width: @screen-xs-max) {
            .table>tbody>tr>th, .table>tfoot>tr>th, .table>thead>tr>th {
                font-weight: 700;
            }
            .table>tbody>tr>td, .table>tfoot>tr>td, .table>thead>tr>td {
                padding-top: @space-tiny;
            }
        }
    }

// error-page
    &.error-page {
        .as-main-contents-header__title {
            padding: 0 !important;
        }
    
        .maintenance-messages p {
            font-size: @font-size-4 !important;
            line-height: @font-size-4 !important;
            &.reload {
                color: darken(#328acd, 6.5%);
                font-weight: 700 !important;
            }
        }
    }

}


