// Step Navigation
//
// Using at wizard page

.as-step-nav {
  .display-flex;
  .flex-flow(row,nowrap);
}

.as-step-nav__step {
  .flex(1,1,auto);

}

// Alternatives

.as-step-nav--arrow {

  .as-step-nav__step-chart {
    display: none;
  }
  .as-step-nav__step-text {
    position: relative;
    height: 40px;
    margin: 0 auto;
    padding: 10px 10px;
    text-align: left;
    font-size: @font-size-h5;
    background-color: @as-label-moderate-background-color;

    // Put an arrow on the left side
    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: initial;
      left: -10px;
      display: block;
      width: 0;
      height: 40px;
      border: 20px solid #fff;
      border-left-color: @as-label-moderate-background-color;
      border-left-width: 10px;
      border-right-width: 10px;
    }
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: initial;
      left: -5px;
      display: block;
      width: 0;
      height: 40px;
      border: 20px solid @as-label-moderate-background-color;
      border-left-color: transparent;
      border-left-width: 10px;
      border-right-width: 5px;
    }
  }
  // Style for current step
  .as-step-nav__step--current {
    .as-step-nav__step-text {
      background-color: @as-brand-key;
      font-weight: bold;
      &:after {
        border-color: @as-brand-key;
        border-left-color: transparent;
      }
    }
    // Change color of next element for current step
    & + .as-step-nav__step {
      .as-step-nav__step-text {
        &:before {
          border-left-color: @as-brand-key;
        }
      }
    }
  }
  // Hide at fisrt step
  .as-step-nav__step:first-child {
    .as-step-nav__step-text {
      &:before, &:after {
        display: none;
      }
    }
  }
  .as-step-nav__step:not(:first-child) {
    .as-step-nav__step-text {
      padding-left: 15px;
    }
  }

}

.as-step-nav--line {

  .as-step-nav__step-chart {
    .as-step-nav__step-number {
      display: none;
    }
    // Step Line
    &:before {
      content: "";
      position: relative;
      top: initial;
      right: initial;
      left:  initial;
      display: block;
      height: 5px;
      width: 100%;
      background-color: darken(@as-label-moderate-background-color, 30%);
    }
  }
  .as-step-nav__step:first-child .as-step-nav__step-chart:before {
    width: auto;
    left: auto;
  }
  .as-step-nav__step:last-child .as-step-nav__step-chart:before {
    width: auto;
    right: auto;
  }
  .as-step-nav__step-text {
    margin: 0 auto;
    text-align: left;
    font-size: @font-size-h6;
  }
  // Style for current step
  .as-step-nav__step--current {
    .as-step-nav__step-chart {
      // Step Line
      &:before {
        background-color: @as-brand-key;
      }
    }
  }

}

.as-step-nav--numbers {

  .as-step-nav__step-chart {
    position: relative;
    width: 100%;
    padding-top: 5px;

    // Step Number
    .as-step-nav__step-number {
      position: relative;
      .display-flex;
      .flex-flow(column,nowrap);
      .justify-content(center);
      width:  32px;
      height: 32px;
      margin: 0 auto;
      border-radius: 50%;
      background-color: @as-label-moderate-background-color;
      color: @as-label-moderate-color;
      font-size: @font-size-h3;
      text-align: center;
      vertical-align: middle;
      z-index: 2;
    }

    // Step Line
    &:before {
      content: "";
      position: absolute;
      top: 18px;
      right:0;
      left: 0;
      display: block;
      height: 5px;
      width: 100%;
      background-color: darken(@as-label-moderate-background-color, 30%);
      z-index: 1;
    }
    .as-step-nav__step:first-child &:before {
      width: 50%;
      left: 50%;
    }
    .as-step-nav__step:last-child &:before {
      width: 50%;
      right: 50%;
    }
  }

  .as-step-nav__step-text {
    margin: 0 auto;
    text-align: center;
    font-size: @font-size-h6;
  }

  // Style for current step
  .as-step-nav__step--current {
    .as-step-nav__step& {

      .as-step-nav__step-number {
        background-color: @as-brand-key;
        color: @as-heading-text-color-primary;
        font-weight: bold;
      }

      .as-step-nav__step-text {
        font-weight: bold;
      }

    }
  }


}
