@import '../sitewide.less';

.lp-auction-seller {
    width: 100%;
    max-width: 750px;
    margin: auto;

    & + .as-footer{
        padding-bottom: 94px;
    }

    h1{
        margin: 0;
    }

    h2{
        margin: 0;
    }

    h3{
        margin: 0;
    }

    img{
        width: 100%;
    }

    .header-box{
        position: absolute;
        top: 38px;
        right: 4%;
        padding-top: 5px;
        & .phone-number-box{
            & .phone-number{
                font-size: 26px;
                & .tip{
                    font-size: 10px;
                    padding: 0 5px;
                }
                & + p{
                    font-size: 11px;
                }
            }
        }
    }

    .fixed-box{
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 999;
        background-color: #f04d4d;
        padding-top: 15px;
        padding-bottom: 15px;
        color: #ffffff;
        & .phone-number-box{
            & .phone-number{
                &::before{
                    content: "";
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    background-image: url('../images/lp/icon-phone.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                    margin-right: 7px;
                    margin-bottom: -7px;
                }
                a{
                    text-decoration: underline !important;
                    color: #ffffff;
                }
            }
            & p{
                text-align: center;
                margin-bottom: 10px;
            }
            & .phone-number-box-title{
                font-weight: bold;
                font-size: 22px;
            }
        }
    }

    .btn-box{
        padding: 15px;
        & .btn{
            font-size: 28px;
            position: relative;
            min-height: 80px;
            font-weight: bold;
            white-space: normal;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        & .btn-contact{
            color: #242424;
            background-color: #edd20f;
            box-shadow: inset 0 -4px 0 #998809;
            &:hover{
                color: #fff;
                background-color: #998809;
                box-shadow: inset 0 -4px 0 #665b06;
            }
        }

        & .btn-appeal{
            color: #fff;
            background-color: #ff5400;
            box-shadow: inset 0 -4px 0 #b33b00;
            &:hover{
                background-color: #cc4300;
            }
        }
    }

    & .phone-number-box{
        & .phone-number{
            font-size: 32px;
            font-weight: bold;
            display: flex;
            justify-content: center;
            align-items: center;
            white-space: nowrap;
            & .tip{
                background-color: #4fa22c;
                color: #fff;
                font-size: 13px;
                font-weight: normal;
                padding: 5px;
                margin-right: 5px;
            }
            & + p{
                font-size: 15px;
                text-align: center;
            }
            & a{
                cursor: pointer;
            }
        }
        & p{
            margin: 0;
        }
    }

    .inquiry-form{
        background-color: #f4da20;
        .form-group{
            margin: 0;
        }
        .form-group-body{
            margin: 0 5px;
            padding: 10px 15px;
            background-color: #fff9d1;
            label.control-label{
                font-size: 19px;
                line-height: 120%;
                padding: 8px 0;
                text-align: right;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-end;
                height: 59px;
                & .label-danger{
                    position: relative;
                    top: 2px;
                }
            }
            .control-label{
                padding-top: 0;
            }
        }
        .auction-seller-inquiry-submit-button{
            margin: 0 5px 5px 5px;
            padding: 10px 15px;
            background-color: #fff9d1;
            & p{
                text-align: center;
                margin: 5px 0 0 0; 
            }
        }
    }

    //レスポンシブ対応
    @media (max-width: @screen-xs-max){
        & .btn-box{
            & .btn{
                white-space: pre-line;
                font-size: 20px;
                min-height: 55px;
            }
            & .btn-contact{
                box-shadow: inset 0 -2px 0 #998809;
                &:hover{
                    box-shadow: inset 0 -2px 0 #665b06;
                }
            }

            & .btn-appeal{
                box-shadow: inset 0 -2px 0 #b33b00;
            }
        }

        & .header-box{
            transform: scale(0.75);
            top: 5px;
            right: -20px;
        }

        .inquiry-form{
            .form-group-body{
                label.control-label{
                    font-size: 14px;
                }
            }
        }
    }
}