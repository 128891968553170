.as-companies {
    @company-theme-color: var(--as-primary-color);
    @company-theme-sub-color: var(--as-secondary-color);
    @company-font-color: var(--as-base-text-color);


    .companies-index{
        width: 100%;
        margin: auto;
        .as-main-contents-header{
            display: none;
        }
        .as-main-contents-body{
            padding-top: 0;
        }
        .company-message{
            padding: 10px;
            border: 4px solid @company-theme-color;
            color: @company-theme-color;
            font-size: 20px;
            font-weight: bold;
            line-height: 1.2;
            white-space: pre-wrap;
        }
        .v-carousel__controls{
            width: 70px;
            height: 30px;
            border-radius: 4px;
            position: absolute;
            right: 0 !important;
            left: 0 !important;
            bottom: 15px;
            margin: auto;
            .v-item-group{
                .v-carousel__controls__item{
                    margin: 0 4px !important;
                }
                .v-btn--icon{
                    width: 14px;
                    height: 14px;
                    .v-icon{
                        font-size: 10px !important;
                    }
                }
            }
        }
        .company-page-label{
            background-color: @company-theme-color;
            color: @company-font-color;
            padding: 4px 4px 4px 45px;
            font-size: 23px;
            font-weight: bold;
            position: relative;
            line-height: 1.5;
            margin-bottom: 0;
            &::before{
                content: "";
                width: 25px;
                height: 25px;
                position: absolute;
                left: 10px;
                top: 0;
                bottom: 0;
                margin: auto 0;
                background-repeat: no-repeat;
                background-position: center;
            } 
            &.icon-excavator{
                margin-bottom: 12px;
            }
            &.icon-excavator::before{
                background-image: url(/images/company-page/icon-excavator.svg);
            }
            &.icon-company::before{
                background-image: url(/images/company-page/icon-company.svg);
            }
        }
        .feature-item{
            flex: 0 1 20%;
            max-width: 20%;
            p{
                margin-bottom: 0;
            }
            .v-card{
                box-shadow: none;
                border: 1px solid #cfcfcf;
                .v-card__text{
                    padding-bottom: 0 !important;
                }
                .v-responsive{
                    overflow: visible;
                }
                .v-chip{
                    background-color: #ffc001;
                    margin-top: 6px;
                    margin-left: -5px;
                    border-radius: 0 !important;
                    position: relative;
                    height: auto;
                    overflow: visible;
                    margin: 6px 4px 4px -5px;
                    padding: 0 5px;
                    &::before{
                        content: "";
                        position: absolute;
                        left: 0;
                        right: auto;
                        bottom: -4px;
                        top: auto;
                        border-right: 5px solid #906c00;
                        border-bottom: 4px solid transparent;
                        background-color: transparent;
                        opacity: 1;
                    }
                    .v-chip__content{
                        height: auto;
                        padding: 0 5px;
                        font-weight: bold;
                    }
                }
                .v-image{
                    width: 100%;
                    padding-bottom: 75%;
                    height: 0 !important;
                }
                .maker-name,
                .model-number{
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 1;
                }
                .price-box{
                    color: #ff5400;
                    font-weight: bold;
                    font-size: 18px;
                    margin: 10px 0;
                    line-height: 1;
                    letter-spacing: -0.5px;
                    > span{
                        margin-right: -4px;
                    }
                    .price-unit,
                    .tax-text{
                        font-size: 12px;
                        letter-spacing: -1px;
                    }
                }
                .as-icon--year,
                .as-icon--hours{
                    font-size: 12px;
                }
                .v-card__actions{
                    padding: 0 8px 4px 8px;
                }
                .link-detail-box{
                    display: flex;
                    justify-content: flex-end;
                    position: relative;
                    &::after{
                        content: "chevron_right";
                        font-family: 'Material Icons';
                        text-transform: none;
                        font-size: 20px;
                        position: absolute;
                        top: -5px;
                        right: 10px;
                        width: 16px;
                        height: 100%;
                        color: #14a2cc;
                    }
                    .link-detail{
                        display: flex;
                        align-items: center;
                        padding-right: 15px;
                        color: #14a2cc;
                        &:hover{
                            text-decoration: none;
                        }
                    }
                }
            }
        }
        .show-list{
            display: flex;
            justify-content: center;
            margin: 20px 0;
            .v-btn{
                background-color: @company-theme-sub-color !important;
                color: @company-font-color;
                font-weight: bold;
                padding: 8px 30px;
                min-width: 300px;
                border-radius: 2px;
                box-shadow: none;
                &::after{
                    content: "chevron_right";
                    font-family: 'Material Icons';
                    text-transform: none;
                    font-size: 24px;
                    position: absolute;
                    top: 0;
                    right: 10px;
                    width: 20px;
                    height: 100%;
                    box-shadow: none;
                }
            }
        }
        .company-detail-text{
            pre{
                background-color: #f2f2f2;
                padding: 15px;
                border: 1px solid #d2d2d2;
                border-radius: 0;
                font-size: 14px;
                white-space: pre-wrap;

            }
        }

        //レスポンシブ対応
        @media (max-width: @screen-xs-max){
            .feature-item{
                flex: 0 1 50%;
                max-width: 50%;
            }
        }
    }
}