.as-lists {
    margin-bottom: 30px;
}
.as-list {
    overflow: hidden;
    border-top: solid 1px @as-ui-border-color;

    p, h1, h2, h3, h4, h5, h6 {
      margin: 0;
    }
    a {
      display: inline-block;
      height: 100%;
      width: 100%;
      padding-top:10px;
      padding-bottom:10px;
      color: @as-brand-base;


      &:hover {
        background: @as-brand-hover;
        text-decoration: none;
      }
    }

    img {
      margin-right: 15px;
      float: left;
    }

    &:last-child {
      border-bottom: solid 1px @as-ui-border-color;
    }
}
.as-list__search-header .pagination{
  float: right;
}

.as-list__hit {
  margin-top: 20px;
  line-height: 36px;
}

// Responsive

@media (min-width: @screen-xs-min) {
  .as-list a {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
