@import '../sitewide.less';

.scope-user-registration {
    .form-group {
        background: @grey-1;
    }

    .as-field-container {
        width: 100%;
        margin-right: 16px;
        //margin-bottom: @space-base; 

        &:last-child {
            margin-right: 0;
        }

        & .form-control {
            min-height: 48px;
            padding: 0 16px;
            border: solid 1px @border-color;
            border-radius: 2px;
            font-size: @font-size-4;
        }
    
        & .form-input-field {
            padding-top: 8px;
            padding-bottom: 8px;
        }
    }

    .as-field-container--currency {
        width: 120px
    }
    /* Swapping the order */
    .as-invert-field-positions {
        flex-direction: row-reverse;
    }
    .as-invert-field-positions .as-field-container {
        margin-right: 16px;

        &:first-child {
            margin-right: 0;
        }
    }

    .input-field input {
        width: 100%;
        min-height: 48px;
        box-sizing: border-box;
        padding: 0 16px;
        border: solid 1px @border-color;
        border-radius: 2px;

        &:extend(.sw-p-lg);
        margin-bottom: 0;
    }

    .password-strength {
        &-0 {
            width: 20%;
            height: 8px;
        }
        &-1 {
            width: 20%;
            height: 8px;
            background: @warning-color;
        }
        &-2 {
            width: 40%;
            height: 8px;
            background: @warning-color;
        }
        &-3 {
            width: 60%;
            height: 8px;
            background: @link-color;
        }
        &-4 {
            width: 80%;
            height: 8px;
            background: @link-color;
        }
        &-5 {
            width: 100%;
            height: 8px;
            background: @link-color;
        }
        
    }

    .as-horizontal_fields {
        display: flex;
    }

    .new-password-wrapper {
        position: relative;

        input {
            padding-right: 100px;
        }
    }

    .show-password-wrapper {
        position: absolute;
        top: 0;
        right: 16px;
        margin-top: @space-tiny;
        margin-bottom: @space-tiny; 
    }

    .show-password-checkbox {
        display: none;
    }

    .show-password-label {
        line-height: 32px;
        border-radius: 16px;
        padding-left: 16px;
        padding-right: 16px;
        color: @link-color;
        background: @grey-2;
        font-size: 15px;
        font-weight: 500;
        margin-top: 8px; 

        &:hover {
            background: @grey-3;
        }
    }

    .user-registration-submit-button {
        margin-top: @space-base;
        margin-bottom: @space-base; 
    }

    .form-group-header {
        margin-top: 0px;
        margin-bottom: 0px;
        font-weight: 500;
        padding: 15px;
        border-bottom: 1px solid #ccc;
    }

    .form-group-body {
        padding: @space-base;
        & > .form-group-body-element {
            margin-bottom: 14px;
        }
        & > .form-group-body-element:last-child {
            margin-bottom: 0;
        }

        .checkbox{
            font-size: @font-size-4;
            margin-bottom: 20px;
        }
    }

    .user-registration-label {
        .sw-type-reset;
        text-align: right;
        margin: 8px 0 0 0;
        font-size: @font-size-4;
        line-height: 48px;

        .label-danger {
            position: relative;
            bottom: 2px;
        }
    }

    .user-registration-notices {
        list-style-type: disc;
        margin-left: 15px;
        margin-bottom: 10px;
    }

    .signupLink {
        font-weight: bold;
        font-size: 16px;
    }
    .signupLink:before {
        content: "";
        display: inline-block;
        margin-bottom: 2px;
        width: 0;
        height: 0;
        border: 4px solid transparent;
        border-left: 7px solid @link-color;
    }

    @media (max-width: @screen-xs-max) {
        .user-registration-label {
            .sw-type-reset;
            text-align: right;
            margin-bottom: @space-tiny;
            font-size: @font-size-4;
            line-height: @line-height-6;
        }

        .form-group-body .user-registration-label{
            padding: 0 8px;
        }
    }
        
}