.seller-register-sale {

  .as-field-group-title select {
    float: right;
    height: 44px;
    width: auto;
    padding-left:  @space-sm;
    padding-right:  @space-base;
    margin-left: @space-sm;
    margin-top: -12px;
    border: none;
    border-radius: @border-radius-base;
    background-color: @grey-3;
    box-shadow: none;
    font-size: @font-size-6;
    white-space: nowrap;  
  }

}