// Button variants

.button-variant(@color; @background; @border) {
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus {
      background-color: fadeout(@background, 45%);
      border-color: fadeout(@border, 45%);
      color: fadeout(@color, 45%);
      .box-shadow(none);
    }
  }
}
