// cookie consent Override


.cc-window {
  &.cc-floating{
    padding: 20px;
    border: 1px solid #ccc;
    //レスポンシブ対応
    @media (max-width: @screen-xs-max){
      margin: 0 20px 50px 20px;
    }
    &.cc-floating{
      & .cc-message{
        font-size: 14px;
        line-height: 150%;
      }
      & .cc-compliance{
        & .cc-btn{
          font-weight: normal;
          border-radius: @btn-border-radius-base;
          border: none;
          color: @btn-primary-color;
          background-color: @btn-primary-bg;
          box-shadow: inset 0 -2px 0 @btn-primary-shadow;
          text-decoration: none;
          &:hover,
          &:active{
            color: @btn-primary-color-focus;
            background-color: @btn-primary-bg-focus;
            box-shadow: inset 0 -2px 0 @btn-primary-shadow-focus;
          }
        }
      }
    }
  }
}
