//　siteside.lessの変数やクラスの使い方はデザインガイドラインにまとめてあります　https://sorabito.esa.io/posts/1117


/****** DESIGN COMPONENT VARIABLES *********/

/* 
TYPE VARIABLES
*/
@font-size-1: 44px;
@line-height-1: 56px;

@font-size-2: 32px;
@line-height-2: 36px; 

@font-size-3: 24px;
@line-height-3: 28px; 

@font-size-4: 19px;
@line-height-4: 24px; 

@font-size-5: 17px;
@line-height-5: 22px;  

@font-size-6: 14px; 
@line-height-6: 18px;


/*
SPACE VARIABLES
*/
@space-xlg:      64px;
@space-lg:       48px;
@space-base:     24px;
@space-sm:       16px;
@space-tiny:     8px;


/*
COLOR VARIABLES
*/
@theme-color:          	               #FFB900;
@link-color:           	               #14A2CC;
@warning-color:        	               #f05522;

/*
GREYSCALE
*/

// Background
@white:			                       #ffffff; //100%  e.g. Standard background
@grey-1: 		                       #fafafa;	//98%   e.g. Section background, list menus
@grey-2: 		                       #f5f5f5; //96%   e.g. Hover state
@grey-3:		                       #f0f0f0;	//94%   e.g. Hover state
// Border
@grey-4:		                       #e5e5e5;	//90%   e.g. Borders
// Icon
@grey-5:		                       #cccccc;	//80%   e.g. icons
// Text
@grey-6:		                       #b3b3b3;	//70%   e.g. Placeholder, Thin text
@grey-7:		                       #808080;	//55%   e.g. Secondary Text
@grey-8:	     	                   #4c4c4c;	//30%   e.g. Standard text



/****** UTIL VARIABLES *********/

@border-radius-base: 2px;

@modal-z-index: 999;

// TEXT COLORS
@text-color:                            @grey-8;
@light-text-color:                      @grey-7;
@x-light-text-color:                    @grey-6;

// BORDER  COLORS
@border-color:                          @grey-4;

// SEMI-TRANSPARENT COLORS
@modal-background-color:               rgba(0,0,0,.6);



/****** DESIGN COMPONENT CLASSES *********/


//新ボタンスタイルを反映したいページはここにスコープを追加してください。

.marketplace-seller-machine-list,
.seller-contents,
.support-machinescsvupload {
    
    .button-shadow {
        -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);
    }
    
    /*
    BUTTON COLORS
    */
    // Button Color gets darker on hover state.
    @link-shadow-color:                     darken(@link-color, 10%);
    @link-hover-color:                      darken(@link-color, 8%);
    @outline-link-hover-background-color:   @grey-1;
    
    // warning button color is  @warning-color;
    @warning-shadow-color:                  darken(@warning-color, 10%);
    @warning-hover-color:                   darken(@warning-color, 8%);
    @warning-hover-shadow-color:            darken(@warning-hover-color, 10%);


    
    //Link
    a {
        color: @link-color;
        text-decoration: none;
        cursor: pointer;
        &:hover {
        color: @link-hover-color;
        }
    }

    .btn {
        height: 48px;
        padding-top: 12px;
        padding-left: @space-sm;
        padding-right: @space-sm;
        background: none;
        font-size: @font-size-5;
        line-height: @line-height-5;
        border-radius: 2px;
        .button-shadow;
    
        &.disabled,
        &[disabled],
        fieldset[disabled] & {
        -webkit-filter: grayscale(1);
        filter: grayscale(1);
        cursor: @cursor-disabled;
        opacity: 0.3;
        box-shadow: none;
        }
    }

    //Search Button
    .search-container{
        .btn{
            height: 40px;
            border-radius: 0 2px 2px 0;
            padding: 10px 16px 6px;
        }
    }

    .btn-sm {
        height: 32px;
        padding-top: 6px;
        font-size: @font-size-6;
        line-height: @line-height-6;
    }
    
    .btn-xs {}



    //Primary Button
    .btn-primary {
        background: @link-color;
        color: @white;
        &:hover {
        background: @link-hover-color;
        color: @white;
        }
    }

    //Secondary Button
    .btn-secondary {
        color: @link-color;
        border: 1px solid @link-color;
        box-shadow: none;
        &:hover {
        background: @grey-2;
        color: @link-hover-color;
        border: 1px solid @link-hover-color;
        }
    }

    //Warning Button
    .btn-warning {
        color: @warning-color;
        border: 1px solid @warning-color;
        box-shadow: none;
        &:hover {
        background: @warning-color;
        color: @white;
        border: 1px solid @warning-color;
        }
    }

    //Price Form Button
    .btn-form-align-right {
        background: @white;
        color: @text-color;
        border: 1px solid @border-color;
        text-align: right;
        box-shadow: none;
        &:hover {
        color: @text-color;
        border: 1px solid @border-color;
        }
    }
}




/*
TYPES
*/

.sw-type-reset {
    margin: 0;
    padding: 0;
    font-weight: normal;
}

.sw-catch {
    .sw-type-reset;
    margin-bottom: @space-lg;
    font-size: @font-size-1;
    line-height: @line-height-1;
}

.sw-h1 {
    .sw-type-reset;
    margin-bottom: @space-base;
    font-size: @font-size-2;
    line-height: @line-height-2;
}

.sw-h2 {
    .sw-type-reset;
    margin-bottom: @space-base;
    font-size: @font-size-3;
    line-height: @line-height-3;
}

.sw-h3 {
    .sw-type-reset;
    margin-bottom: @space-sm;
    font-size: @font-size-4;
    line-height: @line-height-4;
}

.sw-p-lg {
    .sw-type-reset;
    margin-bottom: @space-sm;
    font-size: @font-size-4;
    line-height: @line-height-4;
}

.sw-p {
    .sw-type-reset;
    margin-bottom: @space-sm;
    font-size: @font-size-5;
    line-height: @line-height-5;
}

.sw-p-sm {
    .sw-type-reset;
    margin-bottom: @space-sm;
    font-size: @font-size-6;
    line-height: @line-height-6;
}

.bold {
    font-weight: 700;
}

@media (max-width: @screen-xs-max) {
    .sw-catch {
        .sw-type-reset;
        margin-bottom: @space-lg;
        font-size: @font-size-2;
        line-height: @line-height-2;
    }
    
    .sw-h1 {
        .sw-type-reset;
        margin-bottom: @space-base;
        font-size: @font-size-3;
        line-height: @line-height-3;
    }
    
    .sw-h2 {
        .sw-type-reset;
        margin-bottom: @space-base;
        font-size: @font-size-4;
        line-height: @line-height-4;
    }
    
}





/****** OTHER GLOBAL CLASSES *********/

// body直下のdivに対応できるのが、ここしかないので記述している（iphone用バグ対応）
@media (max-width: @screen-xs-max) {
    .modal-backdrop.fade.in {
        display: none;
    }
}

// Vuetify使用時にBootstrapとの競合分をオーバーライド
.as-material {
    .container {
        max-width: 1800px;
        padding: 0px;
        -webkit-font-smoothing: subpixel-antialiased
    }
}