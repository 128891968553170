// Typography
//
// Nothing with Bootstlap type.less

// use for span containing only text
.as-text--invisible {
  display: inline-block !important;
  width: 0  !important;
  height: 1em !important;
  min-width: 0 !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
  line-height: 1 !important;
  white-space: nowrap  !important;
  overflow: hidden !important;
  position: absolute !important;  // for ignore size calculation
}

.as-text--invisible-xs {
  @media (max-width: @screen-xs-max) {
    .as-text--invisible;
  }
}

.as-text--visible-xs {
  @media (min-width: @screen-sm-min) {
    .as-text--invisible;
  }
}
