// Banner

.as-banner {
  display: block;
  text-align: center;

  img {
    .as-img-responsive;
    display: inline-block;
  }
}

.as-banner--feature {
  display: block;
  // text-align: center;
  // vertical-align: middle;

  img {
    display: inline-block;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%
  }
}
