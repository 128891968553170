// Alert Modal
// Use with Bootstrap .modal class

.as-alert-modal {

  &.modal.in {
    background-color: rgba(255,255,255,0.5);
  }

  .modal-dialog {
    max-width: 300px;
    width: ~"calc(100% - @{as-grid-gutter-width} - 5px)";  // fix horizontal position
  }
  .modal-content {
    .display-flex;
    .flex-flow(column,nowrap);
    min-height: 200px;
  }
  .modal-header {
    display: none;
  }
  .modal-body {
    .flex(1,0,auto);
    .display-flex;
    .flex-flow(column,nowrap);
    .justify-content(center);
    padding-bottom: 10px;
    text-align: center;
    font-size: @font-size-h4;
  }
  .modal-footer {
    .flex(0,0,auto);
    padding-bottom: 10px;
    text-align: center;

    .btn {
      min-width: 80px;
    }
  }

  // Show in center

  &.modal {
    text-align: center;
  }

  &.modal:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
  }

  .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }

}
