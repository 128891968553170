// For Top Index Page

body{
  background-color:initial;
}
.as-frame .as-contents-body_top{
  background-image: url(../images/bg-image_top.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top center;
  background-color: #FFFFFF;
}

@media (max-width: @screen-xs-max) {
  .as-frame .as-contents-body{
    background-image: none;
  }
}


.as-main-contents-header.as-jumbotron-carousel-wrapper {
  margin-bottom: 15px;
}

.as-groups-topics {

  .panel.panel-default {
    margin-bottom: 10px;
    text-align: center;
  }
  .as-topic__title {
    display: inline-block;
    border-bottom: solid 2px @as-brand-key;
  }
  .as-topic__body {
    // Date info
    strong {
      white-space: nowrap;
    }
  }

}

.as-groups-search-entrance {

  //Heading Size
  > .as-heading--widget-title {
    font-size: @font-size-h2;
  }

  //Child Heading Size
  .panel > .panel-heading.as-heading--primary {
    min-height: 35px;

    h1,h2,h3,h4,h5,h6 {
      font-size: @font-size-h3;
      margin-top: ~"calc( ( @{as-heading-height-narrow} - 1em ) / 2 - 8px)";
    }

    .as-icon--search {
      font-size: 1.2em;
      bottom: 0.3em;
      margin-bottom: -0.4em;
    }
  }

  //Panel bottom mergin
  > .row > div[class*="col-"] {
    &:nth-last-child(n+2) .panel{
      margin-bottom: 0;
    }
  }

  //Category search
  .as-groups-categories {
    margin-top: -14px;
    .as-clearfix;

    .as-groups-categories__group-title {
      margin-top: 10px;
      margin-bottom: 5px;
      .as-responsible-font-size(h4);

      // for Multi Language
      position: relative;
      height: 1em;

      > span {
        display: block;
        position: absolute;
        right: 0;
        bottom : 0;
        left: 0;
      }
    }

    .as-panel-button__button {
      .as-panel-button__title {
        font-weight: 500;
        letter-spacing: 0.04em;
      }

      @media (min-width: @screen-md-min) {
        // for Multi Language

        html:lang(ja) & {
          .as-panel-button__title {
            letter-spacing: 0.03em;
          }
        }

        // other machineries
        &#as-index-category-link-9,
        &#as-index-category-link-19,
        &#as-index-category-link-21 {
          .as-panel-button__title {
            font-size: 11px;
            height: ~"calc(2.1em + 2px)";
            line-height: ~"calc(1em + 1px)";
            padding-bottom: 1px;
          }
        }
        &#as-index-category-link-9,
        &#as-index-category-link-19 {
          html:lang(en) & {
            .as-panel-button__title {
              padding-bottom: 2px;
            }
          }
        }

      }

      @media (min-width: @screen-sm-min) and (max-width: @screen-md-min) {
        html:lang(en) &#as-index-category-link-9 {
          &:before {
            background-size: 100% 81%;
          }
          .as-panel-button__title {
            padding-bottom: 3px;
          }
        }
      }

      @media (min-width: @screen-md-min) and (max-width: @screen-lg-min) {
        html:lang(en) &#as-index-category-link-9 {
          &:before {
            background-size: 100% 69%;
            background-position: center 16%;
          }
          .as-panel-button__title {
            height: ~"calc(2.1em)";
            line-height: ~"calc(1em)";
            padding-bottom: 2px;
          }
        }
      }
    }

    @media (min-width: @screen-md-min) {
      html:lang(th) &#as-index-category-link-9,
      html:lang(th) &#as-index-category-link-19,
      html:lang(th) &#as-index-category-link-21 {
        .as-panel-button__title {
          font-size: 10px;
        }
      }
    }

    .as-groups-categories__group-title--long-term {
      html:lang(en) &,
      html:lang(th) &,
      html:lang(vi) & {
        > span {
          // Addapt only for md ~ size layout
          @media (min-width: @screen-md-min) {
            font-size: 0.9em;
            padding-bottom: 0.05em
          }
        }
      }

    }

  }
  //Keyword search
  .search-container {
    .display-flex;
    .justify-content(center);

    .input-group {
      max-width: 90%;
      width: 90%;
    }

    .as-keyword-input.input-lg {
      font-size: @font-size-h4;
      line-height: 1.5;
    }

    .as-keyword-submit.btn-lg {
      font-size: @font-size-h4;
      line-height: 1.5;
      min-width: 80px;
    }

  }

}

.as-index-search-container {
  margin-top: 10px;
  margin-bottom: 1px; 
  padding:20px 0px;
}

.as-groups-new-arrivals__title {

}

.as-groups-new-arrivals__last-update {
  margin-left:   20px;
  float: left;
}

.as-groups-new-arrivals__view-all {
  .as-heading__actions & {
    float: right;
  }
  .panel-footer & {
    margin-top: 10px;
    text-align: right;
  }
}

.as-groups-banners {
  margin-top:   30px;
  margin-bottom: 20px;

  .as-banner {
    margin: 10px 0;
  }
}

.as-groups-feature-banners {

  // for style with pagination UI
  @media (max-width:@screen-xs-max) {
    margin-bottom: -5px;
  }

  .as-banner--feature {
    // compatible with 4 facebook banners
    // width: 245px;
    // height: 127px;

    // for 2 large banners
    @media (min-width:@screen-sm-min) {
      &:first-child {
        padding-right: 10px;
      }
      &:last-child {
        padding-left: 10px;
      }
    }
  }

  // override Formstone carousel
  .fs-carousel-pagination {
    line-height: 1;
  }
}

.as-groups-feature-banners__wrapper {
  .panel-widget > .panel-body& {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}


// top information
.as-main-contents-info{
  height:150px;
  margin-bottom:10px;
  & > div:not(:last-child) {
    margin-bottom: 15px;
  }
  p{
    margin:2px 0px;
  }
  div#asv-site-features {
    display: table;
  }
}
@media (max-width: @screen-xs-max) {
  .as-main-contents-info{
    height:initial;
  }
}


// top btn
.btn-register {
  color: #202020;
  background-color: #ffc001;
  padding: 3px 10px 3px 10px;
}
.btn-register:hover {
  color: #202020;
  background-color: #EFA000;
}
.btn-login {
  color: #202020;
  background-color: #CCCCCC;
  padding: 3px 10px 3px 10px;
  margin-left: 6px;
}
.btn-login:hover {
  color: #202020;
  background-color: #999999;
}
@media (max-width: @screen-xs-max) {
  .btn-register,
  .btn-login,
  .btn-member {
    font-size:12px;
    margin-top: 5px;
    padding:4px 6px 4px 6px;
    margin-left: 0px;
    margin-bottom: 3px;
  }
}
.btn-member {
  color: #202020;
  font-weight:bold;
  background-color: #CCCCCC;
  padding: 3px 10px 3px 10px;
}
.btn-member:hover {
  color: #202020;
  background-color: #999999;
}


// Responsive

@media (max-width: @screen-xs-max) {

  .as-main-contents-header.as-jumbotron-carousel-wrapper {
    margin-bottom: 10px;
  }

  .as-groups-search-entrance {

    .as-groups-categories {

      .as-panel-button__button {
        // .flex(0, 1, 100% / 3);  // Use when no-growing
        .flex(1, 1, 100% / 3);
        min-height: 72px;
      }

      .as-groups-categories__column-1 {
        .as-panel-button__container {
          width: 100%;
        }
      }

      .as-groups-categories__column-2 {

        > div {

          .as-panel-button__container {
            width: 100%;
          }

          &:nth-child(1) .as-panel-button__container {
            // width: 100% / 3;  // Use when no-growing
            width: 100%;
          }
          &:nth-child(2) .as-panel-button__container {
            // width: 100% / 3 * 2;  // Use when no-growing
            width: 100%;
          }

        }

      }

    }

  }

  .as-groups-banners {
    margin-top:   10px;
    margin-bottom: 10px;
  }

  .as-groups-new-arrivals__last-update {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0 !important;
    font-size: @as-font-size-caption-xs;
  }

  .as-jumbotron-carousel-wrapper.container {
    padding-left: 0;
    padding-right:0;
  }
}

@media (min-width: @screen-sm-min) {

  .as-groups-topics {
    .panel.panel-default {
      .panel-body {
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }
  }

  .as-groups-search-entrance {

    .as-groups-categories {
      .display-flex;
      .flex-flow(row,nowrap);

      .as-groups-categories__column {

        > div[class*="col-"] {
          padding-right: 0;
          padding-left: 0;
        }
      }

      .as-groups-categories__column-1 {
        .flex(1,1,609px);
        margin-right: 0;
        margin-left: 0;

        //13 Buttons in Construction Equipment
        .as-panel-button__button {

          //Button Width (4 Buttons)
          width: 100% / 4;
          min-height: 87px;

          //Button Height (4 Buttons at first low)
          &:nth-last-child(n+10){
            min-height: 105px;
          }

          //Button Width (5 Buttons at last row)
          &:nth-child(n+9){
            width: 100% / 5;
          }
        }
      }

      .as-groups-categories__column-2 {
        .flex(1,1,409px);
        margin-right: 0;
        margin-left: 30px;

        .as-panel-button__button {
          min-height: 72px;
        }

        > div {

          //1 Buttons in Material Handler
          &:nth-child(1) {
            .as-panel-button__button {
              width: 100% / 1;
            }
          }

          //2 Buttons in Agriculture
          &:nth-child(2) {
            padding-left: 20px;

            .as-panel-button__button {
              width: 100% / 2;
            }
          }

          //3 Buttons in Transportation
          &:nth-child(3) {
            .as-panel-button__button {
              width: 100% / 3;
            }
          }

          //3 Buttons in Other
          &:nth-child(4) {
            .as-panel-button__button {
              width: 100% / 3;
            }
          }

        }
      }

    }

  }

}

@media (min-width: @screen-sm-min) and (max-width: @screen-md-min) {

  .as-groups-search-entrance {

    .as-groups-categories {
      .flex-flow(column,nowrap);

      .as-groups-categories__column-1,
      .as-groups-categories__column-2 {
        .flex(1,1,auto);
        margin-right: 0;
        margin-left: 0;
      }
    }
  }

}

@media (min-width: @screen-md-min) {
  //Keyword search
  .as-groups-search-entrance {

    .search-container {
      .input-group {
        max-width: 900px;
      }
    }

  }
}


//Banner Menus
.as-site-top-banners{
  display: flex;
  flex-wrap: wrap;
  float: left;
  width: 100%;
}
.as-panel-banner{
  display:block;
  text-align:center;
  border:1px solid #CCC;
  padding:10px 0px;
  margin-bottom: 20px;
  background-color: #fff;
  height: ~"calc(100% - 20px)";
  span{
    display:block;
  }
  a{
    display:block;
    color:#666;
  }
  a:hover{
    text-decoration: none;
  }
}
.as-panel-banner:hover{
  background-color: #FFF9D0;
}

@media (max-width: @screen-xs-max) {
  .as-site-top-banners{
    display: block;
  }
  .as-panel-banner{
    display:block;
    text-align:initial;
    border:1px solid #CCC;
    padding:5px;
    margin-bottom: 5px;
    span{
      display:initial;
      margin-right:3px;
      img{
        width:10%;
      }
      &.small{
        display: block;
        margin-left: ~"calc(10% + 7px)";
      }
    }
  }
}


//Category Icons

@selector: ~".as-groups-search-entrance .as-groups-categories";

.as-panel-button__button {
  @{selector} #as-index-category-link-1& {
    &:before       {.background-image-icon("../images/category/icon-mini-excavator.svg", 100%, 90%, center, 70%, left);}
    &:hover:before {background-image: url("../images/category/icon-mini-excavator-hover.svg");}
  }
  @{selector} #as-index-category-link-2& {
    &:before       {.background-image-icon("../images/category/icon-excavator.svg", 100%, 92%, center, 10%, left);}
    &:hover:before {background-image: url("../images/category/icon-excavator-hover.svg");}
  }
  @{selector} #as-index-category-link-3& {
    &:before       {.background-image-icon("../images/category/icon-wheel-loader.svg", 100%, 92%, center, 10%, left);}
    &:hover:before {background-image: url("../images/category/icon-wheel-loader-hover.svg");}
  }
  @{selector} #as-index-category-link-4& {
    &:before       {.background-image-icon("../images/category/icon-dozer.svg", 100%, 89%, center, 55%, left);}
    &:hover:before {background-image: url("../images/category/icon-dozer-hover.svg");}
  }
  @{selector} #as-index-category-link-5& {
    &:before       {.background-image-icon("../images/category/icon-roller.svg", 100%, 84%, center, 56%, left);}
    &:hover:before {background-image: url("../images/category/icon-roller-hover.svg");}
  }
  @{selector} #as-index-category-link-6& {
    &:before       {.background-image-icon("../images/category/icon-grader.svg", 100%, 76%, center, 55%, left);}
    &:hover:before {background-image: url("../images/category/icon-grader-hover.svg");}
  }
  @{selector} #as-index-category-link-7& {
    &:before       {.background-image-icon("../images/category/icon-finisher.svg", 100%, 72%, center, 55%, left);}
    &:hover:before {background-image: url("../images/category/icon-finisher-hover.svg");}
  }
  @{selector} #as-index-category-link-8& {
    &:before       {.background-image-icon("../images/category/icon-crane.svg", 100%, 80%, center, 40%, left);}
    &:hover:before {background-image: url("../images/category/icon-crane-hover.svg");}
  }
  @{selector} #as-index-category-link-9& {
    &:before       {.background-image-icon("../images/category/icon-other-construction-machineries.svg", 100%, 90%, center, 35%, left);}
    &:hover:before {background-image: url("../images/category/icon-other-construction-machineries-hover.svg");}
  }
  @{selector} #as-index-category-link-10& {
    &:before       {.background-image-icon("../images/category/icon-forklift.svg", 100%, 80%, center, 50%, left);}
    &:hover:before {background-image: url("../images/category/icon-forklift-hover.svg");}
  }
  @{selector} #as-index-category-link-11& {
    &:before       {.background-image-icon("../images/category/icon-generator.svg", 100%, 80%, center, 50%, left);}
    &:hover:before {background-image: url("../images/category/icon-generator-hover.svg");}
  }
  @{selector} #as-index-category-link-12& {
    &:before       {.background-image-icon("../images/category/icon-welder.svg", 100%, 70%, center, 55%, left);}
    &:hover:before {background-image: url("../images/category/icon-welder-hover.svg");}
  }
  @{selector} #as-index-category-link-13& {
    &:before       {.background-image-icon("../images/category/icon-compressor.svg", 100%, 80%, center, 50%, left);}
    &:hover:before {background-image: url("../images/category/icon-compressor-hover.svg");}
  }
  @{selector} #as-index-category-link-14& {
    &:before       {.background-image-icon("../images/category/icon-attachment.svg", 100%, 70%, center, 55%, left);}
    &:hover:before {background-image: url("../images/category/icon-attachment-hover.svg");}
  }
  @{selector} #as-index-category-link-15& {
    &:before       {.background-image-icon("../images/category/icon-parts.svg", 100%, 75%, center, 55%, left);}
    &:hover:before {background-image: url("../images/category/icon-parts-hover.svg");}
  }
  @{selector} #as-index-category-link-16& {
    &:before       {.background-image-icon("../images/category/icon-small-item.svg", 100%, 85%, center, 50%, left);}
    &:hover:before {background-image: url("../images/category/icon-small-item-hover.svg");}
  }
  @{selector} #as-index-category-link-17& {
    &:before       {.background-image-icon("../images/category/icon-dump.svg", 100%, 78%, center, 55%, left);}
    &:hover:before {background-image: url("../images/category/icon-dump-hover.svg");}
  }
  @{selector} #as-index-category-link-18& {
    &:before       {.background-image-icon("../images/category/icon-truck.svg", 100%, 78%, center, 55%, left);}
    &:hover:before {background-image: url("../images/category/icon-truck-hover.svg");}
  }
  @{selector} #as-index-category-link-19& {
    &:before       {.background-image-icon("../images/category/icon-other-vehicles.svg", 100%, 90%, center, 10%, left);}
    &:hover:before {background-image: url("../images/category/icon-other-vehicles-hover.svg");}
  }
  @{selector} #as-index-category-link-20& {
    &:before       {.background-image-icon("../images/category/icon-tractor.svg", 100%, 80%, center, 50%, left);}
    &:hover:before {background-image: url("../images/category/icon-tractor-hover.svg");}
  }
  @{selector} #as-index-category-link-21& {
    &:before       {.background-image-icon("../images/category/icon-other-farm-machineries.svg", 100%, 80%, center, 10%, left);}
    &:hover:before {background-image: url("../images/category/icon-other-farm-machineries-hover.svg");}
  }
  @{selector} #as-index-category-link-22& {
    &:before       {.background-image-icon("../images/category/icon-others.svg", 100%, 75%, center, 55%, left);}
    &:hover:before {background-image: url("../images/category/icon-others-hover.svg");}
  }
}
