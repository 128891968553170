.market-data-seller {

  padding: 20px;

  .as-main-contents-body {
    width: 100%;
  }

  .unit-display {
    font-size: smaller;
    padding-left: 10px;
  }

  .align-center {
    text-align: center;
  }

  .c3-line {
    stroke-width: 4px;
  }

  .seasonal-tooltip {
    background: white;
    border: 1px solid grey;
    padding: 5px;
    border-radius: 10px;

    .title {
      font-size: normal;
      font-weight: bold;
    }

    li {
      margin-left: 3px;
    }

    .series-indicator {
      width: 10px;
      height: 10px;
      float: left;
      margin-top: 4px;
      margin-right: 4px;
    }

  }

  .no-data-notification {
    text-align: center;
    font-size: larger;
  }

  div.vertical {
    margin-left: -30px;
    position: absolute;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg); /* Safari/Chrome */
    -moz-transform: rotate(-90deg); /* Firefox */
    -o-transform: rotate(-90deg); /* Opera */
    -ms-transform: rotate(-90deg); /* IE 9 */
  }
  
  th.vertical {
    height: 220px;
    line-height: 14px;
    padding-bottom: 20px;
    text-align: left;
    vertical-align: middle;
  }

  .axis-title {
    color: white;
    background: rgba(84, 84, 84, 1.0);
    border-radius: 10px;
    padding: 5px;
    font-weight: normal;
  }

  .simple-cell {
    padding: 0px;
    border: none;
  }

  .table {

    margin-bottom: 0px;

    td.table-wrapper, th.table-wrapper, tr.table-wrapper {
      border: none;
      background: white;
    }

    tr, th {
      border: none;
    }
  }

  td.price-table, th.price-table {
    text-align: center;
    padding: 0px;
    border: none;
  }

  th.price-table {
    background: white;
  }

  td.price-table {
    border: 1px solid grey;
    cursor: pointer;
  }
  .as-balloon {
    background-color: rgba(84, 84, 84, 1.0);
  }
  .selected-cell {
    background: #FFB500 !important;
  }
  td.price-table:not(.selected-cell):hover {
    background: #FFF0C8;
  }

  .used-hours-column {
    width: 7%;
  }

}