// Photo Viewer
// Use with Formstone Carousel


.as-photo-viewer {
}

.as-photo-viewer__main-photos {
  position: relative;
  margin-bottom: 10px;
}

.main-photos-container {
  position: relative;
}

.as-photo-viewer__thumbnail-photos {
  margin-bottom: 10px;

  // Override as-carousel
  .as-carousel__control-button--prev {
    left: 0;
  }
  .as-carousel__control-button--next {
    right: 0;
  }

}

.as-photo-viewer__thumbnail-photos-container {
}

.as-photo-viewer__thumbnail-photo {
  padding: 0 2px;

  // Hover style
  &.focus,
  &:focus,
  &:hover {
    opacity: .8;
  }

  // Active item
  &.fs-carousel-active {
    opacity: .65;
  }
}

.as-photo-viewer__thumbnail-photos-wrapper {
  .display-flex;

  // Override formstone carousel
  .fs-carousel-controls {
    position: relative;
    .flex(0,0,(24px + 2px));  // button width + margin
  }
  .as-carousel__control-button {
  }

}

.as-photo-viewer__count {
  position: absolute;
  bottom: 5px;
  left: 5px;
}

.as-photo-viewer__count-container {
  margin-bottom: 0;
  padding: 6px 12px;
  background-color: fadeout(#ffffff, 25%);
  border-radius: @border-radius-base;
  font-size: @font-size-h5;
  font-weight: bold;
}

.as-photo-viewer__showmore {
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.as-photo-viewer__showmore-container {
  min-width: 60px;

  &.btn.btn-update {
    padding-top: 8px;
    padding-bottom: 8px;
    box-shadow: none;
    opacity: .9;
    // text-decoration: underline;
  }
}

.as-photo-viewer__showmore-text {

  // Hover
  .as-photo-viewer__showmore-container.focus &,
  .as-photo-viewer__showmore-container:focus &,
  .as-photo-viewer__showmore-container:hover & {
    color: @as-heading-text-color-primary;
    [class*="as-icon--view-large-invert"] {
      .as-icon--view-large;
    }
  }
}

.as-photo-viewer__bookmark {
    position: absolute;
    top: 5px;
    right: 5px;
}

.as-photo-viewer__bookmark-container {
  min-width: 60px;

  &.btn.btn-update {
    padding-top: 8px;
    padding-bottom: 8px;
    box-shadow: none;
    opacity: .9;
    // text-decoration: underline;
  }
}
  
.as-photo-viewer__bookmark-text {
  // Hover
  .as-photo-viewer__bookmark-container.focus &,
  .as-photo-viewer__bookmark-container:focus &,
  .as-photo-viewer__bookmark-container:hover & {
    color: @as-heading-text-color-primary;
    [class*="as-icon--star"] {
      .as-icon--star;
    }
  }
}

// Override for formstone controls
.as-photo-viewer__main-photos {
  // Control
  .fs-carousel.fs-light.fs-carousel-enabled .fs-carousel-control {
    background-color: @btn-primary-bg;
    opacity: .9;
  }
  // Prev
  .fs-carousel.fs-light.fs-carousel-enabled .fs-carousel-control_previous {
    left: 15px;
    &:before {
      .as-icon__base--pseudo("../images/icon-arrow-left-button.svg", 80%);
      left: -4px;
    }

    &.focus,
    &:focus,
    &:hover {
      background-color: @btn-update-bg-focus;
      &:before {
      .as-icon__base--pseudo("../images/icon-arrow-left-button-hover.svg", 80%);
      }
    }

    &,
    &.focus,
    &:focus,
    &:hover {
      &:before {
        left: -4px;
      }
    }
  }
  // Next
  .fs-carousel.fs-light.fs-carousel-enabled .fs-carousel-control_next {
    right: 15px;
    &:before {
      .as-icon__base--pseudo("../images/icon-arrow-right-button.svg", 80%);
    }

    &.focus,
    &:focus,
    &:hover {
      background-color: @btn-update-bg-focus;
      &:before {
        .as-icon__base--pseudo("../images/icon-arrow-right-button-hover.svg", 80%);
      }
    }

    &,
    &.focus,
    &:focus,
    &:hover {
      &:before {
        right: -4px;
      }
    }
  }
  // Icon fix
  .fs-carousel.fs-light.fs-carousel-enabled .fs-carousel-control_previous,
  .fs-carousel.fs-light.fs-carousel-enabled .fs-carousel-control_next {
    &,
    &.focus,
    &:focus,
    &:hover {
      &:before {
        position: absolute;
        width: 20px;
        height: 20px;
        border: none;
      }
    }
  }
}



@media (max-width: @screen-xs-max) {

  .as-photo-viewer__showmore-container {
    &.btn.btn-update {
      padding-top: 6px;
      padding-bottom: 8px;
    }
  }

  .as-photo-viewer__showmore-text {
    font-size: @font-size-h4;
    [class*="as-icon--"] {
      margin-right: 0;
    }
  }

  .as-photo-viewer__bookmark-container {
    &.btn.btn-update {
      padding-top: 6px;
      padding-bottom: 8px;
    }
  }

  .as-photo-viewer__bookmark-text {
    font-size: @font-size-h4;
    [class*="as-icon--"] {
      margin-right: 0;
    }
  }


  // Customize Formstone Carousel
  // Horizontal smooth scroll
  // Show next one a little
  .as-photo-viewer__main-photos {
    .as-col--no-gutter;
    margin-bottom: 0;

    // Override Formstone
    .fs-carousel-canister.fs-touch-element {
      // // Scroll
      // Use as container
      // overflow-x: scroll !important;  // Force to "auto" from "hidden"
      // -webkit-overflow-scrolling: touch;  // for smooth scroll
      // -webkit-transform: translateX(0) !important;
      // transform: translateX(0) !important;

      // // Show next one a little
      // // Relayout by flex
      // .flex-flow(row,nowrap);
      // width: auto !important;  // Ignore Formstone element style width
      //
      // // Left space for offset at first view
      // &:before {
      //   content: "";
      //   display: block;
      //   .flex(0,0,19%);
      // }
      // // Right space for last one
      // &:after {
      //   content: "";
      //   display: block;
      //   .flex(0,0,5px);
      // }

      // Additional style
      // padding: 5px 0;
        background-color: @as-image-background-color;

      // // Fix for winEdge touch tablet
      // touch-action: auto !important;
    }

    .as-photo-viewer__main-photo {
      // // Show next one a little
      // // Relayout by flex
      // .flex(0,0,62%);  // Show next one a little
      // width: auto !important;  // Ignore Formstone element style width
      // float: none !important;

      // Fix for Formstone
      width: 100%;

      .as-photo-viewer__main-photo-container {
        // Fix for Formstone
        width: 80%;
        margin-right: auto;
        margin-left: auto;
      }

    }


  }

  // Fix for carousel
  // Hide thumbnail-photos on mobile but keep DOM
  .as-photo-viewer__thumbnail-photos {
    visibility: hidden;
    height: 0;
    min-height: 0;
    margin: 0;
  }

}


@media (min-width: @screen-sm-min) {

  .as-photo-viewer__count {
    bottom: 10px;
    left: 20px;
  }

  .as-photo-viewer__showmore {
    right: 20px;
    bottom: 10px;
  }

  .as-photo-viewer__bookmark {
    right: 20px;
    top: 10px;
  }
}

.as-marketplace {
  @media (max-width: @screen-xs-max) {
    .as-photo-viewer__thumbnail-photos {
      visibility: initial !important;
      height: initial !important;
      min-height: initial !important;
      margin: initial !important;
      padding-top: 10px;

      .as-carousel__control-button--prev, .as-carousel__control-button--next {
        width: 40px;
      }
      .as-carousel__control-button--prev:before, .as-carousel__control-button--next:before {
        right: -13px;
      }
    }
  }
}