// Tables

// Flexible Table
//
// This widget is supported applying bootstrap "col-" class to tbodys.

.as-flexible-table__base() {
  width: 100%;
  margin-bottom: 10px;
  // table-layout: fixed;

  caption {
    width: 100%;
    margin-bottom: 5px;
    padding-right: 0;
    padding-bottom: 1px;
    padding-left: 0;
    color: @as-flexible-table-caoption-color;
    font-weight: bold;
    line-height: floor(@as-flexible-table-font-size *  1.72); //  24
    border-bottom: 1px solid @as-ui-border-color;
  }

  tbody {
    // margin-left: -20px;  //for multi column

    // for responsive
    // .display-flex;
    // .flex-flow(column, wrap);


  }
  tr {
    // width: 100%;
    // margin-left: 20px;  //for multi column
    // padding-top: 9px;
    // padding-right: 10px;
    // padding-bottom: 4px;
    // padding-left: 10px;
    // line-height: floor(@as-flexible-table-font-size * 2.14); // 30

    .break-inside(avoid);

  }

  th, td {
    padding-top: 10px;
    padding-bottom: 6px;
    word-break:break-word;
    font-weight: normal;
    vertical-align: middle;
  }
  th {
    min-width: @as-flexible-table-head-min-width;
    padding-left: @as-grid-gutter-width / 2;
  }
  td {
    padding-right: @as-grid-gutter-width / 2;
    padding-left: @as-grid-gutter-width / 2;
  }
}

// Mixins

.as-flexible-table__responsible-column-count(@count-xs:1,@count-sm:1,@count-md:1,@count-lg:1) {
  @media (max-width: @screen-xs-max) {
    .columns(@count-xs,auto);
  }
  @media (min-width: @screen-sm-min) {
    .columns(@count-sm,auto);
  }
  @media (min-width: @screen-md-min) {
    .columns(@count-md,auto);
  }
  @media (min-width: @screen-lg-min) {
    .columns(@count-lg,auto);
  }
}

.as-flexible-table__multi-column-set(@count, @gap:@grid-gutter-width) {
  tbody {
    display: block;
    .columns(@count,auto);
    .column-gap(@gap);
  }
}
