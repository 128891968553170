
.as-orders-order-id {

  input {
    border: none;
    background: none !important;
    box-shadow: none;
    display: inline;
    max-width: 500px;
  }

  .alpaca-control-buttons-container {
    display: inline;
  }

}

.alpaca-form-buttons-container {

  text-align: center !important;

}

.as-orders-alert {

  font-size: 1.1em;
  color: orange;
  font-weight: bold;

}