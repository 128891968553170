// Search List Auction Watches
// Using in Watch List Page

@as-search-list-goods-auction-watches-image-min-width: 90px;
@as-search-list-goods-auction-watches-watch-button-min-width: 25px;

// Column width
@as-search-list-goods-auction-watches-column-width-1: 27%;   // Item
@as-search-list-goods-auction-watches-column-width-2: 18%;   // Current Price
@as-search-list-goods-auction-watches-column-width-3: 15%;   // Time left
@as-search-list-goods-auction-watches-column-width-4: 10%;   // Watches
@as-search-list-goods-auction-watches-column-width-5: 10%;   // Bids
@as-search-list-goods-auction-watches-column-width-6: 20%;   // Highest bidder


// Override .as-search-list-goods
.as-search-list-goods--auction-watches {
  &.as-search-list-goods {

    // List Header
    // Only show on PC view
    .as-search-list-goods__list-header {
      .display-flex;
      .flex-flow(row,nowrap);
      .align-items(center);
      margin-left: @as-search-list-goods-auction-watches-image-min-width + @as-search-list-goods-auction-watches-watch-button-min-width + @as-grid-gutter-width + @as-grid-gutter-width / 2;
      margin-right: @as-grid-gutter-width / 2;
    }

    .as-search-list-goods__list-header-wrapper--fixed {

      @media (min-width: @screen-sm-min) {width: @container-sm - @as-grid-gutter-width; }
      @media (min-width: @screen-md-min) {width: @container-md - @as-grid-gutter-width; }
      @media (min-width: @screen-lg-min) {width: @container-lg - @as-grid-gutter-width; }
      position: fixed;
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      height: auto;
      padding-top: 15px;
      padding-bottom: 10px;
      background-color: @as-brand-background ;
      border-bottom: 1px solid @as-sub-border-color;
    }

    .as-search-list-goods__list-header-item {
      margin-bottom: 5px;
      padding: 0;
      color: @as-text-term-color;
      font-size: @font-size-h5;
      font-weight: bold;
      line-height: 1;
      text-align:center;
      &:nth-child(1) {
        position: relative;
        left: -(@as-search-list-goods-auction-watches-image-min-width + @as-search-list-goods-auction-watches-watch-button-min-width + @as-grid-gutter-width);
      }
      &:nth-child(1) { .flex(1,0,auto); width:@as-search-list-goods-auction-watches-column-width-1; text-align:left;}  // Item
      &:nth-child(2) { .flex(0,0,auto); width:@as-search-list-goods-auction-watches-column-width-2; }  // Current Price
      &:nth-child(3) { .flex(0,0,auto); width:@as-search-list-goods-auction-watches-column-width-3; }  // Time left
      &:nth-child(4) { .flex(0,0,auto); width:@as-search-list-goods-auction-watches-column-width-4; }  // Watches
      &:nth-child(5) { .flex(0,0,auto); width:@as-search-list-goods-auction-watches-column-width-5; }  // Bids
      &:nth-child(6) { .flex(0,0,auto); width:@as-search-list-goods-auction-watches-column-width-6; text-align:left; padding-left:20px; }  // Highest bidder
    }


    // List Body
    .as-search-list-goods__item {
      cursor: pointer;

      &.as-sale-ending-soon {
        .as-search-list-goods__time-left {
          &, & .as-search-list-goods__other-features-value {
            color: @as-brand-attention;
          }
        }
      }
      &.as-sale-ended  {
        background-color: @as-search-list-sold-out-background-color;
      }
      &.as-outbid {
        background-color: lighten(spin(saturate(@as-brand-attention, 90%), 7%), 45%);
      }
      &.as-winning {
        background-color: lighten(spin(saturate(@brand-success, 20%), -39%), 40%);
      }
      &:hover {
        background-color: @as-brand-hover;
      }
      &.as-sale-old-event {
        cursor: default;
      }
    }
    .as-search-list-goods__wrapper {
      .display-flex;
    }
    .as-search-list-goods__header,
    .as-search-list-goods__body {
      width: auto;
      height: auto;
    }
    .as-search-list-goods__header {
      .flex(0,0,auto);
      .display-flex;
      .flex-flow(column,nowrap);
      width: (@as-search-list-goods-auction-watches-image-min-width + @as-grid-gutter-width);
      background-color: transparent;
    }
    .as-search-list-goods__actions {
      margin: 10px 0 0 0;

      // Watch Button
      .as-button--watch {
        .btn-default;
        .as-button__icon("../images/icon-check.svg");
        min-width: @as-search-list-goods-auction-watches-watch-button-min-width;
        height: auto;
        padding-right: 5px;
        padding-left: 5px;
        border: none;
        .display-flex;
        .flex-flow(row,nowrap);
        .justify-content(center);
        .align-items(center);

        &:hover {
          opacity: 1;
        }

        // Icon
        &:before {
          right: 0;
        }

        // Text
        .as-button--watch__text {
          .as-text--invisible;
        }

      }

      .as-button--watching {
        .as-button--watch;
        .as-button__icon("../images/icon-trash.svg");

        // Icon
        &:before {
          right: 0;
          opacity: .8;
        }

        // Text
        .as-button--watching__text {
          .as-text--invisible;
        }
      }

      // Watch Button Balloon
      .as-watchlist-button .as-balloon {
        // Align left
        right: auto;
        left: 0;
        .transform(translateX(0));

        &:after {
          left: 7px;
          right: auto;
        }
      }

    }

    // When watch has removed
    .as-search-list-goods__item.as-removed {
      .as-search-list-goods__image {
        opacity: .6;
      }
      .as-search-list-goods__body {
        opacity: .6;
      }
    }

    .as-search-list-goods__body {
      .flex(1,1,auto);
      padding-right: @as-grid-gutter-width / 2;
      .background-image-icon("../images/icon-arrow-right.svg", 15px, 15px, 5px, 25px, right);
    }
    .as-search-list-goods__header {
      padding-right: @as-grid-gutter-width / 2;
      padding-left: @as-grid-gutter-width / 2;
    }
    .as-search-list-goods__auction-features {
      padding-left: @as-grid-gutter-width / 2;
    }
    .as-search-list-goods__auction-features-container {
      // .display-flex;
      // .flex-flow(column,nowrap);
      .clearfix;
    }

    // Fix for each values
    .as-search-list-goods__auction-features {
      p {
        margin-bottom: 3px;
      }
      .as-search-list-goods__auction-event {
        font-size: @font-size-h6;
        line-height: 1;
      }
      .as-search-list-goods__auction-unit-number {
        font-size: @font-size-h6;
        color: @as-text-description-color;
        font-weight: bold;
        line-height: 1;
      }
      .as-search-list-goods__category {
        margin-bottom: 5px;
        font-size: @font-size-h6;
        line-height: 1;
      }
      .as-search-list-goods__title {
        font-size: initial;
        line-height: 1;

        // Fix for btn btn_link
        .btn.btn-link {
          padding: 0;
          font-size: @font-size-h5;
          font-weight: bold;
          line-height: 1;
          text-align: left;
          white-space: normal;
          word-break: break-word;
          word-wrap: break-word;
          overflow-wrap: break-word;
        }
      }
      .as-search-list-goods__features {
        font-size: @font-size-h6;
        [class*="as-icon-"] {
          vertical-align: baseline;
          bottom: 0;
        }
      }
      .as-search-list-goods__auction-price {
        dt {
          font-size: @font-size-h6;
          height: 1.3em;
          padding-top: @font-size-h5 - @font-size-h6;

          // fix for languages
          html:lang(en) & { min-width: 6.5em; }

        }
        dd {
          font-size: @font-size-h5;
          height: 1.3em;
          font-weight: bold;
          &.text-price {
            .text-price;
          }
        }
      }
      .text-notice {
        margin-top: 3px;
        text-align: left;
      }
      .as-search-list-goods__highest-bidder {
        // Hide invalid empty flag icon
        [class="flag-icon flag-icon-"] {
          display: none;
        }
      }
      .as-search-list-goods__time-left {
        margin-right: 16px;
        dd {
          font-weight: bold;
        }

      }
      .as-search-list-goods__watches {
        margin-right: 5px;
      }
      .as-search-list-goods__bids {
        margin-right: 5px;
      }
    }
    .as-search-list-goods__other-features {
      .clearfix;
    }
    .as-search-list-goods__auction-features-item--current-price,
    .as-search-list-goods__auction-features-item--highest-bidder {
      .clearfix;
      display: block;
      clear: both;
      span.text-price-lowest{
        color:#ff5400;
        font-size:12px;
      }
    }
    .as-search-list-goods__auction-features-item--time-left,
    .as-search-list-goods__auction-features-item--watches,
    .as-search-list-goods__auction-features-item--bids {
      display: block;
      float: left;
    }

    .as-search-list-goods__other-features-head {
      color: @as-text-term-color;
    }
    .as-search-list-goods__other-features-value {
      color: @as-text-description-color;
    }

  }
}




// Responsive Style

@media (max-width: @screen-xs-max) {
  .as-search-list-goods--auction-watches {
    &.as-search-list-goods {

      // Fix for each values
      .as-search-list-goods__auction-features {
        .as-search-list-goods__title {
          .btn.btn-link {
            font-size: @font-size-h4;
          }
        }
        .as-search-list-goods__auction-features-item--current-price {
          margin-bottom: 3px;
          dd {
            font-size: @font-size-h4;
          }
        }
        .as-search-list-goods__auction-features-item--highest-bidder,
        .as-search-list-goods__auction-features-item--time-left,
        .as-search-list-goods__auction-features-item--watches,
        .as-search-list-goods__auction-features-item--bids {
          font-size: @font-size-h6;
        }

        .as-empty{
          &.as-search-list-goods__auction-features-item--highest-bidder{
            display: none;
          }
        }

      }

    }
  }
}


@media (min-width: @screen-sm-min) {
  .as-search-list-goods--auction-watches {
    &.as-search-list-goods {

      .as-search-list-goods__header {
        .flex-flow(row,nowrap);
        .align-items(flex-start);
        width: (@as-search-list-goods-auction-watches-image-min-width + @as-search-list-goods-auction-watches-watch-button-min-width + @as-grid-gutter-width + @as-grid-gutter-width / 2);

        .as-search-list-goods__image {
          .flex(0,0,auto);
          .order(2);
          width: (@as-search-list-goods-auction-watches-image-min-width);
          height: auto;
        }
        .as-search-list-goods__actions {
          .flex(0,0,auto);
          .order(1);
          margin-top: 0;
          margin-right: @as-grid-gutter-width / 2;

          // Watch Button
          .btn {
            height: 70px;
          }
        }
      }
      .as-search-list-goods__item {
        padding-top: 20px;
        padding-bottom: 10px;
      }
      .as-search-list-goods__body {
        width: calc(~"100% - "(@as-search-list-goods-auction-watches-image-min-width + @as-grid-gutter-width));
        padding-left: @as-grid-gutter-width / 2;
        background-image: none;
      }
      .as-search-list-goods__auction-features {
        padding-right: @as-grid-gutter-width / 2;

        .as-search-list-goods__auction-features-container {
          .display-flex;
          .flex-flow(row,nowrap);
        }
        .as-search-list-goods__auction-features-item {

          > dl,
          > dl > dt,
          > dl > dd {
            float: none;
            vertical-align: baseline;
            font-weight: normal;
            color: #4d4d4d;
          }

          &:not(:nth-child(1)) {
            padding: 20px 10px 15px;
            text-align:center;

            dl {
              margin-right: 0;
            }
            dd {
              display: inline;
              font-size: @font-size-h4;
              font-weight: bold;
            }
          }

          &:nth-child(1) { .flex(1,0,auto); width:@as-search-list-goods-auction-watches-column-width-1; }  // Item
          &:nth-child(2) { .flex(0,0,auto); width:@as-search-list-goods-auction-watches-column-width-2; text-align:right; }  // Current Price
          &:nth-child(3) { .flex(0,0,auto); width:@as-search-list-goods-auction-watches-column-width-3; }  // Time left
          &:nth-child(4) { .flex(0,0,auto); width:@as-search-list-goods-auction-watches-column-width-4; }  // Watches
          &:nth-child(5) { .flex(0,0,auto); width:@as-search-list-goods-auction-watches-column-width-5; }  // Bids
          &:nth-child(6) { .flex(0,0,auto); width:@as-search-list-goods-auction-watches-column-width-6; text-align:left; word-break:break-all; padding-left:20px;}  // Highest bidder
        }
        .as-search-list-goods__title {
          .btn.btn-link  {
            font-size: @font-size-h4;
          }
        }
        .as-search-list-goods__auction-price {
          text-align: right;

          dd {
            font-size: @font-size-h4;
          }

          // Buyout price
          &.as-search-list-goods__auction-price--buyout {
            dt {
              margin-right: 0;
            }
            dt,dd {
              .text-caption;
              display: block;
              height: auto;
              line-height: 1.4;
            }
          }
        }
        .text-notice {
          text-align: center;
        }
        .as-search-list-goods__highest-bidder {
          dt, dd {
            .text-caption;
            line-height: 2;
            word-break: break-all;
          }
          dd {
            font-size: @font-size-h4;
            line-height: inherit;
          }
        }
      }
      .as-search-list-goods__time-left,
      .as-search-list-goods__watches,
      .as-search-list-goods__bids {
        margin-right: 5px;
      }

    }
  }
}


@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
  .as-search-list-goods--auction-watches {
    &.as-search-list-goods {

      .as-search-list-goods__list-header-item {
        font-size: @font-size-h6;

        &:last-child {
          padding-left: 10px;
        }
      }
      .as-search-list-goods__auction-features{

        .as-search-list-goods__auction-features-item {

          &:not(:nth-child(1)) {
            padding-left: 5px;
            padding-right: 5px;

            dd {
              font-size: @font-size-h5;

              span{
                line-height: 1.3;
              }
            }
          }

          &:first-child {
            padding-right: 10px;
          }

          &:last-child {
            padding-left: 10px;
          }
        }
      }

    }
  }
}
