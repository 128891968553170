// Account Page

.as-groups-account {}

.as-groups-account__menus {
  margin-top: 10px;
}

.as-groups-account__contents {
  margin-top: 10px;
}

.as-groups-account__contents .as-mixed-columns-table__group {
  th {
    width: 260px;
    font-weight: bold;
  }
  td {
    color: @as-text-editable-color;
  }
}



// Edit Account

.as-groups-account__edit-contents {
  tr{
    padding-bottom: 30px;
  }

  th{
    text-align: right;
  }

  label {
    line-height: 37px;
  }

  input:nth-of-type(2) {
    margin-top: 20px;
  }

}

.as-groups-account {
  .table > tbody > tr > td,
  .table > tbody > tr > th {
    margin-top: 30px;
    border: none;
  }
  .table {
    border: solid 1px @as-area-border-color;
  }

  .form-control {
    height: 37px;
  }
}


// Change Password

.as-groups-account-password {
  th{
    text-align: right;
  }
  label {
    line-height: 37px;
  }
}
