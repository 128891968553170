// Button

.as-button--notice {
  .btn-primary;
  .as-button__icon("../images/notice.svg");
}

.as-button--bookmark {
  .btn-primary;
  .as-button__icon("../images/icon-bookmark.svg");
}

.as-button--text-link {
  display: inline-block;
  width: 100%;
  text-align: center;
  text-indent: 1em;
  font-size: @font-size-h4;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.as-button--icon-only {
  [class*="as-icon--"] {
    margin-right: 0;
  }
}

//back to page top button
.as-button--scroll-top {
  position: fixed;
  top: auto;
  bottom: 100px;
  right: 18px;
  width: 50px;
  height: 50px;
  margin: 0;
  padding: 0 1px 0 0;
  font-size: 28px;
  line-height: 48px;
  text-decoration: none;
  text-align: center;
  z-index: 999;

  &,
  &:hover,
  &:focus,
  &:active {
    box-shadow: none;
  }

  @media (min-width: @screen-sm-min) {
    right: 10px;
    bottom: 60px;
  }

}


// Toggle text button

.as-toggle-open-button {
  display: initial;

  // fore Panel footer
  &.as-opened {
    display: none;
  }
}

.as-toggle-close-button {
  display: none;

  // fore Panel footer
  &.as-opened {
    display: initial;
  }
}


// Toggle text button

.as-toggle-open-button {
  display: inline;

  // fore Panel footer
  &.as-opened {
    display: none;
  }
}

.as-toggle-close-button {
  display: none;

  // fore Panel footer
  &.as-opened {
    display: inline;
  }
}


// SNS login button
.btn-twitter {
    color: #fff;
    background: #00A3F9 url(/images/sns-login/twitter.png) no-repeat 10px 6px;
    border-color: #03669B;
    box-shadow: inset 0 -3px 0 #03669B;
}
.btn-twitter:hover {
    color: #fff;
    background-color: #0084CD;
}
.btn-facebook {
    color: #fff;
    background: #33599D url(/images/sns-login/facebook.png) no-repeat 14px 6px;
    border-color: #17345C;
    box-shadow: inset 0 -3px 0 #17345C;
}
.btn-facebook:hover {
    color: #fff;
    background-color: #204782;
}
.btn-google {
    color: #fff;
    background: #E72E2A url(/images/sns-login/google.png) no-repeat 8px 6px;
    border-color: #8F1E1B;
    box-shadow: inset 0 -3px 0 #8F1E1B;
}
.btn-google:hover {
    color: #fff;
    background-color: #C80014;
}
