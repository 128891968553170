// Thumbnail

// Usage:
// <li>
//   <div class="as-thumbnail"><img src="" alt=""></div>
// </li>

.as-thumbnail {
  .as-responsible-image-frame(4,3);
  background-color: @as-image-background-color;
}
