// Icon

.as-icon--bids {
  .as-icon__base("../images/icon-bids.svg");
}

.as-icon--bids-invert {
  .as-icon__base("../images/icon-bids-invert.svg");
}

.as-icon--bids-transition {
  .as-icon__base("../images/icon-bids-transition.svg");
}
.as-icon--time {
  .as-icon__base("../images/icon-time.svg");
}

.as-icon--time-invert {
  .as-icon__base("../images/icon-time-invert.svg");
}

.as-icon--decrement, .as-icon--decrement:active {
  display: inline-block;
  vertical-align: middle;
  .background-image-icon("../images/icon-minus-invert.svg", 50%, 50%, center, center, left);
}

.as-icon--increment, .as-icon--increment:active {
  display: inline-block;
  vertical-align: middle;
  height: 40px;
  .background-image-icon("../images/icon-plus-invert.svg", 50%, 50%, center, center, left);
}

.as-icon--remove {
  .as-icon__base("../images/icon-trash.svg");
}

.as-icon--has-removed {
  .as-icon__base("../images/icon-check.svg");
  opacity: .8;
}

.as-icon--shipping {
  .as-icon__base("../images/icon-shipping.svg");
}