// Inquiry Confirm Page

.as-groups-inquiry-confirm {

  .as-wizard {
    margin-top: 0;
    padding-top: 0;
    .as-col--no-gutter;
    text-align: left;
  }

  .as-wizard__footer-button {
    margin-top: 40px;
    margin-bottom: 60px;
  }

}
.as-groups-inquiry-confirm__title {
}
.as-groups-inquiry-confirm__steps {

}
.as-groups-inquiry-confirm__machine-info {
  .panel {
    .panel-title {
      font-size: @font-size-h5;
    }
  }
}
.as-groups-inquiry-confirm__machine-info-image {
  padding-right: 0;
  padding-left: 0;

  .as-goods__image {
    display: block;
  }

}
.as-groups-inquiry-confirm__machine-info-features {
  padding-right: 0;
}
.as-groups-inquiry-confirm__inquiries,
.as-groups-inquiry-confirm__customer-infos {
  .as-heading {
    margin-bottom: 10px;
  }
}
.as-groups-inquiry-confirm__inquiry,
.as-groups-inquiry-confirm__customer-info {

  .panel-selectable {
    margin-bottom: 5px;

    .panel-heading {
      background-color: @as-brand-key;
      .panel-title {
        font-size: @font-size-h6;
        font-weight: bold;
        .panel-checkbox {
          border-color: transparent;
        }
        &:hover {
          .panel-checkbox {
            box-shadow: none;
          }
        }
      }
    }

    .panel-body {
      label, p {
        margin-bottom: 5px;
      }
    }

  }


  // Inputed form style
  &.as-inputed {
    .panel-selectable {
      .panel-heading {
        .panel-title {

           .panel-checkbox {
             border-color: transparent;

              // Chrck Mrak
              &:after {
                content: "";
                position: absolute;
                top:  -4px;
                left: 4px;
                display: block;
                width: 27px;
                height: 27px;
                .background-image-icon("../images/icon-check.svg", contain, contain, 0, 0, left);
              }

          }
        }
      }
    }
  }
  // Not inputed form style
  &:not(.as-inputed) {
    .panel-selectable {
      .panel-heading {
        background-color: lighten(@as-menu-background-color, 7%);
        .panel-title {
          color: fadeout(@as-heading-text-color-primary, 90%);

           .panel-checkbox {
             opacity: .6;
           }
       }
     }
     .panel-body {
       background-color: lighten(@as-submenu-background-color, 0%);
       > * {
         opacity: .2;
       }
     }
   }
  }
  // For static inquiry
  .panel.panel-widget {
    .panel-title {
      font-size: @font-size-h5;
    }
    .panel-body {
      .form-group:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.as-groups-inquiry-confirm__customer-info {
  .panel {
    .panel-body {
      > *:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.as-groups-inquiry-confirm__options {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: @font-size-h5;

  .panel {
    .panel-body {
      background-color: transparent;
      border: 1px dotted @as-area-border-color;
      border-right: none;
      border-left: none;
      padding-top: 10px;
      padding-bottom: 10px;

      > *:last-child {
        margin-bottom: 0;
      }

    }
  }
}
.as-groups-inquiry-confirm__textarea {
   background-color: fadeout(#fff, 30%);
  //  margin-right: -10px;
   margin-bottom: 0;
  //  margin-left: -10px;
   padding: 15px;
}




// Responsive

@media (min-width: @screen-sm-min) {

  .as-groups-inquiry-confirm__form {
    // Fix font size
    .panel-selectable {
      .panel-heading {
        .panel-title {
          font-size: @font-size-h5;
        }
      }
    }
  }

}
