.as-contents-body_top {
  .popular-model-list{
    display: flex;
    flex-wrap: wrap;
    .popular-model-list-item{
      margin: 5px 10px 5px 0;
      padding-right: 10px;
      border-right: 1px solid @as-sub-border-color;
      &:last-of-type{
        padding-right: 0;
        border: none;
      }
    }
  }
  .as-banner {
    text-align: center;
    margin-bottom: 8px;
  }
}