.auction-dashboard {
  @columnWidth: 150px;
  @headerHeight: 20px;

  display: flex;
  overflow-x: scroll;
  background-color: white;
  margin-left: 10px;

  >div {
    margin-top: @headerHeight * 2;
  }

  .row {
    display: flex;
  }

  .result {
    position: relative;
  
    .totalResultWrapper {
      display: flex;
      flex-direction: column-reverse;
      height: @headerHeight * 3;
      position: absolute;
      bottom: 0%;
      right: 0%;
      color: black;
      padding-bottom: @headerHeight;

      .resultRow {
        display:flex;
        justify-content:flex-end;
      
        .resultRowContent {
            display: block;
            width: 150px;
            border:solid 1px black;
        }
      }
    }
  }

  .result {
    position: relative;

  .totalResultWrapper {
      display: flex;
      flex-direction: column-reverse;
      height: @headerHeight * 3;
      position: absolute;
      bottom: 0%;
      right: @columnWidth;
      color: black;
      padding-bottom: @headerHeight;

      .resultRow {
        display:flex;
        justify-content:flex-end;
      
        .resultRowContent {
            display: block;
            width: @columnWidth;
            border:solid 1px black;
        }
      }
    }
  }

  .column{
    width: 150px;
    word-break: break-all;
    text-align: center;
    box-sizing:border-box;
    border: solid 1px black;

    &.header {
      height: @headerHeight;
      background-color:blue;
      color: white;

      &.center {
        background-color:red;
      }
    }
  }
}