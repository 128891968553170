// Target Item Info
// Infomation widget about target item for something



.as-target-item {
  .display-flex;
}
.as-target-item-info__image {
  .flex(0,0,auto);
  margin-right: 5px;
}
.as-target-item-info__item-details {
  .flex(1,1,auto);
  font-size: @font-size-h6;
}
.as-target-item-info__item-detail {
  margin-bottom: 0;

  dl {
    display: inline-block;
    white-space: nowrap;
  }
}
.as-target-item-info__item-detail--title,
.as-target-item-info__item-detail--time, {
  font-weight: bold;
}
