// Recommended Links
// Use in .panel


.as-recommended-link{

  .as-recommended-link__list{

    .as-recommended-link__item{

    }

  }

}




.as-recommended-link__item{
  background-color: @as-widget-background-color;
}

.as-recommended-link__list{
}
