// Features
// For icon + value elements with <dl>, <dt>, <dd>

.as-features {
  // font-size: @font-size-h6 * 0.9;

  &,dl {
    .clearfix;
    margin-bottom: 0;
  }
  dl {
    margin-right: 5px;

    &:last-of-type {
      margin-right: 0;
    }
  }
  dl, dt, dd {
    display: block;
    float: left;
    white-space: nowrap;
  }
}

.as-features__head {
  display: inline-block;
  vertical-align: baseline;
  color: @as-text-term-color;
  margin-right: 3px;

  [class*="as-icon-"] {
    margin-right: 0;
  }
}

.as-features__value {
  display: inline-block;
  vertical-align: baseline;
  color: @as-text-description-color;
}
