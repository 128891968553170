.as-marketplace {
    width: 100%;
    margin: auto;
    .as-contents-body {
        padding-top: 0;
    }
    .invoice-bulk-generation-item-list-box{
        margin: 10px 0;
        border-top: 1px solid #cccccc;
        border-bottom: 1px solid #cccccc;
        .v-data-table {
            overflow-x: auto !important;
        }
    }
    
    .invoice-bulk-generation-button {
        padding: 4px 12px;
    }

    .invoice-bulk-generation-message {
        white-space: pre;
    }
}