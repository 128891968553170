// Badges

.badge {
  min-width: 2.25em; // width 27px / fontsize 12px
  padding: 3px 7px;
}

// AS original classes
.badge-large {
  .badge;
  padding:  3px 7px 3px 7px;
  font-size: @font-size-h3;
  border-radius: 12px;
}
