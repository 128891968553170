// Table list
//
// ul, li ,dl ,dt ,dd based list like a table


.as-table-list {
}

.as-table-list__list {
  margin-bottom: 40px;
}

.as-table-list__item-heading {
  margin-bottom: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  font-weight: bold;
  background-color: @as-widget-background-color;
}

.as-table-list__item {
  margin-bottom: 40px;

  dl {
    border-bottom: 1px dotted @as-ui-border-color;
    letter-spacing: -.5em;
  }

  dt, dd {
    display: inline-block;
    padding-top: 5px;
    padding-bottom: 5px;
    letter-spacing: normal;
    vertical-align: middle;
  }

  dt {
    width: 30%;
    padding-right: 20px;
    padding-left: 20px;
    font-weight: normal;
  }

  dd {
    width: 70%;
    font-weight: bold;
  }

}
