////////////////////////
// PROGRESSBAR
////////////////////////

// bootstrap override
.progress {
    overflow: hidden;
    height: 8px;
    border-radius: 4px;
    margin-top: 8px;
    margin-bottom: 0px;
    background-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
}


.progress-bar {

    -webkit-box-shadow: none;
    box-shadow: none;
}

.progress-bar-success {
    background-color: @theme-color;
}

// Registeration
.registeration-progress-bar {
  display: flex;
  background: @grey-2;
  margin-bottom: 24px;
}

.progress-section--completed {
  width: 100%;
  height: 8px;
  background: @theme-color;
}
.progress-section {
  width: 100%;
  height: 8px;
}





////////////////////////
// FORM STYLES
////////////////////////

.form-control {
  color: @text-color; 
  cursor: pointer;
}

.as-field-group {
    padding: 24px 16px 32px;
    margin-bottom: 32px;
    background: @grey-1;

    &:last-child {
      margin-bottom: 0; 
    }
}

.as-field-wrapper {
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0px;
    }
}

.as-field-label {
    display: block;
    margin-bottom: 8px;
    font-weight: normal;
}

.as-field-title {
    margin-bottom: 8px;
}


.as-field-option {
    display: block;
    float: right;
    color: @x-light-text-color;
    font-size: 0.8em;
    line-height: 24px;
}

.as-field-description {
    display: block;
    font-size: 0.8em;
    line-height: 24px;
}

.as-field-required {
    display: block;
    float: right;
    color: @warning-color;
    font-size: 0.8em;
    line-height: 24px;
}

.as-field-container {
    width: 100%;
    margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
}


.as-field-container {

  & .form-control {
    min-height: 48px;
    padding: 0 16px;
    border: solid 1px @border-color;
    border-radius: 2px;
    font-size: 1.2em;
  }

  & .form-input-field {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.as-field-container--currency {
  width: 120px
}

.as-invert-field-positions .as-field-container {
    margin-right: 10px;

  &:first-child {
    margin-right: 0;
  }
}

.as-input-field {
    width: 100%;
    min-height: 48px;
    box-sizing: border-box;
    padding: 0 10px;
    border: solid 1px @border-color;
    border-radius: 2px;
    font-size: 1.2em;
}

.as-select-field {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: solid 1px @border-color;
    cursor: pointer;
    background-color: @white;
    background-image: url('../images/widgets/select-arrow.svg');
    background-repeat: no-repeat;
    background-size: auto 6px;
    background-position: right 10px center;

    &::-ms-expand {
      display: none;
    }
}


::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: @grey-6;
}

:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: @grey-6;
    opacity: 1;
}

::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: @grey-6;
    opacity: 1;
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: @grey-6;
}


.is-field-error {
  border: solid 1px @warning-color;
}
.as-field-error {
  margin-top: 8px;
  color: @warning-color;
}

.as-field-group-title {
    margin-top: 0;
    margin-bottom: 16px;
    color: @text-color;
    font-weight: normal;
    font-size: 1.2em;
}


/* Machine Information */
.as-form-info {
  padding: 24px 16px;
  background: @grey-2;
}
.as-form-info-row {
  margin-bottom: 10px;
}

/* Textarea */
.textarea-field {
    height: 90px;
    resize: none;
    padding-top: 5px;
    line-height: 1.5;
}

.textarea-field-long {
    height: 150px;
}



@media(max-width: @screen-xs-max) {
  .as-field-group {
    margin-left: -10px;
    margin-right: -10px;
  }
  .as-form-info {
    margin-left: -10px;
    margin-right: -10px;
  }
}
@media(min-width: @screen-sm-min) {
  .as-field-group {
      padding: 32px;
      margin-bottom: 64px;
  }
  .as-field-group-title {
      margin-bottom: 32px;
      font-size: 1.4em;
  }

  .as-form-info {
    padding: 32px;
  }
}

/* deprecated: @screen-tablet, or @screen-sm */
@media(min-width: @screen-md-min) {}
/* deprecated: @screen-desktop, or @screen-md */
@media(min-width: @screen-lg-min) {}
/* deprecated: @screen-lg-desktop, or @screen-lg */



////////////////////////
// User Registration Form
////////////////////////

.as-horizontal_fields {
    display: flex;
}
/* Swapping the order */
.as-invert-field-positions {
    flex-direction: row-reverse;
}
.as-field-hint {
  margin-top: 5px;
  font-size: 0.8em;
  color: @light-text-color;
}
.as-field-hint:before {
  content: "*";
}

.checkbox-form {
  height: 24px;
  width: 24px;
}

.footer-note {
  margin-top: 32px;
  margin-bottom: 32px;
  text-align: center;
  color: @light-text-color;
}



////////////////
// Drag and Drop
////////////////

.as-drag-and-drop-space {
  // max-height: 600px;
  width: 100%;
  padding-top: 64px;
  padding-bottom: 32px;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 32px;
  border: dashed 2px @border-color;
  border-radius: 2px;
  background-color: @grey-1;
  font-size: 1.2em;
  text-align: center;
  cursor: pointer;
  white-space: normal;
  line-height: 1.5em;
}

.as-drag-and-drop-space:hover {
  background-color: @grey-2;
}

.as-wizard-header {
  text-align: center;
}

.as-input-display-none {
  display: none;
}

.as-photo-upload-warning {
  font-size: 1.5em;
}

.as-photo-upload-error-message {
  color: @warning-color;
  word-wrap: break-word;
}


////////////////
// Photo Uploader
////////////////


.as-photo-upload {
  position: relative;
  margin-bottom: 10px;
}

.as-photo-upload-img {
 
  width: 100%;
  box-sizing: border-box;
}

.as-photo-upload-img-overlay {
  position: absolute;
  top: auto;
  bottom: 0;
  left: 10px;
  right: 10px;
  height: inherit;
  padding: 10px;
  background: rgba(255, 255, 255, 0.8);
}

.as-photo-upload-img:hover   {
  width: 100%;
}

.as-photo-upload-img-overlay-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.as-select-file__text {
  color: @link-color;
}

.as-select-file__text:hover {
  text-decoration: underline;
}

.as-set-to-top__text {
  color: @link-color;
}
.as-set-to-top__text:hover {
  text-decoration: underline;
}




.as-seller-list-image-wrapper {
    float: left;
    margin-left: 4px;
    margin-right: 8px;
}
/* Trimming Photos */
.as-seller-list-image {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    width: 96px;
    /* trimming width */
    height: 96px;
    /* trimming height */
    img {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: auto;
        height: 100%;
        z-index: 1;
    }

    &:hover {
        text-decoration: none;
    }
}