// Notify
// Contains Overrides for PNotify.

// Notice style
// for auction activity on event page
.as-notify {
  .ui-pnotify {
    top: 0;
    right: initial;
  }
  .ui-pnotify-container {
    padding: 6px 15px 5px;
    background-color: @as-notify-background-color;

    // For ellipse contents
    .display-flex;
    .align-items(center);
  }
  .ui-pnotify-title {
    .label;
    .label-default;
    display: inline-block;
    vertical-align: middle;
    margin-top: 0;
    margin-right: 10px;
    margin-bottom: 0;
    font-size: @font-size-h6;
    font-weight: normal;

  }
  .ui-pnotify-text {
    display: inline-block;
    vertical-align: baseline;;
    font-size: @font-size-h6;
    color: @as-notify-color;

    a {
      color: inherit;
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;

      &.focus,
      &:focus,
      &:hover {
        color: lighten(@as-notify-color, 10%);;
      }
    }

    // For ellipse contents
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.as-notify__item {
}

.as-notify__date {
  &:after {
    content: ":";
  }
}

.as-notify__image {
  display: inline-block;
  vertical-align:  middle;
  width: 2em;
  margin-right: 5px;
  margin-left: 10px;
}

.as-notify__item-title {
}


// Alternative

.as-notify__item--bid {
}
.as-notify__item--win {
}
.as-notify__item--buyout {
}


// Responsive

@media (min-width: @screen-sm-min) {
  .as-notify {
    .ui-pnotify-container {
      padding: 10px 15px;
    }
    .ui-pnotify-title {
      font-size: @font-size-h5;
    }
  }
  .as-notify__image {
    width: 20px;
  }
}
