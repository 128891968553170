// Goods
// A block contents of one machine info.
// mainly for carousel widget

@as-goods-image-max-width: 150px;
@as-goods-image-max-height: 112px;
@as-goods-image-max-height-xs: 90px;

.as-goods {
  .as-col--no-gutter;

  &:focus > .row:nth-child(n+2),
  &:hover > .row:nth-child(n+2) {
    background-color: @as-brand-hover;
  }

  > .row {
    margin-left: 10px;
    margin-right: 10px;

    @media (max-width: @screen-xs-max) {
      margin-left:  5px;
      margin-right: 5px;
    }

    @media (min-width: @screen-sm-min) {
      // width: @as-goods-image-max-width * 0.785;  //88px
    }

    @media (min-width: @screen-md-min) {
      // width: @as-goods-image-max-width * 0.883;  //99px
    }

    @media (min-width: @screen-lg-min) {
      // width: @as-goods-image-max-width;  //112px
    }
  }

  > .row > div[class*="col-"] {
    .as-col--no-gutter;
  }
}

.as-goods__image {
  background-color: @as-image-background-color;
  .display-flex;
  .flex-flow(column,nowrap);
  .justify-content(center);
  .align-items(center);
}

.as-goods__image-wrapper {
  width: 100%;

  > a {
    .as-responsible-image-frame(4,3);
  }
}

.as-goods__unit-number {
  margin-bottom: -3px;
  font-size: @font-size-h6;
}

.as-goods__title {
  display: inline-block;
  // min-height: 4.5em;  //3 rows
  line-height: 1.4em;
  word-break: break-all;
  overflow-wrap : break-all;
  margin-top: 3px;
  margin-bottom: 3px;
}

.as-goods__price {
  .text-price;
  font-weight: bold;
  line-height: 1.3em;
  margin-bottom: 3px;
  min-height: 1.3em;
}

.as-goods__other-features {
  font-size: @font-size-h6 * 0.9;

  ul {
    margin:0;
    padding: 0;
    .as-clearfix;
    list-style: none;

    & > li {
      float:left;
      margin-right: 10px;
      padding: 0;

      //Year
      &:nth-child(1) .as-goods__other-features-value {
        min-width:  3em;
      }
    }
  }
  dl {
    margin-bottom: 0;
  }
  dl, dt, dd {
    white-space: nowrap;
  }
}

.as-goods__other-features-head {
  display: inline-block;
  vertical-align: middle;

  .as-icon--year,
  .as-icon--hours {
    bottom: 0.15em;
  }
}

.as-goods__other-features-value {
  display: inline-block;
  vertical-align: middle;
}


@media (max-width: @screen-xs-max) {
  .as-goods {
    font-size: @font-size-h5;
    .flex(0, 1, 100% / 2);

    &:nth-child(n+3) {
      padding-top: 15px;
    }
  }
  .as-goods__other-features {
    font-size: @font-size-h5 * 0.9;
    // margin-bottom: 15px;
    margin-bottom: 5px;
  }
}

@media (min-width: @screen-sm-min) {
  .as-goods {
    font-size: @font-size-h6;
  }
  .as-goods__other-features {
    font-size: @font-size-h6 * 0.9;
  }
}
