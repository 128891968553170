// Frame
//
// Styles for site framework

// Structure
.as-frame {
  min-height: 100%;
  .as-header {
    background-color: @as-brand-background;
  }
  .as-contents-body {
    background-color: @as-brand-background;
    .as-right-side-panel {
    }
    .as-main-contents {
      .as-main-contents-header {
        [class*="col-"] {
          min-height: 0;
        }
      }
      .as-main-contents-body {
        padding-bottom: 20px;
      }
    }
  }
  .as-footer {
  }
}

// Alternatives

// Basic page style
.as-main-contents-header--page {
  // Strech background width
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-left: @grid-gutter-width / 2;
  padding-right: @grid-gutter-width / 2;
  padding-bottom: 30px;
  > .row > [class*="col-"] {
    .container;
    margin-right: auto;
    margin-left: auto;
    float: none;
  }

  @media (min-width:@screen-sm-min) {
    padding-top: 20px;
  }

}

.as-main-contents-body--page {
}
.as-main-contents-body--page__lead {
  margin-bottom: 40px;
}

// Elements
@as-main-contents-header-title-height: 100px;
.as-main-contents-header__title {
  color: @as-brand-emphasis;
  font-weight: bold;
  .horizontal-gradient(@as-brand-key-gradient-start, @as-brand-key-gradient-end);

  h1, h2 {
    display: table-cell;
    vertical-align: middle;
    height: @as-main-contents-header-title-height;
    line-height: 1.3;
  }

  @media (max-width:@screen-xs-max) {
    h1, h2 {
    height: @as-main-contents-header-title-height * 0.7;  // 70px
    }
  }

}
