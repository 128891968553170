// Address

.as-address__tel,
.as-groups-auction-confirm-info__email {
  font-size: @font-size-h4;
  font-weight: bold;
}


.as-address__tel {
  display: inline-block;
  background-color: #22b11d;
  margin-bottom: 0;
  padding: 5px 15px;
  border-radius: 2em;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  letter-spacing: .035em;

  a {
    font-size: 1.1em;
    color: #fff;
    text-decoration: none;
  }
}


.as-groups-auction-confirm-info__email {
}
