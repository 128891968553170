//@import '../sitewide.less';

.common-assessment-request {
    /* 
    TYPE VARIABLES
    */
    //@font-size-1: 44px;
    //@line-height-1: 56px;

    @font-size-2: 32px;
    @line-height-2: 30px; 

    @font-size-3: 22px;
    //@line-height-3: 28px; 

    @font-size-4: 20px;
    //@line-height-4: 24px; 

    @font-size-5: 16px;
    //@line-height-5: 22px;  

    @font-size-6: 14px; 
    //@line-height-6: 18px;

    @font-size-7: 12px;

    /*
    COLOR VARIABLES
    */
    @brand-color:          	               #FFC011;
    @brand-border-color:          	       #D6A922;
    @sub-color:           	               #1F50BC;
    @accent-color:        	               #EB2A2A;

    /*
    GREYSCALE
    */

    // Background
    @white:			                       #ffffff; //e.g. Standard background
    @grey-1: 		                       #f2f2f2;	//e.g. Header background, Form background
    @grey-2: 		                       #eaeaea; //e.g. Background
    @grey-3:		                       #dedede;	//e.g. line, Footer background
    @grey-4:		                       #cecece;	//e.g. border
    @grey-5:		                       #afafaf;	//e.g. text, line, icon, Ghost button
    @grey-6:		                       #838383;	//e.g. Main text
    @grey-7:		                       #565656;	//e.g. Main text

    // Shadow
    @shadow-1: 		                     rgba(0,0,0,0.3);//e.g. checkbox shadow

    /*
    BUTTON COLORS
    */
    // Button Color gets darker on hover state.
    @brand-hover-color:                      darken(@brand-color, 8%);
    @sub-hover-color:                      darken(@sub-color, 8%);
    @white-hover-color:                      darken(@white, 8%);


    /*text color*/
    color: @grey-7;

    /*form*/
    .form-group{
        .form-group-header,
        .form-group-explaination{
            font-size: @font-size-7;
            margin-bottom: 14px;
        }
        .form-group-body-element{
            margin-bottom: 14px;
        }
    }
    .form-control{
        border: 1px solid @grey-3;
        box-shadow: none;
    }

    .action-panel{
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
    }

    /*
      checkbox
    */
    .panel-selectable{
        margin-bottom: 10px;
        .panel-heading{
            color: @grey-7;
            .control-label{
                padding: 0;
            }
            .panel-checkbox {
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                margin-top: auto;
                margin-bottom: auto;
                display: block;
                width: 30px;
                height: 30px;
                background-color: @grey-1;
                border: 1px solid @grey-4;
                border-radius: 3px;
                box-shadow: 0 3px 6px @shadow-1;
            }
            .panel-title{
                padding: 7px 0 7px 38px;
                font-size: @font-size-7;
                color: @grey-7;
            }
            input[type="checkbox"]{
                display: none;
                &:checked ~ .panel-checkbox:after {
                    content: "";
                    position: absolute;
                    top: 9px;
                    left: 5px;
                    display: block;
                    width: 18px;
                    height: 11px;
                    background-image: url(../images/icon-check-neo.svg);
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    background-position: 0 0;
                }
            }
            .attention{
                color: @accent-color;
                font-weight: bold;
            }
        }
        > .panel-heading{
            background-color: transparent;
          }
    }

    /*button*/
    .btn{
        width: 100%;
        display: inline-block;
        min-height: 46px;
        line-height: @line-height-2;
        box-shadow: none;
        border-radius: 3px;
        font-size: @font-size-5;
        &.btn-appeal{
            background-color: @sub-color;
            color: @white;
            width: auto;
            min-width: 160px;
            &:hover,
            &:active{
                background-color: @sub-hover-color;
            }
        }
    }

    .scope-assessment-request{
        .assessment-request-request-title{
            font-size: @font-size-5;
            text-align: center;
            font-weight: bold;
            border-bottom: 1px solid @grey-5;
        }
    }

    .assessment-request-submit-button{
        text-align: center;
    }

}