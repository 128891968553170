// Inquiry Input Page

.as-groups-inquiry-input {

  .as-wizard {
    margin-top: 0;
    padding-top: 0;
    .as-col--no-gutter;
    text-align: left;

    .as-wizard__footer-button {
      margin-top: 40px;
      margin-bottom: 60px;
    }

  }

}
.as-groups-inquiry-input__title {
}
.as-groups-inquiry-input__steps {

}
.as-groups-inquiry-input__machine-info {
  margin-bottom: 30px;
  .panel {
    .panel-title {
      font-size: @font-size-h5;
    }
    .panel-body {
      background-color: transparent;
      border: 1px solid @as-area-border-color;
    }
  }
}
.as-groups-inquiry-input__machine-info-image {
  padding-right: 0;
  padding-left: 0;

  .as-goods__image {
    display: block;
  }

}
.as-groups-inquiry-input__machine-info-features {
  padding-right: 0;

  .as-goods__unit-number {
    font-size: @font-size-h5;
    font-weight: bold;
  }
  .as-goods__title {
    font-size: @font-size-h3;
    font-weight: bold;
  }
  .as-goods__other-features {
    // font-size: @font-size-h6;
  }
  .as-goods__price {
    font-size: @font-size-h3;
    text-align: right;
  }

}
.as-groups-inquiry-input__inquiry-forms,
.as-groups-inquiry-input__customer-info-forms {
  .as-heading {
    margin-bottom: 10px;
  }
}
.as-groups-inquiry-input__inquiry-forms {
  margin-bottom: 30px;
}
.as-groups-inquiry-input__inquiry-form,
.as-groups-inquiry-input__customer-info-form {

  .panel-selectable {
    margin-bottom: 5px;

    .panel-heading {
      .panel-title {
        font-size: @font-size-h5;
      }
    }

  }

  & .form-horizontal .control-label {
    text-align: left;
  }
}
.as-groups-inquiry-input__inquiry-form {
  // For each items
  .as-groups-inquiry-input__asking-price {
    .display-flex;
    .align-items(center);
    font-weight: bold;

    input {
      text-align: right;
    }
    .text-currency {
      margin-left: 5px;
      color: @as-text-description-color;
      font-size: @font-size-h4;
    }
  }
  // For static inquiry
  .panel.panel-widget {
    .panel-title {
      font-size: @font-size-h5;
    }
    .panel-body {
      background-color: @as-submenu-background-color;
      .form-group:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.as-groups-inquiry-input__customer-info-form {
  .panel {
    .panel-body {
      padding-top: 5px;
    }
  }

  // For each items
  .as-groups-inquiry-input__name {
    &.form-inline .form-control {
      width: 48%;
      display: inline;
    }
  }
}
.as-groups-inquiry-input__option-forms {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  font-size: @font-size-h4;

  .panel {
    .panel-body {
      background-color: transparent;
      border: 1px dotted @as-area-border-color;
      border-right: none;
      border-left: none;
      padding: 10px 0;
    }
  }
}
.as-groups-inquiry-input__option-form {
}
// Responsive
@media (max-width: @screen-xs-max) {
  .as-wizard__footer-button {
    .btn {
      width: 100%;
    }
  }
}

@media (min-width: @screen-sm-min) {

  .as-groups-inquiry-input__inquiry-forms,
  .as-groups-inquiry-input__customer-info-forms {
    // Fix font size
    .panel-selectable {
      .panel-heading {
        .panel-title {
          font-size: @font-size-h5;
        }
      }
    }

    // For each items
    .as-groups-inquiry-input__asking-price {
      margin-top: -.2em;
    }

  }

}
