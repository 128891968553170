// Search Header

.as-search-header {
  margin-bottom: 20px;
}

.as-search-header__container {
  padding: 10px 15px;
  // background-color: lighten(@as-brand-base, 8%);  //40% gary, #666
  background-color: @as-widget-background-color;     //#f2f2f2
  border: 1px solid @as-area-border-color;

  .as-keyword-input.input-lg {
    font-size: @font-size-h4;
    line-height: 1.5;
  }

  .as-keyword-submit.btn-lg {
    font-size: @font-size-h4;
    line-height: 1.5;
    min-width: 80px;
  }
}

@media (max-width:@screen-xs-max) {
  .as-search-header {
    margin-bottom: 20px;

    & > .row > *[class*="col-"] {
      .as-col--no-gutter;
    }
  }

  .as-search-header__container {
    padding: @as-grid-gutter-width / 2;
    border: none;

    .as-keyword-submit.btn-lg {
      min-width: 50px;
    }
  }
}

// 2 types of Search Tab
.as-search-tab{
  margin-bottom: 5px;
  ul{
    li{
      font-weight: bold;
    }
    p{
      font-size: @font-size-h3;
      position: absolute;
      bottom: 0;
      right: 10px;
    }
  }
  h5{
    background: @as-brand-base;
    color: #FFF;
    text-align: center;
    margin-top: 0px;
    padding-bottom: 5px;

  }
}
@media (max-width: @screen-xs-max) {
  html:lang(en) &,
  html:lang(vi) &{
    .as-search-tab ul li{
      font-size:0.9em;
    }
  }
}
@media (max-width: @screen-xs-max) {
  html:lang(th) &{
    .as-search-tab ul li{
      font-size:0.8em;
    }
  }
}

// Search Form
.as-search-bar {
  text-align: left;
  margin-top: 5px;
  margin-bottom: 10px;
  input{
    padding:0px 10px;
  }
  .as-keyword-submit{
    padding:5px 18px;
  }
}
